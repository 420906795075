const areaData = [
  {
    name: 'Afghanistan',
    iso3: 'AFG',
    iso2: 'AF',
    phone_code: '93',
    capital: 'Kabul',
    currency: 'AFN',
    states: [
      {
        id: 3901,
        name: 'Badakhshan',
        state_code: 'BDS',
      },
      {
        id: 3871,
        name: 'Badghis',
        state_code: 'BDG',
      },
      {
        id: 3875,
        name: 'Baghlan',
        state_code: 'BGL',
      },
      {
        id: 3884,
        name: 'Balkh',
        state_code: 'BAL',
      },
      {
        id: 3872,
        name: 'Bamyan',
        state_code: 'BAM',
      },
      {
        id: 3892,
        name: 'Daykundi',
        state_code: 'DAY',
      },
      {
        id: 3899,
        name: 'Farah',
        state_code: 'FRA',
      },
      {
        id: 3889,
        name: 'Faryab',
        state_code: 'FYB',
      },
      {
        id: 3870,
        name: 'Ghazni',
        state_code: 'GHA',
      },
      {
        id: 3888,
        name: 'Ghōr',
        state_code: 'GHO',
      },
      {
        id: 3873,
        name: 'Helmand',
        state_code: 'HEL',
      },
      {
        id: 3887,
        name: 'Herat',
        state_code: 'HER',
      },
      {
        id: 3886,
        name: 'Jowzjan',
        state_code: 'JOW',
      },
      {
        id: 3902,
        name: 'Kabul',
        state_code: 'KAB',
      },
      {
        id: 3890,
        name: 'Kandahar',
        state_code: 'KAN',
      },
      {
        id: 3879,
        name: 'Kapisa',
        state_code: 'KAP',
      },
      {
        id: 3878,
        name: 'Khost',
        state_code: 'KHO',
      },
      {
        id: 3876,
        name: 'Kunar',
        state_code: 'KNR',
      },
      {
        id: 3900,
        name: 'Kunduz Province',
        state_code: 'KDZ',
      },
      {
        id: 3891,
        name: 'Laghman',
        state_code: 'LAG',
      },
      {
        id: 3897,
        name: 'Logar',
        state_code: 'LOG',
      },
      {
        id: 3882,
        name: 'Nangarhar',
        state_code: 'NAN',
      },
      {
        id: 3896,
        name: 'Nimruz',
        state_code: 'NIM',
      },
      {
        id: 3880,
        name: 'Nuristan',
        state_code: 'NUR',
      },
      {
        id: 3894,
        name: 'Paktia',
        state_code: 'PIA',
      },
      {
        id: 3877,
        name: 'Paktika',
        state_code: 'PKA',
      },
      {
        id: 3881,
        name: 'Panjshir',
        state_code: 'PAN',
      },
      {
        id: 3895,
        name: 'Parwan',
        state_code: 'PAR',
      },
      {
        id: 3883,
        name: 'Samangan',
        state_code: 'SAM',
      },
      {
        id: 3885,
        name: 'Sar-e Pol',
        state_code: 'SAR',
      },
      {
        id: 3893,
        name: 'Takhar',
        state_code: 'TAK',
      },
      {
        id: 3898,
        name: 'Urozgan',
        state_code: 'URU',
      },
      {
        id: 3874,
        name: 'Zabul',
        state_code: 'ZAB',
      },
    ],
  },
  {
    name: 'Aland Islands',
    iso3: 'ALA',
    iso2: 'AX',
    phone_code: '+358-18',
    capital: 'Mariehamn',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Albania',
    iso3: 'ALB',
    iso2: 'AL',
    phone_code: '355',
    capital: 'Tirana',
    currency: 'ALL',
    states: [
      {
        id: 603,
        name: 'Berat County',
        state_code: '01',
      },
      {
        id: 629,
        name: 'Berat District',
        state_code: 'BR',
      },
      {
        id: 607,
        name: 'Bulqizë District',
        state_code: 'BU',
      },
      {
        id: 618,
        name: 'Delvinë District',
        state_code: 'DL',
      },
      {
        id: 608,
        name: 'Devoll District',
        state_code: 'DV',
      },
      {
        id: 610,
        name: 'Dibër County',
        state_code: '09',
      },
      {
        id: 605,
        name: 'Dibër District',
        state_code: 'DI',
      },
      {
        id: 632,
        name: 'Durrës County',
        state_code: '02',
      },
      {
        id: 639,
        name: 'Durrës District',
        state_code: 'DR',
      },
      {
        id: 598,
        name: 'Elbasan County',
        state_code: '03',
      },
      {
        id: 631,
        name: 'Fier County',
        state_code: '04',
      },
      {
        id: 627,
        name: 'Fier District',
        state_code: 'FR',
      },
      {
        id: 604,
        name: 'Gjirokastër County',
        state_code: '05',
      },
      {
        id: 621,
        name: 'Gjirokastër District',
        state_code: 'GJ',
      },
      {
        id: 617,
        name: 'Gramsh District',
        state_code: 'GR',
      },
      {
        id: 600,
        name: 'Has District',
        state_code: 'HA',
      },
      {
        id: 594,
        name: 'Kavajë District',
        state_code: 'KA',
      },
      {
        id: 628,
        name: 'Kolonjë District',
        state_code: 'ER',
      },
      {
        id: 630,
        name: 'Korçë County',
        state_code: '06',
      },
      {
        id: 597,
        name: 'Korçë District',
        state_code: 'KO',
      },
      {
        id: 614,
        name: 'Krujë District',
        state_code: 'KR',
      },
      {
        id: 612,
        name: 'Kuçovë District',
        state_code: 'KC',
      },
      {
        id: 601,
        name: 'Kukës County',
        state_code: '07',
      },
      {
        id: 623,
        name: 'Kukës District',
        state_code: 'KU',
      },
      {
        id: 622,
        name: 'Kurbin District',
        state_code: 'KB',
      },
      {
        id: 609,
        name: 'Lezhë County',
        state_code: '08',
      },
      {
        id: 595,
        name: 'Lezhë District',
        state_code: 'LE',
      },
      {
        id: 596,
        name: 'Librazhd District',
        state_code: 'LB',
      },
      {
        id: 599,
        name: 'Lushnjë District',
        state_code: 'LU',
      },
      {
        id: 602,
        name: 'Malësi e Madhe District',
        state_code: 'MM',
      },
      {
        id: 637,
        name: 'Mallakastër District',
        state_code: 'MK',
      },
      {
        id: 635,
        name: 'Mat District',
        state_code: 'MT',
      },
      {
        id: 638,
        name: 'Mirditë District',
        state_code: 'MR',
      },
      {
        id: 619,
        name: 'Peqin District',
        state_code: 'PQ',
      },
      {
        id: 625,
        name: 'Përmet District',
        state_code: 'PR',
      },
      {
        id: 606,
        name: 'Pogradec District',
        state_code: 'PG',
      },
      {
        id: 620,
        name: 'Pukë District',
        state_code: 'PU',
      },
      {
        id: 624,
        name: 'Sarandë District',
        state_code: 'SR',
      },
      {
        id: 611,
        name: 'Shkodër County',
        state_code: '10',
      },
      {
        id: 626,
        name: 'Shkodër District',
        state_code: 'SH',
      },
      {
        id: 593,
        name: 'Skrapar District',
        state_code: 'SK',
      },
      {
        id: 616,
        name: 'Tepelenë District',
        state_code: 'TE',
      },
      {
        id: 615,
        name: 'Tirana County',
        state_code: '11',
      },
      {
        id: 633,
        name: 'Tirana District',
        state_code: 'TR',
      },
      {
        id: 636,
        name: 'Tropojë District',
        state_code: 'TP',
      },
      {
        id: 634,
        name: 'Vlorë County',
        state_code: '12',
      },
      {
        id: 613,
        name: 'Vlorë District',
        state_code: 'VL',
      },
    ],
  },
  {
    name: 'Algeria',
    iso3: 'DZA',
    iso2: 'DZ',
    phone_code: '213',
    capital: 'Algiers',
    currency: 'DZD',
    states: [
      {
        id: 1118,
        name: 'Adrar Province',
        state_code: '01',
      },
      {
        id: 1119,
        name: 'Aïn Defla Province',
        state_code: '44',
      },
      {
        id: 1122,
        name: 'Aïn Témouchent Province',
        state_code: '46',
      },
      {
        id: 1144,
        name: 'Algiers Province',
        state_code: '16',
      },
      {
        id: 1103,
        name: 'Annaba Province',
        state_code: '23',
      },
      {
        id: 1142,
        name: 'Batna Province',
        state_code: '05',
      },
      {
        id: 1108,
        name: 'Béchar Province',
        state_code: '08',
      },
      {
        id: 1128,
        name: 'Béjaïa Province',
        state_code: '06',
      },
      {
        id: 1114,
        name: 'Biskra',
        state_code: '07',
      },
      {
        id: 1111,
        name: 'Blida Province',
        state_code: '09',
      },
      {
        id: 1116,
        name: 'Bordj Bou Arréridj Province',
        state_code: '34',
      },
      {
        id: 1104,
        name: 'Bouïra Province',
        state_code: '10',
      },
      {
        id: 1125,
        name: 'Boumerdès Province',
        state_code: '35',
      },
      {
        id: 1105,
        name: 'Chlef Province',
        state_code: '02',
      },
      {
        id: 1121,
        name: 'Constantine Province',
        state_code: '25',
      },
      {
        id: 1098,
        name: 'Djelfa Province',
        state_code: '17',
      },
      {
        id: 1129,
        name: 'El Bayadh Province',
        state_code: '32',
      },
      {
        id: 1099,
        name: 'El Oued Province',
        state_code: '39',
      },
      {
        id: 1100,
        name: 'El Tarf Province',
        state_code: '36',
      },
      {
        id: 1127,
        name: 'Ghardaïa Province',
        state_code: '47',
      },
      {
        id: 1137,
        name: 'Guelma Province',
        state_code: '24',
      },
      {
        id: 1112,
        name: 'Illizi Province',
        state_code: '33',
      },
      {
        id: 1113,
        name: 'Jijel Province',
        state_code: '18',
      },
      {
        id: 1126,
        name: 'Khenchela Province',
        state_code: '40',
      },
      {
        id: 1138,
        name: 'Laghouat Province',
        state_code: '03',
      },
      {
        id: 1134,
        name: "M'Sila Province",
        state_code: '28',
      },
      {
        id: 1124,
        name: 'Mascara Province',
        state_code: '29',
      },
      {
        id: 1109,
        name: 'Médéa Province',
        state_code: '26',
      },
      {
        id: 1132,
        name: 'Mila Province',
        state_code: '43',
      },
      {
        id: 1140,
        name: 'Mostaganem Province',
        state_code: '27',
      },
      {
        id: 1102,
        name: 'Naama Province',
        state_code: '45',
      },
      {
        id: 1101,
        name: 'Oran Province',
        state_code: '31',
      },
      {
        id: 1139,
        name: 'Ouargla Province',
        state_code: '30',
      },
      {
        id: 1136,
        name: 'Oum El Bouaghi Province',
        state_code: '04',
      },
      {
        id: 1130,
        name: 'Relizane Province',
        state_code: '48',
      },
      {
        id: 1123,
        name: 'Saïda Province',
        state_code: '20',
      },
      {
        id: 1141,
        name: 'Sétif Province',
        state_code: '19',
      },
      {
        id: 1110,
        name: 'Skikda Province',
        state_code: '21',
      },
      {
        id: 1143,
        name: 'Souk Ahras Province',
        state_code: '41',
      },
      {
        id: 1135,
        name: 'Tamanghasset Province',
        state_code: '11',
      },
      {
        id: 1117,
        name: 'Tébessa Province',
        state_code: '12',
      },
      {
        id: 1106,
        name: 'Tiaret Province',
        state_code: '14',
      },
      {
        id: 1120,
        name: 'Tindouf Province',
        state_code: '37',
      },
      {
        id: 1115,
        name: 'Tipasa Province',
        state_code: '42',
      },
      {
        id: 1133,
        name: 'Tissemsilt Province',
        state_code: '38',
      },
      {
        id: 1131,
        name: 'Tizi Ouzou Province',
        state_code: '15',
      },
      {
        id: 1107,
        name: 'Tlemcen Province',
        state_code: '13',
      },
    ],
  },
  {
    name: 'American Samoa',
    iso3: 'ASM',
    iso2: 'AS',
    phone_code: '+1-684',
    capital: 'Pago Pago',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Andorra',
    iso3: 'AND',
    iso2: 'AD',
    phone_code: '376',
    capital: 'Andorra la Vella',
    currency: 'EUR',
    states: [
      {
        id: 488,
        name: 'Andorra la Vella',
        state_code: '07',
      },
      {
        id: 489,
        name: 'Canillo',
        state_code: '02',
      },
      {
        id: 487,
        name: 'Encamp',
        state_code: '03',
      },
      {
        id: 492,
        name: 'Escaldes-Engordany',
        state_code: '08',
      },
      {
        id: 493,
        name: 'La Massana',
        state_code: '04',
      },
      {
        id: 491,
        name: 'Ordino',
        state_code: '05',
      },
      {
        id: 490,
        name: 'Sant Julià de Lòria',
        state_code: '06',
      },
    ],
  },
  {
    name: 'Angola',
    iso3: 'AGO',
    iso2: 'AO',
    phone_code: '244',
    capital: 'Luanda',
    currency: 'AOA',
    states: [
      {
        id: 221,
        name: 'Bengo Province',
        state_code: 'BGO',
      },
      {
        id: 218,
        name: 'Benguela Province',
        state_code: 'BGU',
      },
      {
        id: 212,
        name: 'Bié Province',
        state_code: 'BIE',
      },
      {
        id: 228,
        name: 'Cabinda Province',
        state_code: 'CAB',
      },
      {
        id: 226,
        name: 'Cuando Cubango Province',
        state_code: 'CCU',
      },
      {
        id: 217,
        name: 'Cuanza Norte Province',
        state_code: 'CNO',
      },
      {
        id: 216,
        name: 'Cuanza Sul',
        state_code: 'CUS',
      },
      {
        id: 215,
        name: 'Cunene Province',
        state_code: 'CNN',
      },
      {
        id: 213,
        name: 'Huambo Province',
        state_code: 'HUA',
      },
      {
        id: 225,
        name: 'Huíla Province',
        state_code: 'HUI',
      },
      {
        id: 222,
        name: 'Luanda Province',
        state_code: 'LUA',
      },
      {
        id: 223,
        name: 'Lunda Norte Province',
        state_code: 'LNO',
      },
      {
        id: 220,
        name: 'Lunda Sul Province',
        state_code: 'LSU',
      },
      {
        id: 227,
        name: 'Malanje Province',
        state_code: 'MAL',
      },
      {
        id: 219,
        name: 'Moxico Province',
        state_code: 'MOX',
      },
      {
        id: 224,
        name: 'Uíge Province',
        state_code: 'UIG',
      },
      {
        id: 214,
        name: 'Zaire Province',
        state_code: 'ZAI',
      },
    ],
  },
  {
    name: 'Anguilla',
    iso3: 'AIA',
    iso2: 'AI',
    phone_code: '+1-264',
    capital: 'The Valley',
    currency: 'XCD',
    states: [],
  },
  {
    name: 'Antarctica',
    iso3: 'ATA',
    iso2: 'AQ',
    phone_code: '',
    capital: '',
    currency: '',
    states: [],
  },
  {
    name: 'Antigua And Barbuda',
    iso3: 'ATG',
    iso2: 'AG',
    phone_code: '+1-268',
    capital: "St. John's",
    currency: 'XCD',
    states: [
      {
        id: 3708,
        name: 'Barbuda',
        state_code: '10',
      },
      {
        id: 3703,
        name: 'Redonda',
        state_code: '11',
      },
      {
        id: 3709,
        name: 'Saint George Parish',
        state_code: '03',
      },
      {
        id: 3706,
        name: 'Saint John Parish',
        state_code: '04',
      },
      {
        id: 3707,
        name: 'Saint Mary Parish',
        state_code: '05',
      },
      {
        id: 3705,
        name: 'Saint Paul Parish',
        state_code: '06',
      },
      {
        id: 3704,
        name: 'Saint Peter Parish',
        state_code: '07',
      },
      {
        id: 3710,
        name: 'Saint Philip Parish',
        state_code: '08',
      },
    ],
  },
  {
    name: 'Argentina',
    iso3: 'ARG',
    iso2: 'AR',
    phone_code: '54',
    capital: 'Buenos Aires',
    currency: 'ARS',
    states: [
      {
        id: 3656,
        name: 'Buenos Aires',
        state_code: 'C',
      },
      {
        id: 3647,
        name: 'Catamarca Province',
        state_code: 'K',
      },
      {
        id: 3640,
        name: 'Chaco Province',
        state_code: 'H',
      },
      {
        id: 3651,
        name: 'Chubut Province',
        state_code: 'U',
      },
      {
        id: 3642,
        name: 'Córdoba Province',
        state_code: 'X',
      },
      {
        id: 3638,
        name: 'Corrientes',
        state_code: 'W',
      },
      {
        id: 3654,
        name: 'Entre Ríos Province',
        state_code: 'E',
      },
      {
        id: 3652,
        name: 'Formosa Province',
        state_code: 'P',
      },
      {
        id: 3645,
        name: 'Jujuy Province',
        state_code: 'Y',
      },
      {
        id: 3655,
        name: 'La Pampa',
        state_code: 'L',
      },
      {
        id: 3653,
        name: 'La Rioja Province',
        state_code: 'F',
      },
      {
        id: 3646,
        name: 'Mendoza',
        state_code: 'M',
      },
      {
        id: 3644,
        name: 'Misiones Province',
        state_code: 'N',
      },
      {
        id: 3648,
        name: 'Neuquén Province',
        state_code: 'Q',
      },
      {
        id: 3639,
        name: 'Río Negro Province',
        state_code: 'R',
      },
      {
        id: 3643,
        name: 'Salta Province',
        state_code: 'A',
      },
      {
        id: 3634,
        name: 'San Juan Province',
        state_code: 'J',
      },
      {
        id: 3636,
        name: 'San Luis Province',
        state_code: 'D',
      },
      {
        id: 3649,
        name: 'Santa Cruz Province',
        state_code: 'Z',
      },
      {
        id: 3641,
        name: 'Santa Fe Province',
        state_code: 'S',
      },
      {
        id: 3635,
        name: 'Santiago del Estero Province',
        state_code: 'G',
      },
      {
        id: 3650,
        name: 'Tierra del Fuego Province',
        state_code: 'V',
      },
      {
        id: 3637,
        name: 'Tucumán Province',
        state_code: 'T',
      },
    ],
  },
  {
    name: 'Armenia',
    iso3: 'ARM',
    iso2: 'AM',
    phone_code: '374',
    capital: 'Yerevan',
    currency: 'AMD',
    states: [
      {
        id: 2023,
        name: 'Aragatsotn Region',
        state_code: 'AG',
      },
      {
        id: 2024,
        name: 'Ararat Province',
        state_code: 'AR',
      },
      {
        id: 2026,
        name: 'Armavir Region',
        state_code: 'AV',
      },
      {
        id: 2028,
        name: 'Gegharkunik Province',
        state_code: 'GR',
      },
      {
        id: 2033,
        name: 'Kotayk Region',
        state_code: 'KT',
      },
      {
        id: 2029,
        name: 'Lori Region',
        state_code: 'LO',
      },
      {
        id: 2031,
        name: 'Shirak Region',
        state_code: 'SH',
      },
      {
        id: 2027,
        name: 'Syunik Province',
        state_code: 'SU',
      },
      {
        id: 2032,
        name: 'Tavush Region',
        state_code: 'TV',
      },
      {
        id: 2025,
        name: 'Vayots Dzor Region',
        state_code: 'VD',
      },
      {
        id: 2030,
        name: 'Yerevan',
        state_code: 'ER',
      },
    ],
  },
  {
    name: 'Aruba',
    iso3: 'ABW',
    iso2: 'AW',
    phone_code: '297',
    capital: 'Oranjestad',
    currency: 'AWG',
    states: [],
  },
  {
    name: 'Australia',
    iso3: 'AUS',
    iso2: 'AU',
    phone_code: '61',
    capital: 'Canberra',
    currency: 'AUD',
    states: [
      {
        id: 3907,
        name: 'Australian Capital Territory',
        state_code: 'ACT',
      },
      {
        id: 3909,
        name: 'New South Wales',
        state_code: 'NSW',
      },
      {
        id: 3910,
        name: 'Northern Territory',
        state_code: 'NT',
      },
      {
        id: 3905,
        name: 'Queensland',
        state_code: 'QLD',
      },
      {
        id: 3904,
        name: 'South Australia',
        state_code: 'SA',
      },
      {
        id: 3908,
        name: 'Tasmania',
        state_code: 'TAS',
      },
      {
        id: 3903,
        name: 'Victoria',
        state_code: 'VIC',
      },
      {
        id: 3906,
        name: 'Western Australia',
        state_code: 'WA',
      },
    ],
  },
  {
    name: 'Austria',
    iso3: 'AUT',
    iso2: 'AT',
    phone_code: '43',
    capital: 'Vienna',
    currency: 'EUR',
    states: [
      {
        id: 2062,
        name: 'Burgenland',
        state_code: '1',
      },
      {
        id: 2057,
        name: 'Carinthia',
        state_code: '2',
      },
      {
        id: 2065,
        name: 'Lower Austria',
        state_code: '3',
      },
      {
        id: 2061,
        name: 'Salzburg',
        state_code: '5',
      },
      {
        id: 2059,
        name: 'Styria',
        state_code: '6',
      },
      {
        id: 2064,
        name: 'Tyrol',
        state_code: '7',
      },
      {
        id: 2058,
        name: 'Upper Austria',
        state_code: '4',
      },
      {
        id: 2060,
        name: 'Vienna',
        state_code: '9',
      },
      {
        id: 2063,
        name: 'Vorarlberg',
        state_code: '8',
      },
    ],
  },
  {
    name: 'Azerbaijan',
    iso3: 'AZE',
    iso2: 'AZ',
    phone_code: '994',
    capital: 'Baku',
    currency: 'AZN',
    states: [
      {
        id: 540,
        name: 'Absheron District',
        state_code: 'ABS',
      },
      {
        id: 559,
        name: 'Agdam District',
        state_code: 'AGM',
      },
      {
        id: 553,
        name: 'Agdash District',
        state_code: 'AGS',
      },
      {
        id: 577,
        name: 'Aghjabadi District',
        state_code: 'AGC',
      },
      {
        id: 543,
        name: 'Agstafa District',
        state_code: 'AGA',
      },
      {
        id: 547,
        name: 'Agsu District',
        state_code: 'AGU',
      },
      {
        id: 528,
        name: 'Astara District',
        state_code: 'AST',
      },
      {
        id: 575,
        name: 'Babek District',
        state_code: 'BAB',
      },
      {
        id: 552,
        name: 'Baku',
        state_code: 'BA',
      },
      {
        id: 560,
        name: 'Balakan District',
        state_code: 'BAL',
      },
      {
        id: 569,
        name: 'Barda District',
        state_code: 'BAR',
      },
      {
        id: 554,
        name: 'Beylagan District',
        state_code: 'BEY',
      },
      {
        id: 532,
        name: 'Bilasuvar District',
        state_code: 'BIL',
      },
      {
        id: 561,
        name: 'Dashkasan District',
        state_code: 'DAS',
      },
      {
        id: 527,
        name: 'Fizuli District',
        state_code: 'FUZ',
      },
      {
        id: 585,
        name: 'Ganja',
        state_code: 'GA',
      },
      {
        id: 589,
        name: 'Gədəbəy',
        state_code: 'GAD',
      },
      {
        id: 573,
        name: 'Gobustan District',
        state_code: 'QOB',
      },
      {
        id: 551,
        name: 'Goranboy District',
        state_code: 'GOR',
      },
      {
        id: 531,
        name: 'Goychay',
        state_code: 'GOY',
      },
      {
        id: 574,
        name: 'Goygol District',
        state_code: 'GYG',
      },
      {
        id: 571,
        name: 'Hajigabul District',
        state_code: 'HAC',
      },
      {
        id: 544,
        name: 'Imishli District',
        state_code: 'IMI',
      },
      {
        id: 564,
        name: 'Ismailli District',
        state_code: 'ISM',
      },
      {
        id: 570,
        name: 'Jabrayil District',
        state_code: 'CAB',
      },
      {
        id: 578,
        name: 'Jalilabad District',
        state_code: 'CAL',
      },
      {
        id: 572,
        name: 'Julfa District',
        state_code: 'CUL',
      },
      {
        id: 525,
        name: 'Kalbajar District',
        state_code: 'KAL',
      },
      {
        id: 567,
        name: 'Kangarli District',
        state_code: 'KAN',
      },
      {
        id: 590,
        name: 'Khachmaz District',
        state_code: 'XAC',
      },
      {
        id: 537,
        name: 'Khizi District',
        state_code: 'XIZ',
      },
      {
        id: 524,
        name: 'Khojali District',
        state_code: 'XCI',
      },
      {
        id: 549,
        name: 'Kurdamir District',
        state_code: 'KUR',
      },
      {
        id: 541,
        name: 'Lachin District',
        state_code: 'LAC',
      },
      {
        id: 587,
        name: 'Lankaran',
        state_code: 'LAN',
      },
      {
        id: 558,
        name: 'Lankaran District',
        state_code: 'LA',
      },
      {
        id: 546,
        name: 'Lerik District',
        state_code: 'LER',
      },
      {
        id: 568,
        name: 'Martuni',
        state_code: 'XVD',
      },
      {
        id: 555,
        name: 'Masally District',
        state_code: 'MAS',
      },
      {
        id: 580,
        name: 'Mingachevir',
        state_code: 'MI',
      },
      {
        id: 562,
        name: 'Nakhchivan Autonomous Republic',
        state_code: 'NX',
      },
      {
        id: 530,
        name: 'Neftchala District',
        state_code: 'NEF',
      },
      {
        id: 556,
        name: 'Oghuz District',
        state_code: 'OGU',
      },
      {
        id: 534,
        name: 'Ordubad District',
        state_code: 'ORD',
      },
      {
        id: 542,
        name: 'Qabala District',
        state_code: 'QAB',
      },
      {
        id: 526,
        name: 'Qakh District',
        state_code: 'QAX',
      },
      {
        id: 521,
        name: 'Qazakh District',
        state_code: 'QAZ',
      },
      {
        id: 563,
        name: 'Quba District',
        state_code: 'QBA',
      },
      {
        id: 548,
        name: 'Qubadli District',
        state_code: 'QBI',
      },
      {
        id: 588,
        name: 'Qusar District',
        state_code: 'QUS',
      },
      {
        id: 557,
        name: 'Saatly District',
        state_code: 'SAT',
      },
      {
        id: 565,
        name: 'Sabirabad District',
        state_code: 'SAB',
      },
      {
        id: 522,
        name: 'Sadarak District',
        state_code: 'SAD',
      },
      {
        id: 545,
        name: 'Salyan District',
        state_code: 'SAL',
      },
      {
        id: 536,
        name: 'Samukh District',
        state_code: 'SMX',
      },
      {
        id: 591,
        name: 'Shabran District',
        state_code: 'SBN',
      },
      {
        id: 579,
        name: 'Shahbuz District',
        state_code: 'SAH',
      },
      {
        id: 518,
        name: 'Shaki',
        state_code: 'SA',
      },
      {
        id: 586,
        name: 'Shaki District',
        state_code: 'SAK',
      },
      {
        id: 529,
        name: 'Shamakhi District',
        state_code: 'SMI',
      },
      {
        id: 583,
        name: 'Shamkir District',
        state_code: 'SKR',
      },
      {
        id: 535,
        name: 'Sharur District',
        state_code: 'SAR',
      },
      {
        id: 520,
        name: 'Shirvan',
        state_code: 'SR',
      },
      {
        id: 592,
        name: 'Shusha District',
        state_code: 'SUS',
      },
      {
        id: 584,
        name: 'Siazan District',
        state_code: 'SIY',
      },
      {
        id: 582,
        name: 'Sumqayit',
        state_code: 'SM',
      },
      {
        id: 519,
        name: 'Tartar District',
        state_code: 'TAR',
      },
      {
        id: 533,
        name: 'Tovuz District',
        state_code: 'TOV',
      },
      {
        id: 539,
        name: 'Ujar District',
        state_code: 'UCA',
      },
      {
        id: 550,
        name: 'Yardymli District',
        state_code: 'YAR',
      },
      {
        id: 538,
        name: 'Yevlakh',
        state_code: 'YE',
      },
      {
        id: 523,
        name: 'Yevlakh District',
        state_code: 'YEV',
      },
      {
        id: 581,
        name: 'Zangilan District',
        state_code: 'ZAN',
      },
      {
        id: 566,
        name: 'Zaqatala District',
        state_code: 'ZAQ',
      },
      {
        id: 576,
        name: 'Zardab District',
        state_code: 'ZAR',
      },
    ],
  },
  {
    name: 'Bahamas The',
    iso3: 'BHS',
    iso2: 'BS',
    phone_code: '+1-242',
    capital: 'Nassau',
    currency: 'BSD',
    states: [
      {
        id: 3601,
        name: 'Acklins',
        state_code: 'AK',
      },
      {
        id: 3628,
        name: 'Acklins and Crooked Islands',
        state_code: 'AC',
      },
      {
        id: 3593,
        name: 'Berry Islands',
        state_code: 'BY',
      },
      {
        id: 3629,
        name: 'Bimini',
        state_code: 'BI',
      },
      {
        id: 3605,
        name: 'Black Point',
        state_code: 'BP',
      },
      {
        id: 3611,
        name: 'Cat Island',
        state_code: 'CI',
      },
      {
        id: 3603,
        name: 'Central Abaco',
        state_code: 'CO',
      },
      {
        id: 3631,
        name: 'Central Andros',
        state_code: 'CS',
      },
      {
        id: 3596,
        name: 'Central Eleuthera',
        state_code: 'CE',
      },
      {
        id: 3621,
        name: 'Crooked Island',
        state_code: 'CK',
      },
      {
        id: 3614,
        name: 'East Grand Bahama',
        state_code: 'EG',
      },
      {
        id: 3612,
        name: 'Exuma',
        state_code: 'EX',
      },
      {
        id: 3626,
        name: 'Freeport',
        state_code: 'FP',
      },
      {
        id: 3619,
        name: 'Fresh Creek',
        state_code: 'FC',
      },
      {
        id: 3597,
        name: "Governor's Harbour",
        state_code: 'GH',
      },
      {
        id: 3632,
        name: 'Grand Cay',
        state_code: 'GC',
      },
      {
        id: 3595,
        name: 'Green Turtle Cay',
        state_code: 'GT',
      },
      {
        id: 3613,
        name: 'Harbour Island',
        state_code: 'HI',
      },
      {
        id: 3598,
        name: 'High Rock',
        state_code: 'HR',
      },
      {
        id: 3624,
        name: 'Hope Town',
        state_code: 'HT',
      },
      {
        id: 3609,
        name: 'Inagua',
        state_code: 'IN',
      },
      {
        id: 3618,
        name: 'Kemps Bay',
        state_code: 'KB',
      },
      {
        id: 3610,
        name: 'Long Island',
        state_code: 'LI',
      },
      {
        id: 3625,
        name: 'Mangrove Cay',
        state_code: 'MC',
      },
      {
        id: 3604,
        name: 'Marsh Harbour',
        state_code: 'MH',
      },
      {
        id: 3633,
        name: 'Mayaguana District',
        state_code: 'MG',
      },
      {
        id: 3594,
        name: 'Nichollstown and Berry Islands',
        state_code: 'NB',
      },
      {
        id: 3616,
        name: 'North Abaco',
        state_code: 'NO',
      },
      {
        id: 3617,
        name: 'North Andros',
        state_code: 'NS',
      },
      {
        id: 3602,
        name: 'North Eleuthera',
        state_code: 'NE',
      },
      {
        id: 3615,
        name: 'Ragged Island',
        state_code: 'RI',
      },
      {
        id: 3623,
        name: 'Rock Sound',
        state_code: 'RS',
      },
      {
        id: 3600,
        name: 'Rum Cay District',
        state_code: 'RC',
      },
      {
        id: 3620,
        name: 'San Salvador and Rum Cay',
        state_code: 'SR',
      },
      {
        id: 3627,
        name: 'San Salvador Island',
        state_code: 'SS',
      },
      {
        id: 3606,
        name: 'Sandy Point',
        state_code: 'SP',
      },
      {
        id: 3608,
        name: 'South Abaco',
        state_code: 'SO',
      },
      {
        id: 3622,
        name: 'South Andros',
        state_code: 'SA',
      },
      {
        id: 3607,
        name: 'South Eleuthera',
        state_code: 'SE',
      },
      {
        id: 3630,
        name: 'Spanish Wells',
        state_code: 'SW',
      },
      {
        id: 3599,
        name: 'West Grand Bahama',
        state_code: 'WG',
      },
    ],
  },
  {
    name: 'Bahrain',
    iso3: 'BHR',
    iso2: 'BH',
    phone_code: '973',
    capital: 'Manama',
    currency: 'BHD',
    states: [
      {
        id: 1992,
        name: 'Capital Governorate',
        state_code: '13',
      },
      {
        id: 1996,
        name: 'Central Governorate',
        state_code: '16',
      },
      {
        id: 1995,
        name: 'Muharraq Governorate',
        state_code: '15',
      },
      {
        id: 1994,
        name: 'Northern Governorate',
        state_code: '17',
      },
      {
        id: 1993,
        name: 'Southern Governorate',
        state_code: '14',
      },
    ],
  },
  {
    name: 'Bangladesh',
    iso3: 'BGD',
    iso2: 'BD',
    phone_code: '880',
    capital: 'Dhaka',
    currency: 'BDT',
    states: [
      {
        id: 796,
        name: 'Bagerhat District',
        state_code: '05',
      },
      {
        id: 802,
        name: 'Bahadia',
        state_code: '33',
      },
      {
        id: 752,
        name: 'Bandarban District',
        state_code: '01',
      },
      {
        id: 784,
        name: 'Barguna District',
        state_code: '02',
      },
      {
        id: 818,
        name: 'Barisal District',
        state_code: '06',
      },
      {
        id: 807,
        name: 'Barisal Division',
        state_code: 'A',
      },
      {
        id: 756,
        name: 'Bhola District',
        state_code: '07',
      },
      {
        id: 797,
        name: 'Bogra District',
        state_code: '03',
      },
      {
        id: 810,
        name: 'Brahmanbaria District',
        state_code: '04',
      },
      {
        id: 768,
        name: 'Chandpur District',
        state_code: '09',
      },
      {
        id: 761,
        name: 'Chapai Nawabganj District',
        state_code: '45',
      },
      {
        id: 785,
        name: 'Chittagong District',
        state_code: '10',
      },
      {
        id: 803,
        name: 'Chittagong Division',
        state_code: 'B',
      },
      {
        id: 788,
        name: 'Chuadanga District',
        state_code: '12',
      },
      {
        id: 763,
        name: 'Comilla District',
        state_code: '08',
      },
      {
        id: 751,
        name: "Cox's Bazar District",
        state_code: '11',
      },
      {
        id: 771,
        name: 'Dhaka District',
        state_code: '13',
      },
      {
        id: 760,
        name: 'Dhaka Division',
        state_code: 'C',
      },
      {
        id: 783,
        name: 'Dinajpur District',
        state_code: '14',
      },
      {
        id: 762,
        name: 'Faridpur District',
        state_code: '15',
      },
      {
        id: 816,
        name: 'Feni District',
        state_code: '16',
      },
      {
        id: 795,
        name: 'Gaibandha District',
        state_code: '19',
      },
      {
        id: 798,
        name: 'Gazipur District',
        state_code: '18',
      },
      {
        id: 792,
        name: 'Gopalganj District',
        state_code: '17',
      },
      {
        id: 805,
        name: 'Habiganj District',
        state_code: '20',
      },
      {
        id: 808,
        name: 'Jamalpur District',
        state_code: '21',
      },
      {
        id: 757,
        name: 'Jessore District',
        state_code: '22',
      },
      {
        id: 778,
        name: 'Jhalokati District',
        state_code: '25',
      },
      {
        id: 789,
        name: 'Jhenaidah District',
        state_code: '23',
      },
      {
        id: 806,
        name: 'Joypurhat District',
        state_code: '24',
      },
      {
        id: 786,
        name: 'Khagrachari District',
        state_code: '29',
      },
      {
        id: 811,
        name: 'Khulna District',
        state_code: '27',
      },
      {
        id: 775,
        name: 'Khulna Division',
        state_code: 'D',
      },
      {
        id: 779,
        name: 'Kishoreganj District',
        state_code: '26',
      },
      {
        id: 793,
        name: 'Kurigram District',
        state_code: '28',
      },
      {
        id: 774,
        name: 'Kushtia District',
        state_code: '30',
      },
      {
        id: 819,
        name: 'Lakshmipur District',
        state_code: '31',
      },
      {
        id: 780,
        name: 'Lalmonirhat District',
        state_code: '32',
      },
      {
        id: 817,
        name: 'Madaripur District',
        state_code: '36',
      },
      {
        id: 776,
        name: 'Meherpur District',
        state_code: '39',
      },
      {
        id: 794,
        name: 'Moulvibazar District',
        state_code: '38',
      },
      {
        id: 790,
        name: 'Munshiganj District',
        state_code: '35',
      },
      {
        id: 766,
        name: 'Mymensingh District',
        state_code: '34',
      },
      {
        id: 758,
        name: 'Mymensingh Division',
        state_code: 'H',
      },
      {
        id: 814,
        name: 'Naogaon District',
        state_code: '48',
      },
      {
        id: 769,
        name: 'Narail District',
        state_code: '43',
      },
      {
        id: 770,
        name: 'Narayanganj District',
        state_code: '40',
      },
      {
        id: 787,
        name: 'Natore District',
        state_code: '44',
      },
      {
        id: 764,
        name: 'Netrokona District',
        state_code: '41',
      },
      {
        id: 772,
        name: 'Nilphamari District',
        state_code: '46',
      },
      {
        id: 815,
        name: 'Noakhali District',
        state_code: '47',
      },
      {
        id: 754,
        name: 'Pabna District',
        state_code: '49',
      },
      {
        id: 800,
        name: 'Panchagarh District',
        state_code: '52',
      },
      {
        id: 777,
        name: 'Patuakhali District',
        state_code: '51',
      },
      {
        id: 791,
        name: 'Pirojpur District',
        state_code: '50',
      },
      {
        id: 773,
        name: 'Rajbari District',
        state_code: '53',
      },
      {
        id: 813,
        name: 'Rajshahi District',
        state_code: '54',
      },
      {
        id: 753,
        name: 'Rajshahi Division',
        state_code: 'E',
      },
      {
        id: 809,
        name: 'Rangamati Hill District',
        state_code: '56',
      },
      {
        id: 759,
        name: 'Rangpur District',
        state_code: '55',
      },
      {
        id: 750,
        name: 'Rangpur Division',
        state_code: 'F',
      },
      {
        id: 799,
        name: 'Satkhira District',
        state_code: '58',
      },
      {
        id: 801,
        name: 'Shariatpur District',
        state_code: '62',
      },
      {
        id: 755,
        name: 'Sherpur District',
        state_code: '57',
      },
      {
        id: 781,
        name: 'Sirajganj District',
        state_code: '59',
      },
      {
        id: 812,
        name: 'Sunamganj District',
        state_code: '61',
      },
      {
        id: 767,
        name: 'Sylhet District',
        state_code: '60',
      },
      {
        id: 765,
        name: 'Sylhet Division',
        state_code: 'G',
      },
      {
        id: 782,
        name: 'Tangail District',
        state_code: '63',
      },
      {
        id: 804,
        name: 'Thakurgaon District',
        state_code: '64',
      },
    ],
  },
  {
    name: 'Barbados',
    iso3: 'BRB',
    iso2: 'BB',
    phone_code: '+1-246',
    capital: 'Bridgetown',
    currency: 'BBD',
    states: [
      {
        id: 1228,
        name: 'Christ Church',
        state_code: '01',
      },
      {
        id: 1229,
        name: 'Saint Andrew',
        state_code: '02',
      },
      {
        id: 1226,
        name: 'Saint George',
        state_code: '03',
      },
      {
        id: 1224,
        name: 'Saint James',
        state_code: '04',
      },
      {
        id: 1227,
        name: 'Saint John',
        state_code: '05',
      },
      {
        id: 1223,
        name: 'Saint Joseph',
        state_code: '06',
      },
      {
        id: 1221,
        name: 'Saint Lucy',
        state_code: '07',
      },
      {
        id: 1230,
        name: 'Saint Michael',
        state_code: '08',
      },
      {
        id: 1222,
        name: 'Saint Peter',
        state_code: '09',
      },
      {
        id: 1220,
        name: 'Saint Philip',
        state_code: '10',
      },
      {
        id: 1225,
        name: 'Saint Thomas',
        state_code: '11',
      },
    ],
  },
  {
    name: 'Belarus',
    iso3: 'BLR',
    iso2: 'BY',
    phone_code: '375',
    capital: 'Minsk',
    currency: 'BYR',
    states: [
      {
        id: 2959,
        name: 'Brest Region',
        state_code: 'BR',
      },
      {
        id: 2955,
        name: 'Gomel Region',
        state_code: 'HO',
      },
      {
        id: 2956,
        name: 'Grodno Region',
        state_code: 'HR',
      },
      {
        id: 2958,
        name: 'Minsk',
        state_code: 'HM',
      },
      {
        id: 2957,
        name: 'Minsk Region',
        state_code: 'MI',
      },
      {
        id: 2954,
        name: 'Mogilev Region',
        state_code: 'MA',
      },
      {
        id: 2960,
        name: 'Vitebsk Region',
        state_code: 'VI',
      },
    ],
  },
  {
    name: 'Belgium',
    iso3: 'BEL',
    iso2: 'BE',
    phone_code: '32',
    capital: 'Brussels',
    currency: 'EUR',
    states: [
      {
        id: 1381,
        name: 'Antwerp',
        state_code: 'VAN',
      },
      {
        id: 1376,
        name: 'Brussels-Capital Region',
        state_code: 'BRU',
      },
      {
        id: 1377,
        name: 'East Flanders',
        state_code: 'VOV',
      },
      {
        id: 1373,
        name: 'Flanders',
        state_code: 'VLG',
      },
      {
        id: 1374,
        name: 'Flemish Brabant',
        state_code: 'VBR',
      },
      {
        id: 1375,
        name: 'Hainaut',
        state_code: 'WHT',
      },
      {
        id: 1384,
        name: 'Liège',
        state_code: 'WLG',
      },
      {
        id: 1372,
        name: 'Limburg',
        state_code: 'VLI',
      },
      {
        id: 1379,
        name: 'Luxembourg',
        state_code: 'WLX',
      },
      {
        id: 1378,
        name: 'Namur',
        state_code: 'WNA',
      },
      {
        id: 1380,
        name: 'Wallonia',
        state_code: 'WAL',
      },
      {
        id: 1382,
        name: 'Walloon Brabant',
        state_code: 'WBR',
      },
      {
        id: 1383,
        name: 'West Flanders',
        state_code: 'VWV',
      },
    ],
  },
  {
    name: 'Belize',
    iso3: 'BLZ',
    iso2: 'BZ',
    phone_code: '501',
    capital: 'Belmopan',
    currency: 'BZD',
    states: [
      {
        id: 264,
        name: 'Belize District',
        state_code: 'BZ',
      },
      {
        id: 269,
        name: 'Cayo District',
        state_code: 'CY',
      },
      {
        id: 266,
        name: 'Corozal District',
        state_code: 'CZL',
      },
      {
        id: 268,
        name: 'Orange Walk District',
        state_code: 'OW',
      },
      {
        id: 265,
        name: 'Stann Creek District',
        state_code: 'SC',
      },
      {
        id: 267,
        name: 'Toledo District',
        state_code: 'TOL',
      },
    ],
  },
  {
    name: 'Benin',
    iso3: 'BEN',
    iso2: 'BJ',
    phone_code: '229',
    capital: 'Porto-Novo',
    currency: 'XOF',
    states: [
      {
        id: 3077,
        name: 'Alibori Department',
        state_code: 'AL',
      },
      {
        id: 3076,
        name: 'Atakora Department',
        state_code: 'AK',
      },
      {
        id: 3079,
        name: 'Atlantique Department',
        state_code: 'AQ',
      },
      {
        id: 3078,
        name: 'Borgou Department',
        state_code: 'BO',
      },
      {
        id: 3070,
        name: 'Collines Department',
        state_code: 'CO',
      },
      {
        id: 3072,
        name: 'Donga Department',
        state_code: 'DO',
      },
      {
        id: 3071,
        name: 'Kouffo Department',
        state_code: 'KO',
      },
      {
        id: 3081,
        name: 'Littoral Department',
        state_code: 'LI',
      },
      {
        id: 3075,
        name: 'Mono Department',
        state_code: 'MO',
      },
      {
        id: 3080,
        name: 'Ouémé Department',
        state_code: 'OU',
      },
      {
        id: 3074,
        name: 'Plateau Department',
        state_code: 'PL',
      },
      {
        id: 3073,
        name: 'Zou Department',
        state_code: 'ZO',
      },
    ],
  },
  {
    name: 'Bermuda',
    iso3: 'BMU',
    iso2: 'BM',
    phone_code: '+1-441',
    capital: 'Hamilton',
    currency: 'BMD',
    states: [],
  },
  {
    name: 'Bhutan',
    iso3: 'BTN',
    iso2: 'BT',
    phone_code: '975',
    capital: 'Thimphu',
    currency: 'BTN',
    states: [
      {
        id: 240,
        name: 'Bumthang District',
        state_code: '33',
      },
      {
        id: 239,
        name: 'Chukha District',
        state_code: '12',
      },
      {
        id: 238,
        name: 'Dagana District',
        state_code: '22',
      },
      {
        id: 229,
        name: 'Gasa District',
        state_code: 'GA',
      },
      {
        id: 232,
        name: 'Haa District',
        state_code: '13',
      },
      {
        id: 234,
        name: 'Lhuntse District',
        state_code: '44',
      },
      {
        id: 242,
        name: 'Mongar District',
        state_code: '42',
      },
      {
        id: 237,
        name: 'Paro District',
        state_code: '11',
      },
      {
        id: 244,
        name: 'Pemagatshel District',
        state_code: '43',
      },
      {
        id: 235,
        name: 'Punakha District',
        state_code: '23',
      },
      {
        id: 243,
        name: 'Samdrup Jongkhar District',
        state_code: '45',
      },
      {
        id: 246,
        name: 'Samtse District',
        state_code: '14',
      },
      {
        id: 247,
        name: 'Sarpang District',
        state_code: '31',
      },
      {
        id: 241,
        name: 'Thimphu District',
        state_code: '15',
      },
      {
        id: 236,
        name: 'Trashigang District',
        state_code: '41',
      },
      {
        id: 245,
        name: 'Trongsa District',
        state_code: '32',
      },
      {
        id: 230,
        name: 'Tsirang District',
        state_code: '21',
      },
      {
        id: 231,
        name: 'Wangdue Phodrang District',
        state_code: '24',
      },
      {
        id: 233,
        name: 'Zhemgang District',
        state_code: '34',
      },
    ],
  },
  {
    name: 'Bolivia',
    iso3: 'BOL',
    iso2: 'BO',
    phone_code: '591',
    capital: 'Sucre',
    currency: 'BOB',
    states: [
      {
        id: 3375,
        name: 'Beni Department',
        state_code: 'B',
      },
      {
        id: 3382,
        name: 'Chuquisaca Department',
        state_code: 'H',
      },
      {
        id: 3381,
        name: 'Cochabamba Department',
        state_code: 'C',
      },
      {
        id: 3380,
        name: 'La Paz Department',
        state_code: 'L',
      },
      {
        id: 3376,
        name: 'Oruro Department',
        state_code: 'O',
      },
      {
        id: 3379,
        name: 'Pando Department',
        state_code: 'N',
      },
      {
        id: 3383,
        name: 'Potosí Department',
        state_code: 'P',
      },
      {
        id: 3377,
        name: 'Santa Cruz Department',
        state_code: 'S',
      },
      {
        id: 3378,
        name: 'Tarija Department',
        state_code: 'T',
      },
    ],
  },
  {
    name: 'Bosnia and Herzegovina',
    iso3: 'BIH',
    iso2: 'BA',
    phone_code: '387',
    capital: 'Sarajevo',
    currency: 'BAM',
    states: [
      {
        id: 472,
        name: 'Bosnian Podrinje Canton',
        state_code: '05',
      },
      {
        id: 460,
        name: 'Brčko District',
        state_code: 'BRC',
      },
      {
        id: 471,
        name: 'Canton 10',
        state_code: '10',
      },
      {
        id: 462,
        name: 'Central Bosnia Canton',
        state_code: '06',
      },
      {
        id: 467,
        name: 'Federation of Bosnia and Herzegovina',
        state_code: 'BIH',
      },
      {
        id: 463,
        name: 'Herzegovina-Neretva Canton',
        state_code: '07',
      },
      {
        id: 464,
        name: 'Posavina Canton',
        state_code: '02',
      },
      {
        id: 470,
        name: 'Republika Srpska',
        state_code: 'SRP',
      },
      {
        id: 466,
        name: 'Sarajevo Canton',
        state_code: '09',
      },
      {
        id: 461,
        name: 'Tuzla Canton',
        state_code: '03',
      },
      {
        id: 465,
        name: 'Una-Sana Canton',
        state_code: '01',
      },
      {
        id: 469,
        name: 'West Herzegovina Canton',
        state_code: '08',
      },
      {
        id: 468,
        name: 'Zenica-Doboj Canton',
        state_code: '04',
      },
    ],
  },
  {
    name: 'Botswana',
    iso3: 'BWA',
    iso2: 'BW',
    phone_code: '267',
    capital: 'Gaborone',
    currency: 'BWP',
    states: [
      {
        id: 3067,
        name: 'Central District',
        state_code: 'CE',
      },
      {
        id: 3061,
        name: 'Ghanzi District',
        state_code: 'GH',
      },
      {
        id: 3066,
        name: 'Kgalagadi District',
        state_code: 'KG',
      },
      {
        id: 3062,
        name: 'Kgatleng District',
        state_code: 'KL',
      },
      {
        id: 3069,
        name: 'Kweneng District',
        state_code: 'KW',
      },
      {
        id: 3060,
        name: 'Ngamiland',
        state_code: 'NG',
      },
      {
        id: 3068,
        name: 'North-East District',
        state_code: 'NE',
      },
      {
        id: 3065,
        name: 'North-West District',
        state_code: 'NW',
      },
      {
        id: 3064,
        name: 'South-East District',
        state_code: 'SE',
      },
      {
        id: 3063,
        name: 'Southern District',
        state_code: 'SO',
      },
    ],
  },
  {
    name: 'Bouvet Island',
    iso3: 'BVT',
    iso2: 'BV',
    phone_code: '',
    capital: '',
    currency: 'NOK',
    states: [],
  },
  {
    name: 'Brazil',
    iso3: 'BRA',
    iso2: 'BR',
    phone_code: '55',
    capital: 'Brasilia',
    currency: 'BRL',
    states: [
      {
        id: 2012,
        name: 'Acre',
        state_code: 'AC',
      },
      {
        id: 2007,
        name: 'Alagoas',
        state_code: 'AL',
      },
      {
        id: 1999,
        name: 'Amapá',
        state_code: 'AP',
      },
      {
        id: 2004,
        name: 'Amazonas',
        state_code: 'AM',
      },
      {
        id: 2002,
        name: 'Bahia',
        state_code: 'BA',
      },
      {
        id: 2016,
        name: 'Ceará',
        state_code: 'CE',
      },
      {
        id: 2018,
        name: 'Espírito Santo',
        state_code: 'ES',
      },
      {
        id: 2017,
        name: 'Federal District',
        state_code: 'DF',
      },
      {
        id: 2000,
        name: 'Goiás',
        state_code: 'GO',
      },
      {
        id: 2015,
        name: 'Maranhão',
        state_code: 'MA',
      },
      {
        id: 2011,
        name: 'Mato Grosso',
        state_code: 'MT',
      },
      {
        id: 2010,
        name: 'Mato Grosso do Sul',
        state_code: 'MS',
      },
      {
        id: 1998,
        name: 'Minas Gerais',
        state_code: 'MG',
      },
      {
        id: 2009,
        name: 'Pará',
        state_code: 'PA',
      },
      {
        id: 2005,
        name: 'Paraíba',
        state_code: 'PB',
      },
      {
        id: 2022,
        name: 'Paraná',
        state_code: 'PR',
      },
      {
        id: 2006,
        name: 'Pernambuco',
        state_code: 'PE',
      },
      {
        id: 2008,
        name: 'Piauí',
        state_code: 'PI',
      },
      {
        id: 1997,
        name: 'Rio de Janeiro',
        state_code: 'RJ',
      },
      {
        id: 2019,
        name: 'Rio Grande do Norte',
        state_code: 'RN',
      },
      {
        id: 2001,
        name: 'Rio Grande do Sul',
        state_code: 'RS',
      },
      {
        id: 2013,
        name: 'Rondônia',
        state_code: 'RO',
      },
      {
        id: 2014,
        name: 'Santa Catarina',
        state_code: 'SC',
      },
      {
        id: 2021,
        name: 'São Paulo',
        state_code: 'SP',
      },
      {
        id: 2003,
        name: 'Sergipe',
        state_code: 'SE',
      },
      {
        id: 2020,
        name: 'Tocantins',
        state_code: 'TO',
      },
    ],
  },
  {
    name: 'British Indian Ocean Territory',
    iso3: 'IOT',
    iso2: 'IO',
    phone_code: '246',
    capital: 'Diego Garcia',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Brunei',
    iso3: 'BRN',
    iso2: 'BN',
    phone_code: '673',
    capital: 'Bandar Seri Begawan',
    currency: 'BND',
    states: [
      {
        id: 1217,
        name: 'Belait District',
        state_code: 'BE',
      },
      {
        id: 1216,
        name: 'Brunei-Muara District',
        state_code: 'BM',
      },
      {
        id: 1218,
        name: 'Temburong District',
        state_code: 'TE',
      },
      {
        id: 1219,
        name: 'Tutong District',
        state_code: 'TU',
      },
    ],
  },
  {
    name: 'Bulgaria',
    iso3: 'BGR',
    iso2: 'BG',
    phone_code: '359',
    capital: 'Sofia',
    currency: 'BGN',
    states: [
      {
        id: 4699,
        name: 'Blagoevgrad Province',
        state_code: '01',
      },
      {
        id: 4715,
        name: 'Burgas Province',
        state_code: '02',
      },
      {
        id: 4718,
        name: 'Dobrich Province',
        state_code: '08',
      },
      {
        id: 4693,
        name: 'Gabrovo Province',
        state_code: '07',
      },
      {
        id: 4704,
        name: 'Haskovo Province',
        state_code: '26',
      },
      {
        id: 4702,
        name: 'Kardzhali Province',
        state_code: '09',
      },
      {
        id: 4703,
        name: 'Kyustendil Province',
        state_code: '10',
      },
      {
        id: 4710,
        name: 'Lovech Province',
        state_code: '11',
      },
      {
        id: 4696,
        name: 'Montana Province',
        state_code: '12',
      },
      {
        id: 4712,
        name: 'Pazardzhik Province',
        state_code: '13',
      },
      {
        id: 4695,
        name: 'Pernik Province',
        state_code: '14',
      },
      {
        id: 4706,
        name: 'Pleven Province',
        state_code: '15',
      },
      {
        id: 4701,
        name: 'Plovdiv Province',
        state_code: '16',
      },
      {
        id: 4698,
        name: 'Razgrad Province',
        state_code: '17',
      },
      {
        id: 4713,
        name: 'Ruse Province',
        state_code: '18',
      },
      {
        id: 4708,
        name: 'Silistra Province',
        state_code: '19',
      },
      {
        id: 4700,
        name: 'Sliven Province',
        state_code: '20',
      },
      {
        id: 4694,
        name: 'Smolyan Province',
        state_code: '21',
      },
      {
        id: 4705,
        name: 'Sofia City Province',
        state_code: '22',
      },
      {
        id: 4719,
        name: 'Sofia Province',
        state_code: '23',
      },
      {
        id: 4707,
        name: 'Stara Zagora Province',
        state_code: '24',
      },
      {
        id: 4714,
        name: 'Targovishte Province',
        state_code: '25',
      },
      {
        id: 4717,
        name: 'Varna Province',
        state_code: '03',
      },
      {
        id: 4709,
        name: 'Veliko Tarnovo Province',
        state_code: '04',
      },
      {
        id: 4697,
        name: 'Vidin Province',
        state_code: '05',
      },
      {
        id: 4711,
        name: 'Vratsa Province',
        state_code: '06',
      },
      {
        id: 4716,
        name: 'Yambol Province',
        state_code: '28',
      },
    ],
  },
  {
    name: 'Burkina Faso',
    iso3: 'BFA',
    iso2: 'BF',
    phone_code: '226',
    capital: 'Ouagadougou',
    currency: 'XOF',
    states: [
      {
        id: 3160,
        name: 'Balé Province',
        state_code: 'BAL',
      },
      {
        id: 3155,
        name: 'Bam Province',
        state_code: 'BAM',
      },
      {
        id: 3120,
        name: 'Banwa Province',
        state_code: 'BAN',
      },
      {
        id: 3152,
        name: 'Bazèga Province',
        state_code: 'BAZ',
      },
      {
        id: 3138,
        name: 'Boucle du Mouhoun Region',
        state_code: '01',
      },
      {
        id: 3121,
        name: 'Bougouriba Province',
        state_code: 'BGR',
      },
      {
        id: 3131,
        name: 'Boulgou',
        state_code: 'BLG',
      },
      {
        id: 3153,
        name: 'Cascades Region',
        state_code: '02',
      },
      {
        id: 3136,
        name: 'Centre',
        state_code: '03',
      },
      {
        id: 3162,
        name: 'Centre-Est Region',
        state_code: '04',
      },
      {
        id: 3127,
        name: 'Centre-Nord Region',
        state_code: '05',
      },
      {
        id: 3115,
        name: 'Centre-Ouest Region',
        state_code: '06',
      },
      {
        id: 3149,
        name: 'Centre-Sud Region',
        state_code: '07',
      },
      {
        id: 3167,
        name: 'Comoé Province',
        state_code: 'COM',
      },
      {
        id: 3158,
        name: 'Est Region',
        state_code: '08',
      },
      {
        id: 3148,
        name: 'Ganzourgou Province',
        state_code: 'GAN',
      },
      {
        id: 3122,
        name: 'Gnagna Province',
        state_code: 'GNA',
      },
      {
        id: 3143,
        name: 'Gourma Province',
        state_code: 'GOU',
      },
      {
        id: 3165,
        name: 'Hauts-Bassins Region',
        state_code: '09',
      },
      {
        id: 3129,
        name: 'Houet Province',
        state_code: 'HOU',
      },
      {
        id: 3135,
        name: 'Ioba Province',
        state_code: 'IOB',
      },
      {
        id: 3168,
        name: 'Kadiogo Province',
        state_code: 'KAD',
      },
      {
        id: 3112,
        name: 'Kénédougou Province',
        state_code: 'KEN',
      },
      {
        id: 3132,
        name: 'Komondjari Province',
        state_code: 'KMD',
      },
      {
        id: 3157,
        name: 'Kompienga Province',
        state_code: 'KMP',
      },
      {
        id: 3146,
        name: 'Kossi Province',
        state_code: 'KOS',
      },
      {
        id: 3133,
        name: 'Koulpélogo Province',
        state_code: 'KOP',
      },
      {
        id: 3161,
        name: 'Kouritenga Province',
        state_code: 'KOT',
      },
      {
        id: 3147,
        name: 'Kourwéogo Province',
        state_code: 'KOW',
      },
      {
        id: 3159,
        name: 'Léraba Province',
        state_code: 'LER',
      },
      {
        id: 3151,
        name: 'Loroum Province',
        state_code: 'LOR',
      },
      {
        id: 3123,
        name: 'Mouhoun',
        state_code: 'MOU',
      },
      {
        id: 3116,
        name: 'Nahouri Province',
        state_code: 'NAO',
      },
      {
        id: 3113,
        name: 'Namentenga Province',
        state_code: 'NAM',
      },
      {
        id: 3142,
        name: 'Nayala Province',
        state_code: 'NAY',
      },
      {
        id: 3164,
        name: 'Nord Region, Burkina Faso',
        state_code: '10',
      },
      {
        id: 3156,
        name: 'Noumbiel Province',
        state_code: 'NOU',
      },
      {
        id: 3141,
        name: 'Oubritenga Province',
        state_code: 'OUB',
      },
      {
        id: 3144,
        name: 'Oudalan Province',
        state_code: 'OUD',
      },
      {
        id: 3117,
        name: 'Passoré Province',
        state_code: 'PAS',
      },
      {
        id: 3125,
        name: 'Plateau-Central Region',
        state_code: '11',
      },
      {
        id: 3163,
        name: 'Poni Province',
        state_code: 'PON',
      },
      {
        id: 3114,
        name: 'Sahel Region',
        state_code: '12',
      },
      {
        id: 3154,
        name: 'Sanguié Province',
        state_code: 'SNG',
      },
      {
        id: 3126,
        name: 'Sanmatenga Province',
        state_code: 'SMT',
      },
      {
        id: 3139,
        name: 'Séno Province',
        state_code: 'SEN',
      },
      {
        id: 3119,
        name: 'Sissili Province',
        state_code: 'SIS',
      },
      {
        id: 3166,
        name: 'Soum Province',
        state_code: 'SOM',
      },
      {
        id: 3137,
        name: 'Sourou Province',
        state_code: 'SOR',
      },
      {
        id: 3140,
        name: 'Sud-Ouest Region',
        state_code: '13',
      },
      {
        id: 3128,
        name: 'Tapoa Province',
        state_code: 'TAP',
      },
      {
        id: 3134,
        name: 'Tuy Province',
        state_code: 'TUI',
      },
      {
        id: 3124,
        name: 'Yagha Province',
        state_code: 'YAG',
      },
      {
        id: 3150,
        name: 'Yatenga Province',
        state_code: 'YAT',
      },
      {
        id: 3145,
        name: 'Ziro Province',
        state_code: 'ZIR',
      },
      {
        id: 3130,
        name: 'Zondoma Province',
        state_code: 'ZON',
      },
      {
        id: 3118,
        name: 'Zoundwéogo Province',
        state_code: 'ZOU',
      },
    ],
  },
  {
    name: 'Burundi',
    iso3: 'BDI',
    iso2: 'BI',
    phone_code: '257',
    capital: 'Bujumbura',
    currency: 'BIF',
    states: [
      {
        id: 3196,
        name: 'Bubanza Province',
        state_code: 'BB',
      },
      {
        id: 3198,
        name: 'Bujumbura Mairie Province',
        state_code: 'BM',
      },
      {
        id: 3200,
        name: 'Bujumbura Rural Province',
        state_code: 'BL',
      },
      {
        id: 3202,
        name: 'Bururi Province',
        state_code: 'BR',
      },
      {
        id: 3201,
        name: 'Cankuzo Province',
        state_code: 'CA',
      },
      {
        id: 3190,
        name: 'Cibitoke Province',
        state_code: 'CI',
      },
      {
        id: 3197,
        name: 'Gitega Province',
        state_code: 'GI',
      },
      {
        id: 3194,
        name: 'Karuzi Province',
        state_code: 'KR',
      },
      {
        id: 3192,
        name: 'Kayanza Province',
        state_code: 'KY',
      },
      {
        id: 3195,
        name: 'Kirundo Province',
        state_code: 'KI',
      },
      {
        id: 3188,
        name: 'Makamba Province',
        state_code: 'MA',
      },
      {
        id: 3193,
        name: 'Muramvya Province',
        state_code: 'MU',
      },
      {
        id: 3186,
        name: 'Muyinga Province',
        state_code: 'MY',
      },
      {
        id: 3187,
        name: 'Mwaro Province',
        state_code: 'MW',
      },
      {
        id: 3199,
        name: 'Ngozi Province',
        state_code: 'NG',
      },
      {
        id: 3185,
        name: 'Rumonge Province',
        state_code: 'RM',
      },
      {
        id: 3189,
        name: 'Rutana Province',
        state_code: 'RT',
      },
      {
        id: 3191,
        name: 'Ruyigi Province',
        state_code: 'RY',
      },
    ],
  },
  {
    name: 'Cambodia',
    iso3: 'KHM',
    iso2: 'KH',
    phone_code: '855',
    capital: 'Phnom Penh',
    currency: 'KHR',
    states: [
      {
        id: 3984,
        name: 'Banteay Meanchey Province',
        state_code: '1',
      },
      {
        id: 3976,
        name: 'Battambang Province',
        state_code: '2',
      },
      {
        id: 3991,
        name: 'Kampong Cham Province',
        state_code: '3',
      },
      {
        id: 3979,
        name: 'Kampong Chhnang Province',
        state_code: '4',
      },
      {
        id: 3988,
        name: 'Kampong Speu Province',
        state_code: '5',
      },
      {
        id: 3981,
        name: 'Kampot Province',
        state_code: '7',
      },
      {
        id: 3983,
        name: 'Kandal Province',
        state_code: '8',
      },
      {
        id: 3978,
        name: 'Kep Province',
        state_code: '23',
      },
      {
        id: 3982,
        name: 'Koh Kong Province',
        state_code: '9',
      },
      {
        id: 3986,
        name: 'Kratié Province',
        state_code: '10',
      },
      {
        id: 3985,
        name: 'Mondulkiri Province',
        state_code: '11',
      },
      {
        id: 3987,
        name: 'Oddar Meanchey Province',
        state_code: '22',
      },
      {
        id: 3980,
        name: 'Pailin Province',
        state_code: '24',
      },
      {
        id: 3994,
        name: 'Phnom Penh',
        state_code: '12',
      },
      {
        id: 3973,
        name: 'Preah Vihear Province',
        state_code: '13',
      },
      {
        id: 3974,
        name: 'Prey Veng Province',
        state_code: '14',
      },
      {
        id: 3977,
        name: 'Pursat Province',
        state_code: '15',
      },
      {
        id: 3990,
        name: 'Ratanakiri Province',
        state_code: '16',
      },
      {
        id: 3992,
        name: 'Siem Reap Province',
        state_code: '17',
      },
      {
        id: 3989,
        name: 'Sihanoukville Province',
        state_code: '18',
      },
      {
        id: 3993,
        name: 'Stung Treng Province',
        state_code: '19',
      },
      {
        id: 3972,
        name: 'Svay Rieng Province',
        state_code: '20',
      },
      {
        id: 3975,
        name: 'Takéo Province',
        state_code: '21',
      },
    ],
  },
  {
    name: 'Cameroon',
    iso3: 'CMR',
    iso2: 'CM',
    phone_code: '237',
    capital: 'Yaounde',
    currency: 'XAF',
    states: [
      {
        id: 2663,
        name: 'Adamawa',
        state_code: 'AD',
      },
      {
        id: 2660,
        name: 'Centre',
        state_code: 'CE',
      },
      {
        id: 2661,
        name: 'East',
        state_code: 'ES',
      },
      {
        id: 2656,
        name: 'Far North',
        state_code: 'EN',
      },
      {
        id: 2662,
        name: 'Littoral',
        state_code: 'LT',
      },
      {
        id: 2665,
        name: 'North',
        state_code: 'NO',
      },
      {
        id: 2657,
        name: 'Northwest',
        state_code: 'NW',
      },
      {
        id: 2659,
        name: 'South',
        state_code: 'SU',
      },
      {
        id: 2658,
        name: 'Southwest',
        state_code: 'SW',
      },
      {
        id: 2664,
        name: 'West',
        state_code: 'OU',
      },
    ],
  },
  {
    name: 'Canada',
    iso3: 'CAN',
    iso2: 'CA',
    phone_code: '1',
    capital: 'Ottawa',
    currency: 'CAD',
    states: [
      {
        id: 872,
        name: 'Alberta',
        state_code: 'AB',
      },
      {
        id: 875,
        name: 'British Columbia',
        state_code: 'BC',
      },
      {
        id: 867,
        name: 'Manitoba',
        state_code: 'MB',
      },
      {
        id: 868,
        name: 'New Brunswick',
        state_code: 'NB',
      },
      {
        id: 877,
        name: 'Newfoundland and Labrador',
        state_code: 'NL',
      },
      {
        id: 878,
        name: 'Northwest Territories',
        state_code: 'NT',
      },
      {
        id: 874,
        name: 'Nova Scotia',
        state_code: 'NS',
      },
      {
        id: 876,
        name: 'Nunavut',
        state_code: 'NU',
      },
      {
        id: 866,
        name: 'Ontario',
        state_code: 'ON',
      },
      {
        id: 871,
        name: 'Prince Edward Island',
        state_code: 'PE',
      },
      {
        id: 873,
        name: 'Quebec',
        state_code: 'QC',
      },
      {
        id: 870,
        name: 'Saskatchewan',
        state_code: 'SK',
      },
      {
        id: 869,
        name: 'Yukon',
        state_code: 'YT',
      },
    ],
  },
  {
    name: 'Cape Verde',
    iso3: 'CPV',
    iso2: 'CV',
    phone_code: '238',
    capital: 'Praia',
    currency: 'CVE',
    states: [
      {
        id: 2994,
        name: 'Barlavento Islands',
        state_code: 'B',
      },
      {
        id: 2999,
        name: 'Boa Vista',
        state_code: 'BV',
      },
      {
        id: 2996,
        name: 'Brava',
        state_code: 'BR',
      },
      {
        id: 2991,
        name: 'Maio Municipality',
        state_code: 'MA',
      },
      {
        id: 2987,
        name: 'Mosteiros',
        state_code: 'MO',
      },
      {
        id: 2997,
        name: 'Paul',
        state_code: 'PA',
      },
      {
        id: 2989,
        name: 'Porto Novo',
        state_code: 'PN',
      },
      {
        id: 2988,
        name: 'Praia',
        state_code: 'PR',
      },
      {
        id: 2982,
        name: 'Ribeira Brava Municipality',
        state_code: 'RB',
      },
      {
        id: 3002,
        name: 'Ribeira Grande',
        state_code: 'RG',
      },
      {
        id: 2984,
        name: 'Ribeira Grande de Santiago',
        state_code: 'RS',
      },
      {
        id: 2998,
        name: 'Sal',
        state_code: 'SL',
      },
      {
        id: 2985,
        name: 'Santa Catarina',
        state_code: 'CA',
      },
      {
        id: 2995,
        name: 'Santa Catarina do Fogo',
        state_code: 'CF',
      },
      {
        id: 3004,
        name: 'Santa Cruz',
        state_code: 'CR',
      },
      {
        id: 2986,
        name: 'São Domingos',
        state_code: 'SD',
      },
      {
        id: 3000,
        name: 'São Filipe',
        state_code: 'SF',
      },
      {
        id: 2993,
        name: 'São Lourenço dos Órgãos',
        state_code: 'SO',
      },
      {
        id: 2990,
        name: 'São Miguel',
        state_code: 'SM',
      },
      {
        id: 3001,
        name: 'São Vicente',
        state_code: 'SV',
      },
      {
        id: 2992,
        name: 'Sotavento Islands',
        state_code: 'S',
      },
      {
        id: 2983,
        name: 'Tarrafal',
        state_code: 'TA',
      },
      {
        id: 3003,
        name: 'Tarrafal de São Nicolau',
        state_code: 'TS',
      },
    ],
  },
  {
    name: 'Cayman Islands',
    iso3: 'CYM',
    iso2: 'KY',
    phone_code: '+1-345',
    capital: 'George Town',
    currency: 'KYD',
    states: [],
  },
  {
    name: 'Central African Republic',
    iso3: 'CAF',
    iso2: 'CF',
    phone_code: '236',
    capital: 'Bangui',
    currency: 'XAF',
    states: [
      {
        id: 1259,
        name: 'Bamingui-Bangoran Prefecture',
        state_code: 'BB',
      },
      {
        id: 1262,
        name: 'Bangui',
        state_code: 'BGF',
      },
      {
        id: 1264,
        name: 'Basse-Kotto Prefecture',
        state_code: 'BK',
      },
      {
        id: 1258,
        name: 'Haut-Mbomou Prefecture',
        state_code: 'HM',
      },
      {
        id: 1268,
        name: 'Haute-Kotto Prefecture',
        state_code: 'HK',
      },
      {
        id: 1263,
        name: 'Kémo Prefecture',
        state_code: 'KG',
      },
      {
        id: 1256,
        name: 'Lobaye Prefecture',
        state_code: 'LB',
      },
      {
        id: 1257,
        name: 'Mambéré-Kadéï',
        state_code: 'HS',
      },
      {
        id: 1266,
        name: 'Mbomou Prefecture',
        state_code: 'MB',
      },
      {
        id: 1253,
        name: 'Nana-Grébizi Economic Prefecture',
        state_code: 'KB',
      },
      {
        id: 1260,
        name: 'Nana-Mambéré Prefecture',
        state_code: 'NM',
      },
      {
        id: 1255,
        name: "Ombella-M'Poko Prefecture",
        state_code: 'MP',
      },
      {
        id: 1265,
        name: 'Ouaka Prefecture',
        state_code: 'UK',
      },
      {
        id: 1254,
        name: 'Ouham Prefecture',
        state_code: 'AC',
      },
      {
        id: 1267,
        name: 'Ouham-Pendé Prefecture',
        state_code: 'OP',
      },
      {
        id: 1252,
        name: 'Sangha-Mbaéré',
        state_code: 'SE',
      },
      {
        id: 1261,
        name: 'Vakaga Prefecture',
        state_code: 'VK',
      },
    ],
  },
  {
    name: 'Chad',
    iso3: 'TCD',
    iso2: 'TD',
    phone_code: '235',
    capital: "N'Djamena",
    currency: 'XAF',
    states: [
      {
        id: 3583,
        name: 'Bahr el Gazel',
        state_code: 'BG',
      },
      {
        id: 3590,
        name: 'Batha Region',
        state_code: 'BA',
      },
      {
        id: 3574,
        name: 'Borkou',
        state_code: 'BO',
      },
      {
        id: 3578,
        name: 'Ennedi Region',
        state_code: 'EN',
      },
      {
        id: 3575,
        name: 'Ennedi-Est',
        state_code: 'EE',
      },
      {
        id: 3584,
        name: 'Ennedi-Ouest',
        state_code: 'EO',
      },
      {
        id: 3576,
        name: 'Guéra Region',
        state_code: 'GR',
      },
      {
        id: 3573,
        name: 'Hadjer-Lamis',
        state_code: 'HL',
      },
      {
        id: 3588,
        name: 'Kanem Region',
        state_code: 'KA',
      },
      {
        id: 3577,
        name: 'Lac Region',
        state_code: 'LC',
      },
      {
        id: 3585,
        name: 'Logone Occidental Region',
        state_code: 'LO',
      },
      {
        id: 3591,
        name: 'Logone Oriental Region',
        state_code: 'LR',
      },
      {
        id: 3589,
        name: 'Mandoul Region',
        state_code: 'MA',
      },
      {
        id: 3580,
        name: 'Mayo-Kebbi Est Region',
        state_code: 'ME',
      },
      {
        id: 3571,
        name: 'Mayo-Kebbi Ouest Region',
        state_code: 'MO',
      },
      {
        id: 3570,
        name: 'Moyen-Chari Region',
        state_code: 'MC',
      },
      {
        id: 3586,
        name: "N'Djamena",
        state_code: 'ND',
      },
      {
        id: 3582,
        name: 'Ouaddaï Region',
        state_code: 'OD',
      },
      {
        id: 3592,
        name: 'Salamat Region',
        state_code: 'SA',
      },
      {
        id: 3572,
        name: 'Sila Region',
        state_code: 'SI',
      },
      {
        id: 3579,
        name: 'Tandjilé Region',
        state_code: 'TA',
      },
      {
        id: 3587,
        name: 'Tibesti Region',
        state_code: 'TI',
      },
      {
        id: 3581,
        name: 'Wadi Fira Region',
        state_code: 'WF',
      },
    ],
  },
  {
    name: 'Chile',
    iso3: 'CHL',
    iso2: 'CL',
    phone_code: '56',
    capital: 'Santiago',
    currency: 'CLP',
    states: [
      {
        id: 2832,
        name: 'Antofagasta Region',
        state_code: 'AN',
      },
      {
        id: 2826,
        name: 'Araucanía Region',
        state_code: 'AR',
      },
      {
        id: 2829,
        name: 'Arica y Parinacota Region',
        state_code: 'AP',
      },
      {
        id: 2823,
        name: 'Atacama Region',
        state_code: 'AT',
      },
      {
        id: 2828,
        name: 'Aysén Region',
        state_code: 'AI',
      },
      {
        id: 2827,
        name: 'Bío Bío Region',
        state_code: 'BI',
      },
      {
        id: 2825,
        name: 'Coquimbo Region',
        state_code: 'CO',
      },
      {
        id: 2835,
        name: 'Los Lagos Region',
        state_code: 'LL',
      },
      {
        id: 2834,
        name: 'Los Ríos Region',
        state_code: 'LR',
      },
      {
        id: 2836,
        name: 'Magellan and the Chilean Antarctic Region',
        state_code: 'MA',
      },
      {
        id: 2833,
        name: 'Maule Region',
        state_code: 'ML',
      },
      {
        id: 2831,
        name: 'Ñuble Region',
        state_code: 'NB',
      },
      {
        id: 2838,
        name: "O'Higgins",
        state_code: 'LI',
      },
      {
        id: 2824,
        name: 'Santiago Metropolitan Region',
        state_code: 'RM',
      },
      {
        id: 2837,
        name: 'Tarapacá Region',
        state_code: 'TA',
      },
      {
        id: 2830,
        name: 'Valparaíso',
        state_code: 'VS',
      },
    ],
  },
  {
    name: 'China',
    iso3: 'CHN',
    iso2: 'CN',
    phone_code: '86',
    capital: 'Beijing',
    currency: 'CNY',
    states: [
      {
        id: 2251,
        name: 'Anhui',
        state_code: 'AH',
      },
      {
        id: 2257,
        name: 'Beijing',
        state_code: 'BJ',
      },
      {
        id: 2271,
        name: 'Chongqing',
        state_code: 'CQ',
      },
      {
        id: 2248,
        name: 'Fujian',
        state_code: 'FJ',
      },
      {
        id: 2275,
        name: 'Gansu',
        state_code: 'GS',
      },
      {
        id: 2279,
        name: 'Guangdong',
        state_code: 'GD',
      },
      {
        id: 2278,
        name: 'Guangxi Zhuang Autonomous Region',
        state_code: 'GX',
      },
      {
        id: 2261,
        name: 'Guizhou',
        state_code: 'GZ',
      },
      {
        id: 2273,
        name: 'Hainan',
        state_code: 'HI',
      },
      {
        id: 2280,
        name: 'Hebei',
        state_code: 'HE',
      },
      {
        id: 2265,
        name: 'Heilongjiang',
        state_code: 'HL',
      },
      {
        id: 2259,
        name: 'Henan',
        state_code: 'HA',
      },
      {
        id: 2267,
        name: 'Hong Kong',
        state_code: 'HK',
      },
      {
        id: 2274,
        name: 'Hubei',
        state_code: 'HB',
      },
      {
        id: 2258,
        name: 'Hunan',
        state_code: 'HN',
      },
      {
        id: 2269,
        name: 'Inner Mongolia',
        state_code: 'NM',
      },
      {
        id: 2250,
        name: 'Jiangsu',
        state_code: 'JS',
      },
      {
        id: 2256,
        name: 'Jiangxi',
        state_code: 'JX',
      },
      {
        id: 2253,
        name: 'Jilin',
        state_code: 'JL',
      },
      {
        id: 2276,
        name: 'Keelung',
        state_code: 'TW-KEE',
      },
      {
        id: 2268,
        name: 'Liaoning',
        state_code: 'LN',
      },
      {
        id: 2266,
        name: 'Macau',
        state_code: 'MO',
      },
      {
        id: 2262,
        name: 'Ningxia Hui Autonomous Region',
        state_code: 'NX',
      },
      {
        id: 2270,
        name: 'Qinghai',
        state_code: 'QH',
      },
      {
        id: 2272,
        name: 'Shaanxi',
        state_code: 'SN',
      },
      {
        id: 2252,
        name: 'Shandong',
        state_code: 'SD',
      },
      {
        id: 2249,
        name: 'Shanghai',
        state_code: 'SH',
      },
      {
        id: 2254,
        name: 'Shanxi',
        state_code: 'SX',
      },
      {
        id: 2277,
        name: 'Sichuan',
        state_code: 'SC',
      },
      {
        id: 2255,
        name: "Taiwan Province, People's Republic of China",
        state_code: 'TW',
      },
      {
        id: 2264,
        name: 'Tibet Autonomous Region',
        state_code: 'XZ',
      },
      {
        id: 2263,
        name: 'Xinjiang',
        state_code: 'XJ',
      },
      {
        id: 2260,
        name: 'Yunnan',
        state_code: 'YN',
      },
      {
        id: 2247,
        name: 'Zhejiang',
        state_code: 'ZJ',
      },
    ],
  },
  {
    name: 'Christmas Island',
    iso3: 'CXR',
    iso2: 'CX',
    phone_code: '61',
    capital: 'Flying Fish Cove',
    currency: 'AUD',
    states: [],
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso3: 'CCK',
    iso2: 'CC',
    phone_code: '61',
    capital: 'West Island',
    currency: 'AUD',
    states: [],
  },
  {
    name: 'Colombia',
    iso3: 'COL',
    iso2: 'CO',
    phone_code: '57',
    capital: 'Bogota',
    currency: 'COP',
    states: [
      {
        id: 2895,
        name: 'Amazonas Department',
        state_code: 'AMA',
      },
      {
        id: 2890,
        name: 'Antioquia Department',
        state_code: 'ANT',
      },
      {
        id: 2881,
        name: 'Arauca Department',
        state_code: 'ARA',
      },
      {
        id: 2900,
        name: 'Archipelago of Saint Andréws, Providence and Saint Catalina',
        state_code: 'SAP',
      },
      {
        id: 2880,
        name: 'Atlántico Department',
        state_code: 'ATL',
      },
      {
        id: 2893,
        name: 'Bolívar Department',
        state_code: 'BOL',
      },
      {
        id: 2903,
        name: 'Boyacá Department',
        state_code: 'BOY',
      },
      {
        id: 2887,
        name: 'Caldas Department',
        state_code: 'CAL',
      },
      {
        id: 2891,
        name: 'Caquetá Department',
        state_code: 'CAQ',
      },
      {
        id: 2892,
        name: 'Casanare Department',
        state_code: 'CAS',
      },
      {
        id: 2884,
        name: 'Cauca Department',
        state_code: 'CAU',
      },
      {
        id: 2899,
        name: 'Cesar Department',
        state_code: 'CES',
      },
      {
        id: 2876,
        name: 'Chocó Department',
        state_code: 'CHO',
      },
      {
        id: 2898,
        name: 'Córdoba Department',
        state_code: 'COR',
      },
      {
        id: 2875,
        name: 'Cundinamarca Department',
        state_code: 'CUN',
      },
      {
        id: 2882,
        name: 'Guainía Department',
        state_code: 'GUA',
      },
      {
        id: 2888,
        name: 'Guaviare Department',
        state_code: 'GUV',
      },
      {
        id: 2889,
        name: 'La Guajira Department',
        state_code: 'LAG',
      },
      {
        id: 2886,
        name: 'Magdalena Department',
        state_code: 'MAG',
      },
      {
        id: 2878,
        name: 'Meta',
        state_code: 'MET',
      },
      {
        id: 2897,
        name: 'Nariño Department',
        state_code: 'NAR',
      },
      {
        id: 2877,
        name: 'Norte de Santander Department',
        state_code: 'NSA',
      },
      {
        id: 2896,
        name: 'Putumayo Department',
        state_code: 'PUT',
      },
      {
        id: 2874,
        name: 'Quindío Department',
        state_code: 'QUI',
      },
      {
        id: 2879,
        name: 'Risaralda Department',
        state_code: 'RIS',
      },
      {
        id: 2901,
        name: 'Santander Department',
        state_code: 'SAN',
      },
      {
        id: 2902,
        name: 'Sucre Department',
        state_code: 'SUC',
      },
      {
        id: 2883,
        name: 'Tolima Department',
        state_code: 'TOL',
      },
      {
        id: 2904,
        name: 'Valle del Cauca Department',
        state_code: 'VAC',
      },
      {
        id: 2885,
        name: 'Vaupés Department',
        state_code: 'VAU',
      },
      {
        id: 2894,
        name: 'Vichada Department',
        state_code: 'VID',
      },
    ],
  },
  {
    name: 'Comoros',
    iso3: 'COM',
    iso2: 'KM',
    phone_code: '269',
    capital: 'Moroni',
    currency: 'KMF',
    states: [
      {
        id: 2821,
        name: 'Anjouan',
        state_code: 'A',
      },
      {
        id: 2822,
        name: 'Grande Comore',
        state_code: 'G',
      },
      {
        id: 2820,
        name: 'Mohéli',
        state_code: 'M',
      },
    ],
  },
  {
    name: 'Congo',
    iso3: 'COG',
    iso2: 'CG',
    phone_code: '242',
    capital: 'Brazzaville',
    currency: 'XAF',
    states: [
      {
        id: 2866,
        name: 'Bouenza Department',
        state_code: '11',
      },
      {
        id: 2870,
        name: 'Brazzaville',
        state_code: 'BZV',
      },
      {
        id: 2864,
        name: 'Cuvette Department',
        state_code: '8',
      },
      {
        id: 2869,
        name: 'Cuvette-Ouest Department',
        state_code: '15',
      },
      {
        id: 2867,
        name: 'Kouilou Department',
        state_code: '5',
      },
      {
        id: 2868,
        name: 'Lékoumou Department',
        state_code: '2',
      },
      {
        id: 2865,
        name: 'Likouala Department',
        state_code: '7',
      },
      {
        id: 2872,
        name: 'Niari Department',
        state_code: '9',
      },
      {
        id: 2862,
        name: 'Plateaux Department',
        state_code: '14',
      },
      {
        id: 2863,
        name: 'Pointe-Noire',
        state_code: '16',
      },
      {
        id: 2873,
        name: 'Pool Department',
        state_code: '12',
      },
      {
        id: 2871,
        name: 'Sangha Department',
        state_code: '13',
      },
    ],
  },
  {
    name: 'Congo The Democratic Republic Of The',
    iso3: 'COD',
    iso2: 'CD',
    phone_code: '243',
    capital: 'Kinshasa',
    currency: 'CDF',
    states: [
      {
        id: 2754,
        name: 'Bandundu Province',
        state_code: 'BN',
      },
      {
        id: 2746,
        name: 'Bas-Congo province',
        state_code: 'BC',
      },
      {
        id: 2753,
        name: 'Bas-Uele',
        state_code: 'BU',
      },
      {
        id: 2744,
        name: 'Équateur',
        state_code: 'EQ',
      },
      {
        id: 2750,
        name: 'Haut-Katanga Province',
        state_code: 'HK',
      },
      {
        id: 2758,
        name: 'Haut-Lomami District',
        state_code: 'HL',
      },
      {
        id: 2734,
        name: 'Haut-Uele',
        state_code: 'HU',
      },
      {
        id: 2751,
        name: 'Ituri Interim Administration',
        state_code: 'IT',
      },
      {
        id: 2757,
        name: 'Kasaï District',
        state_code: 'KS',
      },
      {
        id: 2737,
        name: 'Kasaï-Occidental',
        state_code: 'KW',
      },
      {
        id: 2735,
        name: 'Kasaï-Oriental',
        state_code: 'KE',
      },
      {
        id: 2742,
        name: 'Katanga Province',
        state_code: 'KA',
      },
      {
        id: 2741,
        name: 'Kinshasa',
        state_code: 'KN',
      },
      {
        id: 2740,
        name: 'Kwango District',
        state_code: 'KG',
      },
      {
        id: 2759,
        name: 'Kwilu District',
        state_code: 'KL',
      },
      {
        id: 2747,
        name: 'Lomami Province',
        state_code: 'LO',
      },
      {
        id: 2755,
        name: 'Mai-Ndombe Province',
        state_code: 'MN',
      },
      {
        id: 2745,
        name: 'Maniema',
        state_code: 'MA',
      },
      {
        id: 2752,
        name: 'Mongala District',
        state_code: 'MO',
      },
      {
        id: 2739,
        name: 'Nord-Ubangi District',
        state_code: 'NU',
      },
      {
        id: 2749,
        name: 'North Kivu',
        state_code: 'NK',
      },
      {
        id: 2736,
        name: 'Orientale Province',
        state_code: 'OR',
      },
      {
        id: 2743,
        name: 'Sankuru District',
        state_code: 'SA',
      },
      {
        id: 2738,
        name: 'South Kivu',
        state_code: 'SK',
      },
      {
        id: 2748,
        name: 'Sud-Ubangi',
        state_code: 'SU',
      },
      {
        id: 2733,
        name: 'Tanganyika Province',
        state_code: 'TA',
      },
      {
        id: 2756,
        name: 'Tshopo District',
        state_code: 'TO',
      },
      {
        id: 2732,
        name: 'Tshuapa District',
        state_code: 'TU',
      },
    ],
  },
  {
    name: 'Cook Islands',
    iso3: 'COK',
    iso2: 'CK',
    phone_code: '682',
    capital: 'Avarua',
    currency: 'NZD',
    states: [],
  },
  {
    name: 'Costa Rica',
    iso3: 'CRI',
    iso2: 'CR',
    phone_code: '506',
    capital: 'San Jose',
    currency: 'CRC',
    states: [
      {
        id: 1215,
        name: 'Alajuela Province',
        state_code: 'A',
      },
      {
        id: 1209,
        name: 'Guanacaste Province',
        state_code: 'G',
      },
      {
        id: 1212,
        name: 'Heredia Province',
        state_code: 'H',
      },
      {
        id: 1213,
        name: 'Limón Province',
        state_code: 'L',
      },
      {
        id: 1211,
        name: 'Provincia de Cartago',
        state_code: 'C',
      },
      {
        id: 1210,
        name: 'Puntarenas Province',
        state_code: 'P',
      },
      {
        id: 1214,
        name: 'San José Province',
        state_code: 'SJ',
      },
    ],
  },
  {
    name: "Cote D'Ivoire (Ivory Coast)",
    iso3: 'CIV',
    iso2: 'CI',
    phone_code: '225',
    capital: 'Yamoussoukro',
    currency: 'XOF',
    states: [
      {
        id: 2634,
        name: 'Abidjan',
        state_code: 'AB',
      },
      {
        id: 2626,
        name: 'Agnéby',
        state_code: '16',
      },
      {
        id: 2636,
        name: 'Bafing Region',
        state_code: '17',
      },
      {
        id: 2643,
        name: 'Bas-Sassandra District',
        state_code: 'BS',
      },
      {
        id: 2635,
        name: 'Bas-Sassandra Region',
        state_code: '09',
      },
      {
        id: 2654,
        name: 'Comoé District',
        state_code: 'CM',
      },
      {
        id: 2644,
        name: 'Denguélé District',
        state_code: 'DN',
      },
      {
        id: 2642,
        name: 'Denguélé Region',
        state_code: '10',
      },
      {
        id: 2645,
        name: 'Dix-Huit Montagnes',
        state_code: '06',
      },
      {
        id: 2633,
        name: 'Fromager',
        state_code: '18',
      },
      {
        id: 2651,
        name: 'Gôh-Djiboua District',
        state_code: 'GD',
      },
      {
        id: 2638,
        name: 'Haut-Sassandra',
        state_code: '02',
      },
      {
        id: 2632,
        name: 'Lacs District',
        state_code: 'LC',
      },
      {
        id: 2640,
        name: 'Lacs Region',
        state_code: '07',
      },
      {
        id: 2627,
        name: 'Lagunes District',
        state_code: 'LG',
      },
      {
        id: 2639,
        name: 'Lagunes region',
        state_code: '01',
      },
      {
        id: 2631,
        name: 'Marahoué Region',
        state_code: '12',
      },
      {
        id: 2629,
        name: 'Montagnes District',
        state_code: 'MG',
      },
      {
        id: 2646,
        name: 'Moyen-Cavally',
        state_code: '19',
      },
      {
        id: 2630,
        name: 'Moyen-Comoé',
        state_code: '05',
      },
      {
        id: 2655,
        name: "N'zi-Comoé",
        state_code: '11',
      },
      {
        id: 2648,
        name: 'Sassandra-Marahoué District',
        state_code: 'SM',
      },
      {
        id: 2625,
        name: 'Savanes Region',
        state_code: '03',
      },
      {
        id: 2628,
        name: 'Sud-Bandama',
        state_code: '15',
      },
      {
        id: 2652,
        name: 'Sud-Comoé',
        state_code: '13',
      },
      {
        id: 2637,
        name: 'Vallée du Bandama District',
        state_code: 'VB',
      },
      {
        id: 2647,
        name: 'Vallée du Bandama Region',
        state_code: '04',
      },
      {
        id: 2650,
        name: 'Woroba District',
        state_code: 'WR',
      },
      {
        id: 2649,
        name: 'Worodougou',
        state_code: '14',
      },
      {
        id: 2653,
        name: 'Yamoussoukro',
        state_code: 'YM',
      },
      {
        id: 2641,
        name: 'Zanzan Region',
        state_code: 'ZZ',
      },
    ],
  },
  {
    name: 'Croatia (Hrvatska)',
    iso3: 'HRV',
    iso2: 'HR',
    phone_code: '385',
    capital: 'Zagreb',
    currency: 'HRK',
    states: [
      {
        id: 734,
        name: 'Bjelovar-Bilogora County',
        state_code: '07',
      },
      {
        id: 737,
        name: 'Brod-Posavina County',
        state_code: '12',
      },
      {
        id: 728,
        name: 'Dubrovnik-Neretva County',
        state_code: '19',
      },
      {
        id: 743,
        name: 'Istria County',
        state_code: '18',
      },
      {
        id: 742,
        name: 'Koprivnica-Križevci County',
        state_code: '06',
      },
      {
        id: 729,
        name: 'Krapina-Zagorje County',
        state_code: '02',
      },
      {
        id: 731,
        name: 'Lika-Senj County',
        state_code: '09',
      },
      {
        id: 726,
        name: 'Međimurje County',
        state_code: '20',
      },
      {
        id: 740,
        name: 'Osijek-Baranja County',
        state_code: '14',
      },
      {
        id: 724,
        name: 'Požega-Slavonia County',
        state_code: '11',
      },
      {
        id: 735,
        name: 'Primorje-Gorski Kotar County',
        state_code: '08',
      },
      {
        id: 730,
        name: 'Šibenik-Knin County',
        state_code: '15',
      },
      {
        id: 733,
        name: 'Sisak-Moslavina County',
        state_code: '03',
      },
      {
        id: 725,
        name: 'Split-Dalmatia County',
        state_code: '17',
      },
      {
        id: 739,
        name: 'Varaždin County',
        state_code: '05',
      },
      {
        id: 732,
        name: 'Virovitica-Podravina County',
        state_code: '10',
      },
      {
        id: 741,
        name: 'Vukovar-Syrmia County',
        state_code: '16',
      },
      {
        id: 727,
        name: 'Zadar County',
        state_code: '13',
      },
      {
        id: 738,
        name: 'Zagreb',
        state_code: '21',
      },
      {
        id: 736,
        name: 'Zagreb County',
        state_code: '01',
      },
    ],
  },
  {
    name: 'Cuba',
    iso3: 'CUB',
    iso2: 'CU',
    phone_code: '53',
    capital: 'Havana',
    currency: 'CUP',
    states: [
      {
        id: 283,
        name: 'Artemisa Province',
        state_code: '15',
      },
      {
        id: 286,
        name: 'Camagüey Province',
        state_code: '09',
      },
      {
        id: 282,
        name: 'Ciego de Ávila Province',
        state_code: '08',
      },
      {
        id: 287,
        name: 'Cienfuegos Province',
        state_code: '06',
      },
      {
        id: 275,
        name: 'Granma Province',
        state_code: '12',
      },
      {
        id: 285,
        name: 'Guantánamo Province',
        state_code: '14',
      },
      {
        id: 272,
        name: 'Havana Province',
        state_code: '03',
      },
      {
        id: 279,
        name: 'Holguín Province',
        state_code: '11',
      },
      {
        id: 278,
        name: 'Isla de la Juventud',
        state_code: '99',
      },
      {
        id: 281,
        name: 'Las Tunas Province',
        state_code: '10',
      },
      {
        id: 284,
        name: 'Matanzas Province',
        state_code: '04',
      },
      {
        id: 276,
        name: 'Mayabeque Province',
        state_code: '16',
      },
      {
        id: 277,
        name: 'Pinar del Río Province',
        state_code: '01',
      },
      {
        id: 274,
        name: 'Sancti Spíritus Province',
        state_code: '07',
      },
      {
        id: 273,
        name: 'Santiago de Cuba Province',
        state_code: '13',
      },
      {
        id: 280,
        name: 'Villa Clara Province',
        state_code: '05',
      },
    ],
  },
  {
    name: 'Cyprus',
    iso3: 'CYP',
    iso2: 'CY',
    phone_code: '357',
    capital: 'Nicosia',
    currency: 'EUR',
    states: [
      {
        id: 749,
        name: 'Famagusta District',
        state_code: '04',
      },
      {
        id: 744,
        name: 'Kyrenia District',
        state_code: '06',
      },
      {
        id: 747,
        name: 'Larnaca District',
        state_code: '03',
      },
      {
        id: 748,
        name: 'Limassol District',
        state_code: '02',
      },
      {
        id: 745,
        name: 'Nicosia District',
        state_code: '01',
      },
      {
        id: 746,
        name: 'Paphos District',
        state_code: '05',
      },
    ],
  },
  {
    name: 'Czech Republic',
    iso3: 'CZE',
    iso2: 'CZ',
    phone_code: '420',
    capital: 'Prague',
    currency: 'CZK',
    states: [
      {
        id: 4627,
        name: 'Benešov District',
        state_code: '201',
      },
      {
        id: 4620,
        name: 'Beroun District',
        state_code: '202',
      },
      {
        id: 4615,
        name: 'Blansko District',
        state_code: '641',
      },
      {
        id: 4542,
        name: 'Břeclav District',
        state_code: '644',
      },
      {
        id: 4568,
        name: 'Brno-City District',
        state_code: '642',
      },
      {
        id: 4545,
        name: 'Brno-Country District',
        state_code: '643',
      },
      {
        id: 4644,
        name: 'Bruntál District',
        state_code: '801',
      },
      {
        id: 4554,
        name: 'Central Bohemian Region',
        state_code: '20',
      },
      {
        id: 4633,
        name: 'Česká Lípa District',
        state_code: '511',
      },
      {
        id: 4556,
        name: 'České Budějovice District',
        state_code: '311',
      },
      {
        id: 4543,
        name: 'Český Krumlov District',
        state_code: '312',
      },
      {
        id: 4573,
        name: 'Cheb District',
        state_code: '411',
      },
      {
        id: 4553,
        name: 'Chomutov District',
        state_code: '422',
      },
      {
        id: 4634,
        name: 'Chrudim District',
        state_code: '531',
      },
      {
        id: 4609,
        name: 'Děčín District',
        state_code: '421',
      },
      {
        id: 4641,
        name: 'Domažlice District',
        state_code: '321',
      },
      {
        id: 4559,
        name: 'Frýdek-Místek District',
        state_code: '802',
      },
      {
        id: 4611,
        name: 'Havlíčkův Brod District',
        state_code: '631',
      },
      {
        id: 4561,
        name: 'Hodonín District',
        state_code: '645',
      },
      {
        id: 4577,
        name: 'Horní Počernice',
        state_code: '120',
      },
      {
        id: 4580,
        name: 'Hradec Králové District',
        state_code: '521',
      },
      {
        id: 4614,
        name: 'Hradec Králové Region',
        state_code: '52',
      },
      {
        id: 4612,
        name: 'Jablonec nad Nisou District',
        state_code: '512',
      },
      {
        id: 4625,
        name: 'Jeseník District',
        state_code: '711',
      },
      {
        id: 4640,
        name: 'Jičín District',
        state_code: '522',
      },
      {
        id: 4613,
        name: 'Jihlava District',
        state_code: '632',
      },
      {
        id: 4624,
        name: 'Jindřichův Hradec District',
        state_code: '313',
      },
      {
        id: 4604,
        name: 'Karlovy Vary District',
        state_code: '412',
      },
      {
        id: 4581,
        name: 'Karlovy Vary Region',
        state_code: '41',
      },
      {
        id: 4586,
        name: 'Karviná District',
        state_code: '803',
      },
      {
        id: 4631,
        name: 'Kladno District',
        state_code: '203',
      },
      {
        id: 4591,
        name: 'Klatovy District',
        state_code: '322',
      },
      {
        id: 4618,
        name: 'Kolín District',
        state_code: '204',
      },
      {
        id: 4593,
        name: 'Kroměříž District',
        state_code: '721',
      },
      {
        id: 4590,
        name: 'Liberec District',
        state_code: '513',
      },
      {
        id: 4601,
        name: 'Liberec Region',
        state_code: '51',
      },
      {
        id: 4605,
        name: 'Litoměřice District',
        state_code: '423',
      },
      {
        id: 4617,
        name: 'Louny District',
        state_code: '424',
      },
      {
        id: 4638,
        name: 'Mělník District',
        state_code: '206',
      },
      {
        id: 4643,
        name: 'Mladá Boleslav District',
        state_code: '207',
      },
      {
        id: 4600,
        name: 'Moravian-Silesian Region',
        state_code: '80',
      },
      {
        id: 4629,
        name: 'Most District',
        state_code: '425',
      },
      {
        id: 4550,
        name: 'Náchod District',
        state_code: '523',
      },
      {
        id: 4548,
        name: 'Nový Jičín District',
        state_code: '804',
      },
      {
        id: 4582,
        name: 'Nymburk District',
        state_code: '208',
      },
      {
        id: 4574,
        name: 'Olomouc District',
        state_code: '712',
      },
      {
        id: 4589,
        name: 'Olomouc Region',
        state_code: '71',
      },
      {
        id: 4623,
        name: 'Opava District',
        state_code: '805',
      },
      {
        id: 4584,
        name: 'Ostrava-City District',
        state_code: '806',
      },
      {
        id: 4547,
        name: 'Pardubice District',
        state_code: '532',
      },
      {
        id: 4588,
        name: 'Pardubice Region',
        state_code: '53',
      },
      {
        id: 4645,
        name: 'Pelhřimov District',
        state_code: '633',
      },
      {
        id: 4560,
        name: 'Písek District',
        state_code: '314',
      },
      {
        id: 4607,
        name: 'Plzeň Region',
        state_code: '32',
      },
      {
        id: 4544,
        name: 'Plzeň-City District',
        state_code: '323',
      },
      {
        id: 4564,
        name: 'Plzeň-North District',
        state_code: '325',
      },
      {
        id: 4608,
        name: 'Plzeň-South District',
        state_code: '324',
      },
      {
        id: 4578,
        name: 'Prachatice District',
        state_code: '315',
      },
      {
        id: 4598,
        name: 'Prague',
        state_code: '10',
      },
      {
        id: 4562,
        name: 'Prague 1',
        state_code: '101',
      },
      {
        id: 4603,
        name: 'Prague 10',
        state_code: '110',
      },
      {
        id: 4570,
        name: 'Prague 11',
        state_code: '111',
      },
      {
        id: 4549,
        name: 'Prague 12',
        state_code: '112',
      },
      {
        id: 4555,
        name: 'Prague 13',
        state_code: '113',
      },
      {
        id: 4585,
        name: 'Prague 14',
        state_code: '114',
      },
      {
        id: 4637,
        name: 'Prague 15',
        state_code: '115',
      },
      {
        id: 4567,
        name: 'Prague 16',
        state_code: '116',
      },
      {
        id: 4616,
        name: 'Prague 2',
        state_code: '102',
      },
      {
        id: 4632,
        name: 'Prague 21',
        state_code: '121',
      },
      {
        id: 4635,
        name: 'Prague 3',
        state_code: '103',
      },
      {
        id: 4587,
        name: 'Prague 4',
        state_code: '104',
      },
      {
        id: 4557,
        name: 'Prague 5',
        state_code: '105',
      },
      {
        id: 4569,
        name: 'Prague 6',
        state_code: '106',
      },
      {
        id: 4610,
        name: 'Prague 7',
        state_code: '107',
      },
      {
        id: 4594,
        name: 'Prague 8',
        state_code: '108',
      },
      {
        id: 4566,
        name: 'Prague 9',
        state_code: '109',
      },
      {
        id: 4606,
        name: 'Prague-East District',
        state_code: '209',
      },
      {
        id: 4619,
        name: 'Prague-West District',
        state_code: '20A',
      },
      {
        id: 4626,
        name: 'Přerov District',
        state_code: '714',
      },
      {
        id: 4546,
        name: 'Příbram District',
        state_code: '20B',
      },
      {
        id: 4551,
        name: 'Prostějov District',
        state_code: '713',
      },
      {
        id: 4558,
        name: 'Rakovník District',
        state_code: '20C',
      },
      {
        id: 4583,
        name: 'Rokycany District',
        state_code: '326',
      },
      {
        id: 4636,
        name: 'Rychnov nad Kněžnou District',
        state_code: '524',
      },
      {
        id: 4596,
        name: 'Semily District',
        state_code: '514',
      },
      {
        id: 4595,
        name: 'Sokolov District',
        state_code: '413',
      },
      {
        id: 4639,
        name: 'South Bohemian Region',
        state_code: '31',
      },
      {
        id: 4602,
        name: 'South Moravian Region',
        state_code: '64',
      },
      {
        id: 4628,
        name: 'Strakonice District',
        state_code: '316',
      },
      {
        id: 4642,
        name: 'Šumperk District',
        state_code: '715',
      },
      {
        id: 4571,
        name: 'Svitavy District',
        state_code: '533',
      },
      {
        id: 4565,
        name: 'Tábor District',
        state_code: '317',
      },
      {
        id: 4646,
        name: 'Tachov District',
        state_code: '327',
      },
      {
        id: 4621,
        name: 'Teplice District',
        state_code: '426',
      },
      {
        id: 4597,
        name: 'Třebíč District',
        state_code: '634',
      },
      {
        id: 4579,
        name: 'Trutnov District',
        state_code: '525',
      },
      {
        id: 4592,
        name: 'Uherské Hradiště District',
        state_code: '722',
      },
      {
        id: 4599,
        name: 'Ústí nad Labem District',
        state_code: '427',
      },
      {
        id: 4576,
        name: 'Ústí nad Labem Region',
        state_code: '42',
      },
      {
        id: 4647,
        name: 'Ústí nad Orlicí District',
        state_code: '534',
      },
      {
        id: 4572,
        name: 'Vsetín District',
        state_code: '723',
      },
      {
        id: 4622,
        name: 'Vyškov District',
        state_code: '646',
      },
      {
        id: 4575,
        name: 'Vysočina Region',
        state_code: '63',
      },
      {
        id: 4648,
        name: 'Žďár nad Sázavou District',
        state_code: '635',
      },
      {
        id: 4563,
        name: 'Zlín District',
        state_code: '724',
      },
      {
        id: 4552,
        name: 'Zlín Region',
        state_code: '72',
      },
      {
        id: 4630,
        name: 'Znojmo District',
        state_code: '647',
      },
    ],
  },
  {
    name: 'Denmark',
    iso3: 'DNK',
    iso2: 'DK',
    phone_code: '45',
    capital: 'Copenhagen',
    currency: 'DKK',
    states: [
      {
        id: 1530,
        name: 'Capital Region of Denmark',
        state_code: '84',
      },
      {
        id: 1531,
        name: 'Central Denmark Region',
        state_code: '82',
      },
      {
        id: 1532,
        name: 'North Denmark Region',
        state_code: '81',
      },
      {
        id: 1529,
        name: 'Region of Southern Denmark',
        state_code: '83',
      },
      {
        id: 1528,
        name: 'Region Zealand',
        state_code: '85',
      },
    ],
  },
  {
    name: 'Djibouti',
    iso3: 'DJI',
    iso2: 'DJ',
    phone_code: '253',
    capital: 'Djibouti',
    currency: 'DJF',
    states: [
      {
        id: 2933,
        name: 'Ali Sabieh Region',
        state_code: 'AS',
      },
      {
        id: 2932,
        name: 'Arta Region',
        state_code: 'AR',
      },
      {
        id: 2930,
        name: 'Dikhil Region',
        state_code: 'DI',
      },
      {
        id: 2929,
        name: 'Djibouti',
        state_code: 'DJ',
      },
      {
        id: 2928,
        name: 'Obock Region',
        state_code: 'OB',
      },
      {
        id: 2931,
        name: 'Tadjourah Region',
        state_code: 'TA',
      },
    ],
  },
  {
    name: 'Dominica',
    iso3: 'DMA',
    iso2: 'DM',
    phone_code: '+1-767',
    capital: 'Roseau',
    currency: 'XCD',
    states: [
      {
        id: 4082,
        name: 'Saint Andrew Parish',
        state_code: '02',
      },
      {
        id: 4078,
        name: 'Saint David Parish',
        state_code: '03',
      },
      {
        id: 4079,
        name: 'Saint George Parish',
        state_code: '04',
      },
      {
        id: 4076,
        name: 'Saint John Parish',
        state_code: '05',
      },
      {
        id: 4085,
        name: 'Saint Joseph Parish',
        state_code: '06',
      },
      {
        id: 4083,
        name: 'Saint Luke Parish',
        state_code: '07',
      },
      {
        id: 4077,
        name: 'Saint Mark Parish',
        state_code: '08',
      },
      {
        id: 4080,
        name: 'Saint Patrick Parish',
        state_code: '09',
      },
      {
        id: 4084,
        name: 'Saint Paul Parish',
        state_code: '10',
      },
      {
        id: 4081,
        name: 'Saint Peter Parish',
        state_code: '11',
      },
    ],
  },
  {
    name: 'Dominican Republic',
    iso3: 'DOM',
    iso2: 'DO',
    phone_code: '+1-809 and 1-829',
    capital: 'Santo Domingo',
    currency: 'DOP',
    states: [
      {
        id: 4114,
        name: 'Azua Province',
        state_code: '02',
      },
      {
        id: 4105,
        name: 'Baoruco Province',
        state_code: '03',
      },
      {
        id: 4090,
        name: 'Barahona Province',
        state_code: '04',
      },
      {
        id: 4107,
        name: 'Dajabón Province',
        state_code: '05',
      },
      {
        id: 4095,
        name: 'Distrito Nacional',
        state_code: '01',
      },
      {
        id: 4113,
        name: 'Duarte Province',
        state_code: '06',
      },
      {
        id: 4086,
        name: 'El Seibo Province',
        state_code: '08',
      },
      {
        id: 4102,
        name: 'Espaillat Province',
        state_code: '09',
      },
      {
        id: 4106,
        name: 'Hato Mayor Province',
        state_code: '30',
      },
      {
        id: 4089,
        name: 'Hermanas Mirabal Province',
        state_code: '19',
      },
      {
        id: 4097,
        name: 'Independencia',
        state_code: '10',
      },
      {
        id: 4109,
        name: 'La Altagracia Province',
        state_code: '11',
      },
      {
        id: 4087,
        name: 'La Romana Province',
        state_code: '12',
      },
      {
        id: 4116,
        name: 'La Vega Province',
        state_code: '13',
      },
      {
        id: 4094,
        name: 'María Trinidad Sánchez Province',
        state_code: '14',
      },
      {
        id: 4099,
        name: 'Monseñor Nouel Province',
        state_code: '28',
      },
      {
        id: 4115,
        name: 'Monte Cristi Province',
        state_code: '15',
      },
      {
        id: 4111,
        name: 'Monte Plata Province',
        state_code: '29',
      },
      {
        id: 4101,
        name: 'Pedernales Province',
        state_code: '16',
      },
      {
        id: 4096,
        name: 'Peravia Province',
        state_code: '17',
      },
      {
        id: 4092,
        name: 'Puerto Plata Province',
        state_code: '18',
      },
      {
        id: 4103,
        name: 'Samaná Province',
        state_code: '20',
      },
      {
        id: 4091,
        name: 'San Cristóbal Province',
        state_code: '21',
      },
      {
        id: 4112,
        name: 'San José de Ocoa Province',
        state_code: '31',
      },
      {
        id: 4098,
        name: 'San Juan Province',
        state_code: '22',
      },
      {
        id: 4110,
        name: 'San Pedro de Macorís',
        state_code: '23',
      },
      {
        id: 4088,
        name: 'Sánchez Ramírez Province',
        state_code: '24',
      },
      {
        id: 4108,
        name: 'Santiago Province',
        state_code: '25',
      },
      {
        id: 4100,
        name: 'Santiago Rodríguez Province',
        state_code: '26',
      },
      {
        id: 4093,
        name: 'Santo Domingo Province',
        state_code: '32',
      },
      {
        id: 4104,
        name: 'Valverde Province',
        state_code: '27',
      },
    ],
  },
  {
    name: 'East Timor',
    iso3: 'TLS',
    iso2: 'TL',
    phone_code: '670',
    capital: 'Dili',
    currency: 'USD',
    states: [
      {
        id: 4520,
        name: 'Aileu municipality',
        state_code: 'AL',
      },
      {
        id: 4518,
        name: 'Ainaro Municipality',
        state_code: 'AN',
      },
      {
        id: 4521,
        name: 'Baucau Municipality',
        state_code: 'BA',
      },
      {
        id: 4525,
        name: 'Bobonaro Municipality',
        state_code: 'BO',
      },
      {
        id: 4522,
        name: 'Cova Lima Municipality',
        state_code: 'CO',
      },
      {
        id: 4524,
        name: 'Dili municipality',
        state_code: 'DI',
      },
      {
        id: 4516,
        name: 'Ermera District',
        state_code: 'ER',
      },
      {
        id: 4523,
        name: 'Lautém Municipality',
        state_code: 'LA',
      },
      {
        id: 4515,
        name: 'Liquiçá Municipality',
        state_code: 'LI',
      },
      {
        id: 4517,
        name: 'Manatuto District',
        state_code: 'MT',
      },
      {
        id: 4519,
        name: 'Manufahi Municipality',
        state_code: 'MF',
      },
      {
        id: 4514,
        name: 'Viqueque Municipality',
        state_code: 'VI',
      },
    ],
  },
  {
    name: 'Ecuador',
    iso3: 'ECU',
    iso2: 'EC',
    phone_code: '593',
    capital: 'Quito',
    currency: 'USD',
    states: [
      {
        id: 2923,
        name: 'Azuay Province',
        state_code: 'A',
      },
      {
        id: 2920,
        name: 'Bolívar Province',
        state_code: 'B',
      },
      {
        id: 2917,
        name: 'Cañar Province',
        state_code: 'F',
      },
      {
        id: 2915,
        name: 'Carchi Province',
        state_code: 'C',
      },
      {
        id: 2925,
        name: 'Chimborazo Province',
        state_code: 'H',
      },
      {
        id: 2921,
        name: 'Cotopaxi Province',
        state_code: 'X',
      },
      {
        id: 2924,
        name: 'El Oro Province',
        state_code: 'O',
      },
      {
        id: 2922,
        name: 'Esmeraldas',
        state_code: 'E',
      },
      {
        id: 2905,
        name: 'Galápagos Province',
        state_code: 'W',
      },
      {
        id: 2914,
        name: 'Guayas Province',
        state_code: 'G',
      },
      {
        id: 2911,
        name: 'Imbabura Province',
        state_code: 'I',
      },
      {
        id: 2910,
        name: 'Los Ríos Province',
        state_code: 'R',
      },
      {
        id: 2913,
        name: 'Manabí Province',
        state_code: 'M',
      },
      {
        id: 2918,
        name: 'Morona-Santiago Province',
        state_code: 'S',
      },
      {
        id: 2916,
        name: 'Napo Province',
        state_code: 'N',
      },
      {
        id: 2926,
        name: 'Orellana Province',
        state_code: 'D',
      },
      {
        id: 2907,
        name: 'Pastaza Province',
        state_code: 'Y',
      },
      {
        id: 2927,
        name: 'Pichincha Province',
        state_code: 'P',
      },
      {
        id: 2912,
        name: 'Santa Elena Province',
        state_code: 'SE',
      },
      {
        id: 2919,
        name: 'Santo Domingo de los Tsáchilas Province',
        state_code: 'SD',
      },
      {
        id: 2906,
        name: 'Sucumbíos Province',
        state_code: 'U',
      },
      {
        id: 2908,
        name: 'Tungurahua Province',
        state_code: 'T',
      },
      {
        id: 2909,
        name: 'Zamora-Chinchipe Province',
        state_code: 'Z',
      },
    ],
  },
  {
    name: 'Egypt',
    iso3: 'EGY',
    iso2: 'EG',
    phone_code: '20',
    capital: 'Cairo',
    currency: 'EGP',
    states: [
      {
        id: 3235,
        name: 'Alexandria Governorate',
        state_code: 'ALX',
      },
      {
        id: 3225,
        name: 'Aswan Governorate',
        state_code: 'ASN',
      },
      {
        id: 3236,
        name: 'Asyut Governorate',
        state_code: 'AST',
      },
      {
        id: 3241,
        name: 'Beheira Governorate',
        state_code: 'BH',
      },
      {
        id: 3230,
        name: 'Beni Suef Governorate',
        state_code: 'BNS',
      },
      {
        id: 3223,
        name: 'Cairo Governorate',
        state_code: 'C',
      },
      {
        id: 3245,
        name: 'Dakahlia Governorate',
        state_code: 'DK',
      },
      {
        id: 3224,
        name: 'Damietta Governorate',
        state_code: 'DT',
      },
      {
        id: 3238,
        name: 'Faiyum Governorate',
        state_code: 'FYM',
      },
      {
        id: 3234,
        name: 'Gharbia Governorate',
        state_code: 'GH',
      },
      {
        id: 3239,
        name: 'Giza Governorate',
        state_code: 'GZ',
      },
      {
        id: 3244,
        name: 'Ismailia Governorate',
        state_code: 'IS',
      },
      {
        id: 3222,
        name: 'Kafr el-Sheikh Governorate',
        state_code: 'KFS',
      },
      {
        id: 3242,
        name: 'Luxor Governorate',
        state_code: 'LX',
      },
      {
        id: 3231,
        name: 'Matrouh Governorate',
        state_code: 'MT',
      },
      {
        id: 3243,
        name: 'Minya Governorate',
        state_code: 'MN',
      },
      {
        id: 3228,
        name: 'Monufia Governorate',
        state_code: 'MNF',
      },
      {
        id: 3246,
        name: 'New Valley Governorate',
        state_code: 'WAD',
      },
      {
        id: 3227,
        name: 'North Sinai Governorate',
        state_code: 'SIN',
      },
      {
        id: 3229,
        name: 'Port Said Governorate',
        state_code: 'PTS',
      },
      {
        id: 3232,
        name: 'Qalyubia Governorate',
        state_code: 'KB',
      },
      {
        id: 3247,
        name: 'Qena Governorate',
        state_code: 'KN',
      },
      {
        id: 3240,
        name: 'Red Sea Governorate',
        state_code: 'BA',
      },
      {
        id: 3226,
        name: 'Sohag Governorate',
        state_code: 'SHG',
      },
      {
        id: 3237,
        name: 'South Sinai Governorate',
        state_code: 'JS',
      },
      {
        id: 3233,
        name: 'Suez Governorate',
        state_code: 'SUZ',
      },
    ],
  },
  {
    name: 'El Salvador',
    iso3: 'SLV',
    iso2: 'SV',
    phone_code: '503',
    capital: 'San Salvador',
    currency: 'USD',
    states: [
      {
        id: 4139,
        name: 'Ahuachapán Department',
        state_code: 'AH',
      },
      {
        id: 4132,
        name: 'Cabañas Department',
        state_code: 'CA',
      },
      {
        id: 4131,
        name: 'Chalatenango Department',
        state_code: 'CH',
      },
      {
        id: 4137,
        name: 'Cuscatlán Department',
        state_code: 'CU',
      },
      {
        id: 4134,
        name: 'La Libertad Department',
        state_code: 'LI',
      },
      {
        id: 4136,
        name: 'La Paz Department',
        state_code: 'PA',
      },
      {
        id: 4138,
        name: 'La Unión Department',
        state_code: 'UN',
      },
      {
        id: 4130,
        name: 'Morazán Department',
        state_code: 'MO',
      },
      {
        id: 4135,
        name: 'San Miguel Department',
        state_code: 'SM',
      },
      {
        id: 4133,
        name: 'San Salvador Department',
        state_code: 'SS',
      },
      {
        id: 4127,
        name: 'San Vicente Department',
        state_code: 'SV',
      },
      {
        id: 4128,
        name: 'Santa Ana Department',
        state_code: 'SA',
      },
      {
        id: 4140,
        name: 'Sonsonate Department',
        state_code: 'SO',
      },
      {
        id: 4129,
        name: 'Usulután Department',
        state_code: 'US',
      },
    ],
  },
  {
    name: 'Equatorial Guinea',
    iso3: 'GNQ',
    iso2: 'GQ',
    phone_code: '240',
    capital: 'Malabo',
    currency: 'XAF',
    states: [
      {
        id: 3444,
        name: 'Annobón Province',
        state_code: 'AN',
      },
      {
        id: 3446,
        name: 'Bioko Norte Province',
        state_code: 'BN',
      },
      {
        id: 3443,
        name: 'Bioko Sur Province',
        state_code: 'BS',
      },
      {
        id: 3445,
        name: 'Centro Sur Province',
        state_code: 'CS',
      },
      {
        id: 3442,
        name: 'Insular Region',
        state_code: 'I',
      },
      {
        id: 3439,
        name: 'Kié-Ntem Province',
        state_code: 'KN',
      },
      {
        id: 3441,
        name: 'Litoral Province',
        state_code: 'LI',
      },
      {
        id: 3438,
        name: 'Río Muni',
        state_code: 'C',
      },
      {
        id: 3440,
        name: 'Wele-Nzas Province',
        state_code: 'WN',
      },
    ],
  },
  {
    name: 'Eritrea',
    iso3: 'ERI',
    iso2: 'ER',
    phone_code: '291',
    capital: 'Asmara',
    currency: 'ERN',
    states: [
      {
        id: 3425,
        name: 'Anseba Region',
        state_code: 'AN',
      },
      {
        id: 3427,
        name: 'Debub Region',
        state_code: 'DU',
      },
      {
        id: 3428,
        name: 'Gash-Barka Region',
        state_code: 'GB',
      },
      {
        id: 3426,
        name: 'Maekel Region',
        state_code: 'MA',
      },
      {
        id: 3424,
        name: 'Northern Red Sea Region',
        state_code: 'SK',
      },
      {
        id: 3429,
        name: 'Southern Red Sea Region',
        state_code: 'DK',
      },
    ],
  },
  {
    name: 'Estonia',
    iso3: 'EST',
    iso2: 'EE',
    phone_code: '372',
    capital: 'Tallinn',
    currency: 'EUR',
    states: [
      {
        id: 3567,
        name: 'Harju County',
        state_code: '37',
      },
      {
        id: 3555,
        name: 'Hiiu County',
        state_code: '39',
      },
      {
        id: 3569,
        name: 'Ida-Viru County',
        state_code: '44',
      },
      {
        id: 3566,
        name: 'Järva County',
        state_code: '51',
      },
      {
        id: 3565,
        name: 'Jõgeva County',
        state_code: '49',
      },
      {
        id: 3568,
        name: 'Lääne County',
        state_code: '57',
      },
      {
        id: 3564,
        name: 'Lääne-Viru County',
        state_code: '59',
      },
      {
        id: 3562,
        name: 'Pärnu County',
        state_code: '67',
      },
      {
        id: 3563,
        name: 'Põlva County',
        state_code: '65',
      },
      {
        id: 3559,
        name: 'Rapla County',
        state_code: '70',
      },
      {
        id: 3561,
        name: 'Saare County',
        state_code: '74',
      },
      {
        id: 3557,
        name: 'Tartu County',
        state_code: '78',
      },
      {
        id: 3558,
        name: 'Valga County',
        state_code: '82',
      },
      {
        id: 3556,
        name: 'Viljandi County',
        state_code: '84',
      },
      {
        id: 3560,
        name: 'Võru County',
        state_code: '86',
      },
    ],
  },
  {
    name: 'Ethiopia',
    iso3: 'ETH',
    iso2: 'ET',
    phone_code: '251',
    capital: 'Addis Ababa',
    currency: 'ETB',
    states: [
      {
        id: 11,
        name: 'Addis Ababa',
        state_code: 'AA',
      },
      {
        id: 6,
        name: 'Afar Region',
        state_code: 'AF',
      },
      {
        id: 3,
        name: 'Amhara Region',
        state_code: 'AM',
      },
      {
        id: 9,
        name: 'Benishangul-Gumuz Region',
        state_code: 'BE',
      },
      {
        id: 8,
        name: 'Dire Dawa',
        state_code: 'DD',
      },
      {
        id: 10,
        name: 'Gambela Region',
        state_code: 'GA',
      },
      {
        id: 7,
        name: 'Harari Region',
        state_code: 'HA',
      },
      {
        id: 5,
        name: 'Oromia Region',
        state_code: 'OR',
      },
      {
        id: 2,
        name: 'Somali Region',
        state_code: 'SO',
      },
      {
        id: 1,
        name: "Southern Nations, Nationalities, and Peoples' Region",
        state_code: 'SN',
      },
      {
        id: 4,
        name: 'Tigray Region',
        state_code: 'TI',
      },
    ],
  },
  {
    name: 'Falkland Islands',
    iso3: 'FLK',
    iso2: 'FK',
    phone_code: '500',
    capital: 'Stanley',
    currency: 'FKP',
    states: [],
  },
  {
    name: 'Faroe Islands',
    iso3: 'FRO',
    iso2: 'FO',
    phone_code: '298',
    capital: 'Torshavn',
    currency: 'DKK',
    states: [],
  },
  {
    name: 'Fiji Islands',
    iso3: 'FJI',
    iso2: 'FJ',
    phone_code: '679',
    capital: 'Suva',
    currency: 'FJD',
    states: [
      {
        id: 1917,
        name: 'Ba',
        state_code: '01',
      },
      {
        id: 1930,
        name: 'Bua',
        state_code: '02',
      },
      {
        id: 1924,
        name: 'Cakaudrove',
        state_code: '03',
      },
      {
        id: 1929,
        name: 'Central Division',
        state_code: 'C',
      },
      {
        id: 1932,
        name: 'Eastern Division',
        state_code: 'E',
      },
      {
        id: 1934,
        name: 'Kadavu',
        state_code: '04',
      },
      {
        id: 1933,
        name: 'Lau',
        state_code: '05',
      },
      {
        id: 1916,
        name: 'Lomaiviti',
        state_code: '06',
      },
      {
        id: 1922,
        name: 'Macuata',
        state_code: '07',
      },
      {
        id: 1919,
        name: 'Nadroga-Navosa',
        state_code: '08',
      },
      {
        id: 1927,
        name: 'Naitasiri',
        state_code: '09',
      },
      {
        id: 1928,
        name: 'Namosi',
        state_code: '10',
      },
      {
        id: 1921,
        name: 'Northern Division',
        state_code: 'N',
      },
      {
        id: 1926,
        name: 'Ra',
        state_code: '11',
      },
      {
        id: 1920,
        name: 'Rewa',
        state_code: '12',
      },
      {
        id: 1931,
        name: 'Rotuma',
        state_code: 'R',
      },
      {
        id: 1925,
        name: 'Serua',
        state_code: '13',
      },
      {
        id: 1918,
        name: 'Tailevu',
        state_code: '14',
      },
      {
        id: 1923,
        name: 'Western Division',
        state_code: 'W',
      },
    ],
  },
  {
    name: 'Finland',
    iso3: 'FIN',
    iso2: 'FI',
    phone_code: '358',
    capital: 'Helsinki',
    currency: 'EUR',
    states: [
      {
        id: 1509,
        name: 'Åland Islands',
        state_code: '01',
      },
      {
        id: 1511,
        name: 'Central Finland',
        state_code: '08',
      },
      {
        id: 1494,
        name: 'Central Ostrobothnia',
        state_code: '07',
      },
      {
        id: 1492,
        name: 'Eastern Finland Province',
        state_code: 'IS',
      },
      {
        id: 1507,
        name: 'Finland Proper',
        state_code: '19',
      },
      {
        id: 1496,
        name: 'Kainuu',
        state_code: '05',
      },
      {
        id: 1512,
        name: 'Kymenlaakso',
        state_code: '09',
      },
      {
        id: 1500,
        name: 'Lapland',
        state_code: 'LL',
      },
      {
        id: 1504,
        name: 'North Karelia',
        state_code: '13',
      },
      {
        id: 1505,
        name: 'Northern Ostrobothnia',
        state_code: '14',
      },
      {
        id: 1503,
        name: 'Northern Savonia',
        state_code: '15',
      },
      {
        id: 1508,
        name: 'Ostrobothnia',
        state_code: '12',
      },
      {
        id: 1499,
        name: 'Oulu Province',
        state_code: 'OL',
      },
      {
        id: 1502,
        name: 'Päijänne Tavastia',
        state_code: '16',
      },
      {
        id: 1506,
        name: 'Pirkanmaa',
        state_code: '11',
      },
      {
        id: 1501,
        name: 'Satakunta',
        state_code: '17',
      },
      {
        id: 1497,
        name: 'South Karelia',
        state_code: '02',
      },
      {
        id: 1498,
        name: 'Southern Ostrobothnia',
        state_code: '03',
      },
      {
        id: 1495,
        name: 'Southern Savonia',
        state_code: '04',
      },
      {
        id: 1493,
        name: 'Tavastia Proper',
        state_code: '06',
      },
      {
        id: 1510,
        name: 'Uusimaa',
        state_code: '18',
      },
    ],
  },
  {
    name: 'France',
    iso3: 'FRA',
    iso2: 'FR',
    phone_code: '33',
    capital: 'Paris',
    currency: 'EUR',
    states: [
      {
        id: 4800,
        name: 'Alo',
        state_code: 'WF-AL',
      },
      {
        id: 4811,
        name: 'Alsace',
        state_code: 'A',
      },
      {
        id: 4808,
        name: 'Aquitaine',
        state_code: 'B',
      },
      {
        id: 4789,
        name: 'Auvergne',
        state_code: 'C',
      },
      {
        id: 4798,
        name: 'Auvergne-Rhône-Alpes',
        state_code: 'ARA',
      },
      {
        id: 4825,
        name: 'Bourgogne-Franche-Comté',
        state_code: 'BFC',
      },
      {
        id: 4807,
        name: 'Brittany',
        state_code: 'BRE',
      },
      {
        id: 4788,
        name: 'Burgundy',
        state_code: 'D',
      },
      {
        id: 4818,
        name: 'Centre-Val de Loire',
        state_code: 'CVL',
      },
      {
        id: 4791,
        name: 'Champagne-Ardenne',
        state_code: 'G',
      },
      {
        id: 4806,
        name: 'Corsica',
        state_code: 'COR',
      },
      {
        id: 4805,
        name: 'Franche-Comté',
        state_code: 'I',
      },
      {
        id: 4822,
        name: 'French Guiana',
        state_code: 'GF',
      },
      {
        id: 4824,
        name: 'French Polynesia',
        state_code: 'PF',
      },
      {
        id: 4820,
        name: 'Grand Est',
        state_code: 'GES',
      },
      {
        id: 4829,
        name: 'Guadeloupe',
        state_code: 'GP',
      },
      {
        id: 4828,
        name: 'Hauts-de-France',
        state_code: 'HDF',
      },
      {
        id: 4796,
        name: 'Île-de-France',
        state_code: 'IDF',
      },
      {
        id: 4803,
        name: 'Languedoc-Roussillon',
        state_code: 'K',
      },
      {
        id: 4792,
        name: 'Limousin',
        state_code: 'L',
      },
      {
        id: 4801,
        name: 'Lorraine',
        state_code: 'M',
      },
      {
        id: 4814,
        name: 'Lower Normandy',
        state_code: 'P',
      },
      {
        id: 4827,
        name: 'Martinique',
        state_code: 'MQ',
      },
      {
        id: 4797,
        name: 'Mayotte',
        state_code: 'YT',
      },
      {
        id: 4793,
        name: 'Nord-Pas-de-Calais',
        state_code: 'O',
      },
      {
        id: 4804,
        name: 'Normandy',
        state_code: 'NOR',
      },
      {
        id: 4795,
        name: 'Nouvelle-Aquitaine',
        state_code: 'NAQ',
      },
      {
        id: 4799,
        name: 'Occitania',
        state_code: 'OCC',
      },
      {
        id: 4816,
        name: 'Paris',
        state_code: '75',
      },
      {
        id: 4802,
        name: 'Pays de la Loire',
        state_code: 'PDL',
      },
      {
        id: 4790,
        name: 'Picardy',
        state_code: 'S',
      },
      {
        id: 4815,
        name: 'Poitou-Charentes',
        state_code: 'T',
      },
      {
        id: 4812,
        name: "Provence-Alpes-Côte d'Azur",
        state_code: 'PAC',
      },
      {
        id: 4823,
        name: 'Réunion',
        state_code: 'RE',
      },
      {
        id: 4813,
        name: 'Rhône-Alpes',
        state_code: 'V',
      },
      {
        id: 4794,
        name: 'Saint Barthélemy',
        state_code: 'BL',
      },
      {
        id: 4809,
        name: 'Saint Martin',
        state_code: 'MF',
      },
      {
        id: 4821,
        name: 'Saint Pierre and Miquelon',
        state_code: 'PM',
      },
      {
        id: 4819,
        name: 'Sigave',
        state_code: 'WF-SG',
      },
      {
        id: 4826,
        name: 'Upper Normandy',
        state_code: 'Q',
      },
      {
        id: 4817,
        name: 'Uvea',
        state_code: 'WF-UV',
      },
      {
        id: 4810,
        name: 'Wallis and Futuna',
        state_code: 'WF',
      },
    ],
  },
  {
    name: 'French Guiana',
    iso3: 'GUF',
    iso2: 'GF',
    phone_code: '594',
    capital: 'Cayenne',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'French Polynesia',
    iso3: 'PYF',
    iso2: 'PF',
    phone_code: '689',
    capital: 'Papeete',
    currency: 'XPF',
    states: [],
  },
  {
    name: 'French Southern Territories',
    iso3: 'ATF',
    iso2: 'TF',
    phone_code: '',
    capital: 'Port-aux-Francais',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Gabon',
    iso3: 'GAB',
    iso2: 'GA',
    phone_code: '241',
    capital: 'Libreville',
    currency: 'XAF',
    states: [
      {
        id: 2727,
        name: 'Estuaire Province',
        state_code: '1',
      },
      {
        id: 2726,
        name: 'Haut-Ogooué Province',
        state_code: '2',
      },
      {
        id: 2730,
        name: 'Moyen-Ogooué Province',
        state_code: '3',
      },
      {
        id: 2731,
        name: 'Ngounié Province',
        state_code: '4',
      },
      {
        id: 2725,
        name: 'Nyanga Province',
        state_code: '5',
      },
      {
        id: 2724,
        name: 'Ogooué-Ivindo Province',
        state_code: '6',
      },
      {
        id: 2729,
        name: 'Ogooué-Lolo Province',
        state_code: '7',
      },
      {
        id: 2728,
        name: 'Ogooué-Maritime Province',
        state_code: '8',
      },
      {
        id: 2723,
        name: 'Woleu-Ntem Province',
        state_code: '9',
      },
    ],
  },
  {
    name: 'Gambia The',
    iso3: 'GMB',
    iso2: 'GM',
    phone_code: '220',
    capital: 'Banjul',
    currency: 'GMD',
    states: [
      {
        id: 2666,
        name: 'Banjul',
        state_code: 'B',
      },
      {
        id: 2669,
        name: 'Central River Division',
        state_code: 'M',
      },
      {
        id: 2670,
        name: 'Lower River Division',
        state_code: 'L',
      },
      {
        id: 2671,
        name: 'North Bank Division',
        state_code: 'N',
      },
      {
        id: 2668,
        name: 'Upper River Division',
        state_code: 'U',
      },
      {
        id: 2667,
        name: 'West Coast Division',
        state_code: 'W',
      },
    ],
  },
  {
    name: 'Georgia',
    iso3: 'GEO',
    iso2: 'GE',
    phone_code: '995',
    capital: 'Tbilisi',
    currency: 'GEL',
    states: [
      {
        id: 900,
        name: 'Adjara',
        state_code: 'AJ',
      },
      {
        id: 901,
        name: 'Autonomous Republic of Abkhazia',
        state_code: 'AB',
      },
      {
        id: 907,
        name: 'Guria',
        state_code: 'GU',
      },
      {
        id: 905,
        name: 'Imereti',
        state_code: 'IM',
      },
      {
        id: 910,
        name: 'Kakheti',
        state_code: 'KA',
      },
      {
        id: 897,
        name: 'Khelvachauri Municipality',
        state_code: '29',
      },
      {
        id: 904,
        name: 'Kvemo Kartli',
        state_code: 'KK',
      },
      {
        id: 902,
        name: 'Mtskheta-Mtianeti',
        state_code: 'MM',
      },
      {
        id: 909,
        name: 'Racha-Lechkhumi and Kvemo Svaneti',
        state_code: 'RL',
      },
      {
        id: 908,
        name: 'Samegrelo-Zemo Svaneti',
        state_code: 'SZ',
      },
      {
        id: 906,
        name: 'Samtskhe-Javakheti',
        state_code: 'SJ',
      },
      {
        id: 898,
        name: 'Senaki Municipality',
        state_code: '50',
      },
      {
        id: 903,
        name: 'Shida Kartli',
        state_code: 'SK',
      },
      {
        id: 899,
        name: 'Tbilisi',
        state_code: 'TB',
      },
    ],
  },
  {
    name: 'Germany',
    iso3: 'DEU',
    iso2: 'DE',
    phone_code: '49',
    capital: 'Berlin',
    currency: 'EUR',
    states: [
      {
        id: 3006,
        name: 'Baden-Württemberg',
        state_code: 'BW',
      },
      {
        id: 3009,
        name: 'Bavaria',
        state_code: 'BY',
      },
      {
        id: 3010,
        name: 'Berlin',
        state_code: 'BE',
      },
      {
        id: 3013,
        name: 'Brandenburg',
        state_code: 'BB',
      },
      {
        id: 3014,
        name: 'Bremen',
        state_code: 'HB',
      },
      {
        id: 3016,
        name: 'Hamburg',
        state_code: 'HH',
      },
      {
        id: 3018,
        name: 'Hesse',
        state_code: 'HE',
      },
      {
        id: 3008,
        name: 'Lower Saxony',
        state_code: 'NI',
      },
      {
        id: 3007,
        name: 'Mecklenburg-Vorpommern',
        state_code: 'MV',
      },
      {
        id: 3012,
        name: 'Neufahrn in Niederbayern',
        state_code: 'LA',
      },
      {
        id: 3017,
        name: 'North Rhine-Westphalia',
        state_code: 'NW',
      },
      {
        id: 3019,
        name: 'Rhineland-Palatinate',
        state_code: 'RP',
      },
      {
        id: 3020,
        name: 'Saarland',
        state_code: 'SL',
      },
      {
        id: 3021,
        name: 'Saxony',
        state_code: 'SN',
      },
      {
        id: 3011,
        name: 'Saxony-Anhalt',
        state_code: 'ST',
      },
      {
        id: 3005,
        name: 'Schleswig-Holstein',
        state_code: 'SH',
      },
      {
        id: 3015,
        name: 'Thuringia',
        state_code: 'TH',
      },
    ],
  },
  {
    name: 'Ghana',
    iso3: 'GHA',
    iso2: 'GH',
    phone_code: '233',
    capital: 'Accra',
    currency: 'GHS',
    states: [
      {
        id: 48,
        name: 'Ashanti Region',
        state_code: 'AH',
      },
      {
        id: 53,
        name: 'Brong-Ahafo Region',
        state_code: 'BA',
      },
      {
        id: 52,
        name: 'Central Region',
        state_code: 'CP',
      },
      {
        id: 50,
        name: 'Eastern Region',
        state_code: 'EP',
      },
      {
        id: 54,
        name: 'Greater Accra Region',
        state_code: 'AA',
      },
      {
        id: 51,
        name: 'Northern Region',
        state_code: 'NP',
      },
      {
        id: 55,
        name: 'Upper East Region',
        state_code: 'UE',
      },
      {
        id: 57,
        name: 'Upper West Region',
        state_code: 'UW',
      },
      {
        id: 56,
        name: 'Volta Region',
        state_code: 'TV',
      },
      {
        id: 49,
        name: 'Western Region',
        state_code: 'WP',
      },
    ],
  },
  {
    name: 'Gibraltar',
    iso3: 'GIB',
    iso2: 'GI',
    phone_code: '350',
    capital: 'Gibraltar',
    currency: 'GIP',
    states: [],
  },
  {
    name: 'Greece',
    iso3: 'GRC',
    iso2: 'GR',
    phone_code: '30',
    capital: 'Athens',
    currency: 'EUR',
    states: [
      {
        id: 2116,
        name: 'Achaea Regional Unit',
        state_code: '13',
      },
      {
        id: 2123,
        name: 'Aetolia-Acarnania Regional Unit',
        state_code: '01',
      },
      {
        id: 2098,
        name: 'Arcadia Prefecture',
        state_code: '12',
      },
      {
        id: 2105,
        name: 'Argolis Regional Unit',
        state_code: '11',
      },
      {
        id: 2122,
        name: 'Attica Region',
        state_code: 'I',
      },
      {
        id: 2126,
        name: 'Boeotia Regional Unit',
        state_code: '03',
      },
      {
        id: 2128,
        name: 'Central Greece Region',
        state_code: 'H',
      },
      {
        id: 2125,
        name: 'Central Macedonia',
        state_code: 'B',
      },
      {
        id: 2115,
        name: 'Chania Regional Unit',
        state_code: '94',
      },
      {
        id: 2124,
        name: 'Corfu Prefecture',
        state_code: '22',
      },
      {
        id: 2129,
        name: 'Corinthia Regional Unit',
        state_code: '15',
      },
      {
        id: 2109,
        name: 'Crete Region',
        state_code: 'M',
      },
      {
        id: 2130,
        name: 'Drama Regional Unit',
        state_code: '52',
      },
      {
        id: 2120,
        name: 'East Attica Regional Unit',
        state_code: 'A2',
      },
      {
        id: 2117,
        name: 'East Macedonia and Thrace',
        state_code: 'A',
      },
      {
        id: 2110,
        name: 'Epirus Region',
        state_code: 'D',
      },
      {
        id: 2101,
        name: 'Euboea',
        state_code: '04',
      },
      {
        id: 2102,
        name: 'Grevena Prefecture',
        state_code: '51',
      },
      {
        id: 2099,
        name: 'Imathia Regional Unit',
        state_code: '53',
      },
      {
        id: 2113,
        name: 'Ioannina Regional Unit',
        state_code: '33',
      },
      {
        id: 2131,
        name: 'Ionian Islands Region',
        state_code: 'F',
      },
      {
        id: 2095,
        name: 'Karditsa Regional Unit',
        state_code: '41',
      },
      {
        id: 2100,
        name: 'Kastoria Regional Unit',
        state_code: '56',
      },
      {
        id: 2127,
        name: 'Kefalonia Prefecture',
        state_code: '23',
      },
      {
        id: 2111,
        name: 'Kilkis Regional Unit',
        state_code: '57',
      },
      {
        id: 2112,
        name: 'Kozani Prefecture',
        state_code: '58',
      },
      {
        id: 2106,
        name: 'Laconia',
        state_code: '16',
      },
      {
        id: 2132,
        name: 'Larissa Prefecture',
        state_code: '42',
      },
      {
        id: 2104,
        name: 'Lefkada Regional Unit',
        state_code: '24',
      },
      {
        id: 2107,
        name: 'Pella Regional Unit',
        state_code: '59',
      },
      {
        id: 2119,
        name: 'Peloponnese Region',
        state_code: 'J',
      },
      {
        id: 2114,
        name: 'Phthiotis Prefecture',
        state_code: '06',
      },
      {
        id: 2103,
        name: 'Preveza Prefecture',
        state_code: '34',
      },
      {
        id: 2121,
        name: 'Serres Prefecture',
        state_code: '62',
      },
      {
        id: 2118,
        name: 'South Aegean',
        state_code: 'L',
      },
      {
        id: 2097,
        name: 'Thessaloniki Regional Unit',
        state_code: '54',
      },
      {
        id: 2096,
        name: 'West Greece Region',
        state_code: 'G',
      },
      {
        id: 2108,
        name: 'West Macedonia Region',
        state_code: 'C',
      },
    ],
  },
  {
    name: 'Greenland',
    iso3: 'GRL',
    iso2: 'GL',
    phone_code: '299',
    capital: 'Nuuk',
    currency: 'DKK',
    states: [],
  },
  {
    name: 'Grenada',
    iso3: 'GRD',
    iso2: 'GD',
    phone_code: '+1-473',
    capital: "St. George's",
    currency: 'XCD',
    states: [
      {
        id: 3867,
        name: 'Carriacou and Petite Martinique',
        state_code: '10',
      },
      {
        id: 3865,
        name: 'Saint Andrew Parish',
        state_code: '01',
      },
      {
        id: 3869,
        name: 'Saint David Parish',
        state_code: '02',
      },
      {
        id: 3864,
        name: 'Saint George Parish',
        state_code: '03',
      },
      {
        id: 3868,
        name: 'Saint John Parish',
        state_code: '04',
      },
      {
        id: 3866,
        name: 'Saint Mark Parish',
        state_code: '05',
      },
      {
        id: 3863,
        name: 'Saint Patrick Parish',
        state_code: '06',
      },
    ],
  },
  {
    name: 'Guadeloupe',
    iso3: 'GLP',
    iso2: 'GP',
    phone_code: '590',
    capital: 'Basse-Terre',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Guam',
    iso3: 'GUM',
    iso2: 'GU',
    phone_code: '+1-671',
    capital: 'Hagatna',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Guatemala',
    iso3: 'GTM',
    iso2: 'GT',
    phone_code: '502',
    capital: 'Guatemala City',
    currency: 'GTQ',
    states: [
      {
        id: 3671,
        name: 'Alta Verapaz Department',
        state_code: 'AV',
      },
      {
        id: 3674,
        name: 'Baja Verapaz Department',
        state_code: 'BV',
      },
      {
        id: 3675,
        name: 'Chimaltenango Department',
        state_code: 'CM',
      },
      {
        id: 3666,
        name: 'Chiquimula Department',
        state_code: 'CQ',
      },
      {
        id: 3662,
        name: 'El Progreso Department',
        state_code: 'PR',
      },
      {
        id: 3677,
        name: 'Escuintla Department',
        state_code: 'ES',
      },
      {
        id: 3672,
        name: 'Guatemala Department',
        state_code: 'GU',
      },
      {
        id: 3670,
        name: 'Huehuetenango Department',
        state_code: 'HU',
      },
      {
        id: 3659,
        name: 'Izabal Department',
        state_code: 'IZ',
      },
      {
        id: 3658,
        name: 'Jalapa Department',
        state_code: 'JA',
      },
      {
        id: 3673,
        name: 'Jutiapa Department',
        state_code: 'JU',
      },
      {
        id: 3669,
        name: 'Petén Department',
        state_code: 'PE',
      },
      {
        id: 3668,
        name: 'Quetzaltenango Department',
        state_code: 'QZ',
      },
      {
        id: 3657,
        name: 'Quiché Department',
        state_code: 'QC',
      },
      {
        id: 3664,
        name: 'Retalhuleu Department',
        state_code: 'RE',
      },
      {
        id: 3676,
        name: 'Sacatepéquez Department',
        state_code: 'SA',
      },
      {
        id: 3667,
        name: 'San Marcos Department',
        state_code: 'SM',
      },
      {
        id: 3665,
        name: 'Santa Rosa Department',
        state_code: 'SR',
      },
      {
        id: 3661,
        name: 'Sololá Department',
        state_code: 'SO',
      },
      {
        id: 3660,
        name: 'Suchitepéquez Department',
        state_code: 'SU',
      },
      {
        id: 3663,
        name: 'Totonicapán Department',
        state_code: 'TO',
      },
    ],
  },
  {
    name: 'Guernsey and Alderney',
    iso3: 'GGY',
    iso2: 'GG',
    phone_code: '+44-1481',
    capital: 'St Peter Port',
    currency: 'GBP',
    states: [],
  },
  {
    name: 'Guinea',
    iso3: 'GIN',
    iso2: 'GN',
    phone_code: '224',
    capital: 'Conakry',
    currency: 'GNF',
    states: [
      {
        id: 2672,
        name: 'Beyla Prefecture',
        state_code: 'BE',
      },
      {
        id: 2699,
        name: 'Boffa Prefecture',
        state_code: 'BF',
      },
      {
        id: 2709,
        name: 'Boké Prefecture',
        state_code: 'BK',
      },
      {
        id: 2676,
        name: 'Boké Region',
        state_code: 'B',
      },
      {
        id: 2686,
        name: 'Conakry',
        state_code: 'C',
      },
      {
        id: 2705,
        name: 'Coyah Prefecture',
        state_code: 'CO',
      },
      {
        id: 2679,
        name: 'Dabola Prefecture',
        state_code: 'DB',
      },
      {
        id: 2706,
        name: 'Dalaba Prefecture',
        state_code: 'DL',
      },
      {
        id: 2688,
        name: 'Dinguiraye Prefecture',
        state_code: 'DI',
      },
      {
        id: 2681,
        name: 'Dubréka Prefecture',
        state_code: 'DU',
      },
      {
        id: 2682,
        name: 'Faranah Prefecture',
        state_code: 'FA',
      },
      {
        id: 2683,
        name: 'Forécariah Prefecture',
        state_code: 'FO',
      },
      {
        id: 2675,
        name: 'Fria Prefecture',
        state_code: 'FR',
      },
      {
        id: 2685,
        name: 'Gaoual Prefecture',
        state_code: 'GA',
      },
      {
        id: 2711,
        name: 'Guéckédou Prefecture',
        state_code: 'GU',
      },
      {
        id: 2704,
        name: 'Kankan Prefecture',
        state_code: 'KA',
      },
      {
        id: 2697,
        name: 'Kankan Region',
        state_code: 'K',
      },
      {
        id: 2710,
        name: 'Kérouané Prefecture',
        state_code: 'KE',
      },
      {
        id: 2693,
        name: 'Kindia Prefecture',
        state_code: 'KD',
      },
      {
        id: 2701,
        name: 'Kindia Region',
        state_code: 'D',
      },
      {
        id: 2691,
        name: 'Kissidougou Prefecture',
        state_code: 'KS',
      },
      {
        id: 2692,
        name: 'Koubia Prefecture',
        state_code: 'KB',
      },
      {
        id: 2703,
        name: 'Koundara Prefecture',
        state_code: 'KN',
      },
      {
        id: 2695,
        name: 'Kouroussa Prefecture',
        state_code: 'KO',
      },
      {
        id: 2680,
        name: 'Labé Prefecture',
        state_code: 'LA',
      },
      {
        id: 2677,
        name: 'Labé Region',
        state_code: 'L',
      },
      {
        id: 2690,
        name: 'Lélouma Prefecture',
        state_code: 'LE',
      },
      {
        id: 2708,
        name: 'Lola Prefecture',
        state_code: 'LO',
      },
      {
        id: 2702,
        name: 'Macenta Prefecture',
        state_code: 'MC',
      },
      {
        id: 2700,
        name: 'Mali Prefecture',
        state_code: 'ML',
      },
      {
        id: 2689,
        name: 'Mamou Prefecture',
        state_code: 'MM',
      },
      {
        id: 2698,
        name: 'Mamou Region',
        state_code: 'M',
      },
      {
        id: 2673,
        name: 'Mandiana Prefecture',
        state_code: 'MD',
      },
      {
        id: 2678,
        name: 'Nzérékoré Prefecture',
        state_code: 'NZ',
      },
      {
        id: 2684,
        name: 'Nzérékoré Region',
        state_code: 'N',
      },
      {
        id: 2694,
        name: 'Pita Prefecture',
        state_code: 'PI',
      },
      {
        id: 2707,
        name: 'Siguiri Prefecture',
        state_code: 'SI',
      },
      {
        id: 2687,
        name: 'Télimélé Prefecture',
        state_code: 'TE',
      },
      {
        id: 2696,
        name: 'Tougué Prefecture',
        state_code: 'TO',
      },
      {
        id: 2674,
        name: 'Yomou Prefecture',
        state_code: 'YO',
      },
    ],
  },
  {
    name: 'Guinea-Bissau',
    iso3: 'GNB',
    iso2: 'GW',
    phone_code: '245',
    capital: 'Bissau',
    currency: 'XOF',
    states: [
      {
        id: 2720,
        name: 'Bafatá',
        state_code: 'BA',
      },
      {
        id: 2714,
        name: 'Biombo Region',
        state_code: 'BM',
      },
      {
        id: 2722,
        name: 'Bolama Region',
        state_code: 'BL',
      },
      {
        id: 2713,
        name: 'Cacheu Region',
        state_code: 'CA',
      },
      {
        id: 2719,
        name: 'Gabú Region',
        state_code: 'GA',
      },
      {
        id: 2721,
        name: 'Leste Province',
        state_code: 'L',
      },
      {
        id: 2717,
        name: 'Norte Province',
        state_code: 'N',
      },
      {
        id: 2718,
        name: 'Oio Region',
        state_code: 'OI',
      },
      {
        id: 2715,
        name: 'Quinara Region',
        state_code: 'QU',
      },
      {
        id: 2716,
        name: 'Sul Province',
        state_code: 'S',
      },
      {
        id: 2712,
        name: 'Tombali Region',
        state_code: 'TO',
      },
    ],
  },
  {
    name: 'Guyana',
    iso3: 'GUY',
    iso2: 'GY',
    phone_code: '592',
    capital: 'Georgetown',
    currency: 'GYD',
    states: [
      {
        id: 2764,
        name: 'Barima-Waini',
        state_code: 'BA',
      },
      {
        id: 2760,
        name: 'Cuyuni-Mazaruni',
        state_code: 'CU',
      },
      {
        id: 2767,
        name: 'Demerara-Mahaica',
        state_code: 'DE',
      },
      {
        id: 2766,
        name: 'East Berbice-Corentyne',
        state_code: 'EB',
      },
      {
        id: 2768,
        name: 'Essequibo Islands-West Demerara',
        state_code: 'ES',
      },
      {
        id: 2762,
        name: 'Mahaica-Berbice',
        state_code: 'MA',
      },
      {
        id: 2765,
        name: 'Pomeroon-Supenaam',
        state_code: 'PM',
      },
      {
        id: 2761,
        name: 'Potaro-Siparuni',
        state_code: 'PT',
      },
      {
        id: 2763,
        name: 'Upper Demerara-Berbice',
        state_code: 'UD',
      },
      {
        id: 2769,
        name: 'Upper Takutu-Upper Essequibo',
        state_code: 'UT',
      },
    ],
  },
  {
    name: 'Haiti',
    iso3: 'HTI',
    iso2: 'HT',
    phone_code: '509',
    capital: 'Port-au-Prince',
    currency: 'HTG',
    states: [
      {
        id: 4123,
        name: 'Artibonite',
        state_code: 'AR',
      },
      {
        id: 4125,
        name: 'Centre',
        state_code: 'CE',
      },
      {
        id: 4119,
        name: "Grand'Anse",
        state_code: 'GA',
      },
      {
        id: 4118,
        name: 'Nippes',
        state_code: 'NI',
      },
      {
        id: 4117,
        name: 'Nord',
        state_code: 'ND',
      },
      {
        id: 4121,
        name: 'Nord-Est',
        state_code: 'NE',
      },
      {
        id: 4126,
        name: 'Nord-Ouest',
        state_code: 'NO',
      },
      {
        id: 4120,
        name: 'Ouest',
        state_code: 'OU',
      },
      {
        id: 4122,
        name: 'Sud',
        state_code: 'SD',
      },
      {
        id: 4124,
        name: 'Sud-Est',
        state_code: 'SE',
      },
    ],
  },
  {
    name: 'Heard and McDonald Islands',
    iso3: 'HMD',
    iso2: 'HM',
    phone_code: ' ',
    capital: '',
    currency: 'AUD',
    states: [],
  },
  {
    name: 'Honduras',
    iso3: 'HND',
    iso2: 'HN',
    phone_code: '504',
    capital: 'Tegucigalpa',
    currency: 'HNL',
    states: [
      {
        id: 4047,
        name: 'Atlántida Department',
        state_code: 'AT',
      },
      {
        id: 4045,
        name: 'Bay Islands Department',
        state_code: 'IB',
      },
      {
        id: 4041,
        name: 'Choluteca Department',
        state_code: 'CH',
      },
      {
        id: 4051,
        name: 'Colón Department',
        state_code: 'CL',
      },
      {
        id: 4042,
        name: 'Comayagua Department',
        state_code: 'CM',
      },
      {
        id: 4049,
        name: 'Copán Department',
        state_code: 'CP',
      },
      {
        id: 4046,
        name: 'Cortés Department',
        state_code: 'CR',
      },
      {
        id: 4043,
        name: 'El Paraíso Department',
        state_code: 'EP',
      },
      {
        id: 4052,
        name: 'Francisco Morazán Department',
        state_code: 'FM',
      },
      {
        id: 4048,
        name: 'Gracias a Dios Department',
        state_code: 'GD',
      },
      {
        id: 4044,
        name: 'Intibucá Department',
        state_code: 'IN',
      },
      {
        id: 4058,
        name: 'La Paz Department',
        state_code: 'LP',
      },
      {
        id: 4054,
        name: 'Lempira Department',
        state_code: 'LE',
      },
      {
        id: 4056,
        name: 'Ocotepeque Department',
        state_code: 'OC',
      },
      {
        id: 4050,
        name: 'Olancho Department',
        state_code: 'OL',
      },
      {
        id: 4053,
        name: 'Santa Bárbara Department',
        state_code: 'SB',
      },
      {
        id: 4055,
        name: 'Valle Department',
        state_code: 'VA',
      },
      {
        id: 4057,
        name: 'Yoro Department',
        state_code: 'YO',
      },
    ],
  },
  {
    name: 'Hong Kong S.A.R.',
    iso3: 'HKG',
    iso2: 'HK',
    phone_code: '852',
    capital: 'Hong Kong',
    currency: 'HKD',
    states: [],
  },
  {
    name: 'Hungary',
    iso3: 'HUN',
    iso2: 'HU',
    phone_code: '36',
    capital: 'Budapest',
    currency: 'HUF',
    states: [
      {
        id: 1048,
        name: 'Bács-Kiskun County',
        state_code: 'BK',
      },
      {
        id: 1055,
        name: 'Baranya County',
        state_code: 'BA',
      },
      {
        id: 1060,
        name: 'Békés County',
        state_code: 'BE',
      },
      {
        id: 1036,
        name: 'Békéscsaba',
        state_code: 'BC',
      },
      {
        id: 1058,
        name: 'Borsod-Abaúj-Zemplén County',
        state_code: 'BZ',
      },
      {
        id: 1064,
        name: 'Budapest',
        state_code: 'BU',
      },
      {
        id: 1031,
        name: 'Csongrád County',
        state_code: 'CS',
      },
      {
        id: 1032,
        name: 'Debrecen',
        state_code: 'DE',
      },
      {
        id: 1049,
        name: 'Dunaújváros',
        state_code: 'DU',
      },
      {
        id: 1037,
        name: 'Eger',
        state_code: 'EG',
      },
      {
        id: 1028,
        name: 'Érd',
        state_code: 'ER',
      },
      {
        id: 1044,
        name: 'Fejér County',
        state_code: 'FE',
      },
      {
        id: 1041,
        name: 'Győr',
        state_code: 'GY',
      },
      {
        id: 1042,
        name: 'Győr-Moson-Sopron County',
        state_code: 'GS',
      },
      {
        id: 1063,
        name: 'Hajdú-Bihar County',
        state_code: 'HB',
      },
      {
        id: 1040,
        name: 'Heves County',
        state_code: 'HE',
      },
      {
        id: 1027,
        name: 'Hódmezővásárhely',
        state_code: 'HV',
      },
      {
        id: 1043,
        name: 'Jász-Nagykun-Szolnok County',
        state_code: 'JN',
      },
      {
        id: 1067,
        name: 'Kaposvár',
        state_code: 'KV',
      },
      {
        id: 1056,
        name: 'Kecskemét',
        state_code: 'KM',
      },
      {
        id: 1065,
        name: 'Miskolc',
        state_code: 'MI',
      },
      {
        id: 1030,
        name: 'Nagykanizsa',
        state_code: 'NK',
      },
      {
        id: 1051,
        name: 'Nógrád County',
        state_code: 'NO',
      },
      {
        id: 1034,
        name: 'Nyíregyháza',
        state_code: 'NY',
      },
      {
        id: 1053,
        name: 'Pécs',
        state_code: 'PS',
      },
      {
        id: 1059,
        name: 'Pest County',
        state_code: 'PE',
      },
      {
        id: 1068,
        name: 'Salgótarján',
        state_code: 'ST',
      },
      {
        id: 1035,
        name: 'Somogy County',
        state_code: 'SO',
      },
      {
        id: 1057,
        name: 'Sopron',
        state_code: 'SN',
      },
      {
        id: 1045,
        name: 'Szabolcs-Szatmár-Bereg County',
        state_code: 'SZ',
      },
      {
        id: 1029,
        name: 'Szeged',
        state_code: 'SD',
      },
      {
        id: 1033,
        name: 'Székesfehérvár',
        state_code: 'SF',
      },
      {
        id: 1061,
        name: 'Szekszárd',
        state_code: 'SS',
      },
      {
        id: 1047,
        name: 'Szolnok',
        state_code: 'SK',
      },
      {
        id: 1052,
        name: 'Szombathely',
        state_code: 'SH',
      },
      {
        id: 1066,
        name: 'Tatabánya',
        state_code: 'TB',
      },
      {
        id: 1038,
        name: 'Tolna County',
        state_code: 'TO',
      },
      {
        id: 1039,
        name: 'Vas County',
        state_code: 'VA',
      },
      {
        id: 1062,
        name: 'Veszprém',
        state_code: 'VM',
      },
      {
        id: 1054,
        name: 'Veszprém County',
        state_code: 'VE',
      },
      {
        id: 1046,
        name: 'Zala County',
        state_code: 'ZA',
      },
      {
        id: 1050,
        name: 'Zalaegerszeg',
        state_code: 'ZE',
      },
    ],
  },
  {
    name: 'Iceland',
    iso3: 'ISL',
    iso2: 'IS',
    phone_code: '354',
    capital: 'Reykjavik',
    currency: 'ISK',
    states: [
      {
        id: 3431,
        name: 'Capital Region',
        state_code: '1',
      },
      {
        id: 3433,
        name: 'Eastern Region',
        state_code: '7',
      },
      {
        id: 3437,
        name: 'Northeastern Region',
        state_code: '6',
      },
      {
        id: 3435,
        name: 'Northwestern Region',
        state_code: '5',
      },
      {
        id: 3430,
        name: 'Southern Peninsula Region',
        state_code: '2',
      },
      {
        id: 3434,
        name: 'Southern Region',
        state_code: '8',
      },
      {
        id: 3436,
        name: 'Western Region',
        state_code: '3',
      },
      {
        id: 3432,
        name: 'Westfjords',
        state_code: '4',
      },
    ],
  },
  {
    name: 'India',
    iso3: 'IND',
    iso2: 'IN',
    phone_code: '91',
    capital: 'New Delhi',
    currency: 'INR',
    states: [
      {
        id: 4023,
        name: 'Andaman and Nicobar Islands',
        state_code: 'AN',
      },
      {
        id: 4017,
        name: 'Andhra Pradesh',
        state_code: 'AP',
      },
      {
        id: 4024,
        name: 'Arunachal Pradesh',
        state_code: 'AR',
      },
      {
        id: 4027,
        name: 'Assam',
        state_code: 'AS',
      },
      {
        id: 4037,
        name: 'Bihar',
        state_code: 'BR',
      },
      {
        id: 4031,
        name: 'Chandigarh',
        state_code: 'CH',
      },
      {
        id: 4040,
        name: 'Chhattisgarh',
        state_code: 'CT',
      },
      {
        id: 4032,
        name: 'Dadra and Nagar Haveli',
        state_code: 'DN',
      },
      {
        id: 4033,
        name: 'Daman and Diu',
        state_code: 'DD',
      },
      {
        id: 4021,
        name: 'Delhi',
        state_code: 'DL',
      },
      {
        id: 4009,
        name: 'Goa',
        state_code: 'GA',
      },
      {
        id: 4030,
        name: 'Gujarat',
        state_code: 'GJ',
      },
      {
        id: 4007,
        name: 'Haryana',
        state_code: 'HR',
      },
      {
        id: 4020,
        name: 'Himachal Pradesh',
        state_code: 'HP',
      },
      {
        id: 4029,
        name: 'Jammu and Kashmir',
        state_code: 'JK',
      },
      {
        id: 4025,
        name: 'Jharkhand',
        state_code: 'JH',
      },
      {
        id: 4026,
        name: 'Karnataka',
        state_code: 'KA',
      },
      {
        id: 4028,
        name: 'Kerala',
        state_code: 'KL',
      },
      {
        id: 4852,
        name: 'Ladakh',
        state_code: 'LA',
      },
      {
        id: 4019,
        name: 'Lakshadweep',
        state_code: 'LD',
      },
      {
        id: 4039,
        name: 'Madhya Pradesh',
        state_code: 'MP',
      },
      {
        id: 4008,
        name: 'Maharashtra',
        state_code: 'MH',
      },
      {
        id: 4010,
        name: 'Manipur',
        state_code: 'MN',
      },
      {
        id: 4006,
        name: 'Meghalaya',
        state_code: 'ML',
      },
      {
        id: 4036,
        name: 'Mizoram',
        state_code: 'MZ',
      },
      {
        id: 4018,
        name: 'Nagaland',
        state_code: 'NL',
      },
      {
        id: 4013,
        name: 'Odisha',
        state_code: 'OR',
      },
      {
        id: 4011,
        name: 'Puducherry',
        state_code: 'PY',
      },
      {
        id: 4015,
        name: 'Punjab',
        state_code: 'PB',
      },
      {
        id: 4014,
        name: 'Rajasthan',
        state_code: 'RJ',
      },
      {
        id: 4034,
        name: 'Sikkim',
        state_code: 'SK',
      },
      {
        id: 4035,
        name: 'Tamil Nadu',
        state_code: 'TN',
      },
      {
        id: 4012,
        name: 'Telangana',
        state_code: 'TG',
      },
      {
        id: 4038,
        name: 'Tripura',
        state_code: 'TR',
      },
      {
        id: 4022,
        name: 'Uttar Pradesh',
        state_code: 'UP',
      },
      {
        id: 4016,
        name: 'Uttarakhand',
        state_code: 'UT',
      },
    ],
  },
  {
    name: 'Indonesia',
    iso3: 'IDN',
    iso2: 'ID',
    phone_code: '62',
    capital: 'Jakarta',
    currency: 'IDR',
    states: [
      {
        id: 1822,
        name: 'Aceh',
        state_code: 'AC',
      },
      {
        id: 1826,
        name: 'Bali',
        state_code: 'BA',
      },
      {
        id: 1820,
        name: 'Bangka Belitung Islands',
        state_code: 'BB',
      },
      {
        id: 1810,
        name: 'Banten',
        state_code: 'BT',
      },
      {
        id: 1793,
        name: 'Bengkulu',
        state_code: 'BE',
      },
      {
        id: 1802,
        name: 'Central Java',
        state_code: 'JT',
      },
      {
        id: 1794,
        name: 'Central Kalimantan',
        state_code: 'KT',
      },
      {
        id: 1813,
        name: 'Central Sulawesi',
        state_code: 'ST',
      },
      {
        id: 1827,
        name: 'East Java',
        state_code: 'JI',
      },
      {
        id: 1804,
        name: 'East Kalimantan',
        state_code: 'KI',
      },
      {
        id: 1818,
        name: 'East Nusa Tenggara',
        state_code: 'NT',
      },
      {
        id: 1812,
        name: 'Gorontalo',
        state_code: 'GO',
      },
      {
        id: 1805,
        name: 'Jakarta',
        state_code: 'JK',
      },
      {
        id: 1815,
        name: 'Jambi',
        state_code: 'JA',
      },
      {
        id: 1806,
        name: 'Kalimantan',
        state_code: 'KA',
      },
      {
        id: 1811,
        name: 'Lampung',
        state_code: 'LA',
      },
      {
        id: 1821,
        name: 'Lesser Sunda Islands',
        state_code: 'NU',
      },
      {
        id: 1800,
        name: 'Maluku',
        state_code: 'MA',
      },
      {
        id: 1823,
        name: 'Maluku Islands',
        state_code: 'ML',
      },
      {
        id: 1824,
        name: 'North Kalimantan',
        state_code: 'KU',
      },
      {
        id: 1801,
        name: 'North Maluku',
        state_code: 'MU',
      },
      {
        id: 1808,
        name: 'North Sulawesi',
        state_code: 'SA',
      },
      {
        id: 1792,
        name: 'North Sumatra',
        state_code: 'SU',
      },
      {
        id: 1798,
        name: 'Papua',
        state_code: 'PA',
      },
      {
        id: 1809,
        name: 'Riau',
        state_code: 'RI',
      },
      {
        id: 1807,
        name: 'Riau Islands',
        state_code: 'KR',
      },
      {
        id: 1819,
        name: 'South Kalimantan',
        state_code: 'KS',
      },
      {
        id: 1795,
        name: 'South Sulawesi',
        state_code: 'SN',
      },
      {
        id: 1816,
        name: 'South Sumatra',
        state_code: 'SS',
      },
      {
        id: 1796,
        name: 'Southeast Sulawesi',
        state_code: 'SG',
      },
      {
        id: 1829,
        name: 'Special Region of Yogyakarta',
        state_code: 'YO',
      },
      {
        id: 1803,
        name: 'Sulawesi',
        state_code: 'SL',
      },
      {
        id: 1797,
        name: 'Sumatra',
        state_code: 'SM',
      },
      {
        id: 1825,
        name: 'West Java',
        state_code: 'JB',
      },
      {
        id: 1814,
        name: 'West Nusa Tenggara',
        state_code: 'NB',
      },
      {
        id: 1799,
        name: 'West Papua',
        state_code: 'PB',
      },
      {
        id: 1817,
        name: 'West Sulawesi',
        state_code: 'SR',
      },
      {
        id: 1828,
        name: 'West Sumatra',
        state_code: 'SB',
      },
    ],
  },
  {
    name: 'Iran',
    iso3: 'IRN',
    iso2: 'IR',
    phone_code: '98',
    capital: 'Tehran',
    currency: 'IRR',
    states: [
      {
        id: 3929,
        name: 'Alborz Province',
        state_code: '32',
      },
      {
        id: 3934,
        name: 'Ardabil Province',
        state_code: '03',
      },
      {
        id: 3932,
        name: 'Bushehr Province',
        state_code: '06',
      },
      {
        id: 3921,
        name: 'Chaharmahal and Bakhtiari Province',
        state_code: '08',
      },
      {
        id: 3944,
        name: 'East Azerbaijan Province',
        state_code: '01',
      },
      {
        id: 3939,
        name: 'Fars Province',
        state_code: '14',
      },
      {
        id: 3920,
        name: 'Gilan Province',
        state_code: '19',
      },
      {
        id: 3933,
        name: 'Golestan Province',
        state_code: '27',
      },
      {
        id: 3937,
        name: 'Hormozgan Province',
        state_code: '23',
      },
      {
        id: 3918,
        name: 'Ilam Province',
        state_code: '05',
      },
      {
        id: 3923,
        name: 'Isfahan Province',
        state_code: '04',
      },
      {
        id: 3943,
        name: 'Kerman Province',
        state_code: '15',
      },
      {
        id: 3919,
        name: 'Kermanshah Province',
        state_code: '17',
      },
      {
        id: 3917,
        name: 'Khuzestan Province',
        state_code: '10',
      },
      {
        id: 3926,
        name: 'Kohgiluyeh and Boyer-Ahmad Province',
        state_code: '18',
      },
      {
        id: 3935,
        name: 'Kurdistan Province',
        state_code: '16',
      },
      {
        id: 3928,
        name: 'Lorestan Province',
        state_code: '20',
      },
      {
        id: 3916,
        name: 'Markazi Province',
        state_code: '22',
      },
      {
        id: 3938,
        name: 'Mazandaran Province',
        state_code: '21',
      },
      {
        id: 3942,
        name: 'North Khorasan Province',
        state_code: '31',
      },
      {
        id: 3941,
        name: 'Qazvin Province',
        state_code: '28',
      },
      {
        id: 3922,
        name: 'Qom Province',
        state_code: '26',
      },
      {
        id: 3927,
        name: 'Razavi Khorasan Province',
        state_code: '30',
      },
      {
        id: 3940,
        name: 'Semnan Province',
        state_code: '12',
      },
      {
        id: 3931,
        name: 'Sistan and Baluchestan',
        state_code: '13',
      },
      {
        id: 3930,
        name: 'South Khorasan Province',
        state_code: '29',
      },
      {
        id: 3945,
        name: 'Tehran Province',
        state_code: '07',
      },
      {
        id: 3924,
        name: 'West Azarbaijan Province',
        state_code: '02',
      },
      {
        id: 3936,
        name: 'Yazd Province',
        state_code: '25',
      },
      {
        id: 3925,
        name: 'Zanjan Province',
        state_code: '11',
      },
    ],
  },
  {
    name: 'Iraq',
    iso3: 'IRQ',
    iso2: 'IQ',
    phone_code: '964',
    capital: 'Baghdad',
    currency: 'IQD',
    states: [
      {
        id: 3964,
        name: 'Al Anbar Governorate',
        state_code: 'AN',
      },
      {
        id: 3958,
        name: 'Al Muthanna Governorate',
        state_code: 'MU',
      },
      {
        id: 3956,
        name: 'Al-Qādisiyyah Governorate',
        state_code: 'QA',
      },
      {
        id: 3955,
        name: 'Babylon Governorate',
        state_code: 'BB',
      },
      {
        id: 3959,
        name: 'Baghdad Governorate',
        state_code: 'BG',
      },
      {
        id: 3960,
        name: 'Basra Governorate',
        state_code: 'BA',
      },
      {
        id: 3954,
        name: 'Dhi Qar Governorate',
        state_code: 'DQ',
      },
      {
        id: 3965,
        name: 'Diyala Governorate',
        state_code: 'DI',
      },
      {
        id: 3967,
        name: 'Dohuk Governorate',
        state_code: 'DA',
      },
      {
        id: 3968,
        name: 'Erbil Governorate',
        state_code: 'AR',
      },
      {
        id: 3957,
        name: 'Karbala Governorate',
        state_code: 'KA',
      },
      {
        id: 3971,
        name: 'Kirkuk Governorate',
        state_code: 'KI',
      },
      {
        id: 3966,
        name: 'Maysan Governorate',
        state_code: 'MA',
      },
      {
        id: 3962,
        name: 'Najaf Governorate',
        state_code: 'NA',
      },
      {
        id: 3963,
        name: 'Nineveh Governorate',
        state_code: 'NI',
      },
      {
        id: 3961,
        name: 'Saladin Governorate',
        state_code: 'SD',
      },
      {
        id: 3969,
        name: 'Sulaymaniyah Governorate',
        state_code: 'SU',
      },
      {
        id: 3970,
        name: 'Wasit Governorate',
        state_code: 'WA',
      },
    ],
  },
  {
    name: 'Ireland',
    iso3: 'IRL',
    iso2: 'IE',
    phone_code: '353',
    capital: 'Dublin',
    currency: 'EUR',
    states: [
      {
        id: 1087,
        name: 'Connacht',
        state_code: 'C',
      },
      {
        id: 1095,
        name: 'County Carlow',
        state_code: 'CW',
      },
      {
        id: 1088,
        name: 'County Cavan',
        state_code: 'CN',
      },
      {
        id: 1091,
        name: 'County Clare',
        state_code: 'CE',
      },
      {
        id: 1074,
        name: 'County Cork',
        state_code: 'CO',
      },
      {
        id: 1071,
        name: 'County Donegal',
        state_code: 'DL',
      },
      {
        id: 1072,
        name: 'County Dublin',
        state_code: 'D',
      },
      {
        id: 1079,
        name: 'County Galway',
        state_code: 'G',
      },
      {
        id: 1077,
        name: 'County Kerry',
        state_code: 'KY',
      },
      {
        id: 1082,
        name: 'County Kildare',
        state_code: 'KE',
      },
      {
        id: 1090,
        name: 'County Kilkenny',
        state_code: 'KK',
      },
      {
        id: 1096,
        name: 'County Laois',
        state_code: 'LS',
      },
      {
        id: 1094,
        name: 'County Limerick',
        state_code: 'LK',
      },
      {
        id: 1076,
        name: 'County Longford',
        state_code: 'LD',
      },
      {
        id: 1083,
        name: 'County Louth',
        state_code: 'LH',
      },
      {
        id: 1084,
        name: 'County Mayo',
        state_code: 'MO',
      },
      {
        id: 1092,
        name: 'County Meath',
        state_code: 'MH',
      },
      {
        id: 1075,
        name: 'County Monaghan',
        state_code: 'MN',
      },
      {
        id: 1078,
        name: 'County Offaly',
        state_code: 'OY',
      },
      {
        id: 1081,
        name: 'County Roscommon',
        state_code: 'RN',
      },
      {
        id: 1070,
        name: 'County Sligo',
        state_code: 'SO',
      },
      {
        id: 1069,
        name: 'County Tipperary',
        state_code: 'TA',
      },
      {
        id: 1089,
        name: 'County Waterford',
        state_code: 'WD',
      },
      {
        id: 1097,
        name: 'County Westmeath',
        state_code: 'WH',
      },
      {
        id: 1093,
        name: 'County Wexford',
        state_code: 'WX',
      },
      {
        id: 1085,
        name: 'County Wicklow',
        state_code: 'WW',
      },
      {
        id: 1073,
        name: 'Leinster',
        state_code: 'L',
      },
      {
        id: 1080,
        name: 'Munster',
        state_code: 'M',
      },
      {
        id: 1086,
        name: 'Ulster',
        state_code: 'U',
      },
    ],
  },
  {
    name: 'Israel',
    iso3: 'ISR',
    iso2: 'IL',
    phone_code: '972',
    capital: 'Jerusalem',
    currency: 'ILS',
    states: [
      {
        id: 1367,
        name: 'Central District',
        state_code: 'M',
      },
      {
        id: 1369,
        name: 'Haifa District',
        state_code: 'HA',
      },
      {
        id: 1370,
        name: 'Jerusalem District',
        state_code: 'JM',
      },
      {
        id: 1366,
        name: 'Northern District',
        state_code: 'Z',
      },
      {
        id: 1368,
        name: 'Southern District',
        state_code: 'D',
      },
      {
        id: 1371,
        name: 'Tel Aviv District',
        state_code: 'TA',
      },
    ],
  },
  {
    name: 'Italy',
    iso3: 'ITA',
    iso2: 'IT',
    phone_code: '39',
    capital: 'Rome',
    currency: 'EUR',
    states: [
      {
        id: 1679,
        name: 'Abruzzo',
        state_code: '65',
      },
      {
        id: 1716,
        name: 'Aosta Valley',
        state_code: '23',
      },
      {
        id: 1688,
        name: 'Apulia',
        state_code: '75',
      },
      {
        id: 1706,
        name: 'Basilicata',
        state_code: '77',
      },
      {
        id: 1701,
        name: 'Benevento Province',
        state_code: 'BN',
      },
      {
        id: 1703,
        name: 'Calabria',
        state_code: '78',
      },
      {
        id: 1669,
        name: 'Campania',
        state_code: '72',
      },
      {
        id: 1773,
        name: 'Emilia-Romagna',
        state_code: '45',
      },
      {
        id: 1756,
        name: 'Friuli–Venezia Giulia',
        state_code: '36',
      },
      {
        id: 1678,
        name: 'Lazio',
        state_code: '62',
      },
      {
        id: 1727,
        name: 'Libero consorzio comunale di Agrigento',
        state_code: 'AG',
      },
      {
        id: 1718,
        name: 'Libero consorzio comunale di Caltanissetta',
        state_code: 'CL',
      },
      {
        id: 1723,
        name: 'Libero consorzio comunale di Enna',
        state_code: 'EN',
      },
      {
        id: 1729,
        name: 'Libero consorzio comunale di Ragusa',
        state_code: 'RG',
      },
      {
        id: 1667,
        name: 'Libero consorzio comunale di Siracusa',
        state_code: 'SR',
      },
      {
        id: 1733,
        name: 'Libero consorzio comunale di Trapani',
        state_code: 'TP',
      },
      {
        id: 1768,
        name: 'Liguria',
        state_code: '42',
      },
      {
        id: 1705,
        name: 'Lombardy',
        state_code: '25',
      },
      {
        id: 1670,
        name: 'Marche',
        state_code: '57',
      },
      {
        id: 1772,
        name: 'Metropolitan City of Bari',
        state_code: 'BA',
      },
      {
        id: 1684,
        name: 'Metropolitan City of Bologna',
        state_code: 'BO',
      },
      {
        id: 1682,
        name: 'Metropolitan City of Cagliari',
        state_code: 'CA',
      },
      {
        id: 1766,
        name: 'Metropolitan City of Catania',
        state_code: 'CT',
      },
      {
        id: 1680,
        name: 'Metropolitan City of Florence',
        state_code: 'FI',
      },
      {
        id: 1699,
        name: 'Metropolitan City of Genoa',
        state_code: 'GE',
      },
      {
        id: 1770,
        name: 'Metropolitan City of Messina',
        state_code: 'ME',
      },
      {
        id: 1698,
        name: 'Metropolitan City of Milan',
        state_code: 'MI',
      },
      {
        id: 1724,
        name: 'Metropolitan City of Naples',
        state_code: 'NA',
      },
      {
        id: 1668,
        name: 'Metropolitan City of Palermo',
        state_code: 'PA',
      },
      {
        id: 1671,
        name: 'Metropolitan City of Reggio Calabria',
        state_code: 'RC',
      },
      {
        id: 1711,
        name: 'Metropolitan City of Rome',
        state_code: 'RM',
      },
      {
        id: 1710,
        name: 'Metropolitan City of Turin',
        state_code: 'TO',
      },
      {
        id: 1673,
        name: 'Metropolitan City of Venice',
        state_code: 'VE',
      },
      {
        id: 1695,
        name: 'Molise',
        state_code: '67',
      },
      {
        id: 1693,
        name: 'Pesaro and Urbino Province',
        state_code: 'PU',
      },
      {
        id: 1702,
        name: 'Piedmont',
        state_code: '21',
      },
      {
        id: 1783,
        name: 'Province of Alessandria',
        state_code: 'AL',
      },
      {
        id: 1672,
        name: 'Province of Ancona',
        state_code: 'AN',
      },
      {
        id: 1681,
        name: 'Province of Ascoli Piceno',
        state_code: 'AP',
      },
      {
        id: 1780,
        name: 'Province of Asti',
        state_code: 'AT',
      },
      {
        id: 1692,
        name: 'Province of Avellino',
        state_code: 'AV',
      },
      {
        id: 1686,
        name: 'Province of Barletta-Andria-Trani',
        state_code: 'BT',
      },
      {
        id: 1689,
        name: 'Province of Belluno',
        state_code: 'BL',
      },
      {
        id: 1704,
        name: 'Province of Bergamo',
        state_code: 'BG',
      },
      {
        id: 1778,
        name: 'Province of Biella',
        state_code: 'BI',
      },
      {
        id: 1717,
        name: 'Province of Brescia',
        state_code: 'BS',
      },
      {
        id: 1714,
        name: 'Province of Brindisi',
        state_code: 'BR',
      },
      {
        id: 1721,
        name: 'Province of Campobasso',
        state_code: 'CB',
      },
      {
        id: 1730,
        name: 'Province of Carbonia-Iglesias',
        state_code: 'CI',
      },
      {
        id: 1731,
        name: 'Province of Caserta',
        state_code: 'CE',
      },
      {
        id: 1728,
        name: 'Province of Catanzaro',
        state_code: 'CZ',
      },
      {
        id: 1739,
        name: 'Province of Chieti',
        state_code: 'CH',
      },
      {
        id: 1740,
        name: 'Province of Como',
        state_code: 'CO',
      },
      {
        id: 1742,
        name: 'Province of Cosenza',
        state_code: 'CS',
      },
      {
        id: 1751,
        name: 'Province of Cremona',
        state_code: 'CR',
      },
      {
        id: 1754,
        name: 'Province of Crotone',
        state_code: 'KR',
      },
      {
        id: 1775,
        name: 'Province of Cuneo',
        state_code: 'CN',
      },
      {
        id: 1744,
        name: 'Province of Fermo',
        state_code: 'FM',
      },
      {
        id: 1746,
        name: 'Province of Ferrara',
        state_code: 'FE',
      },
      {
        id: 1771,
        name: 'Province of Foggia',
        state_code: 'FG',
      },
      {
        id: 1779,
        name: 'Province of Forlì-Cesena',
        state_code: 'FC',
      },
      {
        id: 1776,
        name: 'Province of Frosinone',
        state_code: 'FR',
      },
      {
        id: 1777,
        name: 'Province of Gorizia',
        state_code: 'GO',
      },
      {
        id: 1787,
        name: 'Province of Grosseto',
        state_code: 'GR',
      },
      {
        id: 1788,
        name: 'Province of Imperia',
        state_code: 'IM',
      },
      {
        id: 1789,
        name: 'Province of Isernia',
        state_code: 'IS',
      },
      {
        id: 1781,
        name: "Province of L'Aquila",
        state_code: 'AQ',
      },
      {
        id: 1791,
        name: 'Province of La Spezia',
        state_code: 'SP',
      },
      {
        id: 1674,
        name: 'Province of Latina',
        state_code: 'LT',
      },
      {
        id: 1675,
        name: 'Province of Lecce',
        state_code: 'LE',
      },
      {
        id: 1677,
        name: 'Province of Lecco',
        state_code: 'LC',
      },
      {
        id: 1745,
        name: 'Province of Livorno',
        state_code: 'LI',
      },
      {
        id: 1747,
        name: 'Province of Lodi',
        state_code: 'LO',
      },
      {
        id: 1749,
        name: 'Province of Lucca',
        state_code: 'LU',
      },
      {
        id: 1750,
        name: 'Province of Macerata',
        state_code: 'MC',
      },
      {
        id: 1758,
        name: 'Province of Mantua',
        state_code: 'MN',
      },
      {
        id: 1759,
        name: 'Province of Massa and Carrara',
        state_code: 'MS',
      },
      {
        id: 1760,
        name: 'Province of Matera',
        state_code: 'MT',
      },
      {
        id: 1761,
        name: 'Province of Medio Campidano',
        state_code: 'VS',
      },
      {
        id: 1757,
        name: 'Province of Modena',
        state_code: 'MO',
      },
      {
        id: 1769,
        name: 'Province of Monza and Brianza',
        state_code: 'MB',
      },
      {
        id: 1774,
        name: 'Province of Novara',
        state_code: 'NO',
      },
      {
        id: 1790,
        name: 'Province of Nuoro',
        state_code: 'NU',
      },
      {
        id: 1782,
        name: 'Province of Ogliastra',
        state_code: 'OG',
      },
      {
        id: 1784,
        name: 'Province of Olbia-Tempio',
        state_code: 'OT',
      },
      {
        id: 1786,
        name: 'Province of Oristano',
        state_code: 'OR',
      },
      {
        id: 1665,
        name: 'Province of Padua',
        state_code: 'PD',
      },
      {
        id: 1666,
        name: 'Province of Parma',
        state_code: 'PR',
      },
      {
        id: 1676,
        name: 'Province of Pavia',
        state_code: 'PV',
      },
      {
        id: 1691,
        name: 'Province of Perugia',
        state_code: 'PG',
      },
      {
        id: 1694,
        name: 'Province of Pescara',
        state_code: 'PE',
      },
      {
        id: 1696,
        name: 'Province of Piacenza',
        state_code: 'PC',
      },
      {
        id: 1685,
        name: 'Province of Pisa',
        state_code: 'PI',
      },
      {
        id: 1687,
        name: 'Province of Pistoia',
        state_code: 'PT',
      },
      {
        id: 1690,
        name: 'Province of Pordenone',
        state_code: 'PN',
      },
      {
        id: 1697,
        name: 'Province of Potenza',
        state_code: 'PZ',
      },
      {
        id: 1700,
        name: 'Province of Prato',
        state_code: 'PO',
      },
      {
        id: 1707,
        name: 'Province of Ravenna',
        state_code: 'RA',
      },
      {
        id: 1708,
        name: 'Province of Reggio Emilia',
        state_code: 'RE',
      },
      {
        id: 1712,
        name: 'Province of Rieti',
        state_code: 'RI',
      },
      {
        id: 1713,
        name: 'Province of Rimini',
        state_code: 'RN',
      },
      {
        id: 1719,
        name: 'Province of Rovigo',
        state_code: 'RO',
      },
      {
        id: 1720,
        name: 'Province of Salerno',
        state_code: 'SA',
      },
      {
        id: 1722,
        name: 'Province of Sassari',
        state_code: 'SS',
      },
      {
        id: 1732,
        name: 'Province of Savona',
        state_code: 'SV',
      },
      {
        id: 1734,
        name: 'Province of Siena',
        state_code: 'SI',
      },
      {
        id: 1741,
        name: 'Province of Sondrio',
        state_code: 'SO',
      },
      {
        id: 1743,
        name: 'Province of Taranto',
        state_code: 'TA',
      },
      {
        id: 1752,
        name: 'Province of Teramo',
        state_code: 'TE',
      },
      {
        id: 1755,
        name: 'Province of Terni',
        state_code: 'TR',
      },
      {
        id: 1762,
        name: 'Province of Treviso',
        state_code: 'TV',
      },
      {
        id: 1763,
        name: 'Province of Trieste',
        state_code: 'TS',
      },
      {
        id: 1764,
        name: 'Province of Udine',
        state_code: 'UD',
      },
      {
        id: 1765,
        name: 'Province of Varese',
        state_code: 'VA',
      },
      {
        id: 1726,
        name: 'Province of Verbano-Cusio-Ossola',
        state_code: 'VB',
      },
      {
        id: 1785,
        name: 'Province of Vercelli',
        state_code: 'VC',
      },
      {
        id: 1736,
        name: 'Province of Verona',
        state_code: 'VR',
      },
      {
        id: 1737,
        name: 'Province of Vibo Valentia',
        state_code: 'VV',
      },
      {
        id: 1738,
        name: 'Province of Vicenza',
        state_code: 'VI',
      },
      {
        id: 1735,
        name: 'Province of Viterbo',
        state_code: 'VT',
      },
      {
        id: 1715,
        name: 'Sardinia',
        state_code: '88',
      },
      {
        id: 1709,
        name: 'Sicily',
        state_code: '82',
      },
      {
        id: 1767,
        name: 'South Tyrol',
        state_code: 'BZ',
      },
      {
        id: 1748,
        name: 'Trentino',
        state_code: 'TN',
      },
      {
        id: 1725,
        name: 'Trentino-South Tyrol',
        state_code: '32',
      },
      {
        id: 1664,
        name: 'Tuscany',
        state_code: '52',
      },
      {
        id: 1683,
        name: 'Umbria',
        state_code: '55',
      },
      {
        id: 1753,
        name: 'Veneto',
        state_code: '34',
      },
    ],
  },
  {
    name: 'Jamaica',
    iso3: 'JAM',
    iso2: 'JM',
    phone_code: '+1-876',
    capital: 'Kingston',
    currency: 'JMD',
    states: [
      {
        id: 3753,
        name: 'Clarendon Parish',
        state_code: '13',
      },
      {
        id: 3749,
        name: 'Hanover Parish',
        state_code: '09',
      },
      {
        id: 3748,
        name: 'Kingston Parish',
        state_code: '01',
      },
      {
        id: 3754,
        name: 'Manchester Parish',
        state_code: '12',
      },
      {
        id: 3752,
        name: 'Portland Parish',
        state_code: '04',
      },
      {
        id: 3751,
        name: 'Saint Andrew',
        state_code: '02',
      },
      {
        id: 3744,
        name: 'Saint Ann Parish',
        state_code: '06',
      },
      {
        id: 3746,
        name: 'Saint Catherine Parish',
        state_code: '14',
      },
      {
        id: 3743,
        name: 'Saint Elizabeth Parish',
        state_code: '11',
      },
      {
        id: 3745,
        name: 'Saint James Parish',
        state_code: '08',
      },
      {
        id: 3747,
        name: 'Saint Mary Parish',
        state_code: '05',
      },
      {
        id: 3750,
        name: 'Saint Thomas Parish',
        state_code: '03',
      },
      {
        id: 3755,
        name: 'Trelawny Parish',
        state_code: '07',
      },
      {
        id: 3742,
        name: 'Westmoreland Parish',
        state_code: '10',
      },
    ],
  },
  {
    name: 'Japan',
    iso3: 'JPN',
    iso2: 'JP',
    phone_code: '81',
    capital: 'Tokyo',
    currency: 'JPY',
    states: [
      {
        id: 827,
        name: 'Aichi Prefecture',
        state_code: '23',
      },
      {
        id: 829,
        name: 'Akita Prefecture',
        state_code: '05',
      },
      {
        id: 839,
        name: 'Aomori Prefecture',
        state_code: '02',
      },
      {
        id: 821,
        name: 'Chiba Prefecture',
        state_code: '12',
      },
      {
        id: 865,
        name: 'Ehime Prefecture',
        state_code: '38',
      },
      {
        id: 848,
        name: 'Fukui Prefecture',
        state_code: '18',
      },
      {
        id: 861,
        name: 'Fukuoka Prefecture',
        state_code: '40',
      },
      {
        id: 847,
        name: 'Fukushima Prefecture',
        state_code: '07',
      },
      {
        id: 858,
        name: 'Gifu Prefecture',
        state_code: '21',
      },
      {
        id: 862,
        name: 'Gunma Prefecture',
        state_code: '10',
      },
      {
        id: 828,
        name: 'Hiroshima Prefecture',
        state_code: '34',
      },
      {
        id: 832,
        name: 'Hokkaidō Prefecture',
        state_code: '01',
      },
      {
        id: 831,
        name: 'Hyōgo Prefecture',
        state_code: '28',
      },
      {
        id: 851,
        name: 'Ibaraki Prefecture',
        state_code: '08',
      },
      {
        id: 830,
        name: 'Ishikawa Prefecture',
        state_code: '17',
      },
      {
        id: 856,
        name: 'Iwate Prefecture',
        state_code: '03',
      },
      {
        id: 864,
        name: 'Kagawa Prefecture',
        state_code: '37',
      },
      {
        id: 840,
        name: 'Kagoshima Prefecture',
        state_code: '46',
      },
      {
        id: 842,
        name: 'Kanagawa Prefecture',
        state_code: '14',
      },
      {
        id: 846,
        name: 'Kumamoto Prefecture',
        state_code: '43',
      },
      {
        id: 834,
        name: 'Kyōto Prefecture',
        state_code: '26',
      },
      {
        id: 833,
        name: 'Mie Prefecture',
        state_code: '24',
      },
      {
        id: 857,
        name: 'Miyagi Prefecture',
        state_code: '04',
      },
      {
        id: 855,
        name: 'Miyazaki Prefecture',
        state_code: '45',
      },
      {
        id: 843,
        name: 'Nagano Prefecture',
        state_code: '20',
      },
      {
        id: 849,
        name: 'Nagasaki Prefecture',
        state_code: '42',
      },
      {
        id: 824,
        name: 'Nara Prefecture',
        state_code: '29',
      },
      {
        id: 841,
        name: 'Niigata Prefecture',
        state_code: '15',
      },
      {
        id: 822,
        name: 'Ōita Prefecture',
        state_code: '44',
      },
      {
        id: 820,
        name: 'Okayama Prefecture',
        state_code: '33',
      },
      {
        id: 853,
        name: 'Okinawa Prefecture',
        state_code: '47',
      },
      {
        id: 859,
        name: 'Ōsaka Prefecture',
        state_code: '27',
      },
      {
        id: 863,
        name: 'Saga Prefecture',
        state_code: '41',
      },
      {
        id: 860,
        name: 'Saitama Prefecture',
        state_code: '11',
      },
      {
        id: 845,
        name: 'Shiga Prefecture',
        state_code: '25',
      },
      {
        id: 826,
        name: 'Shimane Prefecture',
        state_code: '32',
      },
      {
        id: 825,
        name: 'Shizuoka Prefecture',
        state_code: '22',
      },
      {
        id: 854,
        name: 'Tochigi Prefecture',
        state_code: '09',
      },
      {
        id: 836,
        name: 'Tokushima Prefecture',
        state_code: '36',
      },
      {
        id: 823,
        name: 'Tokyo',
        state_code: '13',
      },
      {
        id: 850,
        name: 'Tottori Prefecture',
        state_code: '31',
      },
      {
        id: 838,
        name: 'Toyama Prefecture',
        state_code: '16',
      },
      {
        id: 844,
        name: 'Wakayama Prefecture',
        state_code: '30',
      },
      {
        id: 837,
        name: 'Yamagata Prefecture',
        state_code: '06',
      },
      {
        id: 835,
        name: 'Yamaguchi Prefecture',
        state_code: '35',
      },
      {
        id: 852,
        name: 'Yamanashi Prefecture',
        state_code: '19',
      },
    ],
  },
  {
    name: 'Jersey',
    iso3: 'JEY',
    iso2: 'JE',
    phone_code: '+44-1534',
    capital: 'Saint Helier',
    currency: 'GBP',
    states: [],
  },
  {
    name: 'Jordan',
    iso3: 'JOR',
    iso2: 'JO',
    phone_code: '962',
    capital: 'Amman',
    currency: 'JOD',
    states: [
      {
        id: 963,
        name: 'Ajloun Governorate',
        state_code: 'AJ',
      },
      {
        id: 965,
        name: 'Amman Governorate',
        state_code: 'AM',
      },
      {
        id: 959,
        name: 'Aqaba Governorate',
        state_code: 'AQ',
      },
      {
        id: 961,
        name: 'Balqa Governorate',
        state_code: 'BA',
      },
      {
        id: 960,
        name: 'Irbid Governorate',
        state_code: 'IR',
      },
      {
        id: 966,
        name: 'Jerash Governorate',
        state_code: 'JA',
      },
      {
        id: 956,
        name: 'Karak Governorate',
        state_code: 'KA',
      },
      {
        id: 964,
        name: "Ma'an Governorate",
        state_code: 'MN',
      },
      {
        id: 958,
        name: 'Madaba Governorate',
        state_code: 'MD',
      },
      {
        id: 962,
        name: 'Mafraq Governorate',
        state_code: 'MA',
      },
      {
        id: 957,
        name: 'Tafilah Governorate',
        state_code: 'AT',
      },
      {
        id: 967,
        name: 'Zarqa Governorate',
        state_code: 'AZ',
      },
    ],
  },
  {
    name: 'Kazakhstan',
    iso3: 'KAZ',
    iso2: 'KZ',
    phone_code: '7',
    capital: 'Astana',
    currency: 'KZT',
    states: [
      {
        id: 145,
        name: 'Akmola Region',
        state_code: 'AKM',
      },
      {
        id: 151,
        name: 'Aktobe Region',
        state_code: 'AKT',
      },
      {
        id: 152,
        name: 'Almaty',
        state_code: 'ALA',
      },
      {
        id: 143,
        name: 'Almaty Region',
        state_code: 'ALM',
      },
      {
        id: 153,
        name: 'Atyrau Region',
        state_code: 'ATY',
      },
      {
        id: 155,
        name: 'Baikonur',
        state_code: 'BAY',
      },
      {
        id: 154,
        name: 'East Kazakhstan Region',
        state_code: 'VOS',
      },
      {
        id: 147,
        name: 'Jambyl Region',
        state_code: 'ZHA',
      },
      {
        id: 150,
        name: 'Karaganda Region',
        state_code: 'KAR',
      },
      {
        id: 157,
        name: 'Kostanay Region',
        state_code: 'KUS',
      },
      {
        id: 142,
        name: 'Kyzylorda Region',
        state_code: 'KZY',
      },
      {
        id: 141,
        name: 'Mangystau Region',
        state_code: 'MAN',
      },
      {
        id: 144,
        name: 'North Kazakhstan Region',
        state_code: 'SEV',
      },
      {
        id: 156,
        name: 'Nur-Sultan',
        state_code: 'AST',
      },
      {
        id: 146,
        name: 'Pavlodar Region',
        state_code: 'PAV',
      },
      {
        id: 149,
        name: 'Turkestan Region',
        state_code: 'YUZ',
      },
      {
        id: 148,
        name: 'West Kazakhstan Province',
        state_code: 'ZAP',
      },
    ],
  },
  {
    name: 'Kenya',
    iso3: 'KEN',
    iso2: 'KE',
    phone_code: '254',
    capital: 'Nairobi',
    currency: 'KES',
    states: [
      {
        id: 181,
        name: 'Baringo County',
        state_code: '01',
      },
      {
        id: 210,
        name: 'Bomet County',
        state_code: '02',
      },
      {
        id: 168,
        name: 'Bungoma County',
        state_code: '03',
      },
      {
        id: 161,
        name: 'Busia County',
        state_code: '04',
      },
      {
        id: 160,
        name: 'Central Province',
        state_code: '200',
      },
      {
        id: 172,
        name: 'Coast Province',
        state_code: '300',
      },
      {
        id: 189,
        name: 'Eastern Province',
        state_code: '400',
      },
      {
        id: 201,
        name: 'Elgeyo-Marakwet County',
        state_code: '05',
      },
      {
        id: 163,
        name: 'Embu County',
        state_code: '06',
      },
      {
        id: 196,
        name: 'Garissa County',
        state_code: '07',
      },
      {
        id: 195,
        name: 'Homa Bay County',
        state_code: '08',
      },
      {
        id: 170,
        name: 'Isiolo County',
        state_code: '09',
      },
      {
        id: 197,
        name: 'Kajiado County',
        state_code: '10',
      },
      {
        id: 158,
        name: 'Kakamega County',
        state_code: '11',
      },
      {
        id: 193,
        name: 'Kericho County',
        state_code: '12',
      },
      {
        id: 199,
        name: 'Kiambu County',
        state_code: '13',
      },
      {
        id: 174,
        name: 'Kilifi County',
        state_code: '14',
      },
      {
        id: 167,
        name: 'Kirinyaga County',
        state_code: '15',
      },
      {
        id: 159,
        name: 'Kisii County',
        state_code: '16',
      },
      {
        id: 171,
        name: 'Kisumu County',
        state_code: '17',
      },
      {
        id: 211,
        name: 'Kitui County',
        state_code: '18',
      },
      {
        id: 173,
        name: 'Kwale County',
        state_code: '19',
      },
      {
        id: 164,
        name: 'Laikipia County',
        state_code: '20',
      },
      {
        id: 166,
        name: 'Lamu County',
        state_code: '21',
      },
      {
        id: 184,
        name: 'Machakos County',
        state_code: '22',
      },
      {
        id: 188,
        name: 'Makueni County',
        state_code: '23',
      },
      {
        id: 187,
        name: 'Mandera County',
        state_code: '24',
      },
      {
        id: 194,
        name: 'Marsabit County',
        state_code: '25',
      },
      {
        id: 198,
        name: 'Meru County',
        state_code: '26',
      },
      {
        id: 190,
        name: 'Migori County',
        state_code: '27',
      },
      {
        id: 200,
        name: 'Mombasa County',
        state_code: '28',
      },
      {
        id: 178,
        name: 'Muranga County',
        state_code: '29',
      },
      {
        id: 191,
        name: 'Nairobi',
        state_code: '110',
      },
      {
        id: 203,
        name: 'Nakuru District',
        state_code: '31',
      },
      {
        id: 165,
        name: 'Nandi District',
        state_code: '32',
      },
      {
        id: 175,
        name: 'Narok County',
        state_code: '33',
      },
      {
        id: 162,
        name: 'North Eastern Province',
        state_code: '500',
      },
      {
        id: 209,
        name: 'Nyamira District',
        state_code: '34',
      },
      {
        id: 192,
        name: 'Nyandarua County',
        state_code: '35',
      },
      {
        id: 204,
        name: 'Nyanza Province',
        state_code: '600',
      },
      {
        id: 180,
        name: 'Nyeri County',
        state_code: '36',
      },
      {
        id: 179,
        name: 'Rift Valley Province',
        state_code: '700',
      },
      {
        id: 207,
        name: 'Samburu County',
        state_code: '37',
      },
      {
        id: 186,
        name: 'Siaya County',
        state_code: '38',
      },
      {
        id: 176,
        name: 'Taita–Taveta County',
        state_code: '39',
      },
      {
        id: 205,
        name: 'Tana River County',
        state_code: '40',
      },
      {
        id: 185,
        name: 'Tharaka Nithi County',
        state_code: '41',
      },
      {
        id: 183,
        name: 'Trans-Nzoia District',
        state_code: '42',
      },
      {
        id: 206,
        name: 'Turkana County',
        state_code: '43',
      },
      {
        id: 169,
        name: 'Uasin Gishu District',
        state_code: '44',
      },
      {
        id: 202,
        name: 'Vihiga District',
        state_code: '45',
      },
      {
        id: 182,
        name: 'Wajir County',
        state_code: '46',
      },
      {
        id: 208,
        name: 'West Pokot County',
        state_code: '47',
      },
      {
        id: 177,
        name: 'Western Province',
        state_code: '800',
      },
    ],
  },
  {
    name: 'Kiribati',
    iso3: 'KIR',
    iso2: 'KI',
    phone_code: '686',
    capital: 'Tarawa',
    currency: 'AUD',
    states: [
      {
        id: 1831,
        name: 'Gilbert Islands',
        state_code: 'G',
      },
      {
        id: 1832,
        name: 'Line Islands',
        state_code: 'L',
      },
      {
        id: 1830,
        name: 'Phoenix Islands',
        state_code: 'P',
      },
    ],
  },
  {
    name: 'Korea North',
    iso3: 'PRK',
    iso2: 'KP',
    phone_code: '850',
    capital: 'Pyongyang',
    currency: 'KPW',
    states: [
      {
        id: 3998,
        name: 'Chagang Province',
        state_code: '04',
      },
      {
        id: 3999,
        name: 'Kangwon Province',
        state_code: '07',
      },
      {
        id: 3995,
        name: 'North Hamgyong Province',
        state_code: '09',
      },
      {
        id: 4004,
        name: 'North Hwanghae Province',
        state_code: '06',
      },
      {
        id: 4002,
        name: 'North Pyongan Province',
        state_code: '03',
      },
      {
        id: 4005,
        name: 'Pyongyang',
        state_code: '01',
      },
      {
        id: 4001,
        name: 'Rason',
        state_code: '13',
      },
      {
        id: 3996,
        name: 'Ryanggang Province',
        state_code: '10',
      },
      {
        id: 4000,
        name: 'South Hamgyong Province',
        state_code: '08',
      },
      {
        id: 4003,
        name: 'South Hwanghae Province',
        state_code: '05',
      },
      {
        id: 3997,
        name: 'South Pyongan Province',
        state_code: '02',
      },
    ],
  },
  {
    name: 'Korea South',
    iso3: 'KOR',
    iso2: 'KR',
    phone_code: '82',
    capital: 'Seoul',
    currency: 'KRW',
    states: [
      {
        id: 3860,
        name: 'Busan',
        state_code: '26',
      },
      {
        id: 3846,
        name: 'Daegu',
        state_code: '27',
      },
      {
        id: 3850,
        name: 'Daejeon',
        state_code: '30',
      },
      {
        id: 3862,
        name: 'Gangwon Province',
        state_code: '42',
      },
      {
        id: 3858,
        name: 'Gwangju',
        state_code: '29',
      },
      {
        id: 3847,
        name: 'Gyeonggi Province',
        state_code: '41',
      },
      {
        id: 3848,
        name: 'Incheon',
        state_code: '28',
      },
      {
        id: 3853,
        name: 'Jeju',
        state_code: '49',
      },
      {
        id: 3854,
        name: 'North Chungcheong Province',
        state_code: '43',
      },
      {
        id: 3855,
        name: 'North Gyeongsang Province',
        state_code: '47',
      },
      {
        id: 3851,
        name: 'North Jeolla Province',
        state_code: '45',
      },
      {
        id: 3861,
        name: 'Sejong City',
        state_code: '50',
      },
      {
        id: 3849,
        name: 'Seoul',
        state_code: '11',
      },
      {
        id: 3859,
        name: 'South Chungcheong Province',
        state_code: '44',
      },
      {
        id: 3857,
        name: 'South Gyeongsang Province',
        state_code: '48',
      },
      {
        id: 3856,
        name: 'South Jeolla Province',
        state_code: '46',
      },
      {
        id: 3852,
        name: 'Ulsan',
        state_code: '31',
      },
    ],
  },
  {
    name: 'Kuwait',
    iso3: 'KWT',
    iso2: 'KW',
    phone_code: '965',
    capital: 'Kuwait City',
    currency: 'KWD',
    states: [
      {
        id: 977,
        name: 'Al Ahmadi Governorate',
        state_code: 'AH',
      },
      {
        id: 975,
        name: 'Al Farwaniyah Governorate',
        state_code: 'FA',
      },
      {
        id: 972,
        name: 'Al Jahra Governorate',
        state_code: 'JA',
      },
      {
        id: 976,
        name: 'Capital Governorate',
        state_code: 'KU',
      },
      {
        id: 973,
        name: 'Hawalli Governorate',
        state_code: 'HA',
      },
      {
        id: 974,
        name: 'Mubarak Al-Kabeer Governorate',
        state_code: 'MU',
      },
    ],
  },
  {
    name: 'Kyrgyzstan',
    iso3: 'KGZ',
    iso2: 'KG',
    phone_code: '996',
    capital: 'Bishkek',
    currency: 'KGS',
    states: [
      {
        id: 998,
        name: 'Batken Region',
        state_code: 'B',
      },
      {
        id: 1001,
        name: 'Bishkek',
        state_code: 'GB',
      },
      {
        id: 1004,
        name: 'Chuy Region',
        state_code: 'C',
      },
      {
        id: 1002,
        name: 'Issyk-Kul Region',
        state_code: 'Y',
      },
      {
        id: 1000,
        name: 'Jalal-Abad Region',
        state_code: 'J',
      },
      {
        id: 999,
        name: 'Naryn Region',
        state_code: 'N',
      },
      {
        id: 1003,
        name: 'Osh',
        state_code: 'GO',
      },
      {
        id: 1005,
        name: 'Osh Region',
        state_code: 'O',
      },
      {
        id: 997,
        name: 'Talas Region',
        state_code: 'T',
      },
    ],
  },
  {
    name: 'Laos',
    iso3: 'LAO',
    iso2: 'LA',
    phone_code: '856',
    capital: 'Vientiane',
    currency: 'LAK',
    states: [
      {
        id: 982,
        name: 'Attapeu Province',
        state_code: 'AT',
      },
      {
        id: 991,
        name: 'Bokeo Province',
        state_code: 'BK',
      },
      {
        id: 985,
        name: 'Bolikhamsai Province',
        state_code: 'BL',
      },
      {
        id: 996,
        name: 'Champasak Province',
        state_code: 'CH',
      },
      {
        id: 989,
        name: 'Houaphanh Province',
        state_code: 'HO',
      },
      {
        id: 986,
        name: 'Khammouane Province',
        state_code: 'KH',
      },
      {
        id: 992,
        name: 'Luang Namtha Province',
        state_code: 'LM',
      },
      {
        id: 978,
        name: 'Luang Prabang Province',
        state_code: 'LP',
      },
      {
        id: 988,
        name: 'Oudomxay Province',
        state_code: 'OU',
      },
      {
        id: 987,
        name: 'Phongsaly Province',
        state_code: 'PH',
      },
      {
        id: 993,
        name: 'Sainyabuli Province',
        state_code: 'XA',
      },
      {
        id: 981,
        name: 'Salavan Province',
        state_code: 'SL',
      },
      {
        id: 990,
        name: 'Savannakhet Province',
        state_code: 'SV',
      },
      {
        id: 984,
        name: 'Sekong Province',
        state_code: 'XE',
      },
      {
        id: 979,
        name: 'Vientiane Prefecture',
        state_code: 'VT',
      },
      {
        id: 980,
        name: 'Vientiane Province',
        state_code: 'VI',
      },
      {
        id: 994,
        name: 'Xaisomboun',
        state_code: 'XN',
      },
      {
        id: 983,
        name: 'Xaisomboun Province',
        state_code: 'XS',
      },
      {
        id: 995,
        name: 'Xiangkhouang Province',
        state_code: 'XI',
      },
    ],
  },
  {
    name: 'Latvia',
    iso3: 'LVA',
    iso2: 'LV',
    phone_code: '371',
    capital: 'Riga',
    currency: 'EUR',
    states: [
      {
        id: 4445,
        name: 'Aglona Municipality',
        state_code: '001',
      },
      {
        id: 4472,
        name: 'Aizkraukle Municipality',
        state_code: '002',
      },
      {
        id: 4496,
        name: 'Aizpute Municipality',
        state_code: '003',
      },
      {
        id: 4499,
        name: 'Aknīste Municipality',
        state_code: '004',
      },
      {
        id: 4484,
        name: 'Aloja Municipality',
        state_code: '005',
      },
      {
        id: 4485,
        name: 'Alsunga Municipality',
        state_code: '006',
      },
      {
        id: 4487,
        name: 'Alūksne Municipality',
        state_code: '007',
      },
      {
        id: 4497,
        name: 'Amata Municipality',
        state_code: '008',
      },
      {
        id: 4457,
        name: 'Ape Municipality',
        state_code: '009',
      },
      {
        id: 4481,
        name: 'Auce Municipality',
        state_code: '010',
      },
      {
        id: 4427,
        name: 'Babīte Municipality',
        state_code: '012',
      },
      {
        id: 4482,
        name: 'Baldone Municipality',
        state_code: '013',
      },
      {
        id: 4498,
        name: 'Baltinava Municipality',
        state_code: '014',
      },
      {
        id: 4505,
        name: 'Balvi Municipality',
        state_code: '015',
      },
      {
        id: 4465,
        name: 'Bauska Municipality',
        state_code: '016',
      },
      {
        id: 4471,
        name: 'Beverīna Municipality',
        state_code: '017',
      },
      {
        id: 4468,
        name: 'Brocēni Municipality',
        state_code: '018',
      },
      {
        id: 4411,
        name: 'Burtnieki Municipality',
        state_code: '019',
      },
      {
        id: 4454,
        name: 'Carnikava Municipality',
        state_code: '020',
      },
      {
        id: 4469,
        name: 'Cēsis Municipality',
        state_code: '022',
      },
      {
        id: 4414,
        name: 'Cesvaine Municipality',
        state_code: '021',
      },
      {
        id: 4410,
        name: 'Cibla Municipality',
        state_code: '023',
      },
      {
        id: 4504,
        name: 'Dagda Municipality',
        state_code: '024',
      },
      {
        id: 4463,
        name: 'Daugavpils',
        state_code: 'DGV',
      },
      {
        id: 4492,
        name: 'Daugavpils Municipality',
        state_code: '025',
      },
      {
        id: 4437,
        name: 'Dobele Municipality',
        state_code: '026',
      },
      {
        id: 4428,
        name: 'Dundaga Municipality',
        state_code: '027',
      },
      {
        id: 4458,
        name: 'Durbe Municipality',
        state_code: '028',
      },
      {
        id: 4448,
        name: 'Engure Municipality',
        state_code: '029',
      },
      {
        id: 4444,
        name: 'Ērgļi Municipality',
        state_code: '030',
      },
      {
        id: 4510,
        name: 'Garkalne Municipality',
        state_code: '031',
      },
      {
        id: 4470,
        name: 'Grobiņa Municipality',
        state_code: '032',
      },
      {
        id: 4400,
        name: 'Gulbene Municipality',
        state_code: '033',
      },
      {
        id: 4441,
        name: 'Iecava Municipality',
        state_code: '034',
      },
      {
        id: 4511,
        name: 'Ikšķile Municipality',
        state_code: '035',
      },
      {
        id: 4399,
        name: 'Ilūkste Municipality',
        state_code: '036',
      },
      {
        id: 4449,
        name: 'Inčukalns Municipality',
        state_code: '037',
      },
      {
        id: 4475,
        name: 'Jaunjelgava Municipality',
        state_code: '038',
      },
      {
        id: 4407,
        name: 'Jaunpiebalga Municipality',
        state_code: '039',
      },
      {
        id: 4489,
        name: 'Jaunpils Municipality',
        state_code: '040',
      },
      {
        id: 4464,
        name: 'Jēkabpils',
        state_code: 'JKB',
      },
      {
        id: 4438,
        name: 'Jēkabpils Municipality',
        state_code: '042',
      },
      {
        id: 4500,
        name: 'Jelgava',
        state_code: 'JEL',
      },
      {
        id: 4424,
        name: 'Jelgava Municipality',
        state_code: '041',
      },
      {
        id: 4446,
        name: 'Jūrmala',
        state_code: 'JUR',
      },
      {
        id: 4420,
        name: 'Kandava Municipality',
        state_code: '043',
      },
      {
        id: 4453,
        name: 'Kārsava Municipality',
        state_code: '044',
      },
      {
        id: 4412,
        name: 'Ķegums Municipality',
        state_code: '051',
      },
      {
        id: 4435,
        name: 'Ķekava Municipality',
        state_code: '052',
      },
      {
        id: 4495,
        name: 'Kocēni Municipality',
        state_code: '045',
      },
      {
        id: 4452,
        name: 'Koknese Municipality',
        state_code: '046',
      },
      {
        id: 4474,
        name: 'Krāslava Municipality',
        state_code: '047',
      },
      {
        id: 4422,
        name: 'Krimulda Municipality',
        state_code: '048',
      },
      {
        id: 4413,
        name: 'Krustpils Municipality',
        state_code: '049',
      },
      {
        id: 4490,
        name: 'Kuldīga Municipality',
        state_code: '050',
      },
      {
        id: 4512,
        name: 'Lielvārde Municipality',
        state_code: '053',
      },
      {
        id: 4460,
        name: 'Liepāja',
        state_code: 'LPX',
      },
      {
        id: 4488,
        name: 'Līgatne Municipality',
        state_code: '055',
      },
      {
        id: 4418,
        name: 'Limbaži Municipality',
        state_code: '054',
      },
      {
        id: 4401,
        name: 'Līvāni Municipality',
        state_code: '056',
      },
      {
        id: 4419,
        name: 'Lubāna Municipality',
        state_code: '057',
      },
      {
        id: 4501,
        name: 'Ludza Municipality',
        state_code: '058',
      },
      {
        id: 4433,
        name: 'Madona Municipality',
        state_code: '059',
      },
      {
        id: 4461,
        name: 'Mālpils Municipality',
        state_code: '061',
      },
      {
        id: 4450,
        name: 'Mārupe Municipality',
        state_code: '062',
      },
      {
        id: 4513,
        name: 'Mazsalaca Municipality',
        state_code: '060',
      },
      {
        id: 4451,
        name: 'Mērsrags Municipality',
        state_code: '063',
      },
      {
        id: 4398,
        name: 'Naukšēni Municipality',
        state_code: '064',
      },
      {
        id: 4432,
        name: 'Nereta Municipality',
        state_code: '065',
      },
      {
        id: 4436,
        name: 'Nīca Municipality',
        state_code: '066',
      },
      {
        id: 4416,
        name: 'Ogre Municipality',
        state_code: '067',
      },
      {
        id: 4417,
        name: 'Olaine Municipality',
        state_code: '068',
      },
      {
        id: 4442,
        name: 'Ozolnieki Municipality',
        state_code: '069',
      },
      {
        id: 4507,
        name: 'Pārgauja Municipality',
        state_code: '070',
      },
      {
        id: 4467,
        name: 'Pāvilosta Municipality',
        state_code: '071',
      },
      {
        id: 4405,
        name: 'Pļaviņas Municipality',
        state_code: '072',
      },
      {
        id: 4483,
        name: 'Preiļi Municipality',
        state_code: '073',
      },
      {
        id: 4429,
        name: 'Priekule Municipality',
        state_code: '074',
      },
      {
        id: 4506,
        name: 'Priekuļi Municipality',
        state_code: '075',
      },
      {
        id: 4479,
        name: 'Rauna Municipality',
        state_code: '076',
      },
      {
        id: 4509,
        name: 'Rēzekne',
        state_code: 'REZ',
      },
      {
        id: 4455,
        name: 'Rēzekne Municipality',
        state_code: '077',
      },
      {
        id: 4502,
        name: 'Riebiņi Municipality',
        state_code: '078',
      },
      {
        id: 4491,
        name: 'Riga',
        state_code: 'RIX',
      },
      {
        id: 4440,
        name: 'Roja Municipality',
        state_code: '079',
      },
      {
        id: 4493,
        name: 'Ropaži Municipality',
        state_code: '080',
      },
      {
        id: 4503,
        name: 'Rucava Municipality',
        state_code: '081',
      },
      {
        id: 4423,
        name: 'Rugāji Municipality',
        state_code: '082',
      },
      {
        id: 4426,
        name: 'Rūjiena Municipality',
        state_code: '084',
      },
      {
        id: 4404,
        name: 'Rundāle Municipality',
        state_code: '083',
      },
      {
        id: 4434,
        name: 'Sala Municipality',
        state_code: '085',
      },
      {
        id: 4396,
        name: 'Salacgrīva Municipality',
        state_code: '086',
      },
      {
        id: 4402,
        name: 'Salaspils Municipality',
        state_code: '087',
      },
      {
        id: 4439,
        name: 'Saldus Municipality',
        state_code: '088',
      },
      {
        id: 4443,
        name: 'Saulkrasti Municipality',
        state_code: '089',
      },
      {
        id: 4408,
        name: 'Sēja Municipality',
        state_code: '090',
      },
      {
        id: 4476,
        name: 'Sigulda Municipality',
        state_code: '091',
      },
      {
        id: 4415,
        name: 'Skrīveri Municipality',
        state_code: '092',
      },
      {
        id: 4447,
        name: 'Skrunda Municipality',
        state_code: '093',
      },
      {
        id: 4462,
        name: 'Smiltene Municipality',
        state_code: '094',
      },
      {
        id: 4478,
        name: 'Stopiņi Municipality',
        state_code: '095',
      },
      {
        id: 4494,
        name: 'Strenči Municipality',
        state_code: '096',
      },
      {
        id: 4459,
        name: 'Talsi Municipality',
        state_code: '097',
      },
      {
        id: 4480,
        name: 'Tērvete Municipality',
        state_code: '098',
      },
      {
        id: 4409,
        name: 'Tukums Municipality',
        state_code: '099',
      },
      {
        id: 4508,
        name: 'Vaiņode Municipality',
        state_code: '100',
      },
      {
        id: 4425,
        name: 'Valka Municipality',
        state_code: '101',
      },
      {
        id: 4473,
        name: 'Valmiera',
        state_code: 'VMR',
      },
      {
        id: 4431,
        name: 'Varakļāni Municipality',
        state_code: '102',
      },
      {
        id: 4406,
        name: 'Vārkava Municipality',
        state_code: '103',
      },
      {
        id: 4466,
        name: 'Vecpiebalga Municipality',
        state_code: '104',
      },
      {
        id: 4397,
        name: 'Vecumnieki Municipality',
        state_code: '105',
      },
      {
        id: 4421,
        name: 'Ventspils',
        state_code: 'VEN',
      },
      {
        id: 4403,
        name: 'Ventspils Municipality',
        state_code: '106',
      },
      {
        id: 4456,
        name: 'Viesīte Municipality',
        state_code: '107',
      },
      {
        id: 4477,
        name: 'Viļaka Municipality',
        state_code: '108',
      },
      {
        id: 4486,
        name: 'Viļāni Municipality',
        state_code: '109',
      },
      {
        id: 4430,
        name: 'Zilupe Municipality',
        state_code: '110',
      },
    ],
  },
  {
    name: 'Lebanon',
    iso3: 'LBN',
    iso2: 'LB',
    phone_code: '961',
    capital: 'Beirut',
    currency: 'LBP',
    states: [
      {
        id: 2285,
        name: 'Akkar Governorate',
        state_code: 'AK',
      },
      {
        id: 2283,
        name: 'Baalbek-Hermel Governorate',
        state_code: 'BH',
      },
      {
        id: 2286,
        name: 'Beirut Governorate',
        state_code: 'BA',
      },
      {
        id: 2287,
        name: 'Beqaa Governorate',
        state_code: 'BI',
      },
      {
        id: 2282,
        name: 'Mount Lebanon Governorate',
        state_code: 'JL',
      },
      {
        id: 2288,
        name: 'Nabatieh Governorate',
        state_code: 'NA',
      },
      {
        id: 2284,
        name: 'North Governorate',
        state_code: 'AS',
      },
      {
        id: 2281,
        name: 'South Governorate',
        state_code: 'JA',
      },
    ],
  },
  {
    name: 'Lesotho',
    iso3: 'LSO',
    iso2: 'LS',
    phone_code: '266',
    capital: 'Maseru',
    currency: 'LSL',
    states: [
      {
        id: 3030,
        name: 'Berea District',
        state_code: 'D',
      },
      {
        id: 3029,
        name: 'Butha-Buthe District',
        state_code: 'B',
      },
      {
        id: 3026,
        name: 'Leribe District',
        state_code: 'C',
      },
      {
        id: 3022,
        name: 'Mafeteng District',
        state_code: 'E',
      },
      {
        id: 3028,
        name: 'Maseru District',
        state_code: 'A',
      },
      {
        id: 3023,
        name: "Mohale's Hoek District",
        state_code: 'F',
      },
      {
        id: 3024,
        name: 'Mokhotlong District',
        state_code: 'J',
      },
      {
        id: 3025,
        name: "Qacha's Nek District",
        state_code: 'H',
      },
      {
        id: 3027,
        name: 'Quthing District',
        state_code: 'G',
      },
      {
        id: 3031,
        name: 'Thaba-Tseka District',
        state_code: 'K',
      },
    ],
  },
  {
    name: 'Liberia',
    iso3: 'LBR',
    iso2: 'LR',
    phone_code: '231',
    capital: 'Monrovia',
    currency: 'LRD',
    states: [
      {
        id: 3041,
        name: 'Bomi County',
        state_code: 'BM',
      },
      {
        id: 3034,
        name: 'Bong County',
        state_code: 'BG',
      },
      {
        id: 3044,
        name: 'Gbarpolu County',
        state_code: 'GP',
      },
      {
        id: 3040,
        name: 'Grand Bassa County',
        state_code: 'GB',
      },
      {
        id: 3036,
        name: 'Grand Cape Mount County',
        state_code: 'CM',
      },
      {
        id: 3039,
        name: 'Grand Gedeh County',
        state_code: 'GG',
      },
      {
        id: 3045,
        name: 'Grand Kru County',
        state_code: 'GK',
      },
      {
        id: 3037,
        name: 'Lofa County',
        state_code: 'LO',
      },
      {
        id: 3043,
        name: 'Margibi County',
        state_code: 'MG',
      },
      {
        id: 3042,
        name: 'Maryland County',
        state_code: 'MY',
      },
      {
        id: 3032,
        name: 'Montserrado County',
        state_code: 'MO',
      },
      {
        id: 3046,
        name: 'Nimba',
        state_code: 'NI',
      },
      {
        id: 3033,
        name: 'River Cess County',
        state_code: 'RI',
      },
      {
        id: 3038,
        name: 'River Gee County',
        state_code: 'RG',
      },
      {
        id: 3035,
        name: 'Sinoe County',
        state_code: 'SI',
      },
    ],
  },
  {
    name: 'Libya',
    iso3: 'LBY',
    iso2: 'LY',
    phone_code: '218',
    capital: 'Tripolis',
    currency: 'LYD',
    states: [
      {
        id: 2964,
        name: 'Al Wahat District',
        state_code: 'WA',
      },
      {
        id: 2981,
        name: 'Benghazi',
        state_code: 'BA',
      },
      {
        id: 2966,
        name: 'Derna District',
        state_code: 'DR',
      },
      {
        id: 2969,
        name: 'Ghat District',
        state_code: 'GT',
      },
      {
        id: 2980,
        name: 'Jabal al Akhdar',
        state_code: 'JA',
      },
      {
        id: 2974,
        name: 'Jabal al Gharbi District',
        state_code: 'JG',
      },
      {
        id: 2979,
        name: 'Jafara',
        state_code: 'JI',
      },
      {
        id: 2970,
        name: 'Jufra',
        state_code: 'JU',
      },
      {
        id: 2972,
        name: 'Kufra District',
        state_code: 'KF',
      },
      {
        id: 2968,
        name: 'Marj District',
        state_code: 'MJ',
      },
      {
        id: 2978,
        name: 'Misrata District',
        state_code: 'MI',
      },
      {
        id: 2961,
        name: 'Murqub',
        state_code: 'MB',
      },
      {
        id: 2967,
        name: 'Murzuq District',
        state_code: 'MQ',
      },
      {
        id: 2976,
        name: 'Nalut District',
        state_code: 'NL',
      },
      {
        id: 2962,
        name: 'Nuqat al Khams',
        state_code: 'NQ',
      },
      {
        id: 2965,
        name: 'Sabha District',
        state_code: 'SB',
      },
      {
        id: 2977,
        name: 'Sirte District',
        state_code: 'SR',
      },
      {
        id: 2971,
        name: 'Tripoli District',
        state_code: 'TB',
      },
      {
        id: 2973,
        name: 'Wadi al Hayaa District',
        state_code: 'WD',
      },
      {
        id: 2975,
        name: 'Wadi al Shatii District',
        state_code: 'WS',
      },
      {
        id: 2963,
        name: 'Zawiya District',
        state_code: 'ZA',
      },
    ],
  },
  {
    name: 'Liechtenstein',
    iso3: 'LIE',
    iso2: 'LI',
    phone_code: '423',
    capital: 'Vaduz',
    currency: 'CHF',
    states: [
      {
        id: 458,
        name: 'Balzers',
        state_code: '01',
      },
      {
        id: 451,
        name: 'Eschen',
        state_code: '02',
      },
      {
        id: 457,
        name: 'Gamprin',
        state_code: '03',
      },
      {
        id: 455,
        name: 'Mauren',
        state_code: '04',
      },
      {
        id: 454,
        name: 'Planken',
        state_code: '05',
      },
      {
        id: 453,
        name: 'Ruggell',
        state_code: '06',
      },
      {
        id: 450,
        name: 'Schaan',
        state_code: '07',
      },
      {
        id: 449,
        name: 'Schellenberg',
        state_code: '08',
      },
      {
        id: 459,
        name: 'Triesen',
        state_code: '09',
      },
      {
        id: 456,
        name: 'Triesenberg',
        state_code: '10',
      },
      {
        id: 452,
        name: 'Vaduz',
        state_code: '11',
      },
    ],
  },
  {
    name: 'Lithuania',
    iso3: 'LTU',
    iso2: 'LT',
    phone_code: '370',
    capital: 'Vilnius',
    currency: 'LTL',
    states: [
      {
        id: 1561,
        name: 'Akmenė District Municipality',
        state_code: '01',
      },
      {
        id: 1605,
        name: 'Alytus City Municipality',
        state_code: '02',
      },
      {
        id: 1574,
        name: 'Alytus County',
        state_code: 'AL',
      },
      {
        id: 1599,
        name: 'Alytus District Municipality',
        state_code: '03',
      },
      {
        id: 1603,
        name: 'Birštonas Municipality',
        state_code: '05',
      },
      {
        id: 1566,
        name: 'Biržai District Municipality',
        state_code: '06',
      },
      {
        id: 1579,
        name: 'Druskininkai municipality',
        state_code: '07',
      },
      {
        id: 1559,
        name: 'Elektrėnai municipality',
        state_code: '08',
      },
      {
        id: 1562,
        name: 'Ignalina District Municipality',
        state_code: '09',
      },
      {
        id: 1567,
        name: 'Jonava District Municipality',
        state_code: '10',
      },
      {
        id: 1581,
        name: 'Joniškis District Municipality',
        state_code: '11',
      },
      {
        id: 1555,
        name: 'Jurbarkas District Municipality',
        state_code: '12',
      },
      {
        id: 1583,
        name: 'Kaišiadorys District Municipality',
        state_code: '13',
      },
      {
        id: 1591,
        name: 'Kalvarija municipality',
        state_code: '14',
      },
      {
        id: 1580,
        name: 'Kaunas City Municipality',
        state_code: '15',
      },
      {
        id: 1556,
        name: 'Kaunas County',
        state_code: 'KU',
      },
      {
        id: 1565,
        name: 'Kaunas District Municipality',
        state_code: '16',
      },
      {
        id: 1575,
        name: 'Kazlų Rūda municipality',
        state_code: '17',
      },
      {
        id: 1584,
        name: 'Kėdainiai District Municipality',
        state_code: '18',
      },
      {
        id: 1618,
        name: 'Kelmė District Municipality',
        state_code: '19',
      },
      {
        id: 1597,
        name: 'Klaipeda City Municipality',
        state_code: '20',
      },
      {
        id: 1600,
        name: 'Klaipėda County',
        state_code: 'KL',
      },
      {
        id: 1604,
        name: 'Klaipėda District Municipality',
        state_code: '21',
      },
      {
        id: 1571,
        name: 'Kretinga District Municipality',
        state_code: '22',
      },
      {
        id: 1585,
        name: 'Kupiškis District Municipality',
        state_code: '23',
      },
      {
        id: 1611,
        name: 'Lazdijai District Municipality',
        state_code: '24',
      },
      {
        id: 1570,
        name: 'Marijampolė County',
        state_code: 'MR',
      },
      {
        id: 1610,
        name: 'Marijampolė Municipality',
        state_code: '25',
      },
      {
        id: 1557,
        name: 'Mažeikiai District Municipality',
        state_code: '26',
      },
      {
        id: 1582,
        name: 'Molėtai District Municipality',
        state_code: '27',
      },
      {
        id: 1563,
        name: 'Neringa Municipality',
        state_code: '28',
      },
      {
        id: 1612,
        name: 'Pagėgiai municipality',
        state_code: '29',
      },
      {
        id: 1595,
        name: 'Pakruojis District Municipality',
        state_code: '30',
      },
      {
        id: 1588,
        name: 'Palanga City Municipality',
        state_code: '31',
      },
      {
        id: 1589,
        name: 'Panevėžys City Municipality',
        state_code: '32',
      },
      {
        id: 1558,
        name: 'Panevėžys County',
        state_code: 'PN',
      },
      {
        id: 1614,
        name: 'Panevėžys District Municipality',
        state_code: '33',
      },
      {
        id: 1616,
        name: 'Pasvalys District Municipality',
        state_code: '34',
      },
      {
        id: 1553,
        name: 'Plungė District Municipality',
        state_code: '35',
      },
      {
        id: 1578,
        name: 'Prienai District Municipality',
        state_code: '36',
      },
      {
        id: 1568,
        name: 'Radviliškis District Municipality',
        state_code: '37',
      },
      {
        id: 1587,
        name: 'Raseiniai District Municipality',
        state_code: '38',
      },
      {
        id: 1590,
        name: 'Rietavas municipality',
        state_code: '39',
      },
      {
        id: 1615,
        name: 'Rokiškis District Municipality',
        state_code: '40',
      },
      {
        id: 1576,
        name: 'Šakiai District Municipality',
        state_code: '41',
      },
      {
        id: 1577,
        name: 'Šalčininkai District Municipality',
        state_code: '42',
      },
      {
        id: 1609,
        name: 'Šiauliai City Municipality',
        state_code: '43',
      },
      {
        id: 1586,
        name: 'Šiauliai County',
        state_code: 'SA',
      },
      {
        id: 1554,
        name: 'Šiauliai District Municipality',
        state_code: '44',
      },
      {
        id: 1613,
        name: 'Šilalė District Municipality',
        state_code: '45',
      },
      {
        id: 1607,
        name: 'Šilutė District Municipality',
        state_code: '46',
      },
      {
        id: 1594,
        name: 'Širvintos District Municipality',
        state_code: '47',
      },
      {
        id: 1617,
        name: 'Skuodas District Municipality',
        state_code: '48',
      },
      {
        id: 1560,
        name: 'Švenčionys District Municipality',
        state_code: '49',
      },
      {
        id: 1573,
        name: 'Tauragė County',
        state_code: 'TA',
      },
      {
        id: 1572,
        name: 'Tauragė District Municipality',
        state_code: '50',
      },
      {
        id: 1569,
        name: 'Telšiai County',
        state_code: 'TE',
      },
      {
        id: 1608,
        name: 'Telšiai District Municipality',
        state_code: '51',
      },
      {
        id: 1593,
        name: 'Trakai District Municipality',
        state_code: '52',
      },
      {
        id: 1596,
        name: 'Ukmergė District Municipality',
        state_code: '53',
      },
      {
        id: 1621,
        name: 'Utena County',
        state_code: 'UT',
      },
      {
        id: 1598,
        name: 'Utena District Municipality',
        state_code: '54',
      },
      {
        id: 1602,
        name: 'Varėna District Municipality',
        state_code: '55',
      },
      {
        id: 1620,
        name: 'Vilkaviškis District Municipality',
        state_code: '56',
      },
      {
        id: 1606,
        name: 'Vilnius City Municipality',
        state_code: '57',
      },
      {
        id: 1601,
        name: 'Vilnius County',
        state_code: 'VL',
      },
      {
        id: 1592,
        name: 'Vilnius District Municipality',
        state_code: '58',
      },
      {
        id: 1564,
        name: 'Visaginas Municipality',
        state_code: '59',
      },
      {
        id: 1619,
        name: 'Zarasai District Municipality',
        state_code: '60',
      },
    ],
  },
  {
    name: 'Luxembourg',
    iso3: 'LUX',
    iso2: 'LU',
    phone_code: '352',
    capital: 'Luxembourg',
    currency: 'EUR',
    states: [
      {
        id: 1518,
        name: 'Canton of Capellen',
        state_code: 'CA',
      },
      {
        id: 1521,
        name: 'Canton of Clervaux',
        state_code: 'CL',
      },
      {
        id: 1513,
        name: 'Canton of Diekirch',
        state_code: 'DI',
      },
      {
        id: 1515,
        name: 'Canton of Echternach',
        state_code: 'EC',
      },
      {
        id: 1517,
        name: 'Canton of Esch-sur-Alzette',
        state_code: 'ES',
      },
      {
        id: 1525,
        name: 'Canton of Grevenmacher',
        state_code: 'GR',
      },
      {
        id: 1527,
        name: 'Canton of Luxembourg',
        state_code: 'LU',
      },
      {
        id: 1522,
        name: 'Canton of Mersch',
        state_code: 'ME',
      },
      {
        id: 1516,
        name: 'Canton of Redange',
        state_code: 'RD',
      },
      {
        id: 1519,
        name: 'Canton of Remich',
        state_code: 'RM',
      },
      {
        id: 1523,
        name: 'Canton of Vianden',
        state_code: 'VD',
      },
      {
        id: 1526,
        name: 'Canton of Wiltz',
        state_code: 'WI',
      },
      {
        id: 1524,
        name: 'Diekirch District',
        state_code: 'D',
      },
      {
        id: 1520,
        name: 'Grevenmacher District',
        state_code: 'G',
      },
      {
        id: 1514,
        name: 'Luxembourg District',
        state_code: 'L',
      },
    ],
  },
  {
    name: 'Macau S.A.R.',
    iso3: 'MAC',
    iso2: 'MO',
    phone_code: '853',
    capital: 'Macao',
    currency: 'MOP',
    states: [],
  },
  {
    name: 'Macedonia',
    iso3: 'MKD',
    iso2: 'MK',
    phone_code: '389',
    capital: 'Skopje',
    currency: 'MKD',
    states: [
      {
        id: 703,
        name: 'Aerodrom Municipality',
        state_code: '01',
      },
      {
        id: 656,
        name: 'Aračinovo Municipality',
        state_code: '02',
      },
      {
        id: 716,
        name: 'Berovo Municipality',
        state_code: '03',
      },
      {
        id: 679,
        name: 'Bitola Municipality',
        state_code: '04',
      },
      {
        id: 649,
        name: 'Bogdanci Municipality',
        state_code: '05',
      },
      {
        id: 721,
        name: 'Bogovinje Municipality',
        state_code: '06',
      },
      {
        id: 652,
        name: 'Bosilovo Municipality',
        state_code: '07',
      },
      {
        id: 660,
        name: 'Brvenica Municipality',
        state_code: '08',
      },
      {
        id: 694,
        name: 'Butel Municipality',
        state_code: '09',
      },
      {
        id: 704,
        name: 'Čair Municipality',
        state_code: '79',
      },
      {
        id: 676,
        name: 'Čaška Municipality',
        state_code: '80',
      },
      {
        id: 702,
        name: 'Centar Municipality',
        state_code: '77',
      },
      {
        id: 720,
        name: 'Centar Župa Municipality',
        state_code: '78',
      },
      {
        id: 644,
        name: 'Češinovo-Obleševo Municipality',
        state_code: '81',
      },
      {
        id: 715,
        name: 'Čučer-Sandevo Municipality',
        state_code: '82',
      },
      {
        id: 645,
        name: 'Debarca Municipality',
        state_code: '22',
      },
      {
        id: 695,
        name: 'Delčevo Municipality',
        state_code: '23',
      },
      {
        id: 687,
        name: 'Demir Hisar Municipality',
        state_code: '25',
      },
      {
        id: 655,
        name: 'Demir Kapija Municipality',
        state_code: '24',
      },
      {
        id: 697,
        name: 'Dojran Municipality',
        state_code: '26',
      },
      {
        id: 675,
        name: 'Dolneni Municipality',
        state_code: '27',
      },
      {
        id: 657,
        name: 'Drugovo Municipality',
        state_code: '28',
      },
      {
        id: 707,
        name: 'Gazi Baba Municipality',
        state_code: '17',
      },
      {
        id: 648,
        name: 'Gevgelija Municipality',
        state_code: '18',
      },
      {
        id: 722,
        name: 'Gjorče Petrov Municipality',
        state_code: '29',
      },
      {
        id: 693,
        name: 'Gostivar Municipality',
        state_code: '19',
      },
      {
        id: 708,
        name: 'Gradsko Municipality',
        state_code: '20',
      },
      {
        id: 684,
        name: 'Greater Skopje',
        state_code: '85',
      },
      {
        id: 690,
        name: 'Ilinden Municipality',
        state_code: '34',
      },
      {
        id: 678,
        name: 'Jegunovce Municipality',
        state_code: '35',
      },
      {
        id: 674,
        name: 'Karbinci',
        state_code: '37',
      },
      {
        id: 681,
        name: 'Karpoš Municipality',
        state_code: '38',
      },
      {
        id: 713,
        name: 'Kavadarci Municipality',
        state_code: '36',
      },
      {
        id: 688,
        name: 'Kičevo Municipality',
        state_code: '40',
      },
      {
        id: 686,
        name: 'Kisela Voda Municipality',
        state_code: '39',
      },
      {
        id: 723,
        name: 'Kočani Municipality',
        state_code: '42',
      },
      {
        id: 665,
        name: 'Konče Municipality',
        state_code: '41',
      },
      {
        id: 641,
        name: 'Kratovo Municipality',
        state_code: '43',
      },
      {
        id: 677,
        name: 'Kriva Palanka Municipality',
        state_code: '44',
      },
      {
        id: 647,
        name: 'Krivogaštani Municipality',
        state_code: '45',
      },
      {
        id: 714,
        name: 'Kruševo Municipality',
        state_code: '46',
      },
      {
        id: 683,
        name: 'Kumanovo Municipality',
        state_code: '47',
      },
      {
        id: 659,
        name: 'Lipkovo Municipality',
        state_code: '48',
      },
      {
        id: 705,
        name: 'Lozovo Municipality',
        state_code: '49',
      },
      {
        id: 701,
        name: 'Makedonska Kamenica Municipality',
        state_code: '51',
      },
      {
        id: 692,
        name: 'Makedonski Brod Municipality',
        state_code: '52',
      },
      {
        id: 669,
        name: 'Mavrovo and Rostuša Municipality',
        state_code: '50',
      },
      {
        id: 653,
        name: 'Mogila Municipality',
        state_code: '53',
      },
      {
        id: 664,
        name: 'Negotino Municipality',
        state_code: '54',
      },
      {
        id: 696,
        name: 'Novaci Municipality',
        state_code: '55',
      },
      {
        id: 718,
        name: 'Novo Selo Municipality',
        state_code: '56',
      },
      {
        id: 699,
        name: 'Ohrid Municipality',
        state_code: '58',
      },
      {
        id: 682,
        name: 'Oslomej Municipality',
        state_code: '57',
      },
      {
        id: 685,
        name: 'Pehčevo Municipality',
        state_code: '60',
      },
      {
        id: 698,
        name: 'Petrovec Municipality',
        state_code: '59',
      },
      {
        id: 670,
        name: 'Plasnica Municipality',
        state_code: '61',
      },
      {
        id: 666,
        name: 'Prilep Municipality',
        state_code: '62',
      },
      {
        id: 646,
        name: 'Probištip Municipality',
        state_code: '63',
      },
      {
        id: 709,
        name: 'Radoviš Municipality',
        state_code: '64',
      },
      {
        id: 717,
        name: 'Rankovce Municipality',
        state_code: '65',
      },
      {
        id: 712,
        name: 'Resen Municipality',
        state_code: '66',
      },
      {
        id: 691,
        name: 'Rosoman Municipality',
        state_code: '67',
      },
      {
        id: 667,
        name: 'Saraj Municipality',
        state_code: '68',
      },
      {
        id: 719,
        name: 'Sopište Municipality',
        state_code: '70',
      },
      {
        id: 643,
        name: 'Staro Nagoričane Municipality',
        state_code: '71',
      },
      {
        id: 661,
        name: 'Štip Municipality',
        state_code: '83',
      },
      {
        id: 700,
        name: 'Struga Municipality',
        state_code: '72',
      },
      {
        id: 710,
        name: 'Strumica Municipality',
        state_code: '73',
      },
      {
        id: 711,
        name: 'Studeničani Municipality',
        state_code: '74',
      },
      {
        id: 680,
        name: 'Šuto Orizari Municipality',
        state_code: '84',
      },
      {
        id: 640,
        name: 'Sveti Nikole Municipality',
        state_code: '69',
      },
      {
        id: 654,
        name: 'Tearce Municipality',
        state_code: '75',
      },
      {
        id: 663,
        name: 'Tetovo Municipality',
        state_code: '76',
      },
      {
        id: 671,
        name: 'Valandovo Municipality',
        state_code: '10',
      },
      {
        id: 658,
        name: 'Vasilevo Municipality',
        state_code: '11',
      },
      {
        id: 651,
        name: 'Veles Municipality',
        state_code: '13',
      },
      {
        id: 662,
        name: 'Vevčani Municipality',
        state_code: '12',
      },
      {
        id: 672,
        name: 'Vinica Municipality',
        state_code: '14',
      },
      {
        id: 650,
        name: 'Vraneštica Municipality',
        state_code: '15',
      },
      {
        id: 689,
        name: 'Vrapčište Municipality',
        state_code: '16',
      },
      {
        id: 642,
        name: 'Zajas Municipality',
        state_code: '31',
      },
      {
        id: 706,
        name: 'Zelenikovo Municipality',
        state_code: '32',
      },
      {
        id: 668,
        name: 'Želino Municipality',
        state_code: '30',
      },
      {
        id: 673,
        name: 'Zrnovci Municipality',
        state_code: '33',
      },
    ],
  },
  {
    name: 'Madagascar',
    iso3: 'MDG',
    iso2: 'MG',
    phone_code: '261',
    capital: 'Antananarivo',
    currency: 'MGA',
    states: [
      {
        id: 2951,
        name: 'Antananarivo Province',
        state_code: 'T',
      },
      {
        id: 2950,
        name: 'Antsiranana Province',
        state_code: 'D',
      },
      {
        id: 2948,
        name: 'Fianarantsoa Province',
        state_code: 'F',
      },
      {
        id: 2953,
        name: 'Mahajanga Province',
        state_code: 'M',
      },
      {
        id: 2952,
        name: 'Toamasina Province',
        state_code: 'A',
      },
      {
        id: 2949,
        name: 'Toliara Province',
        state_code: 'U',
      },
    ],
  },
  {
    name: 'Malawi',
    iso3: 'MWI',
    iso2: 'MW',
    phone_code: '265',
    capital: 'Lilongwe',
    currency: 'MWK',
    states: [
      {
        id: 3096,
        name: 'Balaka District',
        state_code: 'BA',
      },
      {
        id: 3102,
        name: 'Blantyre District',
        state_code: 'BL',
      },
      {
        id: 3092,
        name: 'Central Region',
        state_code: 'C',
      },
      {
        id: 3107,
        name: 'Chikwawa District',
        state_code: 'CK',
      },
      {
        id: 3109,
        name: 'Chiradzulu District',
        state_code: 'CR',
      },
      {
        id: 3087,
        name: 'Chitipa district',
        state_code: 'CT',
      },
      {
        id: 3097,
        name: 'Dedza District',
        state_code: 'DE',
      },
      {
        id: 3090,
        name: 'Dowa District',
        state_code: 'DO',
      },
      {
        id: 3091,
        name: 'Karonga District',
        state_code: 'KR',
      },
      {
        id: 3094,
        name: 'Kasungu District',
        state_code: 'KS',
      },
      {
        id: 3093,
        name: 'Likoma District',
        state_code: 'LK',
      },
      {
        id: 3101,
        name: 'Lilongwe District',
        state_code: 'LI',
      },
      {
        id: 3082,
        name: 'Machinga District',
        state_code: 'MH',
      },
      {
        id: 3110,
        name: 'Mangochi District',
        state_code: 'MG',
      },
      {
        id: 3099,
        name: 'Mchinji District',
        state_code: 'MC',
      },
      {
        id: 3103,
        name: 'Mulanje District',
        state_code: 'MU',
      },
      {
        id: 3084,
        name: 'Mwanza District',
        state_code: 'MW',
      },
      {
        id: 3104,
        name: 'Mzimba District',
        state_code: 'MZ',
      },
      {
        id: 3095,
        name: 'Nkhata Bay District',
        state_code: 'NB',
      },
      {
        id: 3100,
        name: 'Nkhotakota District',
        state_code: 'NK',
      },
      {
        id: 3105,
        name: 'Northern Region',
        state_code: 'N',
      },
      {
        id: 3085,
        name: 'Nsanje District',
        state_code: 'NS',
      },
      {
        id: 3088,
        name: 'Ntcheu District',
        state_code: 'NU',
      },
      {
        id: 3111,
        name: 'Ntchisi District',
        state_code: 'NI',
      },
      {
        id: 3108,
        name: 'Phalombe District',
        state_code: 'PH',
      },
      {
        id: 3089,
        name: 'Rumphi District',
        state_code: 'RU',
      },
      {
        id: 3086,
        name: 'Salima District',
        state_code: 'SA',
      },
      {
        id: 3106,
        name: 'Southern Region',
        state_code: 'S',
      },
      {
        id: 3098,
        name: 'Thyolo District',
        state_code: 'TH',
      },
      {
        id: 3083,
        name: 'Zomba District',
        state_code: 'ZO',
      },
    ],
  },
  {
    name: 'Malaysia',
    iso3: 'MYS',
    iso2: 'MY',
    phone_code: '60',
    capital: 'Kuala Lumpur',
    currency: 'MYR',
    states: [
      {
        id: 1950,
        name: 'Johor',
        state_code: '01',
      },
      {
        id: 1947,
        name: 'Kedah',
        state_code: '02',
      },
      {
        id: 1946,
        name: 'Kelantan',
        state_code: '03',
      },
      {
        id: 1949,
        name: 'Kuala Lumpur',
        state_code: '14',
      },
      {
        id: 1935,
        name: 'Labuan',
        state_code: '15',
      },
      {
        id: 1941,
        name: 'Melaka',
        state_code: '04',
      },
      {
        id: 1948,
        name: 'Negeri Sembilan',
        state_code: '05',
      },
      {
        id: 1940,
        name: 'Pahang',
        state_code: '06',
      },
      {
        id: 1939,
        name: 'Penang',
        state_code: '07',
      },
      {
        id: 1943,
        name: 'Perak',
        state_code: '08',
      },
      {
        id: 1938,
        name: 'Perlis',
        state_code: '09',
      },
      {
        id: 1945,
        name: 'Putrajaya',
        state_code: '16',
      },
      {
        id: 1936,
        name: 'Sabah',
        state_code: '12',
      },
      {
        id: 1937,
        name: 'Sarawak',
        state_code: '13',
      },
      {
        id: 1944,
        name: 'Selangor',
        state_code: '10',
      },
      {
        id: 1942,
        name: 'Terengganu',
        state_code: '11',
      },
    ],
  },
  {
    name: 'Maldives',
    iso3: 'MDV',
    iso2: 'MV',
    phone_code: '960',
    capital: 'Male',
    currency: 'MVR',
    states: [
      {
        id: 2594,
        name: 'Addu Atoll',
        state_code: '01',
      },
      {
        id: 2587,
        name: 'Alif Alif Atoll',
        state_code: '02',
      },
      {
        id: 2600,
        name: 'Alif Dhaal Atoll',
        state_code: '00',
      },
      {
        id: 2604,
        name: 'Central Province',
        state_code: 'CE',
      },
      {
        id: 2590,
        name: 'Dhaalu Atoll',
        state_code: '17',
      },
      {
        id: 2599,
        name: 'Faafu Atoll',
        state_code: '14',
      },
      {
        id: 2598,
        name: 'Gaafu Alif Atoll',
        state_code: '27',
      },
      {
        id: 2603,
        name: 'Gaafu Dhaalu Atoll',
        state_code: '28',
      },
      {
        id: 2595,
        name: 'Gnaviyani Atoll',
        state_code: '29',
      },
      {
        id: 2586,
        name: 'Haa Alif Atoll',
        state_code: '07',
      },
      {
        id: 2597,
        name: 'Haa Dhaalu Atoll',
        state_code: '23',
      },
      {
        id: 2596,
        name: 'Kaafu Atoll',
        state_code: '26',
      },
      {
        id: 2601,
        name: 'Laamu Atoll',
        state_code: '05',
      },
      {
        id: 2607,
        name: 'Lhaviyani Atoll',
        state_code: '03',
      },
      {
        id: 2609,
        name: 'Malé',
        state_code: 'MLE',
      },
      {
        id: 2608,
        name: 'Meemu Atoll',
        state_code: '12',
      },
      {
        id: 2592,
        name: 'Noonu Atoll',
        state_code: '25',
      },
      {
        id: 2589,
        name: 'North Central Province',
        state_code: 'NC',
      },
      {
        id: 2588,
        name: 'North Province',
        state_code: 'NO',
      },
      {
        id: 2602,
        name: 'Raa Atoll',
        state_code: '13',
      },
      {
        id: 2585,
        name: 'Shaviyani Atoll',
        state_code: '24',
      },
      {
        id: 2606,
        name: 'South Central Province',
        state_code: 'SC',
      },
      {
        id: 2605,
        name: 'South Province',
        state_code: 'SU',
      },
      {
        id: 2591,
        name: 'Thaa Atoll',
        state_code: '08',
      },
      {
        id: 2593,
        name: 'Upper South Province',
        state_code: 'US',
      },
      {
        id: 2584,
        name: 'Vaavu Atoll',
        state_code: '04',
      },
    ],
  },
  {
    name: 'Mali',
    iso3: 'MLI',
    iso2: 'ML',
    phone_code: '223',
    capital: 'Bamako',
    currency: 'XOF',
    states: [
      {
        id: 253,
        name: 'Bamako',
        state_code: 'BKO',
      },
      {
        id: 258,
        name: 'Gao Region',
        state_code: '7',
      },
      {
        id: 252,
        name: 'Kayes Region',
        state_code: '1',
      },
      {
        id: 257,
        name: 'Kidal Region',
        state_code: '8',
      },
      {
        id: 250,
        name: 'Koulikoro Region',
        state_code: '2',
      },
      {
        id: 251,
        name: 'Ménaka Region',
        state_code: '9',
      },
      {
        id: 255,
        name: 'Mopti Region',
        state_code: '5',
      },
      {
        id: 249,
        name: 'Ségou Region',
        state_code: '4',
      },
      {
        id: 254,
        name: 'Sikasso Region',
        state_code: '3',
      },
      {
        id: 256,
        name: 'Taoudénit Region',
        state_code: '10',
      },
      {
        id: 248,
        name: 'Tombouctou Region',
        state_code: '6',
      },
    ],
  },
  {
    name: 'Malta',
    iso3: 'MLT',
    iso2: 'MT',
    phone_code: '356',
    capital: 'Valletta',
    currency: 'EUR',
    states: [
      {
        id: 110,
        name: 'Attard',
        state_code: '01',
      },
      {
        id: 108,
        name: 'Balzan',
        state_code: '02',
      },
      {
        id: 107,
        name: 'Birgu',
        state_code: '03',
      },
      {
        id: 97,
        name: 'Birkirkara',
        state_code: '04',
      },
      {
        id: 88,
        name: 'Birżebbuġa',
        state_code: '05',
      },
      {
        id: 138,
        name: 'Cospicua',
        state_code: '06',
      },
      {
        id: 117,
        name: 'Dingli',
        state_code: '07',
      },
      {
        id: 129,
        name: 'Fgura',
        state_code: '08',
      },
      {
        id: 84,
        name: 'Floriana',
        state_code: '09',
      },
      {
        id: 134,
        name: 'Fontana',
        state_code: '10',
      },
      {
        id: 130,
        name: 'Għajnsielem',
        state_code: '13',
      },
      {
        id: 92,
        name: 'Għarb',
        state_code: '14',
      },
      {
        id: 120,
        name: 'Għargħur',
        state_code: '15',
      },
      {
        id: 106,
        name: 'Għasri',
        state_code: '16',
      },
      {
        id: 124,
        name: 'Għaxaq',
        state_code: '17',
      },
      {
        id: 118,
        name: 'Gudja',
        state_code: '11',
      },
      {
        id: 113,
        name: 'Gżira',
        state_code: '12',
      },
      {
        id: 105,
        name: 'Ħamrun',
        state_code: '18',
      },
      {
        id: 93,
        name: 'Iklin',
        state_code: '19',
      },
      {
        id: 99,
        name: 'Kalkara',
        state_code: '21',
      },
      {
        id: 91,
        name: 'Kerċem',
        state_code: '22',
      },
      {
        id: 82,
        name: 'Kirkop',
        state_code: '23',
      },
      {
        id: 126,
        name: 'Lija',
        state_code: '24',
      },
      {
        id: 77,
        name: 'Luqa',
        state_code: '25',
      },
      {
        id: 128,
        name: 'Marsa',
        state_code: '26',
      },
      {
        id: 137,
        name: 'Marsaskala',
        state_code: '27',
      },
      {
        id: 78,
        name: 'Marsaxlokk',
        state_code: '28',
      },
      {
        id: 89,
        name: 'Mdina',
        state_code: '29',
      },
      {
        id: 102,
        name: 'Mellieħa',
        state_code: '30',
      },
      {
        id: 109,
        name: 'Mġarr',
        state_code: '31',
      },
      {
        id: 140,
        name: 'Mosta',
        state_code: '32',
      },
      {
        id: 74,
        name: 'Mqabba',
        state_code: '33',
      },
      {
        id: 96,
        name: 'Msida',
        state_code: '34',
      },
      {
        id: 131,
        name: 'Mtarfa',
        state_code: '35',
      },
      {
        id: 132,
        name: 'Munxar',
        state_code: '36',
      },
      {
        id: 133,
        name: 'Nadur',
        state_code: '37',
      },
      {
        id: 112,
        name: 'Naxxar',
        state_code: '38',
      },
      {
        id: 115,
        name: 'Paola',
        state_code: '39',
      },
      {
        id: 125,
        name: 'Pembroke',
        state_code: '40',
      },
      {
        id: 127,
        name: 'Pietà',
        state_code: '41',
      },
      {
        id: 79,
        name: 'Qala',
        state_code: '42',
      },
      {
        id: 119,
        name: 'Qormi',
        state_code: '43',
      },
      {
        id: 111,
        name: 'Qrendi',
        state_code: '44',
      },
      {
        id: 83,
        name: 'Rabat',
        state_code: '46',
      },
      {
        id: 87,
        name: 'Saint Lawrence',
        state_code: '50',
      },
      {
        id: 75,
        name: 'San Ġwann',
        state_code: '49',
      },
      {
        id: 116,
        name: 'Sannat',
        state_code: '52',
      },
      {
        id: 94,
        name: 'Santa Luċija',
        state_code: '53',
      },
      {
        id: 90,
        name: 'Santa Venera',
        state_code: '54',
      },
      {
        id: 136,
        name: 'Senglea',
        state_code: '20',
      },
      {
        id: 98,
        name: 'Siġġiewi',
        state_code: '55',
      },
      {
        id: 104,
        name: 'Sliema',
        state_code: '56',
      },
      {
        id: 100,
        name: "St. Julian's",
        state_code: '48',
      },
      {
        id: 139,
        name: "St. Paul's Bay",
        state_code: '51',
      },
      {
        id: 86,
        name: 'Swieqi',
        state_code: '57',
      },
      {
        id: 122,
        name: "Ta' Xbiex",
        state_code: '58',
      },
      {
        id: 103,
        name: 'Tarxien',
        state_code: '59',
      },
      {
        id: 95,
        name: 'Valletta',
        state_code: '60',
      },
      {
        id: 101,
        name: 'Victoria',
        state_code: '45',
      },
      {
        id: 114,
        name: 'Xagħra',
        state_code: '61',
      },
      {
        id: 121,
        name: 'Xewkija',
        state_code: '62',
      },
      {
        id: 81,
        name: 'Xgħajra',
        state_code: '63',
      },
      {
        id: 123,
        name: 'Żabbar',
        state_code: '64',
      },
      {
        id: 85,
        name: 'Żebbuġ Gozo',
        state_code: '65',
      },
      {
        id: 80,
        name: 'Żebbuġ Malta',
        state_code: '66',
      },
      {
        id: 135,
        name: 'Żejtun',
        state_code: '67',
      },
      {
        id: 76,
        name: 'Żurrieq',
        state_code: '68',
      },
    ],
  },
  {
    name: 'Man (Isle of)',
    iso3: 'IMN',
    iso2: 'IM',
    phone_code: '+44-1624',
    capital: 'Douglas, Isle of Man',
    currency: 'GBP',
    states: [],
  },
  {
    name: 'Marshall Islands',
    iso3: 'MHL',
    iso2: 'MH',
    phone_code: '692',
    capital: 'Majuro',
    currency: 'USD',
    states: [
      {
        id: 2574,
        name: 'Ralik Chain',
        state_code: 'L',
      },
      {
        id: 2573,
        name: 'Ratak Chain',
        state_code: 'T',
      },
    ],
  },
  {
    name: 'Martinique',
    iso3: 'MTQ',
    iso2: 'MQ',
    phone_code: '596',
    capital: 'Fort-de-France',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Mauritania',
    iso3: 'MRT',
    iso2: 'MR',
    phone_code: '222',
    capital: 'Nouakchott',
    currency: 'MRO',
    states: [
      {
        id: 3344,
        name: 'Adrar Region',
        state_code: '07',
      },
      {
        id: 3349,
        name: 'Assaba Region',
        state_code: '03',
      },
      {
        id: 3339,
        name: 'Brakna Region',
        state_code: '05',
      },
      {
        id: 3346,
        name: 'Dakhlet Nouadhibou',
        state_code: '08',
      },
      {
        id: 3341,
        name: 'Gorgol Region',
        state_code: '04',
      },
      {
        id: 3350,
        name: 'Guidimaka Region',
        state_code: '10',
      },
      {
        id: 3338,
        name: 'Hodh Ech Chargui Region',
        state_code: '01',
      },
      {
        id: 3351,
        name: 'Hodh El Gharbi Region',
        state_code: '02',
      },
      {
        id: 3342,
        name: 'Inchiri Region',
        state_code: '12',
      },
      {
        id: 3343,
        name: 'Nouakchott-Nord Region',
        state_code: '14',
      },
      {
        id: 3352,
        name: 'Nouakchott-Ouest Region',
        state_code: '13',
      },
      {
        id: 3347,
        name: 'Nouakchott-Sud Region',
        state_code: '15',
      },
      {
        id: 3345,
        name: 'Tagant Region',
        state_code: '09',
      },
      {
        id: 3340,
        name: 'Tiris Zemmour Region',
        state_code: '11',
      },
      {
        id: 3348,
        name: 'Trarza Region',
        state_code: '06',
      },
    ],
  },
  {
    name: 'Mauritius',
    iso3: 'MUS',
    iso2: 'MU',
    phone_code: '230',
    capital: 'Port Louis',
    currency: 'MUR',
    states: [
      {
        id: 3248,
        name: 'Agaléga',
        state_code: 'AG',
      },
      {
        id: 3262,
        name: 'Beau Bassin-Rose Hill',
        state_code: 'BR',
      },
      {
        id: 3251,
        name: 'Cargados Carajos',
        state_code: 'CC',
      },
      {
        id: 3255,
        name: 'Curepipe',
        state_code: 'CU',
      },
      {
        id: 3254,
        name: 'Flacq District',
        state_code: 'FL',
      },
      {
        id: 3264,
        name: 'Grand Port District',
        state_code: 'GP',
      },
      {
        id: 3253,
        name: 'Moka District',
        state_code: 'MO',
      },
      {
        id: 3250,
        name: 'Pamplemousses District',
        state_code: 'PA',
      },
      {
        id: 3263,
        name: 'Plaines Wilhems District',
        state_code: 'PW',
      },
      {
        id: 3256,
        name: 'Port Louis',
        state_code: 'PU',
      },
      {
        id: 3260,
        name: 'Port Louis District',
        state_code: 'PL',
      },
      {
        id: 3258,
        name: 'Quatre Bornes',
        state_code: 'QB',
      },
      {
        id: 3261,
        name: 'Rivière du Rempart District',
        state_code: 'RR',
      },
      {
        id: 3259,
        name: 'Rivière Noire District',
        state_code: 'BL',
      },
      {
        id: 3249,
        name: 'Rodrigues',
        state_code: 'RO',
      },
      {
        id: 3257,
        name: 'Savanne District',
        state_code: 'SA',
      },
      {
        id: 3252,
        name: 'Vacoas-Phoenix',
        state_code: 'VP',
      },
    ],
  },
  {
    name: 'Mayotte',
    iso3: 'MYT',
    iso2: 'YT',
    phone_code: '262',
    capital: 'Mamoudzou',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Mexico',
    iso3: 'MEX',
    iso2: 'MX',
    phone_code: '52',
    capital: 'Mexico City',
    currency: 'MXN',
    states: [
      {
        id: 3456,
        name: 'Aguascalientes',
        state_code: 'AGU',
      },
      {
        id: 3457,
        name: 'Baja California',
        state_code: 'BCN',
      },
      {
        id: 3460,
        name: 'Baja California Sur',
        state_code: 'BCS',
      },
      {
        id: 3475,
        name: 'Campeche',
        state_code: 'CAM',
      },
      {
        id: 3451,
        name: 'Chiapas',
        state_code: 'CHP',
      },
      {
        id: 3447,
        name: 'Chihuahua',
        state_code: 'CHH',
      },
      {
        id: 3471,
        name: 'Coahuila',
        state_code: 'COA',
      },
      {
        id: 3472,
        name: 'Colima',
        state_code: 'COL',
      },
      {
        id: 3453,
        name: 'Durango',
        state_code: 'DUR',
      },
      {
        id: 3469,
        name: 'Guanajuato',
        state_code: 'GUA',
      },
      {
        id: 3459,
        name: 'Guerrero',
        state_code: 'GRO',
      },
      {
        id: 3470,
        name: 'Hidalgo',
        state_code: 'HID',
      },
      {
        id: 3450,
        name: 'México',
        state_code: 'MEX',
      },
      {
        id: 3473,
        name: 'Mexico City',
        state_code: 'CMX',
      },
      {
        id: 3474,
        name: 'Michoacán',
        state_code: 'MIC',
      },
      {
        id: 3465,
        name: 'Morelos',
        state_code: 'MOR',
      },
      {
        id: 3477,
        name: 'Nayarit',
        state_code: 'NAY',
      },
      {
        id: 3452,
        name: 'Nuevo León',
        state_code: 'NLE',
      },
      {
        id: 3448,
        name: 'Oaxaca',
        state_code: 'OAX',
      },
      {
        id: 3476,
        name: 'Puebla',
        state_code: 'PUE',
      },
      {
        id: 3455,
        name: 'Querétaro',
        state_code: 'QUE',
      },
      {
        id: 3467,
        name: 'Quintana Roo',
        state_code: 'ROO',
      },
      {
        id: 3461,
        name: 'San Luis Potosí',
        state_code: 'SLP',
      },
      {
        id: 3449,
        name: 'Sinaloa',
        state_code: 'SIN',
      },
      {
        id: 3468,
        name: 'Sonora',
        state_code: 'SON',
      },
      {
        id: 3454,
        name: 'Tabasco',
        state_code: 'TAB',
      },
      {
        id: 3463,
        name: 'Tamaulipas',
        state_code: 'TAM',
      },
      {
        id: 3458,
        name: 'Tlaxcala',
        state_code: 'TLA',
      },
      {
        id: 3464,
        name: 'Veracruz',
        state_code: 'VER',
      },
      {
        id: 3466,
        name: 'Yucatán',
        state_code: 'YUC',
      },
      {
        id: 3462,
        name: 'Zacatecas',
        state_code: 'ZAC',
      },
    ],
  },
  {
    name: 'Micronesia',
    iso3: 'FSM',
    iso2: 'FM',
    phone_code: '691',
    capital: 'Palikir',
    currency: 'USD',
    states: [
      {
        id: 2580,
        name: 'Chuuk State',
        state_code: 'TRK',
      },
      {
        id: 2583,
        name: 'Kosrae State',
        state_code: 'KSA',
      },
      {
        id: 2581,
        name: 'Pohnpei State',
        state_code: 'PNI',
      },
      {
        id: 2582,
        name: 'Yap State',
        state_code: 'YAP',
      },
    ],
  },
  {
    name: 'Moldova',
    iso3: 'MDA',
    iso2: 'MD',
    phone_code: '373',
    capital: 'Chisinau',
    currency: 'MDL',
    states: [
      {
        id: 4368,
        name: 'Anenii Noi District',
        state_code: 'AN',
      },
      {
        id: 4393,
        name: 'Bălți Municipality',
        state_code: 'BA',
      },
      {
        id: 4379,
        name: 'Basarabeasca District',
        state_code: 'BS',
      },
      {
        id: 4362,
        name: 'Bender Municipality',
        state_code: 'BD',
      },
      {
        id: 4375,
        name: 'Briceni District',
        state_code: 'BR',
      },
      {
        id: 4391,
        name: 'Cahul District',
        state_code: 'CA',
      },
      {
        id: 4366,
        name: 'Călărași District',
        state_code: 'CL',
      },
      {
        id: 4380,
        name: 'Cantemir District',
        state_code: 'CT',
      },
      {
        id: 4365,
        name: 'Căușeni District',
        state_code: 'CS',
      },
      {
        id: 4373,
        name: 'Chișinău Municipality',
        state_code: 'CU',
      },
      {
        id: 4360,
        name: 'Cimișlia District',
        state_code: 'CM',
      },
      {
        id: 4390,
        name: 'Criuleni District',
        state_code: 'CR',
      },
      {
        id: 4384,
        name: 'Dondușeni District',
        state_code: 'DO',
      },
      {
        id: 4392,
        name: 'Drochia District',
        state_code: 'DR',
      },
      {
        id: 4383,
        name: 'Dubăsari District',
        state_code: 'DU',
      },
      {
        id: 4387,
        name: 'Edineț District',
        state_code: 'ED',
      },
      {
        id: 4381,
        name: 'Fălești District',
        state_code: 'FA',
      },
      {
        id: 4370,
        name: 'Florești District',
        state_code: 'FL',
      },
      {
        id: 4385,
        name: 'Gagauzia',
        state_code: 'GA',
      },
      {
        id: 4367,
        name: 'Glodeni District',
        state_code: 'GL',
      },
      {
        id: 4382,
        name: 'Hîncești District',
        state_code: 'HI',
      },
      {
        id: 4369,
        name: 'Ialoveni District',
        state_code: 'IA',
      },
      {
        id: 4363,
        name: 'Nisporeni District',
        state_code: 'NI',
      },
      {
        id: 4389,
        name: 'Ocnița District',
        state_code: 'OC',
      },
      {
        id: 4361,
        name: 'Orhei District',
        state_code: 'OR',
      },
      {
        id: 4394,
        name: 'Rezina District',
        state_code: 'RE',
      },
      {
        id: 4376,
        name: 'Rîșcani District',
        state_code: 'RI',
      },
      {
        id: 4364,
        name: 'Sîngerei District',
        state_code: 'SI',
      },
      {
        id: 4388,
        name: 'Șoldănești District',
        state_code: 'SD',
      },
      {
        id: 4374,
        name: 'Soroca District',
        state_code: 'SO',
      },
      {
        id: 4378,
        name: 'Ștefan Vodă District',
        state_code: 'SV',
      },
      {
        id: 4377,
        name: 'Strășeni District',
        state_code: 'ST',
      },
      {
        id: 4372,
        name: 'Taraclia District',
        state_code: 'TA',
      },
      {
        id: 4371,
        name: 'Telenești District',
        state_code: 'TE',
      },
      {
        id: 4395,
        name: 'Transnistria autonomous territorial unit',
        state_code: 'SN',
      },
      {
        id: 4386,
        name: 'Ungheni District',
        state_code: 'UN',
      },
    ],
  },
  {
    name: 'Monaco',
    iso3: 'MCO',
    iso2: 'MC',
    phone_code: '377',
    capital: 'Monaco',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Mongolia',
    iso3: 'MNG',
    iso2: 'MN',
    phone_code: '976',
    capital: 'Ulan Bator',
    currency: 'MNT',
    states: [
      {
        id: 1973,
        name: 'Arkhangai Province',
        state_code: '073',
      },
      {
        id: 1969,
        name: 'Bayan-Ölgii Province',
        state_code: '071',
      },
      {
        id: 1976,
        name: 'Bayankhongor Province',
        state_code: '069',
      },
      {
        id: 1961,
        name: 'Bulgan Province',
        state_code: '067',
      },
      {
        id: 1962,
        name: 'Darkhan-Uul Province',
        state_code: '037',
      },
      {
        id: 1963,
        name: 'Dornod Province',
        state_code: '061',
      },
      {
        id: 1981,
        name: 'Dornogovi Province',
        state_code: '063',
      },
      {
        id: 1970,
        name: 'Dundgovi Province',
        state_code: '059',
      },
      {
        id: 1972,
        name: 'Govi-Altai Province',
        state_code: '065',
      },
      {
        id: 1978,
        name: 'Govisümber Province',
        state_code: '064',
      },
      {
        id: 1974,
        name: 'Khentii Province',
        state_code: '039',
      },
      {
        id: 1964,
        name: 'Khovd Province',
        state_code: '043',
      },
      {
        id: 1975,
        name: 'Khövsgöl Province',
        state_code: '041',
      },
      {
        id: 1967,
        name: 'Ömnögovi Province',
        state_code: '053',
      },
      {
        id: 1966,
        name: 'Orkhon Province',
        state_code: '035',
      },
      {
        id: 1965,
        name: 'Övörkhangai Province',
        state_code: '055',
      },
      {
        id: 1980,
        name: 'Selenge Province',
        state_code: '049',
      },
      {
        id: 1977,
        name: 'Sükhbaatar Province',
        state_code: '051',
      },
      {
        id: 1968,
        name: 'Töv Province',
        state_code: '047',
      },
      {
        id: 1971,
        name: 'Uvs Province',
        state_code: '046',
      },
      {
        id: 1979,
        name: 'Zavkhan Province',
        state_code: '057',
      },
    ],
  },
  {
    name: 'Montenegro',
    iso3: 'MNE',
    iso2: 'ME',
    phone_code: '382',
    capital: 'Podgorica',
    currency: 'EUR',
    states: [
      {
        id: 23,
        name: 'Andrijevica Municipality',
        state_code: '01',
      },
      {
        id: 13,
        name: 'Bar Municipality',
        state_code: '02',
      },
      {
        id: 21,
        name: 'Berane Municipality',
        state_code: '03',
      },
      {
        id: 25,
        name: 'Bijelo Polje Municipality',
        state_code: '04',
      },
      {
        id: 30,
        name: 'Budva Municipality',
        state_code: '05',
      },
      {
        id: 14,
        name: 'Danilovgrad Municipality',
        state_code: '07',
      },
      {
        id: 24,
        name: 'Gusinje Municipality',
        state_code: '22',
      },
      {
        id: 31,
        name: 'Kolašin Municipality',
        state_code: '09',
      },
      {
        id: 26,
        name: 'Kotor Municipality',
        state_code: '10',
      },
      {
        id: 22,
        name: 'Mojkovac Municipality',
        state_code: '11',
      },
      {
        id: 17,
        name: 'Nikšić Municipality',
        state_code: '12',
      },
      {
        id: 28,
        name: 'Old Royal Capital Cetinje',
        state_code: '06',
      },
      {
        id: 12,
        name: 'Petnjica Municipality',
        state_code: '23',
      },
      {
        id: 19,
        name: 'Plav Municipality',
        state_code: '13',
      },
      {
        id: 20,
        name: 'Pljevlja Municipality',
        state_code: '14',
      },
      {
        id: 16,
        name: 'Plužine Municipality',
        state_code: '15',
      },
      {
        id: 27,
        name: 'Podgorica Municipality',
        state_code: '16',
      },
      {
        id: 15,
        name: 'Rožaje Municipality',
        state_code: '17',
      },
      {
        id: 18,
        name: 'Šavnik Municipality',
        state_code: '18',
      },
      {
        id: 29,
        name: 'Tivat Municipality',
        state_code: '19',
      },
      {
        id: 33,
        name: 'Ulcinj Municipality',
        state_code: '20',
      },
      {
        id: 32,
        name: 'Žabljak Municipality',
        state_code: '21',
      },
    ],
  },
  {
    name: 'Montserrat',
    iso3: 'MSR',
    iso2: 'MS',
    phone_code: '+1-664',
    capital: 'Plymouth',
    currency: 'XCD',
    states: [],
  },
  {
    name: 'Morocco',
    iso3: 'MAR',
    iso2: 'MA',
    phone_code: '212',
    capital: 'Rabat',
    currency: 'MAD',
    states: [
      {
        id: 3320,
        name: 'Al Haouz Province',
        state_code: 'HAO',
      },
      {
        id: 3267,
        name: 'Al Hoceïma Province',
        state_code: 'HOC',
      },
      {
        id: 3266,
        name: 'Aousserd Province',
        state_code: 'AOU',
      },
      {
        id: 3297,
        name: 'Assa-Zag Province',
        state_code: 'ASZ',
      },
      {
        id: 3321,
        name: 'Azilal Province',
        state_code: 'AZI',
      },
      {
        id: 3304,
        name: 'Ben Slimane Province',
        state_code: 'BES',
      },
      {
        id: 3278,
        name: 'Béni Mellal-Khénifra',
        state_code: '05',
      },
      {
        id: 3272,
        name: 'Béni-Mellal Province',
        state_code: 'BEM',
      },
      {
        id: 3285,
        name: 'Berkane Province',
        state_code: 'BER',
      },
      {
        id: 3275,
        name: 'Boujdour Province',
        state_code: 'BOD',
      },
      {
        id: 3270,
        name: 'Boulemane Province',
        state_code: 'BOM',
      },
      {
        id: 3303,
        name: 'Casablanca-Settat',
        state_code: '06',
      },
      {
        id: 3310,
        name: 'Chefchaouen Province',
        state_code: 'CHE',
      },
      {
        id: 3274,
        name: 'Chichaoua Province',
        state_code: 'CHI',
      },
      {
        id: 3306,
        name: 'Dakhla-Oued Ed-Dahab',
        state_code: '12',
      },
      {
        id: 3290,
        name: 'Drâa-Tafilalet',
        state_code: '08',
      },
      {
        id: 3291,
        name: 'El Hajeb Province',
        state_code: 'HAJ',
      },
      {
        id: 3280,
        name: 'El Jadida Province',
        state_code: 'JDI',
      },
      {
        id: 3299,
        name: 'Errachidia Province',
        state_code: 'ERR',
      },
      {
        id: 3292,
        name: 'Es Semara Province',
        state_code: 'ESM',
      },
      {
        id: 3316,
        name: 'Essaouira Province',
        state_code: 'ESI',
      },
      {
        id: 3300,
        name: 'Fahs Anjra Province',
        state_code: 'FAH',
      },
      {
        id: 3313,
        name: 'Fès-Meknès',
        state_code: '03',
      },
      {
        id: 3301,
        name: 'Figuig Province',
        state_code: 'FIG',
      },
      {
        id: 3265,
        name: 'Guelmim Province',
        state_code: 'GUE',
      },
      {
        id: 3305,
        name: 'Guelmim-Oued Noun',
        state_code: '10',
      },
      {
        id: 3325,
        name: 'Ifrane Province',
        state_code: 'IFR',
      },
      {
        id: 3294,
        name: 'Inezgane-Aït Melloul Prefecture',
        state_code: 'INE',
      },
      {
        id: 3307,
        name: 'Jerada Province',
        state_code: 'JRA',
      },
      {
        id: 3309,
        name: 'Kelaat Sraghna Province',
        state_code: 'KES',
      },
      {
        id: 3308,
        name: 'Kénitra Province',
        state_code: 'KEN',
      },
      {
        id: 3276,
        name: 'Khémisset Province',
        state_code: 'KHE',
      },
      {
        id: 3317,
        name: 'Khénifra Province',
        state_code: 'KHN',
      },
      {
        id: 3326,
        name: 'Khouribga Province',
        state_code: 'KHO',
      },
      {
        id: 3293,
        name: 'Laâyoune Province',
        state_code: 'LAA',
      },
      {
        id: 3298,
        name: 'Laâyoune-Sakia El Hamra',
        state_code: '11',
      },
      {
        id: 3268,
        name: 'Larache Province',
        state_code: 'LAR',
      },
      {
        id: 3288,
        name: 'Marrakesh-Safi',
        state_code: '07',
      },
      {
        id: 3284,
        name: 'Mediouna Province',
        state_code: 'MED',
      },
      {
        id: 3315,
        name: 'Moulay Yacoub Province',
        state_code: 'MOU',
      },
      {
        id: 3281,
        name: 'Nador Province',
        state_code: 'NAD',
      },
      {
        id: 3287,
        name: 'Nouaceur Province',
        state_code: 'NOU',
      },
      {
        id: 3271,
        name: 'Oriental',
        state_code: '02',
      },
      {
        id: 3269,
        name: 'Ouarzazate Province',
        state_code: 'OUA',
      },
      {
        id: 3319,
        name: 'Oued Ed-Dahab Province',
        state_code: 'OUD',
      },
      {
        id: 3311,
        name: 'Safi Province',
        state_code: 'SAF',
      },
      {
        id: 3289,
        name: 'Sefrou Province',
        state_code: 'SEF',
      },
      {
        id: 3282,
        name: 'Settat Province',
        state_code: 'SET',
      },
      {
        id: 3302,
        name: 'Shtouka Ait Baha Province',
        state_code: 'CHT',
      },
      {
        id: 3279,
        name: 'Sidi Kacem Province',
        state_code: 'SIK',
      },
      {
        id: 3273,
        name: 'Sidi Youssef Ben Ali',
        state_code: 'SYB',
      },
      {
        id: 3295,
        name: 'Souss-Massa',
        state_code: '09',
      },
      {
        id: 3286,
        name: 'Tan-Tan Province',
        state_code: 'TNT',
      },
      {
        id: 3324,
        name: 'Tanger-Tétouan-Al Hoceïma',
        state_code: '01',
      },
      {
        id: 3323,
        name: 'Taounate Province',
        state_code: 'TAO',
      },
      {
        id: 3322,
        name: 'Taourirt Province',
        state_code: 'TAI',
      },
      {
        id: 3314,
        name: 'Taroudant Province',
        state_code: 'TAR',
      },
      {
        id: 3312,
        name: 'Tata Province',
        state_code: 'TAT',
      },
      {
        id: 3296,
        name: 'Taza Province',
        state_code: 'TAZ',
      },
      {
        id: 3318,
        name: 'Tétouan Province',
        state_code: 'TET',
      },
      {
        id: 3277,
        name: 'Tiznit Province',
        state_code: 'TIZ',
      },
      {
        id: 3283,
        name: 'Zagora Province',
        state_code: 'ZAG',
      },
    ],
  },
  {
    name: 'Mozambique',
    iso3: 'MOZ',
    iso2: 'MZ',
    phone_code: '258',
    capital: 'Maputo',
    currency: 'MZN',
    states: [
      {
        id: 3327,
        name: 'Cabo Delgado Province',
        state_code: 'P',
      },
      {
        id: 3329,
        name: 'Gaza Province',
        state_code: 'G',
      },
      {
        id: 3330,
        name: 'Inhambane Province',
        state_code: 'I',
      },
      {
        id: 3337,
        name: 'Manica Province',
        state_code: 'B',
      },
      {
        id: 3335,
        name: 'Maputo',
        state_code: 'MPM',
      },
      {
        id: 3332,
        name: 'Maputo Province',
        state_code: 'L',
      },
      {
        id: 3336,
        name: 'Nampula Province',
        state_code: 'N',
      },
      {
        id: 3333,
        name: 'Niassa Province',
        state_code: 'A',
      },
      {
        id: 3331,
        name: 'Sofala Province',
        state_code: 'S',
      },
      {
        id: 3334,
        name: 'Tete Province',
        state_code: 'T',
      },
      {
        id: 3328,
        name: 'Zambezia Province',
        state_code: 'Q',
      },
    ],
  },
  {
    name: 'Myanmar',
    iso3: 'MMR',
    iso2: 'MM',
    phone_code: '95',
    capital: 'Nay Pyi Taw',
    currency: 'MMK',
    states: [
      {
        id: 2142,
        name: 'Ayeyarwady Region',
        state_code: '07',
      },
      {
        id: 2141,
        name: 'Bago',
        state_code: '02',
      },
      {
        id: 2137,
        name: 'Chin State',
        state_code: '14',
      },
      {
        id: 2143,
        name: 'Kachin State',
        state_code: '11',
      },
      {
        id: 2144,
        name: 'Kayah State',
        state_code: '12',
      },
      {
        id: 2133,
        name: 'Kayin State',
        state_code: '13',
      },
      {
        id: 2136,
        name: 'Magway Region',
        state_code: '03',
      },
      {
        id: 2134,
        name: 'Mandalay Region',
        state_code: '04',
      },
      {
        id: 2147,
        name: 'Mon State',
        state_code: '15',
      },
      {
        id: 2146,
        name: 'Naypyidaw Union Territory',
        state_code: '18',
      },
      {
        id: 2138,
        name: 'Rakhine State',
        state_code: '16',
      },
      {
        id: 2145,
        name: 'Sagaing Region',
        state_code: '01',
      },
      {
        id: 2139,
        name: 'Shan State',
        state_code: '17',
      },
      {
        id: 2140,
        name: 'Tanintharyi Region',
        state_code: '05',
      },
      {
        id: 2135,
        name: 'Yangon Region',
        state_code: '06',
      },
    ],
  },
  {
    name: 'Namibia',
    iso3: 'NAM',
    iso2: 'NA',
    phone_code: '264',
    capital: 'Windhoek',
    currency: 'NAD',
    states: [
      {
        id: 43,
        name: 'Erongo Region',
        state_code: 'ER',
      },
      {
        id: 38,
        name: 'Hardap Region',
        state_code: 'HA',
      },
      {
        id: 45,
        name: 'Karas Region',
        state_code: 'KA',
      },
      {
        id: 36,
        name: 'Kavango East Region',
        state_code: 'KE',
      },
      {
        id: 35,
        name: 'Kavango West Region',
        state_code: 'KW',
      },
      {
        id: 44,
        name: 'Khomas Region',
        state_code: 'KH',
      },
      {
        id: 34,
        name: 'Kunene Region',
        state_code: 'KU',
      },
      {
        id: 40,
        name: 'Ohangwena Region',
        state_code: 'OW',
      },
      {
        id: 41,
        name: 'Omaheke Region',
        state_code: 'OH',
      },
      {
        id: 39,
        name: 'Omusati Region',
        state_code: 'OS',
      },
      {
        id: 37,
        name: 'Oshana Region',
        state_code: 'ON',
      },
      {
        id: 42,
        name: 'Oshikoto Region',
        state_code: 'OT',
      },
      {
        id: 46,
        name: 'Otjozondjupa Region',
        state_code: 'OD',
      },
      {
        id: 47,
        name: 'Zambezi Region',
        state_code: 'CA',
      },
    ],
  },
  {
    name: 'Nauru',
    iso3: 'NRU',
    iso2: 'NR',
    phone_code: '674',
    capital: 'Yaren',
    currency: 'AUD',
    states: [
      {
        id: 4656,
        name: 'Aiwo District',
        state_code: '01',
      },
      {
        id: 4658,
        name: 'Anabar District',
        state_code: '02',
      },
      {
        id: 4667,
        name: 'Anetan District',
        state_code: '03',
      },
      {
        id: 4663,
        name: 'Anibare District',
        state_code: '04',
      },
      {
        id: 4660,
        name: 'Baiti District',
        state_code: '05',
      },
      {
        id: 4665,
        name: 'Boe District',
        state_code: '06',
      },
      {
        id: 4662,
        name: 'Buada District',
        state_code: '07',
      },
      {
        id: 4666,
        name: 'Denigomodu District',
        state_code: '08',
      },
      {
        id: 4654,
        name: 'Ewa District',
        state_code: '09',
      },
      {
        id: 4661,
        name: 'Ijuw District',
        state_code: '10',
      },
      {
        id: 4657,
        name: 'Meneng District',
        state_code: '11',
      },
      {
        id: 4659,
        name: 'Nibok District',
        state_code: '12',
      },
      {
        id: 4655,
        name: 'Uaboe District',
        state_code: '13',
      },
      {
        id: 4664,
        name: 'Yaren District',
        state_code: '14',
      },
    ],
  },
  {
    name: 'Nepal',
    iso3: 'NPL',
    iso2: 'NP',
    phone_code: '977',
    capital: 'Kathmandu',
    currency: 'NPR',
    states: [
      {
        id: 2082,
        name: 'Bagmati Zone',
        state_code: 'BA',
      },
      {
        id: 2071,
        name: 'Bheri Zone',
        state_code: 'BH',
      },
      {
        id: 2073,
        name: 'Central Region',
        state_code: '1',
      },
      {
        id: 2080,
        name: 'Dhaulagiri Zone',
        state_code: 'DH',
      },
      {
        id: 2069,
        name: 'Eastern Development Region',
        state_code: '4',
      },
      {
        id: 2068,
        name: 'Far-Western Development Region',
        state_code: '5',
      },
      {
        id: 2081,
        name: 'Gandaki Zone',
        state_code: 'GA',
      },
      {
        id: 2076,
        name: 'Janakpur Zone',
        state_code: 'JA',
      },
      {
        id: 2079,
        name: 'Karnali Zone',
        state_code: 'KA',
      },
      {
        id: 2072,
        name: 'Kosi Zone',
        state_code: 'KO',
      },
      {
        id: 2074,
        name: 'Lumbini Zone',
        state_code: 'LU',
      },
      {
        id: 2083,
        name: 'Mahakali Zone',
        state_code: 'MA',
      },
      {
        id: 2070,
        name: 'Mechi Zone',
        state_code: 'ME',
      },
      {
        id: 2066,
        name: 'Mid-Western Region',
        state_code: '2',
      },
      {
        id: 2075,
        name: 'Narayani Zone',
        state_code: 'NA',
      },
      {
        id: 2077,
        name: 'Rapti Zone',
        state_code: 'RA',
      },
      {
        id: 2084,
        name: 'Sagarmatha Zone',
        state_code: 'SA',
      },
      {
        id: 2078,
        name: 'Seti Zone',
        state_code: 'SE',
      },
      {
        id: 2067,
        name: 'Western Region',
        state_code: '3',
      },
    ],
  },
  {
    name: 'Netherlands Antilles',
    iso3: 'ANT',
    iso2: 'AN',
    phone_code: '',
    capital: '',
    currency: '',
    states: [],
  },
  {
    name: 'Netherlands The',
    iso3: 'NLD',
    iso2: 'NL',
    phone_code: '31',
    capital: 'Amsterdam',
    currency: 'EUR',
    states: [
      {
        id: 2624,
        name: 'Bonaire',
        state_code: 'BQ1',
      },
      {
        id: 2613,
        name: 'Drenthe',
        state_code: 'DR',
      },
      {
        id: 2619,
        name: 'Flevoland',
        state_code: 'FL',
      },
      {
        id: 2622,
        name: 'Friesland',
        state_code: 'FR',
      },
      {
        id: 2611,
        name: 'Gelderland',
        state_code: 'GE',
      },
      {
        id: 2617,
        name: 'Groningen',
        state_code: 'GR',
      },
      {
        id: 2615,
        name: 'Limburg',
        state_code: 'LI',
      },
      {
        id: 2623,
        name: 'North Brabant',
        state_code: 'NB',
      },
      {
        id: 2612,
        name: 'North Holland',
        state_code: 'NH',
      },
      {
        id: 2618,
        name: 'Overijssel',
        state_code: 'OV',
      },
      {
        id: 2621,
        name: 'Saba',
        state_code: 'BQ2',
      },
      {
        id: 2616,
        name: 'Sint Eustatius',
        state_code: 'BQ3',
      },
      {
        id: 2614,
        name: 'South Holland',
        state_code: 'ZH',
      },
      {
        id: 2610,
        name: 'Utrecht',
        state_code: 'UT',
      },
      {
        id: 2620,
        name: 'Zeeland',
        state_code: 'ZE',
      },
    ],
  },
  {
    name: 'New Caledonia',
    iso3: 'NCL',
    iso2: 'NC',
    phone_code: '687',
    capital: 'Noumea',
    currency: 'XPF',
    states: [],
  },
  {
    name: 'New Zealand',
    iso3: 'NZL',
    iso2: 'NZ',
    phone_code: '64',
    capital: 'Wellington',
    currency: 'NZD',
    states: [
      {
        id: 4072,
        name: 'Auckland Region',
        state_code: 'AUK',
      },
      {
        id: 4074,
        name: 'Bay of Plenty Region',
        state_code: 'BOP',
      },
      {
        id: 4066,
        name: 'Canterbury Region',
        state_code: 'CAN',
      },
      {
        id: 4067,
        name: 'Chatham Islands',
        state_code: 'CIT',
      },
      {
        id: 4068,
        name: 'Gisborne District',
        state_code: 'GIS',
      },
      {
        id: 4075,
        name: "Hawke's Bay Region",
        state_code: 'HKB',
      },
      {
        id: 4060,
        name: 'Manawatu-Wanganui Region',
        state_code: 'MWT',
      },
      {
        id: 4063,
        name: 'Marlborough Region',
        state_code: 'MBH',
      },
      {
        id: 4070,
        name: 'Nelson Region',
        state_code: 'NSN',
      },
      {
        id: 4059,
        name: 'Northland Region',
        state_code: 'NTL',
      },
      {
        id: 4062,
        name: 'Otago Region',
        state_code: 'OTA',
      },
      {
        id: 4071,
        name: 'Southland Region',
        state_code: 'STL',
      },
      {
        id: 4069,
        name: 'Taranaki Region',
        state_code: 'TKI',
      },
      {
        id: 4073,
        name: 'Tasman District',
        state_code: 'TAS',
      },
      {
        id: 4061,
        name: 'Waikato Region',
        state_code: 'WKO',
      },
      {
        id: 4065,
        name: 'Wellington Region',
        state_code: 'WGN',
      },
      {
        id: 4064,
        name: 'West Coast Region',
        state_code: 'WTC',
      },
    ],
  },
  {
    name: 'Nicaragua',
    iso3: 'NIC',
    iso2: 'NI',
    phone_code: '505',
    capital: 'Managua',
    currency: 'NIO',
    states: [
      {
        id: 946,
        name: 'Boaco Department',
        state_code: 'BO',
      },
      {
        id: 950,
        name: 'Carazo Department',
        state_code: 'CA',
      },
      {
        id: 954,
        name: 'Chinandega Department',
        state_code: 'CI',
      },
      {
        id: 940,
        name: 'Chontales Department',
        state_code: 'CO',
      },
      {
        id: 945,
        name: 'Estelí Department',
        state_code: 'ES',
      },
      {
        id: 943,
        name: 'Granada Department',
        state_code: 'GR',
      },
      {
        id: 955,
        name: 'Jinotega Department',
        state_code: 'JI',
      },
      {
        id: 944,
        name: 'León Department',
        state_code: 'LE',
      },
      {
        id: 948,
        name: 'Madriz Department',
        state_code: 'MD',
      },
      {
        id: 941,
        name: 'Managua Department',
        state_code: 'MN',
      },
      {
        id: 953,
        name: 'Masaya Department',
        state_code: 'MS',
      },
      {
        id: 947,
        name: 'Matagalpa Department',
        state_code: 'MT',
      },
      {
        id: 951,
        name: 'North Caribbean Coast Autonomous Region',
        state_code: 'AN',
      },
      {
        id: 949,
        name: 'Río San Juan Department',
        state_code: 'SJ',
      },
      {
        id: 942,
        name: 'Rivas Department',
        state_code: 'RI',
      },
      {
        id: 952,
        name: 'South Caribbean Coast Autonomous Region',
        state_code: 'AS',
      },
    ],
  },
  {
    name: 'Niger',
    iso3: 'NER',
    iso2: 'NE',
    phone_code: '227',
    capital: 'Niamey',
    currency: 'XOF',
    states: [
      {
        id: 71,
        name: 'Agadez Region',
        state_code: '1',
      },
      {
        id: 72,
        name: 'Diffa Region',
        state_code: '2',
      },
      {
        id: 68,
        name: 'Dosso Region',
        state_code: '3',
      },
      {
        id: 70,
        name: 'Maradi Region',
        state_code: '4',
      },
      {
        id: 73,
        name: 'Tahoua Region',
        state_code: '5',
      },
      {
        id: 67,
        name: 'Tillabéri Region',
        state_code: '6',
      },
      {
        id: 69,
        name: 'Zinder Region',
        state_code: '7',
      },
    ],
  },
  {
    name: 'Nigeria',
    iso3: 'NGA',
    iso2: 'NG',
    phone_code: '234',
    capital: 'Abuja',
    currency: 'NGN',
    states: [
      {
        id: 303,
        name: 'Abia State',
        state_code: 'AB',
      },
      {
        id: 320,
        name: 'Adamawa State',
        state_code: 'AD',
      },
      {
        id: 304,
        name: 'Akwa Ibom State',
        state_code: 'AK',
      },
      {
        id: 315,
        name: 'Anambra State',
        state_code: 'AN',
      },
      {
        id: 312,
        name: 'Bauchi State',
        state_code: 'BA',
      },
      {
        id: 305,
        name: 'Bayelsa State',
        state_code: 'BY',
      },
      {
        id: 291,
        name: 'Benue State',
        state_code: 'BE',
      },
      {
        id: 307,
        name: 'Borno State',
        state_code: 'BO',
      },
      {
        id: 314,
        name: 'Cross River State',
        state_code: 'CR',
      },
      {
        id: 316,
        name: 'Delta State',
        state_code: 'DE',
      },
      {
        id: 311,
        name: 'Ebonyi State',
        state_code: 'EB',
      },
      {
        id: 318,
        name: 'Edo State',
        state_code: 'ED',
      },
      {
        id: 309,
        name: 'Ekiti State',
        state_code: 'EK',
      },
      {
        id: 289,
        name: 'Enugu State',
        state_code: 'EN',
      },
      {
        id: 293,
        name: 'Federal Capital Territory',
        state_code: 'FC',
      },
      {
        id: 310,
        name: 'Gombe State',
        state_code: 'GO',
      },
      {
        id: 308,
        name: 'Imo State',
        state_code: 'IM',
      },
      {
        id: 288,
        name: 'Jigawa State',
        state_code: 'JI',
      },
      {
        id: 294,
        name: 'Kaduna State',
        state_code: 'KD',
      },
      {
        id: 300,
        name: 'Kano State',
        state_code: 'KN',
      },
      {
        id: 313,
        name: 'Katsina State',
        state_code: 'KT',
      },
      {
        id: 290,
        name: 'Kebbi State',
        state_code: 'KE',
      },
      {
        id: 298,
        name: 'Kogi State',
        state_code: 'KO',
      },
      {
        id: 295,
        name: 'Kwara State',
        state_code: 'KW',
      },
      {
        id: 306,
        name: 'Lagos',
        state_code: 'LA',
      },
      {
        id: 301,
        name: 'Nasarawa State',
        state_code: 'NA',
      },
      {
        id: 317,
        name: 'Niger State',
        state_code: 'NI',
      },
      {
        id: 323,
        name: 'Ogun State',
        state_code: 'OG',
      },
      {
        id: 321,
        name: 'Ondo State',
        state_code: 'ON',
      },
      {
        id: 322,
        name: 'Osun State',
        state_code: 'OS',
      },
      {
        id: 296,
        name: 'Oyo State',
        state_code: 'OY',
      },
      {
        id: 302,
        name: 'Plateau State',
        state_code: 'PL',
      },
      {
        id: 292,
        name: 'Sokoto State',
        state_code: 'SO',
      },
      {
        id: 319,
        name: 'Taraba State',
        state_code: 'TA',
      },
      {
        id: 297,
        name: 'Yobe State',
        state_code: 'YO',
      },
      {
        id: 299,
        name: 'Zamfara State',
        state_code: 'ZA',
      },
    ],
  },
  {
    name: 'Niue',
    iso3: 'NIU',
    iso2: 'NU',
    phone_code: '683',
    capital: 'Alofi',
    currency: 'NZD',
    states: [],
  },
  {
    name: 'Norfolk Island',
    iso3: 'NFK',
    iso2: 'NF',
    phone_code: '672',
    capital: 'Kingston',
    currency: 'AUD',
    states: [],
  },
  {
    name: 'Northern Mariana Islands',
    iso3: 'MNP',
    iso2: 'MP',
    phone_code: '+1-670',
    capital: 'Saipan',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Norway',
    iso3: 'NOR',
    iso2: 'NO',
    phone_code: '47',
    capital: 'Oslo',
    currency: 'NOK',
    states: [
      {
        id: 1017,
        name: 'Akershus',
        state_code: '02',
      },
      {
        id: 1011,
        name: 'Buskerud',
        state_code: '06',
      },
      {
        id: 1016,
        name: 'Finnmark',
        state_code: '20',
      },
      {
        id: 1019,
        name: 'Hedmark',
        state_code: '04',
      },
      {
        id: 1023,
        name: 'Hordaland',
        state_code: '12',
      },
      {
        id: 1026,
        name: 'Jan Mayen',
        state_code: '22',
      },
      {
        id: 1020,
        name: 'Møre og Romsdal',
        state_code: '15',
      },
      {
        id: 1012,
        name: 'Nord-Trøndelag',
        state_code: '17',
      },
      {
        id: 1025,
        name: 'Nordland',
        state_code: '18',
      },
      {
        id: 1009,
        name: 'Oppland',
        state_code: '05',
      },
      {
        id: 1007,
        name: 'Oslo',
        state_code: '03',
      },
      {
        id: 1022,
        name: 'Østfold',
        state_code: '01',
      },
      {
        id: 1021,
        name: 'Rogaland',
        state_code: '11',
      },
      {
        id: 1018,
        name: 'Sogn og Fjordane',
        state_code: '14',
      },
      {
        id: 1010,
        name: 'Sør-Trøndelag',
        state_code: '16',
      },
      {
        id: 1013,
        name: 'Svalbard',
        state_code: '21',
      },
      {
        id: 1024,
        name: 'Telemark',
        state_code: '08',
      },
      {
        id: 1015,
        name: 'Troms',
        state_code: '19',
      },
      {
        id: 1006,
        name: 'Trøndelag',
        state_code: '50',
      },
      {
        id: 1014,
        name: 'Vest-Agder',
        state_code: '10',
      },
      {
        id: 1008,
        name: 'Vestfold',
        state_code: '07',
      },
    ],
  },
  {
    name: 'Oman',
    iso3: 'OMN',
    iso2: 'OM',
    phone_code: '968',
    capital: 'Muscat',
    currency: 'OMR',
    states: [
      {
        id: 3058,
        name: 'Ad Dakhiliyah Governorate',
        state_code: 'DA',
      },
      {
        id: 3047,
        name: 'Ad Dhahirah Governorate',
        state_code: 'ZA',
      },
      {
        id: 3048,
        name: 'Al Batinah North Governorate',
        state_code: 'BS',
      },
      {
        id: 3050,
        name: 'Al Batinah Region',
        state_code: 'BA',
      },
      {
        id: 3049,
        name: 'Al Batinah South Governorate',
        state_code: 'BJ',
      },
      {
        id: 3059,
        name: 'Al Buraimi Governorate',
        state_code: 'BU',
      },
      {
        id: 3056,
        name: 'Al Wusta Governorate',
        state_code: 'WU',
      },
      {
        id: 3053,
        name: 'Ash Sharqiyah North Governorate',
        state_code: 'SS',
      },
      {
        id: 3051,
        name: 'Ash Sharqiyah Region',
        state_code: 'SH',
      },
      {
        id: 3054,
        name: 'Ash Sharqiyah South Governorate',
        state_code: 'SJ',
      },
      {
        id: 3057,
        name: 'Dhofar Governorate',
        state_code: 'ZU',
      },
      {
        id: 3052,
        name: 'Musandam Governorate',
        state_code: 'MU',
      },
      {
        id: 3055,
        name: 'Muscat Governorate',
        state_code: 'MA',
      },
    ],
  },
  {
    name: 'Pakistan',
    iso3: 'PAK',
    iso2: 'PK',
    phone_code: '92',
    capital: 'Islamabad',
    currency: 'PKR',
    states: [
      {
        id: 3172,
        name: 'Azad Kashmir',
        state_code: 'JK',
      },
      {
        id: 3174,
        name: 'Balochistan',
        state_code: 'BA',
      },
      {
        id: 3173,
        name: 'Federally Administered Tribal Areas',
        state_code: 'TA',
      },
      {
        id: 3170,
        name: 'Gilgit-Baltistan',
        state_code: 'GB',
      },
      {
        id: 3169,
        name: 'Islamabad Capital Territory',
        state_code: 'IS',
      },
      {
        id: 3171,
        name: 'Khyber Pakhtunkhwa',
        state_code: 'KP',
      },
      {
        id: 3176,
        name: 'Punjab',
        state_code: 'PB',
      },
      {
        id: 3175,
        name: 'Sindh',
        state_code: 'SD',
      },
    ],
  },
  {
    name: 'Palau',
    iso3: 'PLW',
    iso2: 'PW',
    phone_code: '680',
    capital: 'Melekeok',
    currency: 'USD',
    states: [
      {
        id: 4540,
        name: 'Aimeliik',
        state_code: '002',
      },
      {
        id: 4528,
        name: 'Airai',
        state_code: '004',
      },
      {
        id: 4538,
        name: 'Angaur',
        state_code: '010',
      },
      {
        id: 4529,
        name: 'Hatohobei',
        state_code: '050',
      },
      {
        id: 4539,
        name: 'Kayangel',
        state_code: '100',
      },
      {
        id: 4532,
        name: 'Koror',
        state_code: '150',
      },
      {
        id: 4530,
        name: 'Melekeok',
        state_code: '212',
      },
      {
        id: 4537,
        name: 'Ngaraard',
        state_code: '214',
      },
      {
        id: 4533,
        name: 'Ngarchelong',
        state_code: '218',
      },
      {
        id: 4527,
        name: 'Ngardmau',
        state_code: '222',
      },
      {
        id: 4531,
        name: 'Ngatpang',
        state_code: '224',
      },
      {
        id: 4536,
        name: 'Ngchesar',
        state_code: '226',
      },
      {
        id: 4541,
        name: 'Ngeremlengui',
        state_code: '227',
      },
      {
        id: 4534,
        name: 'Ngiwal',
        state_code: '228',
      },
      {
        id: 4526,
        name: 'Peleliu',
        state_code: '350',
      },
      {
        id: 4535,
        name: 'Sonsorol',
        state_code: '370',
      },
    ],
  },
  {
    name: 'Palestinian Territory Occupied',
    iso3: 'PSE',
    iso2: 'PS',
    phone_code: '970',
    capital: 'East Jerusalem',
    currency: 'ILS',
    states: [],
  },
  {
    name: 'Panama',
    iso3: 'PAN',
    iso2: 'PA',
    phone_code: '507',
    capital: 'Panama City',
    currency: 'PAB',
    states: [
      {
        id: 1393,
        name: 'Bocas del Toro Province',
        state_code: '1',
      },
      {
        id: 1397,
        name: 'Chiriquí Province',
        state_code: '4',
      },
      {
        id: 1387,
        name: 'Coclé Province',
        state_code: '2',
      },
      {
        id: 1386,
        name: 'Colón Province',
        state_code: '3',
      },
      {
        id: 1385,
        name: 'Darién Province',
        state_code: '5',
      },
      {
        id: 1396,
        name: 'Emberá-Wounaan Comarca',
        state_code: 'EM',
      },
      {
        id: 1388,
        name: 'Guna Yala',
        state_code: 'KY',
      },
      {
        id: 1389,
        name: 'Herrera Province',
        state_code: '6',
      },
      {
        id: 1390,
        name: 'Los Santos Province',
        state_code: '7',
      },
      {
        id: 1391,
        name: 'Ngöbe-Buglé Comarca',
        state_code: 'NB',
      },
      {
        id: 1394,
        name: 'Panamá Oeste Province',
        state_code: '10',
      },
      {
        id: 1395,
        name: 'Panamá Province',
        state_code: '8',
      },
      {
        id: 1392,
        name: 'Veraguas Province',
        state_code: '9',
      },
    ],
  },
  {
    name: 'Papua new Guinea',
    iso3: 'PNG',
    iso2: 'PG',
    phone_code: '675',
    capital: 'Port Moresby',
    currency: 'PGK',
    states: [
      {
        id: 4831,
        name: 'Bougainville',
        state_code: 'NSB',
      },
      {
        id: 4847,
        name: 'Central Province',
        state_code: 'CPM',
      },
      {
        id: 4846,
        name: 'Chimbu Province',
        state_code: 'CPK',
      },
      {
        id: 4834,
        name: 'East New Britain',
        state_code: 'EBR',
      },
      {
        id: 4845,
        name: 'Eastern Highlands Province',
        state_code: 'EHG',
      },
      {
        id: 4848,
        name: 'Enga Province',
        state_code: 'EPW',
      },
      {
        id: 4839,
        name: 'Gulf',
        state_code: 'GPK',
      },
      {
        id: 4833,
        name: 'Hela',
        state_code: 'HLA',
      },
      {
        id: 4832,
        name: 'Jiwaka Province',
        state_code: 'JWK',
      },
      {
        id: 4843,
        name: 'Madang Province',
        state_code: 'MPM',
      },
      {
        id: 4842,
        name: 'Manus Province',
        state_code: 'MRL',
      },
      {
        id: 4849,
        name: 'Milne Bay Province',
        state_code: 'MBA',
      },
      {
        id: 4835,
        name: 'Morobe Province',
        state_code: 'MPL',
      },
      {
        id: 4841,
        name: 'New Ireland Province',
        state_code: 'NIK',
      },
      {
        id: 4838,
        name: 'Oro Province',
        state_code: 'NPP',
      },
      {
        id: 4837,
        name: 'Port Moresby',
        state_code: 'NCD',
      },
      {
        id: 4836,
        name: 'Sandaun Province',
        state_code: 'SAN',
      },
      {
        id: 4844,
        name: 'Southern Highlands Province',
        state_code: 'SHM',
      },
      {
        id: 4830,
        name: 'West New Britain Province',
        state_code: 'WBK',
      },
      {
        id: 4840,
        name: 'Western Highlands Province',
        state_code: 'WHM',
      },
      {
        id: 4850,
        name: 'Western Province',
        state_code: 'WPD',
      },
    ],
  },
  {
    name: 'Paraguay',
    iso3: 'PRY',
    iso2: 'PY',
    phone_code: '595',
    capital: 'Asuncion',
    currency: 'PYG',
    states: [
      {
        id: 2785,
        name: 'Alto Paraguay Department',
        state_code: '16',
      },
      {
        id: 2784,
        name: 'Alto Paraná Department',
        state_code: '10',
      },
      {
        id: 2782,
        name: 'Amambay Department',
        state_code: '13',
      },
      {
        id: 2780,
        name: 'Boquerón Department',
        state_code: '19',
      },
      {
        id: 2773,
        name: 'Caaguazú',
        state_code: '5',
      },
      {
        id: 2775,
        name: 'Caazapá',
        state_code: '6',
      },
      {
        id: 2771,
        name: 'Canindeyú',
        state_code: '14',
      },
      {
        id: 2777,
        name: 'Central Department',
        state_code: '11',
      },
      {
        id: 2779,
        name: 'Concepción Department',
        state_code: '1',
      },
      {
        id: 2783,
        name: 'Cordillera Department',
        state_code: '3',
      },
      {
        id: 2772,
        name: 'Guairá Department',
        state_code: '4',
      },
      {
        id: 2778,
        name: 'Itapúa',
        state_code: '7',
      },
      {
        id: 2786,
        name: 'Misiones Department',
        state_code: '8',
      },
      {
        id: 2781,
        name: 'Ñeembucú Department',
        state_code: '12',
      },
      {
        id: 2774,
        name: 'Paraguarí Department',
        state_code: '9',
      },
      {
        id: 2770,
        name: 'Presidente Hayes Department',
        state_code: '15',
      },
      {
        id: 2776,
        name: 'San Pedro Department',
        state_code: '2',
      },
    ],
  },
  {
    name: 'Peru',
    iso3: 'PER',
    iso2: 'PE',
    phone_code: '51',
    capital: 'Lima',
    currency: 'PEN',
    states: [
      {
        id: 3685,
        name: 'Amazonas',
        state_code: 'AMA',
      },
      {
        id: 3680,
        name: 'Áncash',
        state_code: 'ANC',
      },
      {
        id: 3699,
        name: 'Apurímac',
        state_code: 'APU',
      },
      {
        id: 3681,
        name: 'Arequipa',
        state_code: 'ARE',
      },
      {
        id: 3692,
        name: 'Ayacucho',
        state_code: 'AYA',
      },
      {
        id: 3688,
        name: 'Cajamarca',
        state_code: 'CAJ',
      },
      {
        id: 3701,
        name: 'Callao',
        state_code: 'CAL',
      },
      {
        id: 3691,
        name: 'Cusco',
        state_code: 'CUS',
      },
      {
        id: 3679,
        name: 'Huancavelica',
        state_code: 'HUV',
      },
      {
        id: 3687,
        name: 'Huanuco',
        state_code: 'HUC',
      },
      {
        id: 3700,
        name: 'Ica',
        state_code: 'ICA',
      },
      {
        id: 3693,
        name: 'Junín',
        state_code: 'JUN',
      },
      {
        id: 3683,
        name: 'La Libertad',
        state_code: 'LAL',
      },
      {
        id: 3702,
        name: 'Lambayeque',
        state_code: 'LAM',
      },
      {
        id: 3695,
        name: 'Lima',
        state_code: 'LIM',
      },
      {
        id: 3690,
        name: 'Lima Province',
        state_code: 'LMA',
      },
      {
        id: 3678,
        name: 'Madre de Dios',
        state_code: 'MDD',
      },
      {
        id: 3698,
        name: 'Moquegua',
        state_code: 'MOQ',
      },
      {
        id: 3686,
        name: 'Pasco',
        state_code: 'PAS',
      },
      {
        id: 3697,
        name: 'Piura',
        state_code: 'PIU',
      },
      {
        id: 3682,
        name: 'Puno',
        state_code: 'PUN',
      },
      {
        id: 3694,
        name: 'San Martín',
        state_code: 'SAM',
      },
      {
        id: 3696,
        name: 'Tacna',
        state_code: 'TAC',
      },
      {
        id: 3689,
        name: 'Tumbes',
        state_code: 'TUM',
      },
      {
        id: 3684,
        name: 'Ucayali',
        state_code: 'UCA',
      },
    ],
  },
  {
    name: 'Philippines',
    iso3: 'PHL',
    iso2: 'PH',
    phone_code: '63',
    capital: 'Manila',
    currency: 'PHP',
    states: [
      {
        id: 1324,
        name: 'Abra',
        state_code: 'ABR',
      },
      {
        id: 1323,
        name: 'Agusan del Norte',
        state_code: 'AGN',
      },
      {
        id: 1326,
        name: 'Agusan del Sur',
        state_code: 'AGS',
      },
      {
        id: 1331,
        name: 'Aklan',
        state_code: 'AKL',
      },
      {
        id: 1337,
        name: 'Albay',
        state_code: 'ALB',
      },
      {
        id: 1336,
        name: 'Antique',
        state_code: 'ANT',
      },
      {
        id: 1334,
        name: 'Apayao',
        state_code: 'APA',
      },
      {
        id: 1341,
        name: 'Aurora',
        state_code: 'AUR',
      },
      {
        id: 1316,
        name: 'Autonomous Region in Muslim Mindanao',
        state_code: '14',
      },
      {
        id: 1346,
        name: 'Basilan',
        state_code: 'BAS',
      },
      {
        id: 1344,
        name: 'Bataan',
        state_code: 'BAN',
      },
      {
        id: 1352,
        name: 'Batanes',
        state_code: 'BTN',
      },
      {
        id: 1359,
        name: 'Batangas',
        state_code: 'BTG',
      },
      {
        id: 1363,
        name: 'Benguet',
        state_code: 'BEN',
      },
      {
        id: 1304,
        name: 'Bicol Region',
        state_code: '05',
      },
      {
        id: 1274,
        name: 'Biliran',
        state_code: 'BIL',
      },
      {
        id: 1272,
        name: 'Bohol',
        state_code: 'BOH',
      },
      {
        id: 1270,
        name: 'Bukidnon',
        state_code: 'BUK',
      },
      {
        id: 1278,
        name: 'Bulacan',
        state_code: 'BUL',
      },
      {
        id: 1279,
        name: 'Cagayan',
        state_code: 'CAG',
      },
      {
        id: 1342,
        name: 'Cagayan Valley',
        state_code: '02',
      },
      {
        id: 1294,
        name: 'Calabarzon',
        state_code: '40',
      },
      {
        id: 1283,
        name: 'Camarines Norte',
        state_code: 'CAN',
      },
      {
        id: 1287,
        name: 'Camarines Sur',
        state_code: 'CAS',
      },
      {
        id: 1285,
        name: 'Camiguin',
        state_code: 'CAM',
      },
      {
        id: 1292,
        name: 'Capiz',
        state_code: 'CAP',
      },
      {
        id: 1314,
        name: 'Caraga',
        state_code: '13',
      },
      {
        id: 1301,
        name: 'Catanduanes',
        state_code: 'CAT',
      },
      {
        id: 1307,
        name: 'Cavite',
        state_code: 'CAV',
      },
      {
        id: 1306,
        name: 'Cebu',
        state_code: 'CEB',
      },
      {
        id: 1345,
        name: 'Central Luzon',
        state_code: '03',
      },
      {
        id: 1308,
        name: 'Central Visayas',
        state_code: '07',
      },
      {
        id: 1311,
        name: 'Compostela Valley',
        state_code: 'COM',
      },
      {
        id: 1335,
        name: 'Cordillera Administrative Region',
        state_code: '15',
      },
      {
        id: 1320,
        name: 'Cotabato',
        state_code: 'NCO',
      },
      {
        id: 1319,
        name: 'Davao del Norte',
        state_code: 'DAV',
      },
      {
        id: 1318,
        name: 'Davao del Sur',
        state_code: 'DAS',
      },
      {
        id: 1309,
        name: 'Davao Occidental',
        state_code: 'DVO',
      },
      {
        id: 1289,
        name: 'Davao Oriental',
        state_code: 'DAO',
      },
      {
        id: 1340,
        name: 'Davao Region',
        state_code: '11',
      },
      {
        id: 1291,
        name: 'Dinagat Islands',
        state_code: 'DIN',
      },
      {
        id: 1290,
        name: 'Eastern Samar',
        state_code: 'EAS',
      },
      {
        id: 1322,
        name: 'Eastern Visayas',
        state_code: '08',
      },
      {
        id: 1303,
        name: 'Guimaras',
        state_code: 'GUI',
      },
      {
        id: 1300,
        name: 'Ifugao',
        state_code: 'IFU',
      },
      {
        id: 1298,
        name: 'Ilocos Norte',
        state_code: 'ILN',
      },
      {
        id: 1355,
        name: 'Ilocos Region',
        state_code: '01',
      },
      {
        id: 1321,
        name: 'Ilocos Sur',
        state_code: 'ILS',
      },
      {
        id: 1315,
        name: 'Iloilo',
        state_code: 'ILI',
      },
      {
        id: 1313,
        name: 'Isabela',
        state_code: 'ISA',
      },
      {
        id: 1312,
        name: 'Kalinga',
        state_code: 'KAL',
      },
      {
        id: 1317,
        name: 'La Union',
        state_code: 'LUN',
      },
      {
        id: 1328,
        name: 'Laguna',
        state_code: 'LAG',
      },
      {
        id: 1327,
        name: 'Lanao del Norte',
        state_code: 'LAN',
      },
      {
        id: 1333,
        name: 'Lanao del Sur',
        state_code: 'LAS',
      },
      {
        id: 1332,
        name: 'Leyte',
        state_code: 'LEY',
      },
      {
        id: 1330,
        name: 'Maguindanao',
        state_code: 'MAG',
      },
      {
        id: 1329,
        name: 'Marinduque',
        state_code: 'MAD',
      },
      {
        id: 1338,
        name: 'Masbate',
        state_code: 'MAS',
      },
      {
        id: 1347,
        name: 'Metro Manila',
        state_code: '00',
      },
      {
        id: 1299,
        name: 'Mimaropa',
        state_code: '41',
      },
      {
        id: 1343,
        name: 'Misamis Occidental',
        state_code: 'MSC',
      },
      {
        id: 1348,
        name: 'Misamis Oriental',
        state_code: 'MSR',
      },
      {
        id: 1353,
        name: 'Mountain Province',
        state_code: 'MOU',
      },
      {
        id: 1351,
        name: 'Negros Occidental',
        state_code: 'NEC',
      },
      {
        id: 1350,
        name: 'Negros Oriental',
        state_code: 'NER',
      },
      {
        id: 1339,
        name: 'Northern Mindanao',
        state_code: '10',
      },
      {
        id: 1349,
        name: 'Northern Samar',
        state_code: 'NSA',
      },
      {
        id: 1360,
        name: 'Nueva Ecija',
        state_code: 'NUE',
      },
      {
        id: 1358,
        name: 'Nueva Vizcaya',
        state_code: 'NUV',
      },
      {
        id: 1356,
        name: 'Occidental Mindoro',
        state_code: 'MDC',
      },
      {
        id: 1354,
        name: 'Oriental Mindoro',
        state_code: 'MDR',
      },
      {
        id: 1361,
        name: 'Palawan',
        state_code: 'PLW',
      },
      {
        id: 1365,
        name: 'Pampanga',
        state_code: 'PAM',
      },
      {
        id: 1364,
        name: 'Pangasinan',
        state_code: 'PAN',
      },
      {
        id: 1275,
        name: 'Quezon',
        state_code: 'QUE',
      },
      {
        id: 1273,
        name: 'Quirino',
        state_code: 'QUI',
      },
      {
        id: 1271,
        name: 'Rizal',
        state_code: 'RIZ',
      },
      {
        id: 1269,
        name: 'Romblon',
        state_code: 'ROM',
      },
      {
        id: 1277,
        name: 'Sarangani',
        state_code: 'SAR',
      },
      {
        id: 1276,
        name: 'Siquijor',
        state_code: 'SIG',
      },
      {
        id: 1310,
        name: 'Soccsksargen',
        state_code: '12',
      },
      {
        id: 1281,
        name: 'Sorsogon',
        state_code: 'SOR',
      },
      {
        id: 1280,
        name: 'South Cotabato',
        state_code: 'SCO',
      },
      {
        id: 1284,
        name: 'Southern Leyte',
        state_code: 'SLE',
      },
      {
        id: 1282,
        name: 'Sultan Kudarat',
        state_code: 'SUK',
      },
      {
        id: 1288,
        name: 'Sulu',
        state_code: 'SLU',
      },
      {
        id: 1286,
        name: 'Surigao del Norte',
        state_code: 'SUN',
      },
      {
        id: 1296,
        name: 'Surigao del Sur',
        state_code: 'SUR',
      },
      {
        id: 1295,
        name: 'Tarlac',
        state_code: 'TAR',
      },
      {
        id: 1293,
        name: 'Tawi-Tawi',
        state_code: 'TAW',
      },
      {
        id: 1305,
        name: 'Western Visayas',
        state_code: '06',
      },
      {
        id: 1297,
        name: 'Zambales',
        state_code: 'ZMB',
      },
      {
        id: 1302,
        name: 'Zamboanga del Norte',
        state_code: 'ZAN',
      },
      {
        id: 1357,
        name: 'Zamboanga del Sur',
        state_code: 'ZAS',
      },
      {
        id: 1325,
        name: 'Zamboanga Peninsula',
        state_code: '09',
      },
      {
        id: 1362,
        name: 'Zamboanga Sibugay',
        state_code: 'ZSI',
      },
    ],
  },
  {
    name: 'Pitcairn Island',
    iso3: 'PCN',
    iso2: 'PN',
    phone_code: '870',
    capital: 'Adamstown',
    currency: 'NZD',
    states: [],
  },
  {
    name: 'Poland',
    iso3: 'POL',
    iso2: 'PL',
    phone_code: '48',
    capital: 'Warsaw',
    currency: 'PLN',
    states: [
      {
        id: 1634,
        name: 'Greater Poland Voivodeship',
        state_code: 'WP',
      },
      {
        id: 1627,
        name: 'Kielce',
        state_code: 'KI',
      },
      {
        id: 1625,
        name: 'Kuyavian-Pomeranian Voivodeship',
        state_code: 'KP',
      },
      {
        id: 1635,
        name: 'Lesser Poland Voivodeship',
        state_code: 'MA',
      },
      {
        id: 1629,
        name: 'Lower Silesian Voivodeship',
        state_code: 'DS',
      },
      {
        id: 1638,
        name: 'Lublin Voivodeship',
        state_code: 'LU',
      },
      {
        id: 1631,
        name: 'Lubusz Voivodeship',
        state_code: 'LB',
      },
      {
        id: 1636,
        name: 'Łódź Voivodeship',
        state_code: 'LD',
      },
      {
        id: 1637,
        name: 'Masovian Voivodeship',
        state_code: 'MZ',
      },
      {
        id: 1622,
        name: 'Opole Voivodeship',
        state_code: 'OP',
      },
      {
        id: 1626,
        name: 'Podkarpackie Voivodeship',
        state_code: 'PK',
      },
      {
        id: 1632,
        name: 'Podlaskie Voivodeship',
        state_code: 'PD',
      },
      {
        id: 1624,
        name: 'Pomeranian Voivodeship',
        state_code: 'PM',
      },
      {
        id: 1623,
        name: 'Silesian Voivodeship',
        state_code: 'SL',
      },
      {
        id: 1630,
        name: 'Świętokrzyskie Voivodeship',
        state_code: 'SK',
      },
      {
        id: 1628,
        name: 'Warmian-Masurian Voivodeship',
        state_code: 'WN',
      },
      {
        id: 1633,
        name: 'West Pomeranian Voivodeship',
        state_code: 'ZP',
      },
    ],
  },
  {
    name: 'Portugal',
    iso3: 'PRT',
    iso2: 'PT',
    phone_code: '351',
    capital: 'Lisbon',
    currency: 'EUR',
    states: [
      {
        id: 2235,
        name: 'Aveiro District',
        state_code: '01',
      },
      {
        id: 2233,
        name: 'Azores',
        state_code: '20',
      },
      {
        id: 2230,
        name: 'Beja District',
        state_code: '02',
      },
      {
        id: 2244,
        name: 'Braga District',
        state_code: '03',
      },
      {
        id: 2229,
        name: 'Bragança District',
        state_code: '04',
      },
      {
        id: 2241,
        name: 'Castelo Branco District',
        state_code: '05',
      },
      {
        id: 2246,
        name: 'Coimbra District',
        state_code: '06',
      },
      {
        id: 2236,
        name: 'Évora District',
        state_code: '07',
      },
      {
        id: 2239,
        name: 'Faro District',
        state_code: '08',
      },
      {
        id: 2240,
        name: 'Leiria District',
        state_code: '10',
      },
      {
        id: 2228,
        name: 'Lisbon District',
        state_code: '11',
      },
      {
        id: 2231,
        name: 'Madeira',
        state_code: '30',
      },
      {
        id: 2232,
        name: 'Portalegre District',
        state_code: '12',
      },
      {
        id: 2243,
        name: 'Porto District',
        state_code: '13',
      },
      {
        id: 2238,
        name: 'Santarém District',
        state_code: '14',
      },
      {
        id: 2242,
        name: 'Setúbal District',
        state_code: '15',
      },
      {
        id: 2245,
        name: 'Viana do Castelo District',
        state_code: '16',
      },
      {
        id: 2234,
        name: 'Vila Real District',
        state_code: '17',
      },
      {
        id: 2237,
        name: 'Viseu District',
        state_code: '18',
      },
    ],
  },
  {
    name: 'Puerto Rico',
    iso3: 'PRI',
    iso2: 'PR',
    phone_code: '+1-787 and 1-939',
    capital: 'San Juan',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Qatar',
    iso3: 'QAT',
    iso2: 'QA',
    phone_code: '974',
    capital: 'Doha',
    currency: 'QAR',
    states: [
      {
        id: 3182,
        name: 'Al Daayen',
        state_code: 'ZA',
      },
      {
        id: 3183,
        name: 'Al Khor',
        state_code: 'KH',
      },
      {
        id: 3177,
        name: 'Al Rayyan Municipality',
        state_code: 'RA',
      },
      {
        id: 3179,
        name: 'Al Wakrah',
        state_code: 'WA',
      },
      {
        id: 3178,
        name: 'Al-Shahaniya',
        state_code: 'SH',
      },
      {
        id: 3181,
        name: 'Doha',
        state_code: 'DA',
      },
      {
        id: 3180,
        name: 'Madinat ash Shamal',
        state_code: 'MS',
      },
      {
        id: 3184,
        name: 'Umm Salal Municipality',
        state_code: 'US',
      },
    ],
  },
  {
    name: 'Reunion',
    iso3: 'REU',
    iso2: 'RE',
    phone_code: '262',
    capital: 'Saint-Denis',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Romania',
    iso3: 'ROU',
    iso2: 'RO',
    phone_code: '40',
    capital: 'Bucharest',
    currency: 'RON',
    states: [
      {
        id: 4724,
        name: 'Alba',
        state_code: 'AB',
      },
      {
        id: 4739,
        name: 'Arad County',
        state_code: 'AR',
      },
      {
        id: 4722,
        name: 'Arges',
        state_code: 'AG',
      },
      {
        id: 4744,
        name: 'Bacău County',
        state_code: 'BC',
      },
      {
        id: 4723,
        name: 'Bihor County',
        state_code: 'BH',
      },
      {
        id: 4733,
        name: 'Bistrița-Năsăud County',
        state_code: 'BN',
      },
      {
        id: 4740,
        name: 'Botoșani County',
        state_code: 'BT',
      },
      {
        id: 4736,
        name: 'Braila',
        state_code: 'BR',
      },
      {
        id: 4759,
        name: 'Brașov County',
        state_code: 'BV',
      },
      {
        id: 4730,
        name: 'Bucharest',
        state_code: 'B',
      },
      {
        id: 4756,
        name: 'Buzău County',
        state_code: 'BZ',
      },
      {
        id: 4732,
        name: 'Călărași County',
        state_code: 'CL',
      },
      {
        id: 4753,
        name: 'Caraș-Severin County',
        state_code: 'CS',
      },
      {
        id: 4734,
        name: 'Cluj County',
        state_code: 'CJ',
      },
      {
        id: 4737,
        name: 'Constanța County',
        state_code: 'CT',
      },
      {
        id: 4754,
        name: 'Covasna County',
        state_code: 'CV',
      },
      {
        id: 4745,
        name: 'Dâmbovița County',
        state_code: 'DB',
      },
      {
        id: 4742,
        name: 'Dolj County',
        state_code: 'DJ',
      },
      {
        id: 4747,
        name: 'Galați County',
        state_code: 'GL',
      },
      {
        id: 4726,
        name: 'Giurgiu County',
        state_code: 'GR',
      },
      {
        id: 4750,
        name: 'Gorj County',
        state_code: 'GJ',
      },
      {
        id: 4749,
        name: 'Harghita County',
        state_code: 'HR',
      },
      {
        id: 4721,
        name: 'Hunedoara County',
        state_code: 'HD',
      },
      {
        id: 4743,
        name: 'Ialomița County',
        state_code: 'IL',
      },
      {
        id: 4735,
        name: 'Iași County',
        state_code: 'IS',
      },
      {
        id: 4725,
        name: 'Ilfov County',
        state_code: 'IF',
      },
      {
        id: 4760,
        name: 'Maramureș County',
        state_code: 'MM',
      },
      {
        id: 4751,
        name: 'Mehedinți County',
        state_code: 'MH',
      },
      {
        id: 4731,
        name: 'Neamț County',
        state_code: 'NT',
      },
      {
        id: 4738,
        name: 'Olt County',
        state_code: 'OT',
      },
      {
        id: 4729,
        name: 'Prahova County',
        state_code: 'PH',
      },
      {
        id: 4741,
        name: 'Sălaj County',
        state_code: 'SJ',
      },
      {
        id: 4746,
        name: 'Satu Mare County',
        state_code: 'SM',
      },
      {
        id: 4755,
        name: 'Sibiu County',
        state_code: 'SB',
      },
      {
        id: 4720,
        name: 'Suceava County',
        state_code: 'SV',
      },
      {
        id: 4728,
        name: 'Teleorman County',
        state_code: 'TR',
      },
      {
        id: 4748,
        name: 'Timiș County',
        state_code: 'TM',
      },
      {
        id: 4727,
        name: 'Tulcea County',
        state_code: 'TL',
      },
      {
        id: 4757,
        name: 'Vâlcea County',
        state_code: 'VL',
      },
      {
        id: 4752,
        name: 'Vaslui County',
        state_code: 'VS',
      },
      {
        id: 4758,
        name: 'Vrancea County',
        state_code: 'VN',
      },
    ],
  },
  {
    name: 'Russia',
    iso3: 'RUS',
    iso2: 'RU',
    phone_code: '7',
    capital: 'Moscow',
    currency: 'RUB',
    states: [
      {
        id: 1911,
        name: 'Altai Krai',
        state_code: 'ALT',
      },
      {
        id: 1876,
        name: 'Altai Republic',
        state_code: 'AL',
      },
      {
        id: 1858,
        name: 'Amur Oblast',
        state_code: 'AMU',
      },
      {
        id: 1849,
        name: 'Arkhangelsk',
        state_code: 'ARK',
      },
      {
        id: 1866,
        name: 'Astrakhan Oblast',
        state_code: 'AST',
      },
      {
        id: 1903,
        name: 'Belgorod Oblast',
        state_code: 'BEL',
      },
      {
        id: 1867,
        name: 'Bryansk Oblast',
        state_code: 'BRY',
      },
      {
        id: 1893,
        name: 'Chechen Republic',
        state_code: 'CE',
      },
      {
        id: 1845,
        name: 'Chelyabinsk Oblast',
        state_code: 'CHE',
      },
      {
        id: 1859,
        name: 'Chukotka Autonomous Okrug',
        state_code: 'CHU',
      },
      {
        id: 1914,
        name: 'Chuvash Republic',
        state_code: 'CU',
      },
      {
        id: 1880,
        name: 'Irkutsk',
        state_code: 'IRK',
      },
      {
        id: 1864,
        name: 'Ivanovo Oblast',
        state_code: 'IVA',
      },
      {
        id: 1835,
        name: 'Jewish Autonomous Oblast',
        state_code: 'YEV',
      },
      {
        id: 1892,
        name: 'Kabardino-Balkar Republic',
        state_code: 'KB',
      },
      {
        id: 1902,
        name: 'Kaliningrad',
        state_code: 'KGD',
      },
      {
        id: 1844,
        name: 'Kaluga Oblast',
        state_code: 'KLU',
      },
      {
        id: 1865,
        name: 'Kamchatka Krai',
        state_code: 'KAM',
      },
      {
        id: 1869,
        name: 'Karachay-Cherkess Republic',
        state_code: 'KC',
      },
      {
        id: 1897,
        name: 'Kemerovo Oblast',
        state_code: 'KEM',
      },
      {
        id: 1873,
        name: 'Khabarovsk Krai',
        state_code: 'KHA',
      },
      {
        id: 1838,
        name: 'Khanty-Mansi Autonomous Okrug',
        state_code: 'KHM',
      },
      {
        id: 1890,
        name: 'Kirov Oblast',
        state_code: 'KIR',
      },
      {
        id: 1899,
        name: 'Komi Republic',
        state_code: 'KO',
      },
      {
        id: 1910,
        name: 'Kostroma Oblast',
        state_code: 'KOS',
      },
      {
        id: 1891,
        name: 'Krasnodar Krai',
        state_code: 'KDA',
      },
      {
        id: 1840,
        name: 'Krasnoyarsk Krai',
        state_code: 'KYA',
      },
      {
        id: 1915,
        name: 'Kurgan Oblast',
        state_code: 'KGN',
      },
      {
        id: 1855,
        name: 'Kursk Oblast',
        state_code: 'KRS',
      },
      {
        id: 1896,
        name: 'Leningrad Oblast',
        state_code: 'LEN',
      },
      {
        id: 1889,
        name: 'Lipetsk Oblast',
        state_code: 'LIP',
      },
      {
        id: 1839,
        name: 'Magadan Oblast',
        state_code: 'MAG',
      },
      {
        id: 1870,
        name: 'Mari El Republic',
        state_code: 'ME',
      },
      {
        id: 1901,
        name: 'Moscow',
        state_code: 'MOW',
      },
      {
        id: 1882,
        name: 'Moscow Oblast',
        state_code: 'MOS',
      },
      {
        id: 1843,
        name: 'Murmansk Oblast',
        state_code: 'MUR',
      },
      {
        id: 1836,
        name: 'Nenets Autonomous Okrug',
        state_code: 'NEN',
      },
      {
        id: 1857,
        name: 'Nizhny Novgorod Oblast',
        state_code: 'NIZ',
      },
      {
        id: 1834,
        name: 'Novgorod Oblast',
        state_code: 'NGR',
      },
      {
        id: 1888,
        name: 'Novosibirsk',
        state_code: 'NVS',
      },
      {
        id: 1846,
        name: 'Omsk Oblast',
        state_code: 'OMS',
      },
      {
        id: 1886,
        name: 'Orenburg Oblast',
        state_code: 'ORE',
      },
      {
        id: 1908,
        name: 'Oryol Oblast',
        state_code: 'ORL',
      },
      {
        id: 1909,
        name: 'Penza Oblast',
        state_code: 'PNZ',
      },
      {
        id: 1871,
        name: 'Perm Krai',
        state_code: 'PER',
      },
      {
        id: 1833,
        name: 'Primorsky Krai',
        state_code: 'PRI',
      },
      {
        id: 1863,
        name: 'Pskov Oblast',
        state_code: 'PSK',
      },
      {
        id: 1852,
        name: 'Republic of Adygea',
        state_code: 'AD',
      },
      {
        id: 1854,
        name: 'Republic of Bashkortostan',
        state_code: 'BA',
      },
      {
        id: 1842,
        name: 'Republic of Buryatia',
        state_code: 'BU',
      },
      {
        id: 1850,
        name: 'Republic of Dagestan',
        state_code: 'DA',
      },
      {
        id: 1884,
        name: 'Republic of Ingushetia',
        state_code: 'IN',
      },
      {
        id: 1883,
        name: 'Republic of Kalmykia',
        state_code: 'KL',
      },
      {
        id: 1841,
        name: 'Republic of Karelia',
        state_code: 'KR',
      },
      {
        id: 1877,
        name: 'Republic of Khakassia',
        state_code: 'KK',
      },
      {
        id: 1898,
        name: 'Republic of Mordovia',
        state_code: 'MO',
      },
      {
        id: 1853,
        name: 'Republic of North Ossetia-Alania',
        state_code: 'SE',
      },
      {
        id: 1861,
        name: 'Republic of Tatarstan',
        state_code: 'TA',
      },
      {
        id: 1837,
        name: 'Rostov Oblast',
        state_code: 'ROS',
      },
      {
        id: 1905,
        name: 'Ryazan Oblast',
        state_code: 'RYA',
      },
      {
        id: 1879,
        name: 'Saint Petersburg',
        state_code: 'SPE',
      },
      {
        id: 1848,
        name: 'Sakha Republic',
        state_code: 'SA',
      },
      {
        id: 1875,
        name: 'Sakhalin',
        state_code: 'SAK',
      },
      {
        id: 1862,
        name: 'Samara Oblast',
        state_code: 'SAM',
      },
      {
        id: 1887,
        name: 'Saratov Oblast',
        state_code: 'SAR',
      },
      {
        id: 1912,
        name: 'Sevastopol',
        state_code: 'UA-40',
      },
      {
        id: 1885,
        name: 'Smolensk Oblast',
        state_code: 'SMO',
      },
      {
        id: 1868,
        name: 'Stavropol Krai',
        state_code: 'STA',
      },
      {
        id: 1894,
        name: 'Sverdlovsk',
        state_code: 'SVE',
      },
      {
        id: 1878,
        name: 'Tambov Oblast',
        state_code: 'TAM',
      },
      {
        id: 1872,
        name: 'Tomsk Oblast',
        state_code: 'TOM',
      },
      {
        id: 1895,
        name: 'Tula Oblast',
        state_code: 'TUL',
      },
      {
        id: 1900,
        name: 'Tuva Republic',
        state_code: 'TY',
      },
      {
        id: 1860,
        name: 'Tver Oblast',
        state_code: 'TVE',
      },
      {
        id: 1907,
        name: 'Tyumen Oblast',
        state_code: 'TYU',
      },
      {
        id: 1913,
        name: 'Udmurt Republic',
        state_code: 'UD',
      },
      {
        id: 1856,
        name: 'Ulyanovsk Oblast',
        state_code: 'ULY',
      },
      {
        id: 1881,
        name: 'Vladimir Oblast',
        state_code: 'VLA',
      },
      {
        id: 1874,
        name: 'Vologda Oblast',
        state_code: 'VLG',
      },
      {
        id: 1906,
        name: 'Voronezh Oblast',
        state_code: 'VOR',
      },
      {
        id: 1847,
        name: 'Yamalo-Nenets Autonomous Okrug',
        state_code: 'YAN',
      },
      {
        id: 1851,
        name: 'Yaroslavl Oblast',
        state_code: 'YAR',
      },
      {
        id: 1904,
        name: 'Zabaykalsky Krai',
        state_code: 'ZAB',
      },
    ],
  },
  {
    name: 'Rwanda',
    iso3: 'RWA',
    iso2: 'RW',
    phone_code: '250',
    capital: 'Kigali',
    currency: 'RWF',
    states: [
      {
        id: 261,
        name: 'Eastern Province',
        state_code: '02',
      },
      {
        id: 262,
        name: 'Kigali district',
        state_code: '01',
      },
      {
        id: 263,
        name: 'Northern Province',
        state_code: '03',
      },
      {
        id: 259,
        name: 'Southern Province',
        state_code: '05',
      },
      {
        id: 260,
        name: 'Western Province',
        state_code: '04',
      },
    ],
  },
  {
    name: 'Saint Helena',
    iso3: 'SHN',
    iso2: 'SH',
    phone_code: '290',
    capital: 'Jamestown',
    currency: 'SHP',
    states: [],
  },
  {
    name: 'Saint Kitts And Nevis',
    iso3: 'KNA',
    iso2: 'KN',
    phone_code: '+1-869',
    capital: 'Basseterre',
    currency: 'XCD',
    states: [
      {
        id: 3833,
        name: 'Christ Church Nichola Town Parish',
        state_code: '01',
      },
      {
        id: 3832,
        name: 'Nevis',
        state_code: 'N',
      },
      {
        id: 3836,
        name: 'Saint Anne Sandy Point Parish',
        state_code: '02',
      },
      {
        id: 3837,
        name: 'Saint George Gingerland Parish',
        state_code: '04',
      },
      {
        id: 3835,
        name: 'Saint James Windward Parish',
        state_code: '05',
      },
      {
        id: 3845,
        name: 'Saint John Capisterre Parish',
        state_code: '06',
      },
      {
        id: 3840,
        name: 'Saint John Figtree Parish',
        state_code: '07',
      },
      {
        id: 3841,
        name: 'Saint Kitts',
        state_code: 'K',
      },
      {
        id: 3844,
        name: 'Saint Mary Cayon Parish',
        state_code: '08',
      },
      {
        id: 3834,
        name: 'Saint Paul Capisterre Parish',
        state_code: '09',
      },
      {
        id: 3838,
        name: 'Saint Paul Charlestown Parish',
        state_code: '10',
      },
      {
        id: 3831,
        name: 'Saint Peter Basseterre Parish',
        state_code: '11',
      },
      {
        id: 3839,
        name: 'Saint Thomas Lowland Parish',
        state_code: '12',
      },
      {
        id: 3842,
        name: 'Saint Thomas Middle Island Parish',
        state_code: '13',
      },
      {
        id: 3843,
        name: 'Trinity Palmetto Point Parish',
        state_code: '15',
      },
    ],
  },
  {
    name: 'Saint Lucia',
    iso3: 'LCA',
    iso2: 'LC',
    phone_code: '+1-758',
    capital: 'Castries',
    currency: 'XCD',
    states: [
      {
        id: 3757,
        name: 'Anse la Raye Quarter',
        state_code: '01',
      },
      {
        id: 3761,
        name: 'Canaries',
        state_code: '12',
      },
      {
        id: 3758,
        name: 'Castries Quarter',
        state_code: '02',
      },
      {
        id: 3760,
        name: 'Choiseul Quarter',
        state_code: '03',
      },
      {
        id: 3767,
        name: 'Dauphin Quarter',
        state_code: '04',
      },
      {
        id: 3756,
        name: 'Dennery Quarter',
        state_code: '05',
      },
      {
        id: 3766,
        name: 'Gros Islet Quarter',
        state_code: '06',
      },
      {
        id: 3759,
        name: 'Laborie Quarter',
        state_code: '07',
      },
      {
        id: 3762,
        name: 'Micoud Quarter',
        state_code: '08',
      },
      {
        id: 3765,
        name: 'Praslin Quarter',
        state_code: '09',
      },
      {
        id: 3764,
        name: 'Soufrière Quarter',
        state_code: '10',
      },
      {
        id: 3763,
        name: 'Vieux Fort Quarter',
        state_code: '11',
      },
    ],
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso3: 'SPM',
    iso2: 'PM',
    phone_code: '508',
    capital: 'Saint-Pierre',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Saint Vincent And The Grenadines',
    iso3: 'VCT',
    iso2: 'VC',
    phone_code: '+1-784',
    capital: 'Kingstown',
    currency: 'XCD',
    states: [
      {
        id: 3389,
        name: 'Charlotte Parish',
        state_code: '01',
      },
      {
        id: 3388,
        name: 'Grenadines Parish',
        state_code: '06',
      },
      {
        id: 3386,
        name: 'Saint Andrew Parish',
        state_code: '02',
      },
      {
        id: 3387,
        name: 'Saint David Parish',
        state_code: '03',
      },
      {
        id: 3384,
        name: 'Saint George Parish',
        state_code: '04',
      },
      {
        id: 3385,
        name: 'Saint Patrick Parish',
        state_code: '05',
      },
    ],
  },
  {
    name: 'Saint-Barthelemy',
    iso3: 'BLM',
    iso2: 'BL',
    phone_code: '590',
    capital: 'Gustavia',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Saint-Martin (French part)',
    iso3: 'MAF',
    iso2: 'MF',
    phone_code: '590',
    capital: 'Marigot',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Samoa',
    iso3: 'WSM',
    iso2: 'WS',
    phone_code: '685',
    capital: 'Apia',
    currency: 'WST',
    states: [
      {
        id: 4763,
        name: "A'ana",
        state_code: 'AA',
      },
      {
        id: 4761,
        name: 'Aiga-i-le-Tai',
        state_code: 'AL',
      },
      {
        id: 4765,
        name: 'Atua',
        state_code: 'AT',
      },
      {
        id: 4764,
        name: "Fa'asaleleaga",
        state_code: 'FA',
      },
      {
        id: 4769,
        name: "Gaga'emauga",
        state_code: 'GE',
      },
      {
        id: 4771,
        name: "Gaga'ifomauga",
        state_code: 'GI',
      },
      {
        id: 4767,
        name: 'Palauli',
        state_code: 'PA',
      },
      {
        id: 4762,
        name: "Satupa'itea",
        state_code: 'SA',
      },
      {
        id: 4770,
        name: 'Tuamasaga',
        state_code: 'TU',
      },
      {
        id: 4768,
        name: "Va'a-o-Fonoti",
        state_code: 'VF',
      },
      {
        id: 4766,
        name: 'Vaisigano',
        state_code: 'VS',
      },
    ],
  },
  {
    name: 'San Marino',
    iso3: 'SMR',
    iso2: 'SM',
    phone_code: '378',
    capital: 'San Marino',
    currency: 'EUR',
    states: [
      {
        id: 59,
        name: 'Acquaviva',
        state_code: '01',
      },
      {
        id: 61,
        name: 'Borgo Maggiore',
        state_code: '06',
      },
      {
        id: 60,
        name: 'Chiesanuova',
        state_code: '02',
      },
      {
        id: 64,
        name: 'Domagnano',
        state_code: '03',
      },
      {
        id: 62,
        name: 'Faetano',
        state_code: '04',
      },
      {
        id: 66,
        name: 'Fiorentino',
        state_code: '05',
      },
      {
        id: 63,
        name: 'Montegiardino',
        state_code: '08',
      },
      {
        id: 58,
        name: 'San Marino',
        state_code: '07',
      },
      {
        id: 65,
        name: 'Serravalle',
        state_code: '09',
      },
    ],
  },
  {
    name: 'Sao Tome and Principe',
    iso3: 'STP',
    iso2: 'ST',
    phone_code: '239',
    capital: 'Sao Tome',
    currency: 'STD',
    states: [
      {
        id: 270,
        name: 'Príncipe Province',
        state_code: 'P',
      },
      {
        id: 271,
        name: 'São Tomé Province',
        state_code: 'S',
      },
    ],
  },
  {
    name: 'Saudi Arabia',
    iso3: 'SAU',
    iso2: 'SA',
    phone_code: '966',
    capital: 'Riyadh',
    currency: 'SAR',
    states: [
      {
        id: 2853,
        name: "'Asir Region",
        state_code: '14',
      },
      {
        id: 2859,
        name: 'Al Bahah Region',
        state_code: '11',
      },
      {
        id: 2857,
        name: 'Al Jawf Region',
        state_code: '12',
      },
      {
        id: 2851,
        name: 'Al Madinah Region',
        state_code: '03',
      },
      {
        id: 2861,
        name: 'Al-Qassim Region',
        state_code: '05',
      },
      {
        id: 2856,
        name: 'Eastern Province',
        state_code: '04',
      },
      {
        id: 2855,
        name: "Ha'il Region",
        state_code: '06',
      },
      {
        id: 2858,
        name: 'Jizan Region',
        state_code: '09',
      },
      {
        id: 2850,
        name: 'Makkah Region',
        state_code: '02',
      },
      {
        id: 2860,
        name: 'Najran Region',
        state_code: '10',
      },
      {
        id: 2854,
        name: 'Northern Borders Region',
        state_code: '08',
      },
      {
        id: 2849,
        name: 'Riyadh Region',
        state_code: '01',
      },
      {
        id: 2852,
        name: 'Tabuk Region',
        state_code: '07',
      },
    ],
  },
  {
    name: 'Senegal',
    iso3: 'SEN',
    iso2: 'SN',
    phone_code: '221',
    capital: 'Dakar',
    currency: 'XOF',
    states: [
      {
        id: 473,
        name: 'Dakar',
        state_code: 'DK',
      },
      {
        id: 480,
        name: 'Diourbel Region',
        state_code: 'DB',
      },
      {
        id: 479,
        name: 'Fatick',
        state_code: 'FK',
      },
      {
        id: 475,
        name: 'Kaffrine',
        state_code: 'KA',
      },
      {
        id: 483,
        name: 'Kaolack',
        state_code: 'KL',
      },
      {
        id: 481,
        name: 'Kédougou',
        state_code: 'KE',
      },
      {
        id: 474,
        name: 'Kolda',
        state_code: 'KD',
      },
      {
        id: 485,
        name: 'Louga',
        state_code: 'LG',
      },
      {
        id: 476,
        name: 'Matam',
        state_code: 'MT',
      },
      {
        id: 477,
        name: 'Saint-Louis',
        state_code: 'SL',
      },
      {
        id: 482,
        name: 'Sédhiou',
        state_code: 'SE',
      },
      {
        id: 486,
        name: 'Tambacounda Region',
        state_code: 'TC',
      },
      {
        id: 484,
        name: 'Thiès Region',
        state_code: 'TH',
      },
      {
        id: 478,
        name: 'Ziguinchor',
        state_code: 'ZG',
      },
    ],
  },
  {
    name: 'Serbia',
    iso3: 'SRB',
    iso2: 'RS',
    phone_code: '381',
    capital: 'Belgrade',
    currency: 'RSD',
    states: [
      {
        id: 3730,
        name: 'Autonomous Province of Kosovo and Metohija',
        state_code: 'KM',
      },
      {
        id: 3728,
        name: 'Belgrade',
        state_code: '00',
      },
      {
        id: 3717,
        name: 'Bor District',
        state_code: '14',
      },
      {
        id: 3732,
        name: 'Braničevo District',
        state_code: '11',
      },
      {
        id: 3716,
        name: 'Central Banat District',
        state_code: '02',
      },
      {
        id: 3715,
        name: 'Jablanica District',
        state_code: '23',
      },
      {
        id: 3724,
        name: 'Kolubara District',
        state_code: '09',
      },
      {
        id: 3739,
        name: 'Kosovo-Pomoravlje District',
        state_code: '29',
      },
      {
        id: 3735,
        name: 'Kosovska Mitrovica District',
        state_code: '28',
      },
      {
        id: 3719,
        name: 'Mačva District',
        state_code: '08',
      },
      {
        id: 3727,
        name: 'Moravica District',
        state_code: '17',
      },
      {
        id: 3722,
        name: 'Nišava District',
        state_code: '20',
      },
      {
        id: 3714,
        name: 'North Bačka District',
        state_code: '01',
      },
      {
        id: 3736,
        name: 'North Banat District',
        state_code: '03',
      },
      {
        id: 3721,
        name: 'Pčinja District',
        state_code: '24',
      },
      {
        id: 3738,
        name: 'Peć District',
        state_code: '26',
      },
      {
        id: 3712,
        name: 'Pirot District',
        state_code: '22',
      },
      {
        id: 3741,
        name: 'Podunavlje District',
        state_code: '10',
      },
      {
        id: 3737,
        name: 'Pomoravlje District',
        state_code: '13',
      },
      {
        id: 3723,
        name: 'Prizren District',
        state_code: '27',
      },
      {
        id: 3720,
        name: 'Rasina District',
        state_code: '19',
      },
      {
        id: 3725,
        name: 'Raška District',
        state_code: '18',
      },
      {
        id: 3711,
        name: 'South Bačka District',
        state_code: '06',
      },
      {
        id: 3713,
        name: 'South Banat District',
        state_code: '04',
      },
      {
        id: 3740,
        name: 'Srem District',
        state_code: '07',
      },
      {
        id: 3734,
        name: 'Šumadija District',
        state_code: '12',
      },
      {
        id: 3718,
        name: 'Toplica District',
        state_code: '21',
      },
      {
        id: 3733,
        name: 'Vojvodina',
        state_code: 'VO',
      },
      {
        id: 3726,
        name: 'West Bačka District',
        state_code: '05',
      },
      {
        id: 3731,
        name: 'Zaječar District',
        state_code: '15',
      },
      {
        id: 3729,
        name: 'Zlatibor District',
        state_code: '16',
      },
    ],
  },
  {
    name: 'Seychelles',
    iso3: 'SYC',
    iso2: 'SC',
    phone_code: '248',
    capital: 'Victoria',
    currency: 'SCR',
    states: [
      {
        id: 513,
        name: 'Anse Boileau',
        state_code: '02',
      },
      {
        id: 502,
        name: 'Anse Royale',
        state_code: '05',
      },
      {
        id: 506,
        name: 'Anse-aux-Pins',
        state_code: '01',
      },
      {
        id: 508,
        name: 'Au Cap',
        state_code: '04',
      },
      {
        id: 497,
        name: 'Baie Lazare',
        state_code: '06',
      },
      {
        id: 514,
        name: 'Baie Sainte Anne',
        state_code: '07',
      },
      {
        id: 512,
        name: 'Beau Vallon',
        state_code: '08',
      },
      {
        id: 515,
        name: 'Bel Air',
        state_code: '09',
      },
      {
        id: 505,
        name: 'Bel Ombre',
        state_code: '10',
      },
      {
        id: 517,
        name: 'Cascade',
        state_code: '11',
      },
      {
        id: 503,
        name: 'Glacis',
        state_code: '12',
      },
      {
        id: 500,
        name: "Grand'Anse Mahé",
        state_code: '13',
      },
      {
        id: 504,
        name: "Grand'Anse Praslin",
        state_code: '14',
      },
      {
        id: 495,
        name: 'La Digue',
        state_code: '15',
      },
      {
        id: 516,
        name: 'La Rivière Anglaise',
        state_code: '16',
      },
      {
        id: 499,
        name: 'Les Mamelles',
        state_code: '24',
      },
      {
        id: 494,
        name: 'Mont Buxton',
        state_code: '17',
      },
      {
        id: 498,
        name: 'Mont Fleuri',
        state_code: '18',
      },
      {
        id: 511,
        name: 'Plaisance',
        state_code: '19',
      },
      {
        id: 510,
        name: 'Pointe La Rue',
        state_code: '20',
      },
      {
        id: 507,
        name: 'Port Glaud',
        state_code: '21',
      },
      {
        id: 501,
        name: 'Roche Caiman',
        state_code: '25',
      },
      {
        id: 496,
        name: 'Saint Louis',
        state_code: '22',
      },
      {
        id: 509,
        name: 'Takamaka',
        state_code: '23',
      },
    ],
  },
  {
    name: 'Sierra Leone',
    iso3: 'SLE',
    iso2: 'SL',
    phone_code: '232',
    capital: 'Freetown',
    currency: 'SLL',
    states: [
      {
        id: 914,
        name: 'Eastern Province',
        state_code: 'E',
      },
      {
        id: 911,
        name: 'Northern Province',
        state_code: 'N',
      },
      {
        id: 912,
        name: 'Southern Province',
        state_code: 'S',
      },
      {
        id: 913,
        name: 'Western Area',
        state_code: 'W',
      },
    ],
  },
  {
    name: 'Singapore',
    iso3: 'SGP',
    iso2: 'SG',
    phone_code: '65',
    capital: 'Singapur',
    currency: 'SGD',
    states: [
      {
        id: 4651,
        name: 'Central Singapore Community Development Council',
        state_code: '01',
      },
      {
        id: 4649,
        name: 'North East Community Development Council',
        state_code: '02',
      },
      {
        id: 4653,
        name: 'North West Community Development Council',
        state_code: '03',
      },
      {
        id: 4650,
        name: 'South East Community Development Council',
        state_code: '04',
      },
      {
        id: 4652,
        name: 'South West Community Development Council',
        state_code: '05',
      },
    ],
  },
  {
    name: 'Slovakia',
    iso3: 'SVK',
    iso2: 'SK',
    phone_code: '421',
    capital: 'Bratislava',
    currency: 'EUR',
    states: [
      {
        id: 4352,
        name: 'Banská Bystrica Region',
        state_code: 'BC',
      },
      {
        id: 4356,
        name: 'Bratislava Region',
        state_code: 'BL',
      },
      {
        id: 4353,
        name: 'Košice Region',
        state_code: 'KI',
      },
      {
        id: 4357,
        name: 'Nitra Region',
        state_code: 'NI',
      },
      {
        id: 4354,
        name: 'Prešov Region',
        state_code: 'PV',
      },
      {
        id: 4358,
        name: 'Trenčín Region',
        state_code: 'TC',
      },
      {
        id: 4355,
        name: 'Trnava Region',
        state_code: 'TA',
      },
      {
        id: 4359,
        name: 'Žilina Region',
        state_code: 'ZI',
      },
    ],
  },
  {
    name: 'Slovenia',
    iso3: 'SVN',
    iso2: 'SI',
    phone_code: '386',
    capital: 'Ljubljana',
    currency: 'EUR',
    states: [
      {
        id: 4183,
        name: 'Ajdovščina Municipality',
        state_code: '001',
      },
      {
        id: 4326,
        name: 'Ankaran Municipality',
        state_code: '213',
      },
      {
        id: 4301,
        name: 'Beltinci Municipality',
        state_code: '002',
      },
      {
        id: 4166,
        name: 'Benedikt Municipality',
        state_code: '148',
      },
      {
        id: 4179,
        name: 'Bistrica ob Sotli Municipality',
        state_code: '149',
      },
      {
        id: 4202,
        name: 'Bled Municipality',
        state_code: '003',
      },
      {
        id: 4278,
        name: 'Bloke Municipality',
        state_code: '150',
      },
      {
        id: 4282,
        name: 'Bohinj Municipality',
        state_code: '004',
      },
      {
        id: 4200,
        name: 'Borovnica Municipality',
        state_code: '005',
      },
      {
        id: 4181,
        name: 'Bovec Municipality',
        state_code: '006',
      },
      {
        id: 4141,
        name: 'Braslovče Municipality',
        state_code: '151',
      },
      {
        id: 4240,
        name: 'Brda Municipality',
        state_code: '007',
      },
      {
        id: 4215,
        name: 'Brežice Municipality',
        state_code: '009',
      },
      {
        id: 4165,
        name: 'Brezovica Municipality',
        state_code: '008',
      },
      {
        id: 4147,
        name: 'Cankova Municipality',
        state_code: '152',
      },
      {
        id: 4310,
        name: 'Cerklje na Gorenjskem Municipality',
        state_code: '012',
      },
      {
        id: 4162,
        name: 'Cerknica Municipality',
        state_code: '013',
      },
      {
        id: 4178,
        name: 'Cerkno Municipality',
        state_code: '014',
      },
      {
        id: 4176,
        name: 'Cerkvenjak Municipality',
        state_code: '153',
      },
      {
        id: 4191,
        name: 'City Municipality of Celje',
        state_code: '011',
      },
      {
        id: 4236,
        name: 'City Municipality of Novo Mesto',
        state_code: '085',
      },
      {
        id: 4151,
        name: 'Črenšovci Municipality',
        state_code: '015',
      },
      {
        id: 4232,
        name: 'Črna na Koroškem Municipality',
        state_code: '016',
      },
      {
        id: 4291,
        name: 'Črnomelj Municipality',
        state_code: '017',
      },
      {
        id: 4304,
        name: 'Destrnik Municipality',
        state_code: '018',
      },
      {
        id: 4167,
        name: 'Divača Municipality',
        state_code: '019',
      },
      {
        id: 4295,
        name: 'Dobje Municipality',
        state_code: '154',
      },
      {
        id: 4216,
        name: 'Dobrepolje Municipality',
        state_code: '020',
      },
      {
        id: 4252,
        name: 'Dobrna Municipality',
        state_code: '155',
      },
      {
        id: 4308,
        name: 'Dobrova–Polhov Gradec Municipality',
        state_code: '021',
      },
      {
        id: 4189,
        name: 'Dobrovnik Municipality',
        state_code: '156',
      },
      {
        id: 4173,
        name: 'Dol pri Ljubljani Municipality',
        state_code: '022',
      },
      {
        id: 4281,
        name: 'Dolenjske Toplice Municipality',
        state_code: '157',
      },
      {
        id: 4159,
        name: 'Domžale Municipality',
        state_code: '023',
      },
      {
        id: 4290,
        name: 'Dornava Municipality',
        state_code: '024',
      },
      {
        id: 4345,
        name: 'Dravograd Municipality',
        state_code: '025',
      },
      {
        id: 4213,
        name: 'Duplek Municipality',
        state_code: '026',
      },
      {
        id: 4293,
        name: 'Gorenja Vas–Poljane Municipality',
        state_code: '027',
      },
      {
        id: 4210,
        name: 'Gorišnica Municipality',
        state_code: '028',
      },
      {
        id: 4284,
        name: 'Gorje Municipality',
        state_code: '207',
      },
      {
        id: 4343,
        name: 'Gornja Radgona Municipality',
        state_code: '029',
      },
      {
        id: 4339,
        name: 'Gornji Grad Municipality',
        state_code: '030',
      },
      {
        id: 4271,
        name: 'Gornji Petrovci Municipality',
        state_code: '031',
      },
      {
        id: 4217,
        name: 'Grad Municipality',
        state_code: '158',
      },
      {
        id: 4336,
        name: 'Grosuplje Municipality',
        state_code: '032',
      },
      {
        id: 4145,
        name: 'Hajdina Municipality',
        state_code: '159',
      },
      {
        id: 4175,
        name: 'Hoče–Slivnica Municipality',
        state_code: '160',
      },
      {
        id: 4327,
        name: 'Hodoš Municipality',
        state_code: '161',
      },
      {
        id: 4193,
        name: 'Horjul Municipality',
        state_code: '162',
      },
      {
        id: 4341,
        name: 'Hrastnik Municipality',
        state_code: '034',
      },
      {
        id: 4321,
        name: 'Hrpelje–Kozina Municipality',
        state_code: '035',
      },
      {
        id: 4152,
        name: 'Idrija Municipality',
        state_code: '036',
      },
      {
        id: 4286,
        name: 'Ig Municipality',
        state_code: '037',
      },
      {
        id: 4305,
        name: 'Ivančna Gorica Municipality',
        state_code: '039',
      },
      {
        id: 4322,
        name: 'Izola Municipality',
        state_code: '040',
      },
      {
        id: 4337,
        name: 'Jesenice Municipality',
        state_code: '041',
      },
      {
        id: 4203,
        name: 'Jezersko Municipality',
        state_code: '163',
      },
      {
        id: 4266,
        name: 'Juršinci Municipality',
        state_code: '042',
      },
      {
        id: 4180,
        name: 'Kamnik Municipality',
        state_code: '043',
      },
      {
        id: 4227,
        name: 'Kanal ob Soči Municipality',
        state_code: '044',
      },
      {
        id: 4150,
        name: 'Kidričevo Municipality',
        state_code: '045',
      },
      {
        id: 4243,
        name: 'Kobarid Municipality',
        state_code: '046',
      },
      {
        id: 4325,
        name: 'Kobilje Municipality',
        state_code: '047',
      },
      {
        id: 4335,
        name: 'Kočevje Municipality',
        state_code: '048',
      },
      {
        id: 4315,
        name: 'Komen Municipality',
        state_code: '049',
      },
      {
        id: 4283,
        name: 'Komenda Municipality',
        state_code: '164',
      },
      {
        id: 4319,
        name: 'Koper City Municipality',
        state_code: '050',
      },
      {
        id: 4254,
        name: 'Kostanjevica na Krki Municipality',
        state_code: '197',
      },
      {
        id: 4331,
        name: 'Kostel Municipality',
        state_code: '165',
      },
      {
        id: 4186,
        name: 'Kozje Municipality',
        state_code: '051',
      },
      {
        id: 4287,
        name: 'Kranj City Municipality',
        state_code: '052',
      },
      {
        id: 4340,
        name: 'Kranjska Gora Municipality',
        state_code: '053',
      },
      {
        id: 4238,
        name: 'Križevci Municipality',
        state_code: '166',
      },
      {
        id: 4197,
        name: 'Kungota',
        state_code: '055',
      },
      {
        id: 4211,
        name: 'Kuzma Municipality',
        state_code: '056',
      },
      {
        id: 4338,
        name: 'Laško Municipality',
        state_code: '057',
      },
      {
        id: 4142,
        name: 'Lenart Municipality',
        state_code: '058',
      },
      {
        id: 4225,
        name: 'Lendava Municipality',
        state_code: '059',
      },
      {
        id: 4347,
        name: 'Litija Municipality',
        state_code: '060',
      },
      {
        id: 4270,
        name: 'Ljubljana City Municipality',
        state_code: '061',
      },
      {
        id: 4294,
        name: 'Ljubno Municipality',
        state_code: '062',
      },
      {
        id: 4351,
        name: 'Ljutomer Municipality',
        state_code: '063',
      },
      {
        id: 4306,
        name: 'Log–Dragomer Municipality',
        state_code: '208',
      },
      {
        id: 4350,
        name: 'Logatec Municipality',
        state_code: '064',
      },
      {
        id: 4174,
        name: 'Loška Dolina Municipality',
        state_code: '065',
      },
      {
        id: 4158,
        name: 'Loški Potok Municipality',
        state_code: '066',
      },
      {
        id: 4156,
        name: 'Lovrenc na Pohorju Municipality',
        state_code: '167',
      },
      {
        id: 4219,
        name: 'Luče Municipality',
        state_code: '067',
      },
      {
        id: 4302,
        name: 'Lukovica Municipality',
        state_code: '068',
      },
      {
        id: 4157,
        name: 'Majšperk Municipality',
        state_code: '069',
      },
      {
        id: 4224,
        name: 'Makole Municipality',
        state_code: '198',
      },
      {
        id: 4242,
        name: 'Maribor City Municipality',
        state_code: '070',
      },
      {
        id: 4244,
        name: 'Markovci Municipality',
        state_code: '168',
      },
      {
        id: 4349,
        name: 'Medvode Municipality',
        state_code: '071',
      },
      {
        id: 4348,
        name: 'Mengeš Municipality',
        state_code: '072',
      },
      {
        id: 4323,
        name: 'Metlika Municipality',
        state_code: '073',
      },
      {
        id: 4265,
        name: 'Mežica Municipality',
        state_code: '074',
      },
      {
        id: 4223,
        name: 'Miklavž na Dravskem Polju Municipality',
        state_code: '169',
      },
      {
        id: 4220,
        name: 'Miren–Kostanjevica Municipality',
        state_code: '075',
      },
      {
        id: 4298,
        name: 'Mirna Municipality',
        state_code: '212',
      },
      {
        id: 4237,
        name: 'Mirna Peč Municipality',
        state_code: '170',
      },
      {
        id: 4212,
        name: 'Mislinja Municipality',
        state_code: '076',
      },
      {
        id: 4297,
        name: 'Mokronog–Trebelno Municipality',
        state_code: '199',
      },
      {
        id: 4168,
        name: 'Moravče Municipality',
        state_code: '077',
      },
      {
        id: 4218,
        name: 'Moravske Toplice Municipality',
        state_code: '078',
      },
      {
        id: 4190,
        name: 'Mozirje Municipality',
        state_code: '079',
      },
      {
        id: 4318,
        name: 'Municipality of Apače',
        state_code: '195',
      },
      {
        id: 4309,
        name: 'Municipality of Cirkulane',
        state_code: '196',
      },
      {
        id: 4344,
        name: 'Municipality of Ilirska Bistrica',
        state_code: '038',
      },
      {
        id: 4314,
        name: 'Municipality of Krško',
        state_code: '054',
      },
      {
        id: 4187,
        name: 'Municipality of Škofljica',
        state_code: '123',
      },
      {
        id: 4313,
        name: 'Murska Sobota City Municipality',
        state_code: '080',
      },
      {
        id: 4208,
        name: 'Muta Municipality',
        state_code: '081',
      },
      {
        id: 4177,
        name: 'Naklo Municipality',
        state_code: '082',
      },
      {
        id: 4329,
        name: 'Nazarje Municipality',
        state_code: '083',
      },
      {
        id: 4205,
        name: 'Nova Gorica City Municipality',
        state_code: '084',
      },
      {
        id: 4320,
        name: 'Odranci Municipality',
        state_code: '086',
      },
      {
        id: 4143,
        name: 'Oplotnica',
        state_code: '171',
      },
      {
        id: 4221,
        name: 'Ormož Municipality',
        state_code: '087',
      },
      {
        id: 4199,
        name: 'Osilnica Municipality',
        state_code: '088',
      },
      {
        id: 4172,
        name: 'Pesnica Municipality',
        state_code: '089',
      },
      {
        id: 4201,
        name: 'Piran Municipality',
        state_code: '090',
      },
      {
        id: 4184,
        name: 'Pivka Municipality',
        state_code: '091',
      },
      {
        id: 4146,
        name: 'Podčetrtek Municipality',
        state_code: '092',
      },
      {
        id: 4161,
        name: 'Podlehnik Municipality',
        state_code: '172',
      },
      {
        id: 4234,
        name: 'Podvelka Municipality',
        state_code: '093',
      },
      {
        id: 4239,
        name: 'Poljčane Municipality',
        state_code: '200',
      },
      {
        id: 4272,
        name: 'Polzela Municipality',
        state_code: '173',
      },
      {
        id: 4330,
        name: 'Postojna Municipality',
        state_code: '094',
      },
      {
        id: 4188,
        name: 'Prebold Municipality',
        state_code: '174',
      },
      {
        id: 4303,
        name: 'Preddvor Municipality',
        state_code: '095',
      },
      {
        id: 4274,
        name: 'Prevalje Municipality',
        state_code: '175',
      },
      {
        id: 4228,
        name: 'Ptuj City Municipality',
        state_code: '096',
      },
      {
        id: 4288,
        name: 'Puconci Municipality',
        state_code: '097',
      },
      {
        id: 4204,
        name: 'Rače–Fram Municipality',
        state_code: '098',
      },
      {
        id: 4195,
        name: 'Radeče Municipality',
        state_code: '099',
      },
      {
        id: 4292,
        name: 'Radenci Municipality',
        state_code: '100',
      },
      {
        id: 4275,
        name: 'Radlje ob Dravi Municipality',
        state_code: '101',
      },
      {
        id: 4231,
        name: 'Radovljica Municipality',
        state_code: '102',
      },
      {
        id: 4155,
        name: 'Ravne na Koroškem Municipality',
        state_code: '103',
      },
      {
        id: 4206,
        name: 'Razkrižje Municipality',
        state_code: '176',
      },
      {
        id: 4160,
        name: 'Rečica ob Savinji Municipality',
        state_code: '209',
      },
      {
        id: 4253,
        name: 'Renče–Vogrsko Municipality',
        state_code: '201',
      },
      {
        id: 4235,
        name: 'Ribnica Municipality',
        state_code: '104',
      },
      {
        id: 4207,
        name: 'Ribnica na Pohorju Municipality',
        state_code: '177',
      },
      {
        id: 4233,
        name: 'Rogaška Slatina Municipality',
        state_code: '106',
      },
      {
        id: 4264,
        name: 'Rogašovci Municipality',
        state_code: '105',
      },
      {
        id: 4209,
        name: 'Rogatec Municipality',
        state_code: '107',
      },
      {
        id: 4280,
        name: 'Ruše Municipality',
        state_code: '108',
      },
      {
        id: 4222,
        name: 'Šalovci Municipality',
        state_code: '033',
      },
      {
        id: 4230,
        name: 'Selnica ob Dravi Municipality',
        state_code: '178',
      },
      {
        id: 4346,
        name: 'Semič Municipality',
        state_code: '109',
      },
      {
        id: 4317,
        name: 'Šempeter–Vrtojba Municipality',
        state_code: '183',
      },
      {
        id: 4299,
        name: 'Šenčur Municipality',
        state_code: '117',
      },
      {
        id: 4324,
        name: 'Šentilj Municipality',
        state_code: '118',
      },
      {
        id: 4241,
        name: 'Šentjernej Municipality',
        state_code: '119',
      },
      {
        id: 4171,
        name: 'Šentjur Municipality',
        state_code: '120',
      },
      {
        id: 4311,
        name: 'Šentrupert Municipality',
        state_code: '211',
      },
      {
        id: 4268,
        name: 'Sevnica Municipality',
        state_code: '110',
      },
      {
        id: 4149,
        name: 'Sežana Municipality',
        state_code: '111',
      },
      {
        id: 4170,
        name: 'Škocjan Municipality',
        state_code: '121',
      },
      {
        id: 4316,
        name: 'Škofja Loka Municipality',
        state_code: '122',
      },
      {
        id: 4169,
        name: 'Slovenj Gradec City Municipality',
        state_code: '112',
      },
      {
        id: 4332,
        name: 'Slovenska Bistrica Municipality',
        state_code: '113',
      },
      {
        id: 4198,
        name: 'Slovenske Konjice Municipality',
        state_code: '114',
      },
      {
        id: 4285,
        name: 'Šmarje pri Jelšah Municipality',
        state_code: '124',
      },
      {
        id: 4289,
        name: 'Šmarješke Toplice Municipality',
        state_code: '206',
      },
      {
        id: 4296,
        name: 'Šmartno ob Paki Municipality',
        state_code: '125',
      },
      {
        id: 4279,
        name: 'Šmartno pri Litiji Municipality',
        state_code: '194',
      },
      {
        id: 4277,
        name: 'Sodražica Municipality',
        state_code: '179',
      },
      {
        id: 4261,
        name: 'Solčava Municipality',
        state_code: '180',
      },
      {
        id: 4248,
        name: 'Šoštanj Municipality',
        state_code: '126',
      },
      {
        id: 4263,
        name: 'Središče ob Dravi',
        state_code: '202',
      },
      {
        id: 4259,
        name: 'Starše Municipality',
        state_code: '115',
      },
      {
        id: 4185,
        name: 'Štore Municipality',
        state_code: '127',
      },
      {
        id: 4333,
        name: 'Straža Municipality',
        state_code: '203',
      },
      {
        id: 4164,
        name: 'Sveta Ana Municipality',
        state_code: '181',
      },
      {
        id: 4260,
        name: 'Sveta Trojica v Slovenskih Goricah Municipality',
        state_code: '204',
      },
      {
        id: 4229,
        name: 'Sveti Andraž v Slovenskih Goricah Municipality',
        state_code: '182',
      },
      {
        id: 4255,
        name: 'Sveti Jurij ob Ščavnici Municipality',
        state_code: '116',
      },
      {
        id: 4328,
        name: 'Sveti Jurij v Slovenskih Goricah Municipality',
        state_code: '210',
      },
      {
        id: 4273,
        name: 'Sveti Tomaž Municipality',
        state_code: '205',
      },
      {
        id: 4194,
        name: 'Tabor Municipality',
        state_code: '184',
      },
      {
        id: 4312,
        name: 'Tišina Municipality',
        state_code: '010',
      },
      {
        id: 4247,
        name: 'Tolmin Municipality',
        state_code: '128',
      },
      {
        id: 4246,
        name: 'Trbovlje Municipality',
        state_code: '129',
      },
      {
        id: 4214,
        name: 'Trebnje Municipality',
        state_code: '130',
      },
      {
        id: 4153,
        name: 'Trnovska Vas Municipality',
        state_code: '185',
      },
      {
        id: 4250,
        name: 'Tržič Municipality',
        state_code: '131',
      },
      {
        id: 4334,
        name: 'Trzin Municipality',
        state_code: '186',
      },
      {
        id: 4251,
        name: 'Turnišče Municipality',
        state_code: '132',
      },
      {
        id: 4267,
        name: 'Velika Polana Municipality',
        state_code: '187',
      },
      {
        id: 4144,
        name: 'Velike Lašče Municipality',
        state_code: '134',
      },
      {
        id: 4257,
        name: 'Veržej Municipality',
        state_code: '188',
      },
      {
        id: 4300,
        name: 'Videm Municipality',
        state_code: '135',
      },
      {
        id: 4196,
        name: 'Vipava Municipality',
        state_code: '136',
      },
      {
        id: 4148,
        name: 'Vitanje Municipality',
        state_code: '137',
      },
      {
        id: 4154,
        name: 'Vodice Municipality',
        state_code: '138',
      },
      {
        id: 4245,
        name: 'Vojnik Municipality',
        state_code: '139',
      },
      {
        id: 4163,
        name: 'Vransko Municipality',
        state_code: '189',
      },
      {
        id: 4262,
        name: 'Vrhnika Municipality',
        state_code: '140',
      },
      {
        id: 4226,
        name: 'Vuzenica Municipality',
        state_code: '141',
      },
      {
        id: 4269,
        name: 'Zagorje ob Savi Municipality',
        state_code: '142',
      },
      {
        id: 4258,
        name: 'Žalec Municipality',
        state_code: '190',
      },
      {
        id: 4182,
        name: 'Zavrč Municipality',
        state_code: '143',
      },
      {
        id: 4256,
        name: 'Železniki Municipality',
        state_code: '146',
      },
      {
        id: 4249,
        name: 'Žetale Municipality',
        state_code: '191',
      },
      {
        id: 4192,
        name: 'Žiri Municipality',
        state_code: '147',
      },
      {
        id: 4276,
        name: 'Žirovnica Municipality',
        state_code: '192',
      },
      {
        id: 4342,
        name: 'Zreče Municipality',
        state_code: '144',
      },
      {
        id: 4307,
        name: 'Žužemberk Municipality',
        state_code: '193',
      },
    ],
  },
  {
    name: 'Solomon Islands',
    iso3: 'SLB',
    iso2: 'SB',
    phone_code: '677',
    capital: 'Honiara',
    currency: 'SBD',
    states: [
      {
        id: 4784,
        name: 'Central Province',
        state_code: 'CE',
      },
      {
        id: 4781,
        name: 'Choiseul Province',
        state_code: 'CH',
      },
      {
        id: 4785,
        name: 'Guadalcanal Province',
        state_code: 'GU',
      },
      {
        id: 4778,
        name: 'Honiara',
        state_code: 'CT',
      },
      {
        id: 4780,
        name: 'Isabel Province',
        state_code: 'IS',
      },
      {
        id: 4782,
        name: 'Makira-Ulawa Province',
        state_code: 'MK',
      },
      {
        id: 4783,
        name: 'Malaita Province',
        state_code: 'ML',
      },
      {
        id: 4787,
        name: 'Rennell and Bellona Province',
        state_code: 'RB',
      },
      {
        id: 4779,
        name: 'Temotu Province',
        state_code: 'TE',
      },
      {
        id: 4786,
        name: 'Western Province',
        state_code: 'WE',
      },
    ],
  },
  {
    name: 'Somalia',
    iso3: 'SOM',
    iso2: 'SO',
    phone_code: '252',
    capital: 'Mogadishu',
    currency: 'SOS',
    states: [
      {
        id: 925,
        name: 'Awdal Region',
        state_code: 'AW',
      },
      {
        id: 917,
        name: 'Bakool',
        state_code: 'BK',
      },
      {
        id: 927,
        name: 'Banaadir',
        state_code: 'BN',
      },
      {
        id: 930,
        name: 'Bari',
        state_code: 'BR',
      },
      {
        id: 926,
        name: 'Bay',
        state_code: 'BY',
      },
      {
        id: 918,
        name: 'Galguduud',
        state_code: 'GA',
      },
      {
        id: 928,
        name: 'Gedo',
        state_code: 'GE',
      },
      {
        id: 915,
        name: 'Hiran',
        state_code: 'HI',
      },
      {
        id: 924,
        name: 'Lower Juba',
        state_code: 'JH',
      },
      {
        id: 921,
        name: 'Lower Shebelle',
        state_code: 'SH',
      },
      {
        id: 922,
        name: 'Middle Juba',
        state_code: 'JD',
      },
      {
        id: 923,
        name: 'Middle Shebelle',
        state_code: 'SD',
      },
      {
        id: 916,
        name: 'Mudug',
        state_code: 'MU',
      },
      {
        id: 920,
        name: 'Nugal',
        state_code: 'NU',
      },
      {
        id: 919,
        name: 'Sanaag Region',
        state_code: 'SA',
      },
      {
        id: 929,
        name: 'Togdheer Region',
        state_code: 'TO',
      },
    ],
  },
  {
    name: 'South Africa',
    iso3: 'ZAF',
    iso2: 'ZA',
    phone_code: '27',
    capital: 'Pretoria',
    currency: 'ZAR',
    states: [
      {
        id: 938,
        name: 'Eastern Cape',
        state_code: 'EC',
      },
      {
        id: 932,
        name: 'Free State',
        state_code: 'FS',
      },
      {
        id: 936,
        name: 'Gauteng',
        state_code: 'GT',
      },
      {
        id: 935,
        name: 'KwaZulu-Natal',
        state_code: 'NL',
      },
      {
        id: 933,
        name: 'Limpopo',
        state_code: 'LP',
      },
      {
        id: 937,
        name: 'Mpumalanga',
        state_code: 'MP',
      },
      {
        id: 934,
        name: 'North West',
        state_code: 'NW',
      },
      {
        id: 931,
        name: 'Northern Cape',
        state_code: 'NC',
      },
      {
        id: 939,
        name: 'Western Cape',
        state_code: 'WC',
      },
    ],
  },
  {
    name: 'South Georgia',
    iso3: 'SGS',
    iso2: 'GS',
    phone_code: '',
    capital: 'Grytviken',
    currency: 'GBP',
    states: [],
  },
  {
    name: 'South Sudan',
    iso3: 'SSD',
    iso2: 'SS',
    phone_code: '211',
    capital: 'Juba',
    currency: 'SSP',
    states: [
      {
        id: 2092,
        name: 'Central Equatoria',
        state_code: 'EC',
      },
      {
        id: 2093,
        name: 'Eastern Equatoria',
        state_code: 'EE',
      },
      {
        id: 2094,
        name: 'Jonglei State',
        state_code: 'JG',
      },
      {
        id: 2090,
        name: 'Lakes',
        state_code: 'LK',
      },
      {
        id: 2088,
        name: 'Northern Bahr el Ghazal',
        state_code: 'BN',
      },
      {
        id: 2085,
        name: 'Unity',
        state_code: 'UY',
      },
      {
        id: 2086,
        name: 'Upper Nile',
        state_code: 'NU',
      },
      {
        id: 2087,
        name: 'Warrap',
        state_code: 'WR',
      },
      {
        id: 2091,
        name: 'Western Bahr el Ghazal',
        state_code: 'BW',
      },
      {
        id: 2089,
        name: 'Western Equatoria',
        state_code: 'EW',
      },
    ],
  },
  {
    name: 'Spain',
    iso3: 'ESP',
    iso2: 'ES',
    phone_code: '34',
    capital: 'Madrid',
    currency: 'EUR',
    states: [
      {
        id: 1179,
        name: 'A Coruña Province',
        state_code: 'C',
      },
      {
        id: 1168,
        name: 'Albacete Province',
        state_code: 'AB',
      },
      {
        id: 1156,
        name: 'Alicante Province',
        state_code: 'A',
      },
      {
        id: 1151,
        name: 'Almería Province',
        state_code: 'AL',
      },
      {
        id: 1193,
        name: 'Andalusia',
        state_code: 'AN',
      },
      {
        id: 1148,
        name: 'Araba / Álava',
        state_code: 'VI',
      },
      {
        id: 1177,
        name: 'Aragon',
        state_code: 'AR',
      },
      {
        id: 1153,
        name: 'Badajoz Province',
        state_code: 'BA',
      },
      {
        id: 1174,
        name: 'Balearic Islands',
        state_code: 'PM',
      },
      {
        id: 1180,
        name: 'Barcelona Province',
        state_code: 'B',
      },
      {
        id: 1191,
        name: 'Basque Country',
        state_code: 'PV',
      },
      {
        id: 1186,
        name: 'Biscay',
        state_code: 'BI',
      },
      {
        id: 1146,
        name: 'Burgos Province',
        state_code: 'BU',
      },
      {
        id: 1196,
        name: 'Cáceres Province',
        state_code: 'CC',
      },
      {
        id: 1201,
        name: 'Cádiz Province',
        state_code: 'CA',
      },
      {
        id: 1185,
        name: 'Canary Islands',
        state_code: 'CN',
      },
      {
        id: 1170,
        name: 'Cantabria',
        state_code: 'S',
      },
      {
        id: 1207,
        name: 'Castellón Province',
        state_code: 'CS',
      },
      {
        id: 1184,
        name: 'Castile and León',
        state_code: 'CL',
      },
      {
        id: 1205,
        name: 'Castile-La Mancha',
        state_code: 'CM',
      },
      {
        id: 1203,
        name: 'Catalonia',
        state_code: 'CT',
      },
      {
        id: 1206,
        name: 'Ceuta',
        state_code: 'CE',
      },
      {
        id: 1150,
        name: 'Ciudad Real Province',
        state_code: 'CR',
      },
      {
        id: 1158,
        name: 'Community of Madrid',
        state_code: 'MD',
      },
      {
        id: 1197,
        name: 'Córdoba Province',
        state_code: 'CO',
      },
      {
        id: 1169,
        name: 'Cuenca Province',
        state_code: 'CU',
      },
      {
        id: 1190,
        name: 'Extremadura',
        state_code: 'EX',
      },
      {
        id: 1167,
        name: 'Galicia',
        state_code: 'GA',
      },
      {
        id: 1202,
        name: 'Gipuzkoa',
        state_code: 'SS',
      },
      {
        id: 1178,
        name: 'Girona Province',
        state_code: 'GI',
      },
      {
        id: 1194,
        name: 'Granada Province',
        state_code: 'GR',
      },
      {
        id: 1172,
        name: 'Guadalajara Province',
        state_code: 'GU',
      },
      {
        id: 1199,
        name: 'Huelva Province',
        state_code: 'H',
      },
      {
        id: 1163,
        name: 'Huesca Province',
        state_code: 'HU',
      },
      {
        id: 1181,
        name: 'Jaén Province',
        state_code: 'J',
      },
      {
        id: 1171,
        name: 'La Rioja',
        state_code: 'RI',
      },
      {
        id: 1166,
        name: 'Las Palmas Province',
        state_code: 'GC',
      },
      {
        id: 1200,
        name: 'León Province',
        state_code: 'LE',
      },
      {
        id: 1195,
        name: 'Lleida Province',
        state_code: 'L',
      },
      {
        id: 1187,
        name: 'Lugo Province',
        state_code: 'LU',
      },
      {
        id: 1149,
        name: 'Madrid Province',
        state_code: 'M',
      },
      {
        id: 1188,
        name: 'Málaga Province',
        state_code: 'MA',
      },
      {
        id: 1159,
        name: 'Melilla',
        state_code: 'ML',
      },
      {
        id: 1145,
        name: 'Murcia Province',
        state_code: 'MU',
      },
      {
        id: 1204,
        name: 'Navarre',
        state_code: 'NC',
      },
      {
        id: 1173,
        name: 'Ourense Province',
        state_code: 'OR',
      },
      {
        id: 1157,
        name: 'Palencia Province',
        state_code: 'P',
      },
      {
        id: 1154,
        name: 'Pontevedra Province',
        state_code: 'PO',
      },
      {
        id: 1160,
        name: 'Province of Asturias',
        state_code: 'O',
      },
      {
        id: 1189,
        name: 'Province of Ávila',
        state_code: 'AV',
      },
      {
        id: 1176,
        name: 'Region of Murcia',
        state_code: 'MC',
      },
      {
        id: 1147,
        name: 'Salamanca Province',
        state_code: 'SA',
      },
      {
        id: 1198,
        name: 'Santa Cruz de Tenerife Province',
        state_code: 'TF',
      },
      {
        id: 1192,
        name: 'Segovia Province',
        state_code: 'SG',
      },
      {
        id: 1155,
        name: 'Seville Province',
        state_code: 'SE',
      },
      {
        id: 1208,
        name: 'Soria Province',
        state_code: 'SO',
      },
      {
        id: 1164,
        name: 'Tarragona Province',
        state_code: 'T',
      },
      {
        id: 1182,
        name: 'Teruel Province',
        state_code: 'TE',
      },
      {
        id: 1165,
        name: 'Toledo Province',
        state_code: 'TO',
      },
      {
        id: 1152,
        name: 'Valencia Province',
        state_code: 'V',
      },
      {
        id: 1175,
        name: 'Valencian Community',
        state_code: 'VC',
      },
      {
        id: 1183,
        name: 'Valladolid Province',
        state_code: 'VA',
      },
      {
        id: 1161,
        name: 'Zamora Province',
        state_code: 'ZA',
      },
      {
        id: 1162,
        name: 'Zaragoza Province',
        state_code: 'Z',
      },
    ],
  },
  {
    name: 'Sri Lanka',
    iso3: 'LKA',
    iso2: 'LK',
    phone_code: '94',
    capital: 'Colombo',
    currency: 'LKR',
    states: [
      {
        id: 2799,
        name: 'Ampara District',
        state_code: '52',
      },
      {
        id: 2816,
        name: 'Anuradhapura District',
        state_code: '71',
      },
      {
        id: 2790,
        name: 'Badulla District',
        state_code: '81',
      },
      {
        id: 2818,
        name: 'Batticaloa District',
        state_code: '51',
      },
      {
        id: 2798,
        name: 'Central Province',
        state_code: '2',
      },
      {
        id: 2815,
        name: 'Colombo District',
        state_code: '11',
      },
      {
        id: 2808,
        name: 'Eastern Province',
        state_code: '5',
      },
      {
        id: 2792,
        name: 'Galle District',
        state_code: '31',
      },
      {
        id: 2804,
        name: 'Gampaha District',
        state_code: '12',
      },
      {
        id: 2791,
        name: 'Hambantota District',
        state_code: '33',
      },
      {
        id: 2787,
        name: 'Jaffna District',
        state_code: '41',
      },
      {
        id: 2789,
        name: 'Kalutara District',
        state_code: '13',
      },
      {
        id: 2788,
        name: 'Kandy District',
        state_code: '21',
      },
      {
        id: 2797,
        name: 'Kegalle District',
        state_code: '92',
      },
      {
        id: 2793,
        name: 'Kilinochchi District',
        state_code: '42',
      },
      {
        id: 2805,
        name: 'Mannar District',
        state_code: '43',
      },
      {
        id: 2810,
        name: 'Matale District',
        state_code: '22',
      },
      {
        id: 2806,
        name: 'Matara District',
        state_code: '32',
      },
      {
        id: 2819,
        name: 'Monaragala District',
        state_code: '82',
      },
      {
        id: 2814,
        name: 'Mullaitivu District',
        state_code: '45',
      },
      {
        id: 2800,
        name: 'North Central Province',
        state_code: '7',
      },
      {
        id: 2817,
        name: 'North Western Province',
        state_code: '6',
      },
      {
        id: 2813,
        name: 'Northern Province',
        state_code: '4',
      },
      {
        id: 2794,
        name: 'Nuwara Eliya District',
        state_code: '23',
      },
      {
        id: 2812,
        name: 'Polonnaruwa District',
        state_code: '72',
      },
      {
        id: 2796,
        name: 'Puttalam District',
        state_code: '62',
      },
      {
        id: 2807,
        name: 'Ratnapura district',
        state_code: '91',
      },
      {
        id: 2803,
        name: 'Sabaragamuwa Province',
        state_code: '9',
      },
      {
        id: 2801,
        name: 'Southern Province',
        state_code: '3',
      },
      {
        id: 2795,
        name: 'Trincomalee District',
        state_code: '53',
      },
      {
        id: 2811,
        name: 'Uva Province',
        state_code: '8',
      },
      {
        id: 2809,
        name: 'Vavuniya District',
        state_code: '44',
      },
      {
        id: 2802,
        name: 'Western Province',
        state_code: '1',
      },
    ],
  },
  {
    name: 'Sudan',
    iso3: 'SDN',
    iso2: 'SD',
    phone_code: '249',
    capital: 'Khartoum',
    currency: 'SDG',
    states: [
      {
        id: 885,
        name: 'Al Jazirah',
        state_code: 'GZ',
      },
      {
        id: 886,
        name: 'Al Qadarif',
        state_code: 'GD',
      },
      {
        id: 887,
        name: 'Blue Nile',
        state_code: 'NB',
      },
      {
        id: 896,
        name: 'Central Darfur',
        state_code: 'DC',
      },
      {
        id: 892,
        name: 'East Darfur',
        state_code: 'DE',
      },
      {
        id: 884,
        name: 'Kassala',
        state_code: 'KA',
      },
      {
        id: 881,
        name: 'Khartoum',
        state_code: 'KH',
      },
      {
        id: 890,
        name: 'North Darfur',
        state_code: 'DN',
      },
      {
        id: 893,
        name: 'North Kordofan',
        state_code: 'KN',
      },
      {
        id: 895,
        name: 'Northern',
        state_code: 'NO',
      },
      {
        id: 880,
        name: 'Red Sea',
        state_code: 'RS',
      },
      {
        id: 891,
        name: 'River Nile',
        state_code: 'NR',
      },
      {
        id: 882,
        name: 'Sennar',
        state_code: 'SI',
      },
      {
        id: 894,
        name: 'South Darfur',
        state_code: 'DS',
      },
      {
        id: 883,
        name: 'South Kordofan',
        state_code: 'KS',
      },
      {
        id: 888,
        name: 'West Darfur',
        state_code: 'DW',
      },
      {
        id: 889,
        name: 'West Kordofan',
        state_code: 'GK',
      },
      {
        id: 879,
        name: 'White Nile',
        state_code: 'NW',
      },
    ],
  },
  {
    name: 'Suriname',
    iso3: 'SUR',
    iso2: 'SR',
    phone_code: '597',
    capital: 'Paramaribo',
    currency: 'SRD',
    states: [
      {
        id: 2846,
        name: 'Brokopondo District',
        state_code: 'BR',
      },
      {
        id: 2839,
        name: 'Commewijne District',
        state_code: 'CM',
      },
      {
        id: 2842,
        name: 'Coronie District',
        state_code: 'CR',
      },
      {
        id: 2845,
        name: 'Marowijne District',
        state_code: 'MA',
      },
      {
        id: 2840,
        name: 'Nickerie District',
        state_code: 'NI',
      },
      {
        id: 2841,
        name: 'Para District',
        state_code: 'PR',
      },
      {
        id: 2843,
        name: 'Paramaribo District',
        state_code: 'PM',
      },
      {
        id: 2848,
        name: 'Saramacca District',
        state_code: 'SA',
      },
      {
        id: 2847,
        name: 'Sipaliwini District',
        state_code: 'SI',
      },
      {
        id: 2844,
        name: 'Wanica District',
        state_code: 'WA',
      },
    ],
  },
  {
    name: 'Svalbard And Jan Mayen Islands',
    iso3: 'SJM',
    iso2: 'SJ',
    phone_code: '47',
    capital: 'Longyearbyen',
    currency: 'NOK',
    states: [],
  },
  {
    name: 'Swaziland',
    iso3: 'SWZ',
    iso2: 'SZ',
    phone_code: '268',
    capital: 'Mbabane',
    currency: 'SZL',
    states: [
      {
        id: 969,
        name: 'Hhohho District',
        state_code: 'HH',
      },
      {
        id: 970,
        name: 'Lubombo District',
        state_code: 'LU',
      },
      {
        id: 968,
        name: 'Manzini District',
        state_code: 'MA',
      },
      {
        id: 971,
        name: 'Shiselweni District',
        state_code: 'SH',
      },
    ],
  },
  {
    name: 'Sweden',
    iso3: 'SWE',
    iso2: 'SE',
    phone_code: '46',
    capital: 'Stockholm',
    currency: 'SEK',
    states: [
      {
        id: 1537,
        name: 'Blekinge',
        state_code: 'K',
      },
      {
        id: 1534,
        name: 'Dalarna County',
        state_code: 'W',
      },
      {
        id: 1533,
        name: 'Gävleborg County',
        state_code: 'X',
      },
      {
        id: 1546,
        name: 'Gotland County',
        state_code: 'I',
      },
      {
        id: 1548,
        name: 'Halland County',
        state_code: 'N',
      },
      {
        id: 1550,
        name: 'Jönköping County',
        state_code: 'F',
      },
      {
        id: 1544,
        name: 'Kalmar County',
        state_code: 'H',
      },
      {
        id: 1542,
        name: 'Kronoberg County',
        state_code: 'G',
      },
      {
        id: 1538,
        name: 'Norrbotten County',
        state_code: 'BD',
      },
      {
        id: 1539,
        name: 'Örebro County',
        state_code: 'T',
      },
      {
        id: 1536,
        name: 'Östergötland County',
        state_code: 'E',
      },
      {
        id: 1541,
        name: 'Skåne County',
        state_code: 'M',
      },
      {
        id: 1540,
        name: 'Södermanland County',
        state_code: 'D',
      },
      {
        id: 1551,
        name: 'Stockholm County',
        state_code: 'AB',
      },
      {
        id: 1545,
        name: 'Uppsala County',
        state_code: 'C',
      },
      {
        id: 1535,
        name: 'Värmland County',
        state_code: 'S',
      },
      {
        id: 1543,
        name: 'Västerbotten County',
        state_code: 'AC',
      },
      {
        id: 1552,
        name: 'Västernorrland County',
        state_code: 'Y',
      },
      {
        id: 1549,
        name: 'Västmanland County',
        state_code: 'U',
      },
      {
        id: 1547,
        name: 'Västra Götaland County',
        state_code: 'O',
      },
    ],
  },
  {
    name: 'Switzerland',
    iso3: 'CHE',
    iso2: 'CH',
    phone_code: '41',
    capital: 'Berne',
    currency: 'CHF',
    states: [
      {
        id: 1639,
        name: 'Aargau',
        state_code: 'AG',
      },
      {
        id: 1655,
        name: 'Appenzell Ausserrhoden',
        state_code: 'AR',
      },
      {
        id: 1649,
        name: 'Appenzell Innerrhoden',
        state_code: 'AI',
      },
      {
        id: 1641,
        name: 'Basel-Landschaft',
        state_code: 'BL',
      },
      {
        id: 1645,
        name: 'canton of Bern',
        state_code: 'BE',
      },
      {
        id: 1640,
        name: 'Canton of Fribourg',
        state_code: 'FR',
      },
      {
        id: 1647,
        name: 'Canton of Geneva',
        state_code: 'GE',
      },
      {
        id: 1658,
        name: 'Canton of Jura',
        state_code: 'JU',
      },
      {
        id: 1663,
        name: 'Canton of Lucerne',
        state_code: 'LU',
      },
      {
        id: 1659,
        name: 'Canton of Neuchâtel',
        state_code: 'NE',
      },
      {
        id: 1654,
        name: 'Canton of Schaffhausen',
        state_code: 'SH',
      },
      {
        id: 1662,
        name: 'Canton of Solothurn',
        state_code: 'SO',
      },
      {
        id: 1644,
        name: 'Canton of St. Gallen',
        state_code: 'SG',
      },
      {
        id: 1648,
        name: 'Canton of Valais',
        state_code: 'VS',
      },
      {
        id: 1651,
        name: 'Canton of Vaud',
        state_code: 'VD',
      },
      {
        id: 1646,
        name: 'Canton of Zug',
        state_code: 'ZG',
      },
      {
        id: 1656,
        name: 'canton of Zürich',
        state_code: 'ZH',
      },
      {
        id: 1661,
        name: 'Glarus',
        state_code: 'GL',
      },
      {
        id: 1660,
        name: 'Graubünden',
        state_code: 'GR',
      },
      {
        id: 1652,
        name: 'Nidwalden',
        state_code: 'NW',
      },
      {
        id: 1650,
        name: 'Obwalden',
        state_code: 'OW',
      },
      {
        id: 1653,
        name: 'Schwyz',
        state_code: 'SZ',
      },
      {
        id: 1657,
        name: 'Thurgau',
        state_code: 'TG',
      },
      {
        id: 1643,
        name: 'Ticino',
        state_code: 'TI',
      },
      {
        id: 1642,
        name: 'Uri',
        state_code: 'UR',
      },
    ],
  },
  {
    name: 'Syria',
    iso3: 'SYR',
    iso2: 'SY',
    phone_code: '963',
    capital: 'Damascus',
    currency: 'SYP',
    states: [
      {
        id: 2941,
        name: 'Al-Hasakah Governorate',
        state_code: 'HA',
      },
      {
        id: 2944,
        name: 'Al-Raqqah Governorate',
        state_code: 'RA',
      },
      {
        id: 2946,
        name: 'Aleppo Governorate',
        state_code: 'HL',
      },
      {
        id: 2936,
        name: 'As-Suwayda Governorate',
        state_code: 'SU',
      },
      {
        id: 2939,
        name: 'Damascus Governorate',
        state_code: 'DI',
      },
      {
        id: 2945,
        name: 'Daraa Governorate',
        state_code: 'DR',
      },
      {
        id: 2937,
        name: 'Deir ez-Zor Governorate',
        state_code: 'DY',
      },
      {
        id: 2934,
        name: 'Hama Governorate',
        state_code: 'HM',
      },
      {
        id: 2942,
        name: 'Homs Governorate',
        state_code: 'HI',
      },
      {
        id: 2940,
        name: 'Idlib Governorate',
        state_code: 'ID',
      },
      {
        id: 2938,
        name: 'Latakia Governorate',
        state_code: 'LA',
      },
      {
        id: 2943,
        name: 'Quneitra Governorate',
        state_code: 'QU',
      },
      {
        id: 2935,
        name: 'Rif Dimashq Governorate',
        state_code: 'RD',
      },
      {
        id: 2947,
        name: 'Tartus Governorate',
        state_code: 'TA',
      },
    ],
  },
  {
    name: 'Taiwan',
    iso3: 'TWN',
    iso2: 'TW',
    phone_code: '886',
    capital: 'Taipei',
    currency: 'TWD',
    states: [
      {
        id: 3404,
        name: 'Changhua County',
        state_code: 'CHA',
      },
      {
        id: 3418,
        name: 'Chiayi City',
        state_code: 'CYQ',
      },
      {
        id: 3408,
        name: 'Chiayi County',
        state_code: 'CYI',
      },
      {
        id: 3417,
        name: 'Hsinchu',
        state_code: 'HSZ',
      },
      {
        id: 3423,
        name: 'Hsinchu County',
        state_code: 'HSQ',
      },
      {
        id: 3411,
        name: 'Hualien County',
        state_code: 'HUA',
      },
      {
        id: 3412,
        name: 'Kaohsiung',
        state_code: 'KHH',
      },
      {
        id: 3409,
        name: 'Kaohsiung County',
        state_code: 'KHQ',
      },
      {
        id: 3415,
        name: 'Kinmen',
        state_code: 'KIN',
      },
      {
        id: 3420,
        name: 'Lienchiang County',
        state_code: 'LIE',
      },
      {
        id: 3413,
        name: 'Miaoli County',
        state_code: 'MIA',
      },
      {
        id: 3407,
        name: 'Nantou County',
        state_code: 'NAN',
      },
      {
        id: 3403,
        name: 'Penghu County',
        state_code: 'PEN',
      },
      {
        id: 3405,
        name: 'Pingtung County',
        state_code: 'PIF',
      },
      {
        id: 3406,
        name: 'Taichung',
        state_code: 'TXG',
      },
      {
        id: 3414,
        name: 'Taichung County',
        state_code: 'TXQ',
      },
      {
        id: 3421,
        name: 'Tainan',
        state_code: 'TNN',
      },
      {
        id: 3401,
        name: 'Tainan County',
        state_code: 'TNQ',
      },
      {
        id: 3422,
        name: 'Taipei',
        state_code: 'TPE',
      },
      {
        id: 3410,
        name: 'Taitung County',
        state_code: 'TTT',
      },
      {
        id: 3419,
        name: 'Taoyuan City',
        state_code: 'TAO',
      },
      {
        id: 3402,
        name: 'Yilan County',
        state_code: 'ILA',
      },
      {
        id: 3416,
        name: 'Yunlin County',
        state_code: 'YUN',
      },
    ],
  },
  {
    name: 'Tajikistan',
    iso3: 'TJK',
    iso2: 'TJ',
    phone_code: '992',
    capital: 'Dushanbe',
    currency: 'TJS',
    states: [
      {
        id: 3397,
        name: 'districts of Republican Subordination',
        state_code: 'RA',
      },
      {
        id: 3399,
        name: 'Gorno-Badakhshan Autonomous Province',
        state_code: 'GB',
      },
      {
        id: 3398,
        name: 'Khatlon Province',
        state_code: 'KT',
      },
      {
        id: 3400,
        name: 'Sughd Province',
        state_code: 'SU',
      },
    ],
  },
  {
    name: 'Tanzania',
    iso3: 'TZA',
    iso2: 'TZ',
    phone_code: '255',
    capital: 'Dodoma',
    currency: 'TZS',
    states: [
      {
        id: 1491,
        name: 'Arusha Region',
        state_code: '01',
      },
      {
        id: 1490,
        name: 'Dar es Salaam Region',
        state_code: '02',
      },
      {
        id: 1466,
        name: 'Dodoma Region',
        state_code: '03',
      },
      {
        id: 1481,
        name: 'Geita Region',
        state_code: '27',
      },
      {
        id: 1489,
        name: 'Iringa Region',
        state_code: '04',
      },
      {
        id: 1465,
        name: 'Kagera Region',
        state_code: '05',
      },
      {
        id: 1482,
        name: 'Katavi Region',
        state_code: '28',
      },
      {
        id: 1478,
        name: 'Kigoma Region',
        state_code: '08',
      },
      {
        id: 1467,
        name: 'Kilimanjaro Region',
        state_code: '09',
      },
      {
        id: 1483,
        name: 'Lindi Region',
        state_code: '12',
      },
      {
        id: 1484,
        name: 'Manyara Region',
        state_code: '26',
      },
      {
        id: 1468,
        name: 'Mara Region',
        state_code: '13',
      },
      {
        id: 1470,
        name: 'Morogoro Region',
        state_code: '16',
      },
      {
        id: 1476,
        name: 'Mtwara Region',
        state_code: '17',
      },
      {
        id: 1479,
        name: 'Mwanza Region',
        state_code: '18',
      },
      {
        id: 1480,
        name: 'Njombe Region',
        state_code: '29',
      },
      {
        id: 1488,
        name: 'North Pemba Region',
        state_code: '06',
      },
      {
        id: 1485,
        name: 'Pwani Region',
        state_code: '19',
      },
      {
        id: 1477,
        name: 'Rukwa Region',
        state_code: '20',
      },
      {
        id: 1486,
        name: 'Ruvuma Region',
        state_code: '21',
      },
      {
        id: 1463,
        name: 'Shinyanga Region',
        state_code: '22',
      },
      {
        id: 1464,
        name: 'Simiyu Region',
        state_code: '30',
      },
      {
        id: 1474,
        name: 'Singida Region',
        state_code: '23',
      },
      {
        id: 1472,
        name: 'South Pemba Region',
        state_code: '10',
      },
      {
        id: 1469,
        name: 'Tabora Region',
        state_code: '24',
      },
      {
        id: 1487,
        name: 'Tanga Region',
        state_code: '25',
      },
      {
        id: 1471,
        name: 'Zanzibar Central/South Region',
        state_code: '11',
      },
      {
        id: 1473,
        name: 'Zanzibar North Region',
        state_code: '07',
      },
      {
        id: 1475,
        name: 'Zanzibar Urban/West Region',
        state_code: '15',
      },
    ],
  },
  {
    name: 'Thailand',
    iso3: 'THA',
    iso2: 'TH',
    phone_code: '66',
    capital: 'Bangkok',
    currency: 'THB',
    states: [
      {
        id: 3523,
        name: 'Amnat Charoen',
        state_code: '37',
      },
      {
        id: 3519,
        name: 'Ang Thong',
        state_code: '15',
      },
      {
        id: 3554,
        name: 'Bangkok',
        state_code: '10',
      },
      {
        id: 3533,
        name: 'Bueng Kan',
        state_code: '38',
      },
      {
        id: 3534,
        name: 'Buri Ram',
        state_code: '31',
      },
      {
        id: 3552,
        name: 'Chachoengsao',
        state_code: '24',
      },
      {
        id: 3522,
        name: 'Chai Nat',
        state_code: '18',
      },
      {
        id: 3486,
        name: 'Chanthaburi',
        state_code: '22',
      },
      {
        id: 3491,
        name: 'Chiang Mai',
        state_code: '50',
      },
      {
        id: 3498,
        name: 'Chiang Rai',
        state_code: '57',
      },
      {
        id: 3513,
        name: 'Chon Buri',
        state_code: '20',
      },
      {
        id: 3526,
        name: 'Chumphon',
        state_code: '86',
      },
      {
        id: 3550,
        name: 'Kalasin',
        state_code: '46',
      },
      {
        id: 3516,
        name: 'Kamphaeng Phet',
        state_code: '62',
      },
      {
        id: 3511,
        name: 'Kanchanaburi',
        state_code: '71',
      },
      {
        id: 3485,
        name: 'Khon Kaen',
        state_code: '40',
      },
      {
        id: 3478,
        name: 'Krabi',
        state_code: '81',
      },
      {
        id: 3544,
        name: 'Lampang',
        state_code: '52',
      },
      {
        id: 3483,
        name: 'Lamphun',
        state_code: '51',
      },
      {
        id: 3509,
        name: 'Loei',
        state_code: '42',
      },
      {
        id: 3543,
        name: 'Lopburi',
        state_code: '16',
      },
      {
        id: 3505,
        name: 'Mae Hong Son',
        state_code: '58',
      },
      {
        id: 3517,
        name: 'Maha Sarakham',
        state_code: '44',
      },
      {
        id: 3546,
        name: 'Mukdahan',
        state_code: '49',
      },
      {
        id: 3535,
        name: 'Nakhon Nayok',
        state_code: '26',
      },
      {
        id: 3503,
        name: 'Nakhon Pathom',
        state_code: '73',
      },
      {
        id: 3548,
        name: 'Nakhon Phanom',
        state_code: '48',
      },
      {
        id: 3497,
        name: 'Nakhon Ratchasima',
        state_code: '30',
      },
      {
        id: 3492,
        name: 'Nakhon Sawan',
        state_code: '60',
      },
      {
        id: 3520,
        name: 'Nakhon Si Thammarat',
        state_code: '80',
      },
      {
        id: 3530,
        name: 'Nan',
        state_code: '55',
      },
      {
        id: 3553,
        name: 'Narathiwat',
        state_code: '96',
      },
      {
        id: 3480,
        name: 'Nong Bua Lam Phu',
        state_code: '39',
      },
      {
        id: 3484,
        name: 'Nong Khai',
        state_code: '43',
      },
      {
        id: 3495,
        name: 'Nonthaburi',
        state_code: '12',
      },
      {
        id: 3500,
        name: 'Pathum Thani',
        state_code: '13',
      },
      {
        id: 3540,
        name: 'Pattani',
        state_code: '94',
      },
      {
        id: 3507,
        name: 'Pattaya',
        state_code: 'S',
      },
      {
        id: 3549,
        name: 'Phang Nga',
        state_code: '82',
      },
      {
        id: 3488,
        name: 'Phatthalung',
        state_code: '93',
      },
      {
        id: 3538,
        name: 'Phayao',
        state_code: '56',
      },
      {
        id: 3515,
        name: 'Phetchabun',
        state_code: '67',
      },
      {
        id: 3532,
        name: 'Phetchaburi',
        state_code: '76',
      },
      {
        id: 3514,
        name: 'Phichit',
        state_code: '66',
      },
      {
        id: 3506,
        name: 'Phitsanulok',
        state_code: '65',
      },
      {
        id: 3494,
        name: 'Phra Nakhon Si Ayutthaya',
        state_code: '14',
      },
      {
        id: 3528,
        name: 'Phrae',
        state_code: '54',
      },
      {
        id: 3536,
        name: 'Phuket',
        state_code: '83',
      },
      {
        id: 3542,
        name: 'Prachin Buri',
        state_code: '25',
      },
      {
        id: 3508,
        name: 'Prachuap Khiri Khan',
        state_code: '77',
      },
      {
        id: 3479,
        name: 'Ranong',
        state_code: '85',
      },
      {
        id: 3499,
        name: 'Ratchaburi',
        state_code: '70',
      },
      {
        id: 3518,
        name: 'Rayong',
        state_code: '21',
      },
      {
        id: 3510,
        name: 'Roi Et',
        state_code: '45',
      },
      {
        id: 3529,
        name: 'Sa Kaeo',
        state_code: '27',
      },
      {
        id: 3501,
        name: 'Sakon Nakhon',
        state_code: '47',
      },
      {
        id: 3481,
        name: 'Samut Prakan',
        state_code: '11',
      },
      {
        id: 3504,
        name: 'Samut Sakhon',
        state_code: '74',
      },
      {
        id: 3502,
        name: 'Samut Songkhram',
        state_code: '75',
      },
      {
        id: 3487,
        name: 'Saraburi',
        state_code: '19',
      },
      {
        id: 3537,
        name: 'Satun',
        state_code: '91',
      },
      {
        id: 3547,
        name: 'Si Sa Ket',
        state_code: '33',
      },
      {
        id: 3490,
        name: 'Sing Buri',
        state_code: '17',
      },
      {
        id: 3539,
        name: 'Songkhla',
        state_code: '90',
      },
      {
        id: 3545,
        name: 'Sukhothai',
        state_code: '64',
      },
      {
        id: 3524,
        name: 'Suphanburi',
        state_code: '72',
      },
      {
        id: 3482,
        name: 'Surat Thani',
        state_code: '84',
      },
      {
        id: 3531,
        name: 'Surin',
        state_code: '32',
      },
      {
        id: 3525,
        name: 'Tak',
        state_code: '63',
      },
      {
        id: 3541,
        name: 'Trang',
        state_code: '92',
      },
      {
        id: 3496,
        name: 'Trat',
        state_code: '23',
      },
      {
        id: 3512,
        name: 'Ubon Ratchathani',
        state_code: '34',
      },
      {
        id: 3527,
        name: 'Udon Thani',
        state_code: '41',
      },
      {
        id: 3551,
        name: 'Uthai Thani',
        state_code: '61',
      },
      {
        id: 3489,
        name: 'Uttaradit',
        state_code: '53',
      },
      {
        id: 3493,
        name: 'Yala',
        state_code: '95',
      },
      {
        id: 3521,
        name: 'Yasothon',
        state_code: '35',
      },
    ],
  },
  {
    name: 'Togo',
    iso3: 'TGO',
    iso2: 'TG',
    phone_code: '228',
    capital: 'Lome',
    currency: 'XOF',
    states: [
      {
        id: 2575,
        name: 'Centrale Region',
        state_code: 'C',
      },
      {
        id: 2579,
        name: 'Kara Region',
        state_code: 'K',
      },
      {
        id: 2576,
        name: 'Maritime',
        state_code: 'M',
      },
      {
        id: 2577,
        name: 'Plateaux Region',
        state_code: 'P',
      },
      {
        id: 2578,
        name: 'Savanes Region',
        state_code: 'S',
      },
    ],
  },
  {
    name: 'Tokelau',
    iso3: 'TKL',
    iso2: 'TK',
    phone_code: '690',
    capital: '',
    currency: 'NZD',
    states: [],
  },
  {
    name: 'Tonga',
    iso3: 'TON',
    iso2: 'TO',
    phone_code: '676',
    capital: "Nuku'alofa",
    currency: 'TOP',
    states: [
      {
        id: 3913,
        name: 'Haʻapai',
        state_code: '02',
      },
      {
        id: 3915,
        name: 'ʻEua',
        state_code: '01',
      },
      {
        id: 3914,
        name: 'Niuas',
        state_code: '03',
      },
      {
        id: 3912,
        name: 'Tongatapu',
        state_code: '04',
      },
      {
        id: 3911,
        name: 'Vavaʻu',
        state_code: '05',
      },
    ],
  },
  {
    name: 'Trinidad And Tobago',
    iso3: 'TTO',
    iso2: 'TT',
    phone_code: '+1-868',
    capital: 'Port of Spain',
    currency: 'TTD',
    states: [
      {
        id: 3362,
        name: 'Arima',
        state_code: 'ARI',
      },
      {
        id: 3366,
        name: 'Chaguanas',
        state_code: 'CHA',
      },
      {
        id: 3354,
        name: 'Couva-Tabaquite-Talparo Regional Corporation',
        state_code: 'CTT',
      },
      {
        id: 3367,
        name: 'Diego Martin Regional Corporation',
        state_code: 'DMN',
      },
      {
        id: 3355,
        name: 'Eastern Tobago',
        state_code: 'ETO',
      },
      {
        id: 3365,
        name: 'Penal-Debe Regional Corporation',
        state_code: 'PED',
      },
      {
        id: 3360,
        name: 'Point Fortin',
        state_code: 'PTF',
      },
      {
        id: 3363,
        name: 'Port of Spain',
        state_code: 'POS',
      },
      {
        id: 3368,
        name: 'Princes Town Regional Corporation',
        state_code: 'PRT',
      },
      {
        id: 3356,
        name: 'Rio Claro-Mayaro Regional Corporation',
        state_code: 'MRC',
      },
      {
        id: 3359,
        name: 'San Fernando',
        state_code: 'SFO',
      },
      {
        id: 3357,
        name: 'San Juan-Laventille Regional Corporation',
        state_code: 'SJL',
      },
      {
        id: 3361,
        name: 'Sangre Grande Regional Corporation',
        state_code: 'SGE',
      },
      {
        id: 3364,
        name: 'Siparia Regional Corporation',
        state_code: 'SIP',
      },
      {
        id: 3358,
        name: 'Tunapuna-Piarco Regional Corporation',
        state_code: 'TUP',
      },
      {
        id: 3353,
        name: 'Western Tobago',
        state_code: 'WTO',
      },
    ],
  },
  {
    name: 'Tunisia',
    iso3: 'TUN',
    iso2: 'TN',
    phone_code: '216',
    capital: 'Tunis',
    currency: 'TND',
    states: [
      {
        id: 2550,
        name: 'Ariana Governorate',
        state_code: '12',
      },
      {
        id: 2566,
        name: 'Ben Arous Governorate',
        state_code: '13',
      },
      {
        id: 2551,
        name: 'Bizerte Governorate',
        state_code: '23',
      },
      {
        id: 2558,
        name: 'Gabès Governorate',
        state_code: '81',
      },
      {
        id: 2556,
        name: 'Gafsa Governorate',
        state_code: '71',
      },
      {
        id: 2552,
        name: 'Jendouba Governorate',
        state_code: '32',
      },
      {
        id: 2564,
        name: 'Kairouan Governorate',
        state_code: '41',
      },
      {
        id: 2570,
        name: 'Kasserine Governorate',
        state_code: '42',
      },
      {
        id: 2572,
        name: 'Kassrine',
        state_code: '31',
      },
      {
        id: 2562,
        name: 'Kebili Governorate',
        state_code: '73',
      },
      {
        id: 2561,
        name: 'Kef Governorate',
        state_code: '33',
      },
      {
        id: 2568,
        name: 'Mahdia Governorate',
        state_code: '53',
      },
      {
        id: 2555,
        name: 'Manouba Governorate',
        state_code: '14',
      },
      {
        id: 2560,
        name: 'Medenine Governorate',
        state_code: '82',
      },
      {
        id: 2553,
        name: 'Monastir Governorate',
        state_code: '52',
      },
      {
        id: 2557,
        name: 'Sfax Governorate',
        state_code: '61',
      },
      {
        id: 2567,
        name: 'Sidi Bouzid Governorate',
        state_code: '43',
      },
      {
        id: 2563,
        name: 'Siliana Governorate',
        state_code: '34',
      },
      {
        id: 2571,
        name: 'Sousse Governorate',
        state_code: '51',
      },
      {
        id: 2559,
        name: 'Tataouine Governorate',
        state_code: '83',
      },
      {
        id: 2569,
        name: 'Tozeur Governorate',
        state_code: '72',
      },
      {
        id: 2554,
        name: 'Tunis Governorate',
        state_code: '11',
      },
      {
        id: 2565,
        name: 'Zaghouan Governorate',
        state_code: '22',
      },
    ],
  },
  {
    name: 'Turkey',
    iso3: 'TUR',
    iso2: 'TR',
    phone_code: '90',
    capital: 'Ankara',
    currency: 'TRY',
    states: [
      {
        id: 2212,
        name: 'Adana Province',
        state_code: '01',
      },
      {
        id: 2155,
        name: 'Adıyaman Province',
        state_code: '02',
      },
      {
        id: 2179,
        name: 'Afyonkarahisar Province',
        state_code: '03',
      },
      {
        id: 2193,
        name: 'Ağrı Province',
        state_code: '04',
      },
      {
        id: 2210,
        name: 'Aksaray Province',
        state_code: '68',
      },
      {
        id: 2161,
        name: 'Amasya Province',
        state_code: '05',
      },
      {
        id: 2217,
        name: 'Ankara Province',
        state_code: '06',
      },
      {
        id: 2169,
        name: 'Antalya Province',
        state_code: '07',
      },
      {
        id: 2185,
        name: 'Ardahan Province',
        state_code: '75',
      },
      {
        id: 2191,
        name: 'Artvin Province',
        state_code: '08',
      },
      {
        id: 2187,
        name: 'Aydın Province',
        state_code: '09',
      },
      {
        id: 2175,
        name: 'Balıkesir Province',
        state_code: '10',
      },
      {
        id: 2148,
        name: 'Bartın Province',
        state_code: '74',
      },
      {
        id: 2194,
        name: 'Batman Province',
        state_code: '72',
      },
      {
        id: 2177,
        name: 'Bayburt Province',
        state_code: '69',
      },
      {
        id: 2221,
        name: 'Bilecik Province',
        state_code: '11',
      },
      {
        id: 2153,
        name: 'Bingöl Province',
        state_code: '12',
      },
      {
        id: 2215,
        name: 'Bitlis Province',
        state_code: '13',
      },
      {
        id: 2172,
        name: 'Bolu Province',
        state_code: '14',
      },
      {
        id: 2209,
        name: 'Burdur Province',
        state_code: '15',
      },
      {
        id: 2163,
        name: 'Bursa Province',
        state_code: '16',
      },
      {
        id: 2216,
        name: 'Çanakkale Province',
        state_code: '17',
      },
      {
        id: 2168,
        name: 'Çankırı Province',
        state_code: '18',
      },
      {
        id: 2173,
        name: 'Çorum Province',
        state_code: '19',
      },
      {
        id: 2157,
        name: 'Denizli Province',
        state_code: '20',
      },
      {
        id: 2226,
        name: 'Diyarbakır Province',
        state_code: '21',
      },
      {
        id: 2202,
        name: 'Düzce Province',
        state_code: '81',
      },
      {
        id: 2151,
        name: 'Edirne Province',
        state_code: '22',
      },
      {
        id: 2159,
        name: 'Elazığ Province',
        state_code: '23',
      },
      {
        id: 2160,
        name: 'Erzincan Province',
        state_code: '24',
      },
      {
        id: 2165,
        name: 'Erzurum Province',
        state_code: '25',
      },
      {
        id: 2164,
        name: 'Eskişehir Province',
        state_code: '26',
      },
      {
        id: 2203,
        name: 'Gaziantep Province',
        state_code: '27',
      },
      {
        id: 2186,
        name: 'Giresun Province',
        state_code: '28',
      },
      {
        id: 2204,
        name: 'Gümüşhane Province',
        state_code: '29',
      },
      {
        id: 2190,
        name: 'Hakkâri Province',
        state_code: '30',
      },
      {
        id: 2211,
        name: 'Hatay Province',
        state_code: '31',
      },
      {
        id: 2166,
        name: 'Iğdır Province',
        state_code: '76',
      },
      {
        id: 2222,
        name: 'Isparta Province',
        state_code: '32',
      },
      {
        id: 2170,
        name: 'Istanbul Province',
        state_code: '34',
      },
      {
        id: 2205,
        name: 'İzmir Province',
        state_code: '35',
      },
      {
        id: 2227,
        name: 'Kahramanmaraş Province',
        state_code: '46',
      },
      {
        id: 2223,
        name: 'Karabük Province',
        state_code: '78',
      },
      {
        id: 2184,
        name: 'Karaman Province',
        state_code: '70',
      },
      {
        id: 2208,
        name: 'Kars Province',
        state_code: '36',
      },
      {
        id: 2197,
        name: 'Kastamonu Province',
        state_code: '37',
      },
      {
        id: 2200,
        name: 'Kayseri Province',
        state_code: '38',
      },
      {
        id: 2154,
        name: 'Kilis Province',
        state_code: '79',
      },
      {
        id: 2178,
        name: 'Kırıkkale Province',
        state_code: '71',
      },
      {
        id: 2176,
        name: 'Kırklareli Province',
        state_code: '39',
      },
      {
        id: 2180,
        name: 'Kırşehir Province',
        state_code: '40',
      },
      {
        id: 2195,
        name: 'Kocaeli Province',
        state_code: '41',
      },
      {
        id: 2171,
        name: 'Konya Province',
        state_code: '42',
      },
      {
        id: 2149,
        name: 'Kütahya Province',
        state_code: '43',
      },
      {
        id: 2158,
        name: 'Malatya Province',
        state_code: '44',
      },
      {
        id: 2198,
        name: 'Manisa Province',
        state_code: '45',
      },
      {
        id: 2224,
        name: 'Mardin Province',
        state_code: '47',
      },
      {
        id: 2156,
        name: 'Mersin Province',
        state_code: '33',
      },
      {
        id: 2182,
        name: 'Muğla Province',
        state_code: '48',
      },
      {
        id: 2162,
        name: 'Muş Province',
        state_code: '49',
      },
      {
        id: 2196,
        name: 'Nevşehir Province',
        state_code: '50',
      },
      {
        id: 2189,
        name: 'Niğde Province',
        state_code: '51',
      },
      {
        id: 2174,
        name: 'Ordu Province',
        state_code: '52',
      },
      {
        id: 2214,
        name: 'Osmaniye Province',
        state_code: '80',
      },
      {
        id: 2219,
        name: 'Rize Province',
        state_code: '53',
      },
      {
        id: 2150,
        name: 'Sakarya Province',
        state_code: '54',
      },
      {
        id: 2220,
        name: 'Samsun Province',
        state_code: '55',
      },
      {
        id: 2183,
        name: 'Şanlıurfa Province',
        state_code: '63',
      },
      {
        id: 2207,
        name: 'Siirt Province',
        state_code: '56',
      },
      {
        id: 2181,
        name: 'Sivas Province',
        state_code: '58',
      },
      {
        id: 2225,
        name: 'Şırnak Province',
        state_code: '73',
      },
      {
        id: 2167,
        name: 'Tekirdağ Province',
        state_code: '59',
      },
      {
        id: 2199,
        name: 'Tokat Province',
        state_code: '60',
      },
      {
        id: 2206,
        name: 'Trabzon Province',
        state_code: '61',
      },
      {
        id: 2192,
        name: 'Tunceli Province',
        state_code: '62',
      },
      {
        id: 2201,
        name: 'Uşak Province',
        state_code: '64',
      },
      {
        id: 2152,
        name: 'Van Province',
        state_code: '65',
      },
      {
        id: 2218,
        name: 'Yalova Province',
        state_code: '77',
      },
      {
        id: 2188,
        name: 'Yozgat Province',
        state_code: '66',
      },
      {
        id: 2213,
        name: 'Zonguldak Province',
        state_code: '67',
      },
    ],
  },
  {
    name: 'Turkmenistan',
    iso3: 'TKM',
    iso2: 'TM',
    phone_code: '993',
    capital: 'Ashgabat',
    currency: 'TMT',
    states: [
      {
        id: 3374,
        name: 'Ahal Region',
        state_code: 'A',
      },
      {
        id: 3371,
        name: 'Ashgabat',
        state_code: 'S',
      },
      {
        id: 3372,
        name: 'Balkan Region',
        state_code: 'B',
      },
      {
        id: 3373,
        name: 'Daşoguz Region',
        state_code: 'D',
      },
      {
        id: 3370,
        name: 'Lebap Region',
        state_code: 'L',
      },
      {
        id: 3369,
        name: 'Mary Region',
        state_code: 'M',
      },
    ],
  },
  {
    name: 'Turks And Caicos Islands',
    iso3: 'TCA',
    iso2: 'TC',
    phone_code: '+1-649',
    capital: 'Cockburn Town',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Tuvalu',
    iso3: 'TUV',
    iso2: 'TV',
    phone_code: '688',
    capital: 'Funafuti',
    currency: 'AUD',
    states: [
      {
        id: 3951,
        name: 'Funafuti',
        state_code: 'FUN',
      },
      {
        id: 3947,
        name: 'Nanumanga',
        state_code: 'NMG',
      },
      {
        id: 3949,
        name: 'Nanumea',
        state_code: 'NMA',
      },
      {
        id: 3946,
        name: 'Niutao Island Council',
        state_code: 'NIT',
      },
      {
        id: 3948,
        name: 'Nui',
        state_code: 'NUI',
      },
      {
        id: 3952,
        name: 'Nukufetau',
        state_code: 'NKF',
      },
      {
        id: 3953,
        name: 'Nukulaelae',
        state_code: 'NKL',
      },
      {
        id: 3950,
        name: 'Vaitupu',
        state_code: 'VAI',
      },
    ],
  },
  {
    name: 'Uganda',
    iso3: 'UGA',
    iso2: 'UG',
    phone_code: '256',
    capital: 'Kampala',
    currency: 'UGX',
    states: [
      {
        id: 329,
        name: 'Abim District',
        state_code: '314',
      },
      {
        id: 361,
        name: 'Adjumani District',
        state_code: '301',
      },
      {
        id: 392,
        name: 'Agago District',
        state_code: '322',
      },
      {
        id: 344,
        name: 'Alebtong District',
        state_code: '323',
      },
      {
        id: 416,
        name: 'Amolatar District',
        state_code: '315',
      },
      {
        id: 353,
        name: 'Amudat District',
        state_code: '324',
      },
      {
        id: 352,
        name: 'Amuria District',
        state_code: '216',
      },
      {
        id: 335,
        name: 'Amuru District',
        state_code: '316',
      },
      {
        id: 328,
        name: 'Apac District',
        state_code: '302',
      },
      {
        id: 447,
        name: 'Arua District',
        state_code: '303',
      },
      {
        id: 441,
        name: 'Budaka District',
        state_code: '217',
      },
      {
        id: 349,
        name: 'Bududa District',
        state_code: '218',
      },
      {
        id: 387,
        name: 'Bugiri District',
        state_code: '201',
      },
      {
        id: 391,
        name: 'Buhweju District',
        state_code: '420',
      },
      {
        id: 377,
        name: 'Buikwe District',
        state_code: '117',
      },
      {
        id: 343,
        name: 'Bukedea District',
        state_code: '219',
      },
      {
        id: 375,
        name: 'Bukomansimbi District',
        state_code: '118',
      },
      {
        id: 385,
        name: 'Bukwo District',
        state_code: '220',
      },
      {
        id: 428,
        name: 'Bulambuli District',
        state_code: '225',
      },
      {
        id: 389,
        name: 'Buliisa District',
        state_code: '416',
      },
      {
        id: 419,
        name: 'Bundibugyo District',
        state_code: '401',
      },
      {
        id: 381,
        name: 'Bunyangabu District',
        state_code: '430',
      },
      {
        id: 386,
        name: 'Bushenyi District',
        state_code: '402',
      },
      {
        id: 431,
        name: 'Busia District',
        state_code: '202',
      },
      {
        id: 365,
        name: 'Butaleja District',
        state_code: '221',
      },
      {
        id: 384,
        name: 'Butambala District',
        state_code: '119',
      },
      {
        id: 388,
        name: 'Butebo District',
        state_code: '233',
      },
      {
        id: 414,
        name: 'Buvuma District',
        state_code: '120',
      },
      {
        id: 380,
        name: 'Buyende District',
        state_code: '226',
      },
      {
        id: 396,
        name: 'Central Region',
        state_code: 'C',
      },
      {
        id: 341,
        name: 'Dokolo District',
        state_code: '317',
      },
      {
        id: 372,
        name: 'Eastern Region',
        state_code: 'E',
      },
      {
        id: 366,
        name: 'Gomba District',
        state_code: '121',
      },
      {
        id: 413,
        name: 'Gulu District',
        state_code: '304',
      },
      {
        id: 339,
        name: 'Ibanda District',
        state_code: '417',
      },
      {
        id: 340,
        name: 'Iganga District',
        state_code: '203',
      },
      {
        id: 383,
        name: 'Isingiro District',
        state_code: '418',
      },
      {
        id: 367,
        name: 'Jinja District',
        state_code: '204',
      },
      {
        id: 434,
        name: 'Kaabong District',
        state_code: '318',
      },
      {
        id: 426,
        name: 'Kabale District',
        state_code: '404',
      },
      {
        id: 326,
        name: 'Kabarole District',
        state_code: '405',
      },
      {
        id: 336,
        name: 'Kaberamaido District',
        state_code: '213',
      },
      {
        id: 403,
        name: 'Kagadi District',
        state_code: '427',
      },
      {
        id: 399,
        name: 'Kakumiro District',
        state_code: '428',
      },
      {
        id: 405,
        name: 'Kalangala District',
        state_code: '101',
      },
      {
        id: 398,
        name: 'Kaliro District',
        state_code: '222',
      },
      {
        id: 394,
        name: 'Kalungu District',
        state_code: '122',
      },
      {
        id: 382,
        name: 'Kampala District',
        state_code: '102',
      },
      {
        id: 334,
        name: 'Kamuli District',
        state_code: '205',
      },
      {
        id: 360,
        name: 'Kamwenge District',
        state_code: '413',
      },
      {
        id: 373,
        name: 'Kanungu District',
        state_code: '414',
      },
      {
        id: 432,
        name: 'Kapchorwa District',
        state_code: '206',
      },
      {
        id: 440,
        name: 'Kasese District',
        state_code: '406',
      },
      {
        id: 420,
        name: 'Katakwi District',
        state_code: '207',
      },
      {
        id: 368,
        name: 'Kayunga District',
        state_code: '112',
      },
      {
        id: 436,
        name: 'Kibaale District',
        state_code: '407',
      },
      {
        id: 347,
        name: 'Kiboga District',
        state_code: '103',
      },
      {
        id: 338,
        name: 'Kibuku District',
        state_code: '227',
      },
      {
        id: 355,
        name: 'Kiruhura District',
        state_code: '419',
      },
      {
        id: 346,
        name: 'Kiryandongo District',
        state_code: '421',
      },
      {
        id: 409,
        name: 'Kisoro District',
        state_code: '408',
      },
      {
        id: 348,
        name: 'Kitgum District',
        state_code: '305',
      },
      {
        id: 345,
        name: 'Koboko District',
        state_code: '319',
      },
      {
        id: 401,
        name: 'Kole District',
        state_code: '325',
      },
      {
        id: 443,
        name: 'Kotido District',
        state_code: '306',
      },
      {
        id: 425,
        name: 'Kumi District',
        state_code: '208',
      },
      {
        id: 369,
        name: 'Kween District',
        state_code: '228',
      },
      {
        id: 325,
        name: 'Kyankwanzi District',
        state_code: '123',
      },
      {
        id: 437,
        name: 'Kyegegwa District',
        state_code: '422',
      },
      {
        id: 402,
        name: 'Kyenjojo District',
        state_code: '415',
      },
      {
        id: 448,
        name: 'Kyotera District',
        state_code: '125',
      },
      {
        id: 411,
        name: 'Lamwo District',
        state_code: '326',
      },
      {
        id: 342,
        name: 'Lira District',
        state_code: '307',
      },
      {
        id: 445,
        name: 'Luuka District',
        state_code: '229',
      },
      {
        id: 433,
        name: 'Luwero District',
        state_code: '104',
      },
      {
        id: 417,
        name: 'Lwengo District',
        state_code: '124',
      },
      {
        id: 376,
        name: 'Lyantonde District',
        state_code: '114',
      },
      {
        id: 438,
        name: 'Manafwa District',
        state_code: '223',
      },
      {
        id: 421,
        name: 'Maracha District',
        state_code: '320',
      },
      {
        id: 356,
        name: 'Masaka District',
        state_code: '105',
      },
      {
        id: 354,
        name: 'Masindi District',
        state_code: '409',
      },
      {
        id: 418,
        name: 'Mayuge District',
        state_code: '214',
      },
      {
        id: 350,
        name: 'Mbale District',
        state_code: '209',
      },
      {
        id: 415,
        name: 'Mbarara District',
        state_code: '410',
      },
      {
        id: 435,
        name: 'Mitooma District',
        state_code: '423',
      },
      {
        id: 364,
        name: 'Mityana District',
        state_code: '115',
      },
      {
        id: 395,
        name: 'Moroto District',
        state_code: '308',
      },
      {
        id: 363,
        name: 'Moyo District',
        state_code: '309',
      },
      {
        id: 327,
        name: 'Mpigi District',
        state_code: '106',
      },
      {
        id: 371,
        name: 'Mubende District',
        state_code: '107',
      },
      {
        id: 410,
        name: 'Mukono District',
        state_code: '108',
      },
      {
        id: 393,
        name: 'Nakapiripirit District',
        state_code: '311',
      },
      {
        id: 423,
        name: 'Nakaseke District',
        state_code: '116',
      },
      {
        id: 406,
        name: 'Nakasongola District',
        state_code: '109',
      },
      {
        id: 351,
        name: 'Namayingo District',
        state_code: '230',
      },
      {
        id: 400,
        name: 'Namisindwa District',
        state_code: '234',
      },
      {
        id: 337,
        name: 'Namutumba District',
        state_code: '224',
      },
      {
        id: 430,
        name: 'Napak District',
        state_code: '327',
      },
      {
        id: 446,
        name: 'Nebbi District',
        state_code: '310',
      },
      {
        id: 424,
        name: 'Ngora District',
        state_code: '231',
      },
      {
        id: 332,
        name: 'Northern Region',
        state_code: 'N',
      },
      {
        id: 422,
        name: 'Ntoroko District',
        state_code: '424',
      },
      {
        id: 404,
        name: 'Ntungamo District',
        state_code: '411',
      },
      {
        id: 378,
        name: 'Nwoya District',
        state_code: '328',
      },
      {
        id: 374,
        name: 'Omoro District',
        state_code: '331',
      },
      {
        id: 390,
        name: 'Otuke District',
        state_code: '329',
      },
      {
        id: 397,
        name: 'Oyam District',
        state_code: '321',
      },
      {
        id: 408,
        name: 'Pader District',
        state_code: '312',
      },
      {
        id: 357,
        name: 'Pakwach District',
        state_code: '332',
      },
      {
        id: 412,
        name: 'Pallisa District',
        state_code: '210',
      },
      {
        id: 439,
        name: 'Rakai District',
        state_code: '110',
      },
      {
        id: 358,
        name: 'Rubanda District',
        state_code: '429',
      },
      {
        id: 442,
        name: 'Rubirizi District',
        state_code: '425',
      },
      {
        id: 331,
        name: 'Rukiga District',
        state_code: '431',
      },
      {
        id: 324,
        name: 'Rukungiri District',
        state_code: '412',
      },
      {
        id: 427,
        name: 'Sembabule District',
        state_code: '111',
      },
      {
        id: 333,
        name: 'Serere District',
        state_code: '232',
      },
      {
        id: 407,
        name: 'Sheema District',
        state_code: '426',
      },
      {
        id: 429,
        name: 'Sironko District',
        state_code: '215',
      },
      {
        id: 444,
        name: 'Soroti District',
        state_code: '211',
      },
      {
        id: 359,
        name: 'Tororo District',
        state_code: '212',
      },
      {
        id: 362,
        name: 'Wakiso District',
        state_code: '113',
      },
      {
        id: 370,
        name: 'Western Region',
        state_code: 'W',
      },
      {
        id: 330,
        name: 'Yumbe District',
        state_code: '313',
      },
      {
        id: 379,
        name: 'Zombo District',
        state_code: '330',
      },
    ],
  },
  {
    name: 'Ukraine',
    iso3: 'UKR',
    iso2: 'UA',
    phone_code: '380',
    capital: 'Kiev',
    currency: 'UAH',
    states: [
      {
        id: 4689,
        name: 'Autonomous Republic of Crimea',
        state_code: '43',
      },
      {
        id: 4680,
        name: 'Cherkasy Oblast',
        state_code: '71',
      },
      {
        id: 4692,
        name: 'Chernihiv Oblast',
        state_code: '74',
      },
      {
        id: 4678,
        name: 'Chernivtsi Oblast',
        state_code: '77',
      },
      {
        id: 4675,
        name: 'Dnipropetrovsk Oblast',
        state_code: '12',
      },
      {
        id: 4691,
        name: 'Donetsk Oblast',
        state_code: '14',
      },
      {
        id: 4682,
        name: 'Ivano-Frankivsk Oblast',
        state_code: '26',
      },
      {
        id: 4686,
        name: 'Kharkiv Oblast',
        state_code: '63',
      },
      {
        id: 4684,
        name: 'Kherson Oblast',
        state_code: '65',
      },
      {
        id: 4681,
        name: 'Khmelnytsky Oblast',
        state_code: '68',
      },
      {
        id: 4676,
        name: 'Kiev',
        state_code: '30',
      },
      {
        id: 4677,
        name: 'Kirovohrad Oblast',
        state_code: '35',
      },
      {
        id: 4671,
        name: 'Kyiv Oblast',
        state_code: '32',
      },
      {
        id: 4673,
        name: 'Luhansk Oblast',
        state_code: '09',
      },
      {
        id: 4672,
        name: 'Lviv Oblast',
        state_code: '46',
      },
      {
        id: 4679,
        name: 'Mykolaiv Oblast',
        state_code: '48',
      },
      {
        id: 4688,
        name: 'Odessa Oblast',
        state_code: '51',
      },
      {
        id: 4683,
        name: 'Rivne Oblast',
        state_code: '56',
      },
      {
        id: 4685,
        name: 'Sumy Oblast',
        state_code: '59',
      },
      {
        id: 4674,
        name: 'Ternopil Oblast',
        state_code: '61',
      },
      {
        id: 4669,
        name: 'Vinnytsia Oblast',
        state_code: '05',
      },
      {
        id: 4690,
        name: 'Volyn Oblast',
        state_code: '07',
      },
      {
        id: 4670,
        name: 'Zakarpattia Oblast',
        state_code: '21',
      },
      {
        id: 4687,
        name: 'Zaporizhzhya Oblast',
        state_code: '23',
      },
      {
        id: 4668,
        name: 'Zhytomyr Oblast',
        state_code: '18',
      },
    ],
  },
  {
    name: 'United Arab Emirates',
    iso3: 'ARE',
    iso2: 'AE',
    phone_code: '971',
    capital: 'Abu Dhabi',
    currency: 'AED',
    states: [
      {
        id: 3396,
        name: 'Abu Dhabi Emirate',
        state_code: 'AZ',
      },
      {
        id: 3395,
        name: 'Ajman Emirate',
        state_code: 'AJ',
      },
      {
        id: 3391,
        name: 'Dubai',
        state_code: 'DU',
      },
      {
        id: 3393,
        name: 'Fujairah',
        state_code: 'FU',
      },
      {
        id: 3394,
        name: 'Ras al-Khaimah',
        state_code: 'RK',
      },
      {
        id: 3390,
        name: 'Sharjah Emirate',
        state_code: 'SH',
      },
      {
        id: 3392,
        name: 'Umm al-Quwain',
        state_code: 'UQ',
      },
    ],
  },
  {
    name: 'United Kingdom',
    iso3: 'GBR',
    iso2: 'GB',
    phone_code: '44',
    capital: 'London',
    currency: 'GBP',
    states: [
      {
        id: 2463,
        name: 'Aberdeen',
        state_code: 'ABE',
      },
      {
        id: 2401,
        name: 'Aberdeenshire',
        state_code: 'ABD',
      },
      {
        id: 2387,
        name: 'Angus',
        state_code: 'ANS',
      },
      {
        id: 2533,
        name: 'Antrim',
        state_code: 'ANT',
      },
      {
        id: 2412,
        name: 'Antrim and Newtownabbey',
        state_code: 'ANN',
      },
      {
        id: 2498,
        name: 'Ards',
        state_code: 'ARD',
      },
      {
        id: 2523,
        name: 'Ards and North Down',
        state_code: 'AND',
      },
      {
        id: 2392,
        name: 'Argyll and Bute',
        state_code: 'AGB',
      },
      {
        id: 2331,
        name: 'Armagh City and District Council',
        state_code: 'ARM',
      },
      {
        id: 2324,
        name: 'Armagh, Banbridge and Craigavon',
        state_code: 'ABC',
      },
      {
        id: 2378,
        name: 'Ascension Island',
        state_code: 'SH-AC',
      },
      {
        id: 2363,
        name: 'Ballymena Borough',
        state_code: 'BLA',
      },
      {
        id: 2361,
        name: 'Ballymoney',
        state_code: 'BLY',
      },
      {
        id: 2315,
        name: 'Banbridge',
        state_code: 'BNB',
      },
      {
        id: 2499,
        name: 'Barnsley',
        state_code: 'BNS',
      },
      {
        id: 2339,
        name: 'Bath and North East Somerset',
        state_code: 'BAS',
      },
      {
        id: 2507,
        name: 'Bedford',
        state_code: 'BDF',
      },
      {
        id: 2311,
        name: 'Belfast district',
        state_code: 'BFS',
      },
      {
        id: 2425,
        name: 'Birmingham',
        state_code: 'BIR',
      },
      {
        id: 2329,
        name: 'Blackburn with Darwen',
        state_code: 'BBD',
      },
      {
        id: 2451,
        name: 'Blackpool',
        state_code: 'BPL',
      },
      {
        id: 2530,
        name: 'Blaenau Gwent County Borough',
        state_code: 'BGW',
      },
      {
        id: 2504,
        name: 'Bolton',
        state_code: 'BOL',
      },
      {
        id: 2342,
        name: 'Bournemouth',
        state_code: 'BMH',
      },
      {
        id: 2470,
        name: 'Bracknell Forest',
        state_code: 'BRC',
      },
      {
        id: 2529,
        name: 'Bradford',
        state_code: 'BRD',
      },
      {
        id: 2452,
        name: 'Bridgend County Borough',
        state_code: 'BGE',
      },
      {
        id: 2395,
        name: 'Brighton and Hove',
        state_code: 'BNH',
      },
      {
        id: 2405,
        name: 'Buckinghamshire',
        state_code: 'BKM',
      },
      {
        id: 2459,
        name: 'Bury',
        state_code: 'BUR',
      },
      {
        id: 2298,
        name: 'Caerphilly County Borough',
        state_code: 'CAY',
      },
      {
        id: 2517,
        name: 'Calderdale',
        state_code: 'CLD',
      },
      {
        id: 2423,
        name: 'Cambridgeshire',
        state_code: 'CAM',
      },
      {
        id: 2484,
        name: 'Carmarthenshire',
        state_code: 'CMN',
      },
      {
        id: 2439,
        name: 'Carrickfergus Borough Council',
        state_code: 'CKF',
      },
      {
        id: 2525,
        name: 'Castlereagh',
        state_code: 'CSR',
      },
      {
        id: 2316,
        name: 'Causeway Coast and Glens',
        state_code: 'CCG',
      },
      {
        id: 2303,
        name: 'Central Bedfordshire',
        state_code: 'CBF',
      },
      {
        id: 2509,
        name: 'Ceredigion',
        state_code: 'CGN',
      },
      {
        id: 2444,
        name: 'Cheshire East',
        state_code: 'CHE',
      },
      {
        id: 2442,
        name: 'Cheshire West and Chester',
        state_code: 'CHW',
      },
      {
        id: 2528,
        name: 'City and County of Cardiff',
        state_code: 'CRF',
      },
      {
        id: 2433,
        name: 'City and County of Swansea',
        state_code: 'SWA',
      },
      {
        id: 2413,
        name: 'City of Bristol',
        state_code: 'BST',
      },
      {
        id: 2485,
        name: 'City of Derby',
        state_code: 'DER',
      },
      {
        id: 2475,
        name: 'City of Kingston upon Hull',
        state_code: 'KHL',
      },
      {
        id: 2318,
        name: 'City of Leicester',
        state_code: 'LCE',
      },
      {
        id: 2424,
        name: 'City of London',
        state_code: 'LND',
      },
      {
        id: 2359,
        name: 'City of Nottingham',
        state_code: 'NGM',
      },
      {
        id: 2297,
        name: 'City of Peterborough',
        state_code: 'PTE',
      },
      {
        id: 2514,
        name: 'City of Plymouth',
        state_code: 'PLY',
      },
      {
        id: 2305,
        name: 'City of Portsmouth',
        state_code: 'POR',
      },
      {
        id: 2294,
        name: 'City of Southampton',
        state_code: 'STH',
      },
      {
        id: 2506,
        name: 'City of Stoke-on-Trent',
        state_code: 'STE',
      },
      {
        id: 2372,
        name: 'City of Sunderland',
        state_code: 'SND',
      },
      {
        id: 2357,
        name: 'City of Westminster',
        state_code: 'WSM',
      },
      {
        id: 2489,
        name: 'City of Wolverhampton',
        state_code: 'WLV',
      },
      {
        id: 2426,
        name: 'City of York',
        state_code: 'YOR',
      },
      {
        id: 2450,
        name: 'Clackmannanshire',
        state_code: 'CLK',
      },
      {
        id: 2461,
        name: 'Coleraine Borough Council',
        state_code: 'CLR',
      },
      {
        id: 2352,
        name: 'Conwy County Borough',
        state_code: 'CWY',
      },
      {
        id: 2445,
        name: 'Cookstown District Council',
        state_code: 'CKT',
      },
      {
        id: 2312,
        name: 'Cornwall',
        state_code: 'CON',
      },
      {
        id: 2406,
        name: 'County Durham',
        state_code: 'DUR',
      },
      {
        id: 2438,
        name: 'Coventry',
        state_code: 'COV',
      },
      {
        id: 2449,
        name: 'Craigavon Borough Council',
        state_code: 'CGV',
      },
      {
        id: 2334,
        name: 'Cumbria',
        state_code: 'CMA',
      },
      {
        id: 2389,
        name: 'Darlington',
        state_code: 'DAL',
      },
      {
        id: 2497,
        name: 'Denbighshire',
        state_code: 'DEN',
      },
      {
        id: 2403,
        name: 'Derbyshire',
        state_code: 'DBY',
      },
      {
        id: 2446,
        name: 'Derry City and Strabane',
        state_code: 'DRS',
      },
      {
        id: 2417,
        name: 'Derry City Council',
        state_code: 'DRY',
      },
      {
        id: 2491,
        name: 'Devon',
        state_code: 'DEV',
      },
      {
        id: 2364,
        name: 'Doncaster',
        state_code: 'DNC',
      },
      {
        id: 2345,
        name: 'Dorset',
        state_code: 'DOR',
      },
      {
        id: 2304,
        name: 'Down District Council',
        state_code: 'DOW',
      },
      {
        id: 2457,
        name: 'Dudley',
        state_code: 'DUD',
      },
      {
        id: 2415,
        name: 'Dumfries and Galloway',
        state_code: 'DGY',
      },
      {
        id: 2511,
        name: 'Dundee',
        state_code: 'DND',
      },
      {
        id: 2508,
        name: 'Dungannon and South Tyrone Borough Council',
        state_code: 'DGN',
      },
      {
        id: 2374,
        name: 'East Ayrshire',
        state_code: 'EAY',
      },
      {
        id: 2454,
        name: 'East Dunbartonshire',
        state_code: 'EDU',
      },
      {
        id: 2462,
        name: 'East Lothian',
        state_code: 'ELN',
      },
      {
        id: 2333,
        name: 'East Renfrewshire',
        state_code: 'ERW',
      },
      {
        id: 2370,
        name: 'East Riding of Yorkshire',
        state_code: 'ERY',
      },
      {
        id: 2414,
        name: 'East Sussex',
        state_code: 'ESX',
      },
      {
        id: 2428,
        name: 'Edinburgh',
        state_code: 'EDH',
      },
      {
        id: 2336,
        name: 'England',
        state_code: 'ENG',
      },
      {
        id: 2410,
        name: 'Essex',
        state_code: 'ESS',
      },
      {
        id: 2344,
        name: 'Falkirk',
        state_code: 'FAL',
      },
      {
        id: 2366,
        name: 'Fermanagh and Omagh',
        state_code: 'FMO',
      },
      {
        id: 2531,
        name: 'Fermanagh District Council',
        state_code: 'FER',
      },
      {
        id: 2479,
        name: 'Fife',
        state_code: 'FIF',
      },
      {
        id: 2437,
        name: 'Flintshire',
        state_code: 'FLN',
      },
      {
        id: 2431,
        name: 'Gateshead',
        state_code: 'GAT',
      },
      {
        id: 2404,
        name: 'Glasgow',
        state_code: 'GLG',
      },
      {
        id: 2373,
        name: 'Gloucestershire',
        state_code: 'GLS',
      },
      {
        id: 2379,
        name: 'Gwynedd',
        state_code: 'GWN',
      },
      {
        id: 2466,
        name: 'Halton',
        state_code: 'HAL',
      },
      {
        id: 2435,
        name: 'Hampshire',
        state_code: 'HAM',
      },
      {
        id: 2309,
        name: 'Hartlepool',
        state_code: 'HPL',
      },
      {
        id: 2500,
        name: 'Herefordshire',
        state_code: 'HEF',
      },
      {
        id: 2369,
        name: 'Hertfordshire',
        state_code: 'HRT',
      },
      {
        id: 2383,
        name: 'Highland',
        state_code: 'HLD',
      },
      {
        id: 2388,
        name: 'Inverclyde',
        state_code: 'IVC',
      },
      {
        id: 2289,
        name: 'Isle of Wight',
        state_code: 'IOW',
      },
      {
        id: 2343,
        name: 'Isles of Scilly',
        state_code: 'IOS',
      },
      {
        id: 2464,
        name: 'Kent',
        state_code: 'KEN',
      },
      {
        id: 2371,
        name: 'Kirklees',
        state_code: 'KIR',
      },
      {
        id: 2330,
        name: 'Knowsley',
        state_code: 'KWL',
      },
      {
        id: 2495,
        name: 'Lancashire',
        state_code: 'LAN',
      },
      {
        id: 2515,
        name: 'Larne Borough Council',
        state_code: 'LRN',
      },
      {
        id: 2503,
        name: 'Leeds',
        state_code: 'LDS',
      },
      {
        id: 2516,
        name: 'Leicestershire',
        state_code: 'LEC',
      },
      {
        id: 2382,
        name: 'Limavady Borough Council',
        state_code: 'LMV',
      },
      {
        id: 2355,
        name: 'Lincolnshire',
        state_code: 'LIN',
      },
      {
        id: 2460,
        name: 'Lisburn and Castlereagh',
        state_code: 'LBC',
      },
      {
        id: 2494,
        name: 'Lisburn City Council',
        state_code: 'LSB',
      },
      {
        id: 2340,
        name: 'Liverpool',
        state_code: 'LIV',
      },
      {
        id: 2356,
        name: 'London Borough of Barking and Dagenham',
        state_code: 'BDG',
      },
      {
        id: 2520,
        name: 'London Borough of Barnet',
        state_code: 'BNE',
      },
      {
        id: 2307,
        name: 'London Borough of Bexley',
        state_code: 'BEX',
      },
      {
        id: 2291,
        name: 'London Borough of Brent',
        state_code: 'BEN',
      },
      {
        id: 2490,
        name: 'London Borough of Bromley',
        state_code: 'BRY',
      },
      {
        id: 2349,
        name: 'London Borough of Camden',
        state_code: 'CMD',
      },
      {
        id: 2512,
        name: 'London Borough of Croydon',
        state_code: 'CRY',
      },
      {
        id: 2532,
        name: 'London Borough of Ealing',
        state_code: 'EAL',
      },
      {
        id: 2476,
        name: 'London Borough of Enfield',
        state_code: 'ENF',
      },
      {
        id: 2411,
        name: 'London Borough of Hackney',
        state_code: 'HCK',
      },
      {
        id: 2448,
        name: 'London Borough of Hammersmith and Fulham',
        state_code: 'HMF',
      },
      {
        id: 2306,
        name: 'London Borough of Haringey',
        state_code: 'HRY',
      },
      {
        id: 2385,
        name: 'London Borough of Harrow',
        state_code: 'HRW',
      },
      {
        id: 2347,
        name: 'London Borough of Havering',
        state_code: 'HAV',
      },
      {
        id: 2376,
        name: 'London Borough of Hillingdon',
        state_code: 'HIL',
      },
      {
        id: 2380,
        name: 'London Borough of Hounslow',
        state_code: 'HNS',
      },
      {
        id: 2319,
        name: 'London Borough of Islington',
        state_code: 'ISL',
      },
      {
        id: 2396,
        name: 'London Borough of Lambeth',
        state_code: 'LBH',
      },
      {
        id: 2358,
        name: 'London Borough of Lewisham',
        state_code: 'LEW',
      },
      {
        id: 2483,
        name: 'London Borough of Merton',
        state_code: 'MRT',
      },
      {
        id: 2418,
        name: 'London Borough of Newham',
        state_code: 'NWM',
      },
      {
        id: 2397,
        name: 'London Borough of Redbridge',
        state_code: 'RDB',
      },
      {
        id: 2501,
        name: 'London Borough of Richmond upon Thames',
        state_code: 'RIC',
      },
      {
        id: 2432,
        name: 'London Borough of Southwark',
        state_code: 'SWK',
      },
      {
        id: 2313,
        name: 'London Borough of Sutton',
        state_code: 'STN',
      },
      {
        id: 2390,
        name: 'London Borough of Tower Hamlets',
        state_code: 'TWH',
      },
      {
        id: 2326,
        name: 'London Borough of Waltham Forest',
        state_code: 'WFT',
      },
      {
        id: 2434,
        name: 'London Borough of Wandsworth',
        state_code: 'WND',
      },
      {
        id: 2322,
        name: 'Magherafelt District Council',
        state_code: 'MFT',
      },
      {
        id: 2398,
        name: 'Manchester',
        state_code: 'MAN',
      },
      {
        id: 2381,
        name: 'Medway',
        state_code: 'MDW',
      },
      {
        id: 2328,
        name: 'Merthyr Tydfil County Borough',
        state_code: 'MTY',
      },
      {
        id: 2320,
        name: 'Metropolitan Borough of Wigan',
        state_code: 'WGN',
      },
      {
        id: 2429,
        name: 'Mid and East Antrim',
        state_code: 'MEA',
      },
      {
        id: 2399,
        name: 'Mid Ulster',
        state_code: 'MUL',
      },
      {
        id: 2332,
        name: 'Middlesbrough',
        state_code: 'MDB',
      },
      {
        id: 2519,
        name: 'Midlothian',
        state_code: 'MLN',
      },
      {
        id: 2416,
        name: 'Milton Keynes',
        state_code: 'MIK',
      },
      {
        id: 2402,
        name: 'Monmouthshire',
        state_code: 'MON',
      },
      {
        id: 2360,
        name: 'Moray',
        state_code: 'MRY',
      },
      {
        id: 2348,
        name: 'Moyle District Council',
        state_code: 'MYL',
      },
      {
        id: 2351,
        name: 'Neath Port Talbot County Borough',
        state_code: 'NTL',
      },
      {
        id: 2458,
        name: 'Newcastle upon Tyne',
        state_code: 'NET',
      },
      {
        id: 2524,
        name: 'Newport',
        state_code: 'NWP',
      },
      {
        id: 2350,
        name: 'Newry and Mourne District Council',
        state_code: 'NYM',
      },
      {
        id: 2534,
        name: 'Newry, Mourne and Down',
        state_code: 'NMD',
      },
      {
        id: 2317,
        name: 'Newtownabbey Borough Council',
        state_code: 'NTA',
      },
      {
        id: 2473,
        name: 'Norfolk',
        state_code: 'NFK',
      },
      {
        id: 2535,
        name: 'North Ayrshire',
        state_code: 'NAY',
      },
      {
        id: 2513,
        name: 'North Down Borough Council',
        state_code: 'NDN',
      },
      {
        id: 2384,
        name: 'North East Lincolnshire',
        state_code: 'NEL',
      },
      {
        id: 2487,
        name: 'North Lanarkshire',
        state_code: 'NLK',
      },
      {
        id: 2453,
        name: 'North Lincolnshire',
        state_code: 'NLN',
      },
      {
        id: 2430,
        name: 'North Somerset',
        state_code: 'NSM',
      },
      {
        id: 2521,
        name: 'North Tyneside',
        state_code: 'NTY',
      },
      {
        id: 2522,
        name: 'North Yorkshire',
        state_code: 'NYK',
      },
      {
        id: 2480,
        name: 'Northamptonshire',
        state_code: 'NTH',
      },
      {
        id: 2337,
        name: 'Northern Ireland',
        state_code: 'NIR',
      },
      {
        id: 2365,
        name: 'Northumberland',
        state_code: 'NBL',
      },
      {
        id: 2456,
        name: 'Nottinghamshire',
        state_code: 'NTT',
      },
      {
        id: 2477,
        name: 'Oldham',
        state_code: 'OLD',
      },
      {
        id: 2314,
        name: 'Omagh District Council',
        state_code: 'OMH',
      },
      {
        id: 2474,
        name: 'Orkney Islands',
        state_code: 'ORK',
      },
      {
        id: 2353,
        name: 'Outer Hebrides',
        state_code: 'ELS',
      },
      {
        id: 2321,
        name: 'Oxfordshire',
        state_code: 'OXF',
      },
      {
        id: 2486,
        name: 'Pembrokeshire',
        state_code: 'PEM',
      },
      {
        id: 2325,
        name: 'Perth and Kinross',
        state_code: 'PKN',
      },
      {
        id: 2302,
        name: 'Poole',
        state_code: 'POL',
      },
      {
        id: 2441,
        name: 'Powys',
        state_code: 'POW',
      },
      {
        id: 2455,
        name: 'Reading',
        state_code: 'RDG',
      },
      {
        id: 2527,
        name: 'Redcar and Cleveland',
        state_code: 'RCC',
      },
      {
        id: 2443,
        name: 'Renfrewshire',
        state_code: 'RFW',
      },
      {
        id: 2301,
        name: 'Rhondda Cynon Taf',
        state_code: 'RCT',
      },
      {
        id: 2327,
        name: 'Rochdale',
        state_code: 'RCH',
      },
      {
        id: 2308,
        name: 'Rotherham',
        state_code: 'ROT',
      },
      {
        id: 2492,
        name: 'Royal Borough of Greenwich',
        state_code: 'GRE',
      },
      {
        id: 2368,
        name: 'Royal Borough of Kensington and Chelsea',
        state_code: 'KEC',
      },
      {
        id: 2481,
        name: 'Royal Borough of Kingston upon Thames',
        state_code: 'KTT',
      },
      {
        id: 2472,
        name: 'Rutland',
        state_code: 'RUT',
      },
      {
        id: 2502,
        name: 'Saint Helena',
        state_code: 'SH-HL',
      },
      {
        id: 2493,
        name: 'Salford',
        state_code: 'SLF',
      },
      {
        id: 2341,
        name: 'Sandwell',
        state_code: 'SAW',
      },
      {
        id: 2335,
        name: 'Scotland',
        state_code: 'SCT',
      },
      {
        id: 2346,
        name: 'Scottish Borders',
        state_code: 'SCB',
      },
      {
        id: 2518,
        name: 'Sefton',
        state_code: 'SFT',
      },
      {
        id: 2295,
        name: 'Sheffield',
        state_code: 'SHF',
      },
      {
        id: 2300,
        name: 'Shetland Islands',
        state_code: 'ZET',
      },
      {
        id: 2407,
        name: 'Shropshire',
        state_code: 'SHR',
      },
      {
        id: 2427,
        name: 'Slough',
        state_code: 'SLG',
      },
      {
        id: 2469,
        name: 'Solihull',
        state_code: 'SOL',
      },
      {
        id: 2386,
        name: 'Somerset',
        state_code: 'SOM',
      },
      {
        id: 2377,
        name: 'South Ayrshire',
        state_code: 'SAY',
      },
      {
        id: 2400,
        name: 'South Gloucestershire',
        state_code: 'SGC',
      },
      {
        id: 2362,
        name: 'South Lanarkshire',
        state_code: 'SLK',
      },
      {
        id: 2409,
        name: 'South Tyneside',
        state_code: 'STY',
      },
      {
        id: 2323,
        name: 'Southend-on-Sea',
        state_code: 'SOS',
      },
      {
        id: 2290,
        name: 'St Helens',
        state_code: 'SHN',
      },
      {
        id: 2447,
        name: 'Staffordshire',
        state_code: 'STS',
      },
      {
        id: 2488,
        name: 'Stirling',
        state_code: 'STG',
      },
      {
        id: 2394,
        name: 'Stockport',
        state_code: 'SKP',
      },
      {
        id: 2421,
        name: 'Stockton-on-Tees',
        state_code: 'STT',
      },
      {
        id: 2393,
        name: 'Strabane District Council',
        state_code: 'STB',
      },
      {
        id: 2467,
        name: 'Suffolk',
        state_code: 'SFK',
      },
      {
        id: 2526,
        name: 'Surrey',
        state_code: 'SRY',
      },
      {
        id: 2422,
        name: 'Swindon',
        state_code: 'SWD',
      },
      {
        id: 2367,
        name: 'Tameside',
        state_code: 'TAM',
      },
      {
        id: 2310,
        name: 'Telford and Wrekin',
        state_code: 'TFW',
      },
      {
        id: 2468,
        name: 'Thurrock',
        state_code: 'THR',
      },
      {
        id: 2478,
        name: 'Torbay',
        state_code: 'TOB',
      },
      {
        id: 2496,
        name: 'Torfaen',
        state_code: 'TOF',
      },
      {
        id: 2293,
        name: 'Trafford',
        state_code: 'TRF',
      },
      {
        id: 2375,
        name: 'United Kingdom',
        state_code: 'UKM',
      },
      {
        id: 2299,
        name: 'Vale of Glamorgan',
        state_code: 'VGL',
      },
      {
        id: 2465,
        name: 'Wakefield',
        state_code: 'WKF',
      },
      {
        id: 2338,
        name: 'Wales',
        state_code: 'WLS',
      },
      {
        id: 2292,
        name: 'Walsall',
        state_code: 'WLL',
      },
      {
        id: 2420,
        name: 'Warrington',
        state_code: 'WRT',
      },
      {
        id: 2505,
        name: 'Warwickshire',
        state_code: 'WAR',
      },
      {
        id: 2471,
        name: 'West Berkshire',
        state_code: 'WBK',
      },
      {
        id: 2440,
        name: 'West Dunbartonshire',
        state_code: 'WDU',
      },
      {
        id: 2354,
        name: 'West Lothian',
        state_code: 'WLN',
      },
      {
        id: 2296,
        name: 'West Sussex',
        state_code: 'WSX',
      },
      {
        id: 2391,
        name: 'Wiltshire',
        state_code: 'WIL',
      },
      {
        id: 2482,
        name: 'Windsor and Maidenhead',
        state_code: 'WNM',
      },
      {
        id: 2408,
        name: 'Wirral',
        state_code: 'WRL',
      },
      {
        id: 2419,
        name: 'Wokingham',
        state_code: 'WOK',
      },
      {
        id: 2510,
        name: 'Worcestershire',
        state_code: 'WOR',
      },
      {
        id: 2436,
        name: 'Wrexham County Borough',
        state_code: 'WRX',
      },
    ],
  },
  {
    name: 'United States',
    iso3: 'USA',
    iso2: 'US',
    phone_code: '1',
    capital: 'Washington',
    currency: 'USD',
    states: [
      {
        id: 1456,
        name: 'Alabama',
        state_code: 'AL',
      },
      {
        id: 1400,
        name: 'Alaska',
        state_code: 'AK',
      },
      {
        id: 1424,
        name: 'American Samoa',
        state_code: 'AS',
      },
      {
        id: 1434,
        name: 'Arizona',
        state_code: 'AZ',
      },
      {
        id: 1444,
        name: 'Arkansas',
        state_code: 'AR',
      },
      {
        id: 1402,
        name: 'Baker Island',
        state_code: 'UM-81',
      },
      {
        id: 1416,
        name: 'California',
        state_code: 'CA',
      },
      {
        id: 1450,
        name: 'Colorado',
        state_code: 'CO',
      },
      {
        id: 1435,
        name: 'Connecticut',
        state_code: 'CT',
      },
      {
        id: 1399,
        name: 'Delaware',
        state_code: 'DE',
      },
      {
        id: 1437,
        name: 'District of Columbia',
        state_code: 'DC',
      },
      {
        id: 1436,
        name: 'Florida',
        state_code: 'FL',
      },
      {
        id: 1455,
        name: 'Georgia',
        state_code: 'GA',
      },
      {
        id: 1412,
        name: 'Guam',
        state_code: 'GU',
      },
      {
        id: 1411,
        name: 'Hawaii',
        state_code: 'HI',
      },
      {
        id: 1398,
        name: 'Howland Island',
        state_code: 'UM-84',
      },
      {
        id: 1460,
        name: 'Idaho',
        state_code: 'ID',
      },
      {
        id: 1425,
        name: 'Illinois',
        state_code: 'IL',
      },
      {
        id: 1440,
        name: 'Indiana',
        state_code: 'IN',
      },
      {
        id: 1459,
        name: 'Iowa',
        state_code: 'IA',
      },
      {
        id: 1410,
        name: 'Jarvis Island',
        state_code: 'UM-86',
      },
      {
        id: 1428,
        name: 'Johnston Atoll',
        state_code: 'UM-67',
      },
      {
        id: 1406,
        name: 'Kansas',
        state_code: 'KS',
      },
      {
        id: 1419,
        name: 'Kentucky',
        state_code: 'KY',
      },
      {
        id: 1403,
        name: 'Kingman Reef',
        state_code: 'UM-89',
      },
      {
        id: 1457,
        name: 'Louisiana',
        state_code: 'LA',
      },
      {
        id: 1453,
        name: 'Maine',
        state_code: 'ME',
      },
      {
        id: 1401,
        name: 'Maryland',
        state_code: 'MD',
      },
      {
        id: 1433,
        name: 'Massachusetts',
        state_code: 'MA',
      },
      {
        id: 1426,
        name: 'Michigan',
        state_code: 'MI',
      },
      {
        id: 1438,
        name: 'Midway Atoll',
        state_code: 'UM-71',
      },
      {
        id: 1420,
        name: 'Minnesota',
        state_code: 'MN',
      },
      {
        id: 1430,
        name: 'Mississippi',
        state_code: 'MS',
      },
      {
        id: 1451,
        name: 'Missouri',
        state_code: 'MO',
      },
      {
        id: 1446,
        name: 'Montana',
        state_code: 'MT',
      },
      {
        id: 1439,
        name: 'Navassa Island',
        state_code: 'UM-76',
      },
      {
        id: 1408,
        name: 'Nebraska',
        state_code: 'NE',
      },
      {
        id: 1458,
        name: 'Nevada',
        state_code: 'NV',
      },
      {
        id: 1404,
        name: 'New Hampshire',
        state_code: 'NH',
      },
      {
        id: 1417,
        name: 'New Jersey',
        state_code: 'NJ',
      },
      {
        id: 1423,
        name: 'New Mexico',
        state_code: 'NM',
      },
      {
        id: 1452,
        name: 'New York',
        state_code: 'NY',
      },
      {
        id: 1447,
        name: 'North Carolina',
        state_code: 'NC',
      },
      {
        id: 1418,
        name: 'North Dakota',
        state_code: 'ND',
      },
      {
        id: 1431,
        name: 'Northern Mariana Islands',
        state_code: 'MP',
      },
      {
        id: 4851,
        name: 'Ohio',
        state_code: 'OH',
      },
      {
        id: 1421,
        name: 'Oklahoma',
        state_code: 'OK',
      },
      {
        id: 1415,
        name: 'Oregon',
        state_code: 'OR',
      },
      {
        id: 1448,
        name: 'Palmyra Atoll',
        state_code: 'UM-95',
      },
      {
        id: 1422,
        name: 'Pennsylvania',
        state_code: 'PA',
      },
      {
        id: 1449,
        name: 'Puerto Rico',
        state_code: 'PR',
      },
      {
        id: 1461,
        name: 'Rhode Island',
        state_code: 'RI',
      },
      {
        id: 1443,
        name: 'South Carolina',
        state_code: 'SC',
      },
      {
        id: 1445,
        name: 'South Dakota',
        state_code: 'SD',
      },
      {
        id: 1454,
        name: 'Tennessee',
        state_code: 'TN',
      },
      {
        id: 1407,
        name: 'Texas',
        state_code: 'TX',
      },
      {
        id: 1432,
        name: 'United States Minor Outlying Islands',
        state_code: 'UM',
      },
      {
        id: 1413,
        name: 'United States Virgin Islands',
        state_code: 'VI',
      },
      {
        id: 1414,
        name: 'Utah',
        state_code: 'UT',
      },
      {
        id: 1409,
        name: 'Vermont',
        state_code: 'VT',
      },
      {
        id: 1427,
        name: 'Virginia',
        state_code: 'VA',
      },
      {
        id: 1405,
        name: 'Wake Island',
        state_code: 'UM-79',
      },
      {
        id: 1462,
        name: 'Washington',
        state_code: 'WA',
      },
      {
        id: 1429,
        name: 'West Virginia',
        state_code: 'WV',
      },
      {
        id: 1441,
        name: 'Wisconsin',
        state_code: 'WI',
      },
      {
        id: 1442,
        name: 'Wyoming',
        state_code: 'WY',
      },
    ],
  },
  {
    name: 'United States Minor Outlying Islands',
    iso3: 'UMI',
    iso2: 'UM',
    phone_code: '1',
    capital: '',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Uruguay',
    iso3: 'URY',
    iso2: 'UY',
    phone_code: '598',
    capital: 'Montevideo',
    currency: 'UYU',
    states: [
      {
        id: 3205,
        name: 'Artigas Department',
        state_code: 'AR',
      },
      {
        id: 3213,
        name: 'Canelones Department',
        state_code: 'CA',
      },
      {
        id: 3211,
        name: 'Cerro Largo Department',
        state_code: 'CL',
      },
      {
        id: 3208,
        name: 'Colonia Department',
        state_code: 'CO',
      },
      {
        id: 3209,
        name: 'Durazno Department',
        state_code: 'DU',
      },
      {
        id: 3203,
        name: 'Flores Department',
        state_code: 'FS',
      },
      {
        id: 3217,
        name: 'Florida Department',
        state_code: 'FD',
      },
      {
        id: 3215,
        name: 'Lavalleja Department',
        state_code: 'LA',
      },
      {
        id: 3206,
        name: 'Maldonado Department',
        state_code: 'MA',
      },
      {
        id: 3218,
        name: 'Montevideo Department',
        state_code: 'MO',
      },
      {
        id: 3212,
        name: 'Paysandú Department',
        state_code: 'PA',
      },
      {
        id: 3210,
        name: 'Río Negro Department',
        state_code: 'RN',
      },
      {
        id: 3207,
        name: 'Rivera Department',
        state_code: 'RV',
      },
      {
        id: 3216,
        name: 'Rocha Department',
        state_code: 'RO',
      },
      {
        id: 3220,
        name: 'Salto Department',
        state_code: 'SA',
      },
      {
        id: 3204,
        name: 'San José Department',
        state_code: 'SJ',
      },
      {
        id: 3219,
        name: 'Soriano Department',
        state_code: 'SO',
      },
      {
        id: 3221,
        name: 'Tacuarembó Department',
        state_code: 'TA',
      },
      {
        id: 3214,
        name: 'Treinta y Tres Department',
        state_code: 'TT',
      },
    ],
  },
  {
    name: 'Uzbekistan',
    iso3: 'UZB',
    iso2: 'UZ',
    phone_code: '998',
    capital: 'Tashkent',
    currency: 'UZS',
    states: [
      {
        id: 2540,
        name: 'Andijan Region',
        state_code: 'AN',
      },
      {
        id: 2541,
        name: 'Bukhara Region',
        state_code: 'BU',
      },
      {
        id: 2538,
        name: 'Fergana Region',
        state_code: 'FA',
      },
      {
        id: 2545,
        name: 'Jizzakh Region',
        state_code: 'JI',
      },
      {
        id: 2548,
        name: 'Karakalpakstan',
        state_code: 'QR',
      },
      {
        id: 2537,
        name: 'Namangan Region',
        state_code: 'NG',
      },
      {
        id: 2542,
        name: 'Navoiy Region',
        state_code: 'NW',
      },
      {
        id: 2543,
        name: 'Qashqadaryo Region',
        state_code: 'QA',
      },
      {
        id: 2544,
        name: 'Samarqand Region',
        state_code: 'SA',
      },
      {
        id: 2547,
        name: 'Sirdaryo Region',
        state_code: 'SI',
      },
      {
        id: 2546,
        name: 'Surxondaryo Region',
        state_code: 'SU',
      },
      {
        id: 2536,
        name: 'Tashkent',
        state_code: 'TK',
      },
      {
        id: 2549,
        name: 'Tashkent Region',
        state_code: 'TO',
      },
      {
        id: 2539,
        name: 'Xorazm Region',
        state_code: 'XO',
      },
    ],
  },
  {
    name: 'Vanuatu',
    iso3: 'VUT',
    iso2: 'VU',
    phone_code: '678',
    capital: 'Port Vila',
    currency: 'VUV',
    states: [
      {
        id: 4775,
        name: 'Malampa',
        state_code: 'MAP',
      },
      {
        id: 4773,
        name: 'Penama',
        state_code: 'PAM',
      },
      {
        id: 4776,
        name: 'Sanma',
        state_code: 'SAM',
      },
      {
        id: 4774,
        name: 'Shefa',
        state_code: 'SEE',
      },
      {
        id: 4777,
        name: 'Tafea',
        state_code: 'TAE',
      },
      {
        id: 4772,
        name: 'Torba',
        state_code: 'TOB',
      },
    ],
  },
  {
    name: 'Vatican City State (Holy See)',
    iso3: 'VAT',
    iso2: 'VA',
    phone_code: '379',
    capital: 'Vatican City',
    currency: 'EUR',
    states: [],
  },
  {
    name: 'Venezuela',
    iso3: 'VEN',
    iso2: 'VE',
    phone_code: '58',
    capital: 'Caracas',
    currency: 'VEF',
    states: [
      {
        id: 2044,
        name: 'Amazonas',
        state_code: 'Z',
      },
      {
        id: 2050,
        name: 'Anzoátegui',
        state_code: 'B',
      },
      {
        id: 2047,
        name: 'Aragua',
        state_code: 'D',
      },
      {
        id: 2049,
        name: 'Barinas',
        state_code: 'E',
      },
      {
        id: 2039,
        name: 'Bolívar',
        state_code: 'F',
      },
      {
        id: 2040,
        name: 'Carabobo',
        state_code: 'G',
      },
      {
        id: 2034,
        name: 'Cojedes',
        state_code: 'H',
      },
      {
        id: 2051,
        name: 'Delta Amacuro',
        state_code: 'Y',
      },
      {
        id: 2035,
        name: 'Falcón',
        state_code: 'I',
      },
      {
        id: 2046,
        name: 'Federal Dependencies of Venezuela',
        state_code: 'W',
      },
      {
        id: 2045,
        name: 'Guárico',
        state_code: 'J',
      },
      {
        id: 2038,
        name: 'Lara',
        state_code: 'K',
      },
      {
        id: 2053,
        name: 'Mérida',
        state_code: 'L',
      },
      {
        id: 2037,
        name: 'Miranda',
        state_code: 'M',
      },
      {
        id: 2054,
        name: 'Monagas',
        state_code: 'N',
      },
      {
        id: 2052,
        name: 'Nueva Esparta',
        state_code: 'O',
      },
      {
        id: 2036,
        name: 'Portuguesa',
        state_code: 'P',
      },
      {
        id: 2056,
        name: 'Sucre',
        state_code: 'R',
      },
      {
        id: 2048,
        name: 'Táchira',
        state_code: 'S',
      },
      {
        id: 2043,
        name: 'Trujillo',
        state_code: 'T',
      },
      {
        id: 2055,
        name: 'Vargas',
        state_code: 'X',
      },
      {
        id: 2041,
        name: 'Yaracuy',
        state_code: 'U',
      },
      {
        id: 2042,
        name: 'Zulia',
        state_code: 'V',
      },
    ],
  },
  {
    name: 'Vietnam',
    iso3: 'VNM',
    iso2: 'VN',
    phone_code: '84',
    capital: 'Hanoi',
    currency: 'VND',
    states: [
      {
        id: 3794,
        name: 'An Giang',
        state_code: '44',
      },
      {
        id: 3770,
        name: 'Bà Rịa-Vũng Tàu',
        state_code: '43',
      },
      {
        id: 3815,
        name: 'Bắc Giang',
        state_code: '54',
      },
      {
        id: 3822,
        name: 'Bắc Kạn',
        state_code: '53',
      },
      {
        id: 3804,
        name: 'Bạc Liêu',
        state_code: '55',
      },
      {
        id: 3791,
        name: 'Bắc Ninh',
        state_code: '56',
      },
      {
        id: 3796,
        name: 'Bến Tre',
        state_code: '50',
      },
      {
        id: 3785,
        name: 'Bình Dương',
        state_code: '57',
      },
      {
        id: 3830,
        name: 'Bình Định',
        state_code: '31',
      },
      {
        id: 3797,
        name: 'Bình Phước',
        state_code: '58',
      },
      {
        id: 3787,
        name: 'Bình Thuận',
        state_code: '40',
      },
      {
        id: 3778,
        name: 'Cà Mau',
        state_code: '59',
      },
      {
        id: 3782,
        name: 'Cao Bằng',
        state_code: '04',
      },
      {
        id: 3806,
        name: 'Da Nang',
        state_code: 'DN',
      },
      {
        id: 3829,
        name: 'Đắk Lắk',
        state_code: '33',
      },
      {
        id: 3823,
        name: 'Đắk Nông',
        state_code: '72',
      },
      {
        id: 3773,
        name: 'Điện Biên',
        state_code: '71',
      },
      {
        id: 3821,
        name: 'Đồng Nai',
        state_code: '39',
      },
      {
        id: 3769,
        name: 'Đồng Tháp',
        state_code: '45',
      },
      {
        id: 3813,
        name: 'Gia Lai',
        state_code: '30',
      },
      {
        id: 3779,
        name: 'Hà Giang',
        state_code: '03',
      },
      {
        id: 3802,
        name: 'Hà Nam',
        state_code: '63',
      },
      {
        id: 3820,
        name: 'Hà Tây',
        state_code: '15',
      },
      {
        id: 3816,
        name: 'Hà Tĩnh',
        state_code: '23',
      },
      {
        id: 3827,
        name: 'Hải Dương',
        state_code: '61',
      },
      {
        id: 3783,
        name: 'Haiphong',
        state_code: 'HP',
      },
      {
        id: 3810,
        name: 'Hanoi',
        state_code: 'HN',
      },
      {
        id: 3777,
        name: 'Hậu Giang',
        state_code: '73',
      },
      {
        id: 3811,
        name: 'Ho Chi Minh City',
        state_code: 'SG',
      },
      {
        id: 3799,
        name: 'Hòa Bình',
        state_code: '14',
      },
      {
        id: 3768,
        name: 'Hưng Yên',
        state_code: '66',
      },
      {
        id: 3793,
        name: 'Khánh Hòa',
        state_code: '34',
      },
      {
        id: 3800,
        name: 'Kiên Giang',
        state_code: '47',
      },
      {
        id: 3772,
        name: 'Kon Tum',
        state_code: '28',
      },
      {
        id: 3825,
        name: 'Lai Châu',
        state_code: '01',
      },
      {
        id: 3818,
        name: 'Lâm Đồng',
        state_code: '35',
      },
      {
        id: 3792,
        name: 'Lạng Sơn',
        state_code: '09',
      },
      {
        id: 3817,
        name: 'Lào Cai',
        state_code: '02',
      },
      {
        id: 3808,
        name: 'Long An',
        state_code: '41',
      },
      {
        id: 3789,
        name: 'Nam Định',
        state_code: '67',
      },
      {
        id: 3780,
        name: 'Nghệ An',
        state_code: '22',
      },
      {
        id: 3786,
        name: 'Ninh Bình',
        state_code: '18',
      },
      {
        id: 3788,
        name: 'Ninh Thuận',
        state_code: '36',
      },
      {
        id: 3801,
        name: 'Phú Thọ',
        state_code: '68',
      },
      {
        id: 3824,
        name: 'Phú Yên',
        state_code: '32',
      },
      {
        id: 3809,
        name: 'Quảng Bình',
        state_code: '24',
      },
      {
        id: 3776,
        name: 'Quảng Nam',
        state_code: '27',
      },
      {
        id: 3828,
        name: 'Quảng Ngãi',
        state_code: '29',
      },
      {
        id: 3814,
        name: 'Quảng Ninh',
        state_code: '13',
      },
      {
        id: 3803,
        name: 'Quảng Trị',
        state_code: '25',
      },
      {
        id: 3819,
        name: 'Sóc Trăng',
        state_code: '52',
      },
      {
        id: 3812,
        name: 'Sơn La',
        state_code: '05',
      },
      {
        id: 3826,
        name: 'Tây Ninh',
        state_code: '37',
      },
      {
        id: 3775,
        name: 'Thái Bình',
        state_code: '20',
      },
      {
        id: 3807,
        name: 'Thái Nguyên',
        state_code: '69',
      },
      {
        id: 3771,
        name: 'Thanh Hóa',
        state_code: '21',
      },
      {
        id: 3798,
        name: 'Thừa Thiên-Huế',
        state_code: '26',
      },
      {
        id: 3781,
        name: 'Tiền Giang',
        state_code: '46',
      },
      {
        id: 3805,
        name: 'Trà Vinh',
        state_code: '51',
      },
      {
        id: 3795,
        name: 'Tuyên Quang',
        state_code: '07',
      },
      {
        id: 3790,
        name: 'Vĩnh Long',
        state_code: '49',
      },
      {
        id: 3774,
        name: 'Vĩnh Phúc',
        state_code: '70',
      },
      {
        id: 3784,
        name: 'Yên Bái',
        state_code: '06',
      },
    ],
  },
  {
    name: 'Virgin Islands (British)',
    iso3: 'VGB',
    iso2: 'VG',
    phone_code: '+1-284',
    capital: 'Road Town',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Virgin Islands (US)',
    iso3: 'VIR',
    iso2: 'VI',
    phone_code: '+1-340',
    capital: 'Charlotte Amalie',
    currency: 'USD',
    states: [],
  },
  {
    name: 'Wallis And Futuna Islands',
    iso3: 'WLF',
    iso2: 'WF',
    phone_code: '681',
    capital: 'Mata Utu',
    currency: 'XPF',
    states: [],
  },
  {
    name: 'Western Sahara',
    iso3: 'ESH',
    iso2: 'EH',
    phone_code: '212',
    capital: 'El-Aaiun',
    currency: 'MAD',
    states: [],
  },
  {
    name: 'Yemen',
    iso3: 'YEM',
    iso2: 'YE',
    phone_code: '967',
    capital: 'Sanaa',
    currency: 'YER',
    states: [
      {
        id: 1242,
        name: "'Adan Governorate",
        state_code: 'AD',
      },
      {
        id: 1250,
        name: "'Amran Governorate",
        state_code: 'AM',
      },
      {
        id: 1237,
        name: 'Abyan Governorate',
        state_code: 'AB',
      },
      {
        id: 1240,
        name: "Al Bayda' Governorate",
        state_code: 'BA',
      },
      {
        id: 1241,
        name: 'Al Hudaydah Governorate',
        state_code: 'HU',
      },
      {
        id: 1243,
        name: 'Al Jawf Governorate',
        state_code: 'JA',
      },
      {
        id: 1251,
        name: 'Al Mahrah Governorate',
        state_code: 'MR',
      },
      {
        id: 1235,
        name: 'Al Mahwit Governorate',
        state_code: 'MW',
      },
      {
        id: 1246,
        name: 'Dhamar Governorate',
        state_code: 'DH',
      },
      {
        id: 1238,
        name: 'Hadhramaut Governorate',
        state_code: 'HD',
      },
      {
        id: 1244,
        name: 'Hajjah Governorate',
        state_code: 'HJ',
      },
      {
        id: 1233,
        name: 'Ibb Governorate',
        state_code: 'IB',
      },
      {
        id: 1245,
        name: 'Lahij Governorate',
        state_code: 'LA',
      },
      {
        id: 1234,
        name: "Ma'rib Governorate",
        state_code: 'MA',
      },
      {
        id: 1248,
        name: 'Raymah Governorate',
        state_code: 'RA',
      },
      {
        id: 1249,
        name: 'Saada Governorate',
        state_code: 'SD',
      },
      {
        id: 1232,
        name: "Sana'a",
        state_code: 'SA',
      },
      {
        id: 1236,
        name: "Sana'a Governorate",
        state_code: 'SN',
      },
      {
        id: 1247,
        name: 'Shabwah Governorate',
        state_code: 'SH',
      },
      {
        id: 1239,
        name: 'Socotra Governorate',
        state_code: 'SU',
      },
      {
        id: 1231,
        name: "Ta'izz Governorate",
        state_code: 'TA',
      },
    ],
  },
  {
    name: 'Zambia',
    iso3: 'ZMB',
    iso2: 'ZM',
    phone_code: '260',
    capital: 'Lusaka',
    currency: 'ZMK',
    states: [
      {
        id: 1986,
        name: 'Central Province',
        state_code: '02',
      },
      {
        id: 1984,
        name: 'Copperbelt Province',
        state_code: '08',
      },
      {
        id: 1991,
        name: 'Eastern Province',
        state_code: '03',
      },
      {
        id: 1987,
        name: 'Luapula Province',
        state_code: '04',
      },
      {
        id: 1988,
        name: 'Lusaka Province',
        state_code: '09',
      },
      {
        id: 1989,
        name: 'Muchinga Province',
        state_code: '10',
      },
      {
        id: 1982,
        name: 'Northern Province',
        state_code: '05',
      },
      {
        id: 1985,
        name: 'Northwestern Province',
        state_code: '06',
      },
      {
        id: 1990,
        name: 'Southern Province',
        state_code: '07',
      },
      {
        id: 1983,
        name: 'Western Province',
        state_code: '01',
      },
    ],
  },
  {
    name: 'Zimbabwe',
    iso3: 'ZWE',
    iso2: 'ZW',
    phone_code: '263',
    capital: 'Harare',
    currency: 'ZWL',
    states: [
      {
        id: 1956,
        name: 'Bulawayo Province',
        state_code: 'BU',
      },
      {
        id: 1958,
        name: 'Harare Province',
        state_code: 'HA',
      },
      {
        id: 1959,
        name: 'Manicaland',
        state_code: 'MA',
      },
      {
        id: 1955,
        name: 'Mashonaland Central Province',
        state_code: 'MC',
      },
      {
        id: 1951,
        name: 'Mashonaland East Province',
        state_code: 'ME',
      },
      {
        id: 1953,
        name: 'Mashonaland West Province',
        state_code: 'MW',
      },
      {
        id: 1960,
        name: 'Masvingo Province',
        state_code: 'MV',
      },
      {
        id: 1954,
        name: 'Matabeleland North Province',
        state_code: 'MN',
      },
      {
        id: 1952,
        name: 'Matabeleland South Province',
        state_code: 'MS',
      },
      {
        id: 1957,
        name: 'Midlands Province',
        state_code: 'MI',
      },
    ],
  },
];
const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];
const chartTypes = [
  'Intro',
  'ExecutiveSummary',
  'Fees',
  'Expenses',
  'FinancialPerformanceYTD',
  'YearlyFinancialPerformance',
  'BalanceSheet',
  'CashFlow',
  'Debtors',
  'ProjectPerformance',
  'WorkInHand',
  'Opportunities',
  'WorkGenerated',
  'FeeProjection',
  'ProjectedFinancialPerformance',
  'FinancialPerformanceProjDetail',
  'ProjectedCashFlow',
  'People',
  'KPI',
];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const fMonths = [6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5];
const enMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const years = [
  'All',
  2008,
  2009,
  2010,
  2011,
  2012,
  2013,
  2014,
  2015,
  2016,
  2017,
  2018,
  2019,
  2020,
  2021,
];
const dates = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
const single_years = [
  2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
  2021,
];
const daysRangeKeys = [
  'Outstanding30Days',
  'Outstanding60Days',
  'Outstanding90Days',
  'Outstanding120Days',
  'Outstanding120PlusDays',
];
const debtorKeyTextMap = {
  Outstanding30Days: '0-30',
  Outstanding60Days: '31-60',
  Outstanding90Days: '61-90',
  Outstanding120Days: '91-120',
  Outstanding120PlusDays: '120+',
  Total: 'All',
};
const debtorColors = {
  Outstanding30Days: '#cfe8ae',
  Outstanding60Days: '#eac0bd',
  Outstanding90Days: '#f37263',
  Outstanding120Days: '#d63128',
  Outstanding120PlusDays: '#be1e2d',
  Total: '#919698',
};
const daysRanges = {
  0: {
    key: 0,
    daysRange: 'Outstanding30Days',
    text: '0-30',
    color: '#cfe8ae',
  },
  1: {
    key: 1,
    daysRange: 'Outstanding60Days',
    text: '31-60',
    color: '#eac0bd',
  },
  2: {
    key: 2,
    daysRange: 'Outstanding90Days',
    text: '61-90',
    color: '#f37263',
  },
  3: {
    key: 3,
    daysRange: 'Outstanding120Days',
    text: '91-120',
    color: '#d63128',
  },
  4: {
    key: 4,
    daysRange: 'Outstanding120PlusDays',
    text: '120+',
    color: '#be1e2d',
  },
  5: {
    key: 5,
    daysRange: 'Total',
    text: 'Total',
    color: '#919698',
  },
};

const borderHoverColor = '#939393';
const minusActiveColor = '#be1e2d';
const minusDisableColor = '#e9cccf';
const positiveActiveColor = '#8dc63f';
const positiveDisableColor = '#e0ebd2';
const negativeActiveColor = '#919698';
const negativeDisableColor = '#e1e2e2';
const labelColor = '#000000';
const activeLabelColor = '#316ac5';

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 15 + ITEM_PADDING_TOP,
      width: 130,
    },
  },
};

const barHeight = 30;
const barThinHeight = 25;

const formatedStyle = (width, size, mar = 0, bolds = 0, cursors = '') => {
  return {
    width: `${width}px`,
    fontSize: `${size}px`,
    fontFamily: 'Verdana',
    margin: `${mar}`,
    paddingTop: '5px',
    fontWeight: `${bolds === 1 ? 'bold' : 'normal'}`,
    cursor: `${cursors}`,
  };
};

const subtitle_inv = {
  backgroundColor: '#e0ebd2',
};

const ship = (maxHeight) => {
  return {
    maxHeight: `${maxHeight}px`,
    overflowY: 'auto',
    borderBottom: 'solid 1px #ddd',
    borderTop: 'solid 1px #ddd',
    fontSize: '11px',
  };
};

const axis = {
  height: barHeight,
};

const thinAxis = {
  height: barThinHeight,
};

const tooltip = {
  minWidth: 40,
  maxWidth: 100,
  backgroundColor: 'white',
  color: 'black',
};

const item = {
  height: barHeight - 7,
  margin: 0,
  paddingTop: '7px',
  paddingLeft: '5px',
};

const thinItem = {
  height: barThinHeight + 'px',
  margin: 0,
  lineHeight: barThinHeight + 'px',
  paddingLeft: '5px',
};

const mediumItem = {
  height: barThinHeight + 'px',
  margin: 0,
  lineHeight: barThinHeight + 'px',
  paddingLeft: '5px',
  fontFamily: 'Verdana',
  fontSize: '12px',
};

const empty = (height) => {
  return {
    width: '100%',
    height: `${height}px`,
    lineHeight: `${height}px`,
    textAlign: 'center',
  };
};

const dashparts = [
  'In/Out',
  'Connected People',
  'Timesheets',
  'Debtors',
  'Utilisation',
  'Leave Schedule',
  'AllocatedTime',
  'NextTwoWeeks',
  'ProjectPerformance',
  'Milestone',
  'My Tasks',
  'Rate Table',
  'Invoice Report',
  'People Leave Balance',
  'Business Development',
  'Business Development Graph',
  'JobProfit',
  'MyPeople'
];

const timeparts = ['List View', 'Week View', 'Month View', "Admin View", 'Show Allocated Time Slider', 'Allow Copy Timesheet', 'TIL and Overtime']
const approvalparts = ['My Approvals', 'All Approvals']
const APparts = ['Manual', 'Email', 'Auto']
const invoiceparts = ['My Invoices', 'All Invoices']
const menuItems = ['Dashboard', 'People', 'Companies', 'Business Development', 'Projects', 'Task Manager', 'Time', 'Expenses', 'Leave Application', 'Forecasting', 'Invoicing', 'Accounts Payable', 'Reporting//Jobcost', 'Reporting//TimeReport','Reporting//NewJobcost', 'Approvals']
const listItems = [
  {tag_name: "Active", tag_color: '', font_color: ''},
  {tag_name: "InActive" , tag_color: '', font_color: ''},
  {tag_name: "Client", tag_color: '', font_color: ''},
  {tag_name: "Vendor", tag_color: '', font_color: ''},
  {tag_name: "Employee", tag_color: '', font_color: ''},
  {tag_name: "Contact", tag_color: '', font_color: ''},
  {tag_name: "Auto Approve Leave", tag_color: '', font_color: ''},
  {tag_name: "Auto Approve Expenses", tag_color: '', font_color: ''},
  {tag_name: "Auto Approve Timesheets", tag_color: '', font_color: ''},
]
const database = [
  {
    database: 'DimChartOfAccounts',
    tablename: 'DimChartOfAccounts',
    dimensions: [
      {
        key: 'ApiKey',
        name: 'ApiKey',
        type: 'text',
      },
      {
        key: 'AccountNumber',
        name: 'AccountNumber',
        type: 'text',
      },
      {
        key: 'AccountName',
        name: 'AccountName',
        type: 'text',
      },
      {
        key: 'AccountGLType',
        name: 'AccountGLType',
        type: 'text',
      },
      {
        key: 'AccountType',
        name: 'AccountType',
        type: 'text',
      },
      {
        key: 'AccountSubType',
        name: 'AccountSubType',
        type: 'text',
      },
      {
        key: 'CashFlowGroup',
        name: 'CashFlowGroup',
        type: 'text',
      },
      {
        key: 'CashFlowCategory',
        name: 'CashFlowCategory',
        type: 'text',
      },
      {
        key: 'Income',
        name: 'Income',
        type: 'boolean',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimClient',
    tablename: 'DimClient',
    dimensions: [
      {
        key: 'ClientName',
        name: 'ClientName',
        type: 'text',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'MarketSector',
        name: 'MarketSector',
        type: 'text',
      },
      {
        key: 'Linkedin',
        name: 'Linkedin',
        type: 'text',
      },
      {
        key: 'Website',
        name: 'Website',
        type: 'text',
      },
      {
        key: 'Twitter',
        name: 'Twitter',
        type: 'text',
      },
      {
        key: 'Image',
        name: 'Image',
        type: 'text',
      },
      {
        key: 'Address1',
        name: 'Address1',
        type: 'text',
      },
      {
        key: 'Address2',
        name: 'Address2',
        type: 'text',
      },
      {
        key: 'City',
        name: 'City',
        type: 'text',
      },
      {
        key: 'State',
        name: 'State',
        type: 'text',
      },
      {
        key: 'PostcodeZip',
        name: 'PostcodeZip',
        type: 'text',
      },
      {
        key: 'Country',
        name: 'Country',
        type: 'text',
      },
      {
        key: 'PaymentTerms',
        name: 'PaymentTerms',
        type: 'text',
      },
      {
        key: 'Notes',
        name: 'Notes',
        type: 'text',
      },
      {
        key: 'Log',
        name: 'Log',
        type: 'json',
      },
      {
        key: 'custom_fields',
        name: 'custom_fields',
        type: 'text',
      },
      {
        key: 'image_filename',
        name: 'image_filename',
        type: 'text',
      },
      {
        key: 'image_mimetype',
        name: 'image_mimetype',
        type: 'text',
      },
      {
        key: 'ApiSync',
        name: 'ApiSync',
        type: 'boolean',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimCompany',
    tablename: 'DimCompany',
    dimensions: [
      {
        key: 'CompanyCode',
        name: 'CompanyCode',
        type: 'text',
      },
      {
        key: 'CompanyName',
        name: 'CompanyName',
        type: 'text',
      },
      {
        key: 'FiscalYearStartMonth',
        name: 'FiscalYearStartMonth',
        type: 'number',
      },
      {
        key: 'SelectedApiType',
        name: 'SelectedApiType',
        type: 'text',
      },
      {
        key: 'StoreParams',
        name: 'StoreParams',
        type: 'text',
      },
      {
        key: 'MyobCompanyFiles',
        name: 'MyobCompanyFiles',
        type: 'text',
      },
      {
        key: 'email_creds',
        name: 'email_creds',
        type: 'text',
      },
      {
        key: 'Overhead',
        name: 'Overhead',
        type: 'number',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'DimCompanyRestKey',
    tablename: 'DimCompanyRestKey',
    dimensions: [
      {
        key: 'ApiType',
        name: 'ApiType',
        type: 'text',
      },
      {
        key: 'ApiAccounts',
        name: 'ApiAccounts',
        type: 'text',
      },
      {
        key: 'CompanyFileID',
        name: 'CompanyFileID',
        type: 'text',
      },
      {
        key: 'CompanyFileUsername',
        name: 'CompanyFileUsername',
        type: 'text',
      },
      {
        key: 'CompanyFilePassword',
        name: 'CompanyFilePassword',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'RunDate',
        name: 'RunDate',
        type: 'text',
      },
      {
        key: 'StoreParams',
        name: 'StoreParams',
        type: 'json',
      },
      {
        key: 'MyobCompanyFiles',
        name: 'MyobCompanyFiles',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimDate',
    tablename: 'DimDate',
    dimensions: [
      {
        key: 'DimDate',
        name: 'DimDate',
        type: 'date',
      },
      {
        key: 'Day',
        name: 'Day',
        type: 'number',
      },
      {
        key: 'DaySuffix',
        name: 'DaySuffix',
        type: 'text',
      },
      {
        key: 'Weekday',
        name: 'Weekday',
        type: 'number',
      },
      {
        key: 'WeekDayName',
        name: 'WeekDayName',
        type: 'text',
      },
      {
        key: 'IsWeekend',
        name: 'IsWeekend',
        type: 'boolean',
      },
      {
        key: 'IsHoliday',
        name: 'IsHoliday',
        type: 'boolean',
      },
      {
        key: 'DOWInMonth',
        name: 'DOWInMonth',
        type: 'number',
      },
      {
        key: 'DayOfYear',
        name: 'DayOfYear',
        type: 'number',
      },
      {
        key: 'WeekOfMonth',
        name: 'WeekOfMonth',
        type: 'number',
      },
      {
        key: 'WeekOfYear',
        name: 'WeekOfYear',
        type: 'number',
      },
      {
        key: 'ISOWeekOfYear',
        name: 'ISOWeekOfYear',
        type: 'number',
      },
      {
        key: 'Month',
        name: 'Month',
        type: 'number',
      },
      {
        key: 'MonthName',
        name: 'MonthName',
        type: 'text',
      },
      {
        key: 'Quarter',
        name: 'Quarter',
        type: 'number',
      },
      {
        key: 'QuarterName',
        name: 'QuarterName',
        type: 'text',
      },
      {
        key: 'Year',
        name: 'Year',
        type: 'number',
      },
      {
        key: 'MMYYYY',
        name: 'MMYYYY',
        type: 'text',
      },
      {
        key: 'MonthYear',
        name: 'MonthYear',
        type: 'text',
      },
      {
        key: 'FirstDayOfMonth',
        name: 'FirstDayOfMonth',
        type: 'date',
      },
      {
        key: 'LastDayOfMonth',
        name: 'LastDayOfMonth',
        type: 'date',
      },
      {
        key: 'FirstDayOfQuarter',
        name: 'FirstDayOfQuarter',
        type: 'date',
      },
      {
        key: 'LastDayOfQuarter',
        name: 'LastDayOfQuarter',
        type: 'date',
      },
      {
        key: 'FirstDayOfYear',
        name: 'FirstDayOfYear',
        type: 'date',
      },
      {
        key: 'LastDayOfYear',
        name: 'LastDayOfYear',
        type: 'date',
      },
      {
        key: 'FirstDayOfNextMonth',
        name: 'FirstDayOfNextMonth',
        type: 'date',
      },
      {
        key: 'FirstDayOfNextYear',
        name: 'FirstDayOfNextYear',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'DimEmailTemplateSetting',
    tablename: 'DimEmailTemplateSetting',
    dimensions: [
      {
        key: 'TimeSubmitEmail',
        name: 'TimeSubmitEmail',
        type: 'json',
      },
      {
        key: 'TimeSubmitHtml',
        name: 'TimeSubmitHtml',
        type: 'text',
      },
      {
        key: 'TimeSubmitSubject',
        name: 'TimeSubmitSubject',
        type: 'text',
      },
      {
        key: 'TimeApprovalEmail',
        name: 'TimeApprovalEmail',
        type: 'json',
      },
      {
        key: 'TimeApprovalHtml',
        name: 'TimeApprovalHtml',
        type: 'text',
      },
      {
        key: 'TimeApprovalSubject',
        name: 'TimeApprovalSubject',
        type: 'text',
      },
      {
        key: 'TimeRejectionEmail',
        name: 'TimeRejectionEmail',
        type: 'json',
      },
      {
        key: 'TimeRejectionHtml',
        name: 'TimeRejectionHtml',
        type: 'text',
      },
      {
        key: 'TimeRejectionSubject',
        name: 'TimeRejectionSubject',
        type: 'text',
      },
      {
        key: 'LeaveSubmitEmail',
        name: 'LeaveSubmitEmail',
        type: 'json',
      },
      {
        key: 'LeaveSubmitHtml',
        name: 'LeaveSubmitHtml',
        type: 'text',
      },
      {
        key: 'LeaveSubmitSubject',
        name: 'LeaveSubmitSubject',
        type: 'text',
      },
      {
        key: 'LeaveApprovalEmail',
        name: 'LeaveApprovalEmail',
        type: 'json',
      },
      {
        key: 'LeaveApprovalHtml',
        name: 'LeaveApprovalHtml',
        type: 'text',
      },
      {
        key: 'LeaveApprovalSubject',
        name: 'LeaveApprovalSubject',
        type: 'text',
      },
      {
        key: 'LeaveRejectionEmail',
        name: 'LeaveRejectionEmail',
        type: 'json',
      },
      {
        key: 'LeaveRejectionHtml',
        name: 'LeaveRejectionHtml',
        type: 'text',
      },
      {
        key: 'LeaveRejectionSubject',
        name: 'LeaveRejectionSubject',
        type: 'text',
      },
      {
        key: 'EmailSubmitEmail',
        name: 'EmailSubmitEmail',
        type: 'json',
      },
      {
        key: 'EmailSubmitHtml',
        name: 'EmailSubmitHtml',
        type: 'text',
      },
      {
        key: 'ExpenseSubmitSubject',
        name: 'ExpenseSubmitSubject',
        type: 'text',
      },
      {
        key: 'EmailApprovalEmail',
        name: 'EmailApprovalEmail',
        type: 'json',
      },
      {
        key: 'EmailApprovalHtml',
        name: 'EmailApprovalHtml',
        type: 'text',
      },
      {
        key: 'ExpenseApprovalSubject',
        name: 'ExpenseApprovalSubject',
        type: 'text',
      },
      {
        key: 'EmailRejectionEmail',
        name: 'EmailRejectionEmail',
        type: 'json',
      },
      {
        key: 'EmailRejectionHtml',
        name: 'EmailRejectionHtml',
        type: 'text',
      },
      {
        key: 'ExpenseRejectionSubject',
        name: 'ExpenseRejectionSubject',
        type: 'text',
      },
      {
        key: 'EmailInvoice',
        name: 'EmailInvoice',
        type: 'json',
      },
      {
        key: 'EmailInvoiceHtml',
        name: 'EmailInvoiceHtml',
        type: 'text',
      },
      {
        key: 'EmailInvoiceSubject',
        name: 'EmailInvoiceSubject',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimIntegrationLogs',
    tablename: 'DimIntegrationLogs',
    dimensions: [
      {
        key: 'api_type',
        name: 'api_type',
        type: 'text',
      },
      {
        key: 'function',
        name: 'function',
        type: 'text',
      },
      {
        key: 'parameter',
        name: 'parameter',
        type: 'text',
      },
      {
        key: 'payload',
        name: 'payload',
        type: 'text',
      },
      {
        key: 'response',
        name: 'response',
        type: 'text',
      },
      {
        key: 'success',
        name: 'success',
        type: 'boolean',
      },
      {
        key: 'date_submitted',
        name: 'date_submitted',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimInvoice',
    tablename: 'DimInvoice',
    dimensions: [
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'DueDate',
        name: 'DueDate',
        type: 'date',
      },
      {
        key: 'InvoiceNumber',
        name: 'InvoiceNumber',
        type: 'text',
      },
      {
        key: 'ApiKey',
        name: 'ApiKey',
        type: 'text',
      },
      {
        key: 'TaxCode',
        name: 'TaxCode',
        type: 'text',
      },
      {
        key: 'InvoiceContacts',
        name: 'InvoiceContacts',
        type: 'json',
      },
      {
        key: 'InvoicePath',
        name: 'InvoicePath',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'InvoiceNotes',
        name: 'InvoiceNotes',
        type: 'text',
      },
      {
        key: 'Labour',
        name: 'Labour',
        type: 'json',
      },
      {
        key: 'Fee',
        name: 'Fee',
        type: 'json',
      },
      {
        key: 'Units',
        name: 'Units',
        type: 'json',
      },
      {
        key: 'Expenses',
        name: 'Expenses',
        type: 'json',
      },
      {
        key: 'InvoiceConfig',
        name: 'InvoiceConfig',
        type: 'json',
      },
      {
        key: 'CreditNoteNumber',
        name: 'CreditNoteNumber',
        type: 'text',
      },
      {
        key: 'PaymentAmount',
        name: 'PaymentAmount',
        type: 'number',
      },
      {
        key: 'PaymentDate',
        name: 'PaymentDate',
        type: 'date',
      },
      {
        key: 'InvoiceTaskNotes',
        name: 'InvoiceTaskNotes',
        type: 'text',
      },
      {
        key: 'Annotation',
        name: 'Annotation',
        type: 'json',
      },
      {
        key: 'FormField',
        name: 'FormField',
        type: 'json',
      },
    ],
    metrics: [
      {
        key: 'InvoiceTotal',
        name: 'InvoiceTotal',
        type: 'number',
      },
      {
        key: 'TotalTax',
        name: 'TotalTax',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'ContactPeople',
        fkey: 'id',
      },
      {
        name: 'DimClient',
        pkey: 'ContactClient',
        fkey: 'id',
      },
      {
        name: 'templates',
        pkey: 'temp_id',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimKeyPerformanceIndicator',
    tablename: 'DimKeyPerformanceIndicator',
    dimensions: [
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'Category',
        name: 'Category',
        type: 'text',
      },
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'Priority',
        name: 'Priority',
        type: 'text',
      },
      {
        key: 'DesiredOutcome',
        name: 'DesiredOutcome',
        type: 'text',
      },
      {
        key: 'DisplayFormat',
        name: 'DisplayFormat',
        type: 'text',
      },
      {
        key: 'Enable',
        name: 'Enable',
        type: 'boolean',
      },
    ],
    metrics: [
      {
        key: 'Target',
        name: 'Target',
        type: 'text',
      },
      {
        key: 'PercentageTolerance',
        name: 'PercentageTolerance',
        type: 'number`',
      },
    ],
    linked_databases: [],
  },
  {
    database: 'DimLead',
    tablename: 'DimLead',
    dimensions: [
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'LeadType',
        name: 'LeadType',
        type: 'text',
      },
      {
        key: 'LeadSubType',
        name: 'LeadSubType',
        type: 'text',
      },
      {
        key: 'OpenedDate',
        name: 'OpenedDate',
        type: 'date',
      },
      {
        key: 'ClosedDate',
        name: 'ClosedDate',
        type: 'date',
      },
      {
        key: 'EstimatedStart',
        name: 'EstimatedStart',
        type: 'date',
      },
      {
        key: 'EstimatedFinish',
        name: 'EstimatedFinish',
        type: 'date',
      },
      {
        key: 'ReasonClosed',
        name: 'ReasonClosed',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'PotentialRevenue',
        name: 'PotentialRevenue',
        type: 'number',
      },
      {
        key: 'ProbabilityOfSuccess',
        name: 'ProbabilityOfSuccess',
        type: 'number',
      },
      {
        key: 'WeightedPotentialRevenue',
        name: 'WeightedPotentialRevenue',
        type: 'number',
      },
    ],
    linked_databases: [],
  },
  {
    database: 'DimList',
    tablename: 'DimList',
    dimensions: [
      {
        key: 'ListName',
        name: 'ListName',
        type: 'text',
      },
      {
        key: 'ListOption',
        name: 'ListOption',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'DimNextTwoWeeks',
    tablename: 'DimNextTwoWeeks',
    dimensions: [
      {
        key: 'InOutData',
        name: 'InOutData',
        type: 'text',
      },
      {
        key: 'ListOption',
        name: 'ListOption',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'DimOpportunity',
    tablename: 'DimOpportunity',
    dimensions: [
      {
        key: 'OpportunityId',
        name: 'OpportunityId',
        type: 'text',
      },
      {
        key: 'Image',
        name: 'Image',
        type: 'text',
      },
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'OpportunityType',
        name: 'OpportunityType',
        type: 'text',
      },
      {
        key: 'OpportunitySubType',
        name: 'OpportunitySubType',
        type: 'text',
      },
      {
        key: 'ReasonClosed',
        name: 'ReasonClosed',
        type: 'text',
      },
      {
        key: 'Tasks',
        name: 'Tasks',
        type: 'text',
      },
      {
        key: 'custom_fields',
        name: 'custom_fields',
        type: 'text',
      },
      {
        key: 'image_filename',
        name: 'image_filename',
        type: 'text',
      },
      {
        key: 'image_mimetype',
        name: 'image_mimetype',
        type: 'text',
      },
      {
        key: 'Color',
        name: 'Color',
        type: 'text',
      },
      {
        key: 'ProjectNotes',
        name: 'ProjectNotes',
        type: 'text',
      },
      {
        key: 'OpenedDate',
        name: 'OpenedDate',
        type: 'date',
      },
      {
        key: 'ClosedDate',
        name: 'ClosedDate',
        type: 'date',
      },
      {
        key: 'EstimatedStart',
        name: 'EstimatedStart',
        type: 'date',
      },
      {
        key: 'EstimatedFinish',
        name: 'EstimatedFinish',
        type: 'date',
      },
      {
        key: 'EnableNotes',
        name: 'EnableNotes',
        type: 'boolean',
      },
      {
        key: 'TaskNotes',
        name: 'TaskNotes',
        type: 'text',
      },
      {
        key: 'Log',
        name: 'Log',
        type: 'json',
      },
      {
        key: 'SimplePlan',
        name: 'SimplePlan',
        type: 'boolean',
      },
      {
        key: 'LinkedProject',
        name: 'LinkedProject',
        type: 'json',
      },
      {
        key: 'Resource',
        name: 'Resource',
        type: 'json',
      },
    ],
    metrics: [
      {
        key: 'PotentialRevenue',
        name: 'PotentialRevenue',
        type: 'number',
      },
      {
        key: 'ProbabilityOfSuccess',
        name: 'ProbabilityOfSuccess',
        type: 'number',
      },
      {
        key: 'WeightedPotentialRevenue',
        name: 'WeightedPotentialRevenue',
        type: 'number',
      },
      {
        key: 'ProjectTotalFee',
        name: 'ProjectTotalFee',
        type: 'number',
      },
      {
        key: 'ProjectLabourFee',
        name: 'ProjectLabourFee',
        type: 'number',
      },
      {
        key: 'ProjectConsultantFee',
        name: 'ProjectConsultantFee',
        type: 'number',
      },
      {
        key: 'ProjectExpenseFee',
        name: 'ProjectExpenseFee',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimOrdersQuotes',
    tablename: 'DimOrdersQuotes',
    dimensions: [
      {
        key: 'QuoteNumber',
        name: 'QuoteNumber',
        type: 'text',
      },
      {
        key: 'OrderNumber',
        name: 'OrderNumber',
        type: 'text',
      },
      {
        key: 'QuoteName',
        name: 'QuoteName',
        type: 'text',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'Reason',
        name: 'Reason',
        type: 'text',
      },
      {
        key: 'ValidUntil',
        name: 'ValidUntil',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'CreatedBy',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'CheckedBy',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimOrganisation',
    tablename: 'DimOrganisation',
    dimensions: [
      {
        key: 'OrgId',
        name: 'OrgId',
        type: 'text',
      },
      {
        key: 'OrgName',
        name: 'OrgName',
        type: 'text',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
      {
        key: 'FiscalYearStartMonth',
        name: 'FiscalYearStartMonth',
        type: 'number',
      },
    ],
    metrics: [
      {
        key: 'SafetyBankBalance',
        name: 'SafetyBankBalance',
        type: 'number',
      },
      {
        key: 'Upper',
        name: 'Upper',
        type: 'number',
      },
      {
        key: 'Lower',
        name: 'Lower',
        type: 'number',
      },
      {
        key: 'TargetMultipler',
        name: 'TargetMultipler',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimPeople',
    tablename: 'DimPeople',
    dimensions: [
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'InternalID',
        name: 'InternalID',
        type: 'text',
      },
      {
        key: 'ExternalSystemID',
        name: 'ExternalSystemID',
        type: 'text',
      },
      {
        key: 'Prefix',
        name: 'Prefix',
        type: 'text',
      },
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'FirstName',
        name: 'FirstName',
        type: 'text',
      },
      {
        key: 'LastName',
        name: 'LastName',
        type: 'text',
      },
      {
        key: 'Initials',
        name: 'Initials',
        type: 'text',
      },
      {
        key: 'Role',
        name: 'Role',
        type: 'text',
      },
      {
        key: 'IsAdminStaff',
        name: 'IsAdminStaff',
        type: 'boolean',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
      {
        key: 'ItemSort',
        name: 'ItemSort',
        type: 'boolean',
      },
      {
        key: 'Image',
        name: 'Image',
        type: 'text',
      },
      {
        key: 'Mobile',
        name: 'Mobile',
        type: 'text',
      },
      {
        key: 'WorkPhone',
        name: 'WorkPhone',
        type: 'text',
      },
      {
        key: 'Email',
        name: 'Email',
        type: 'text',
      },
      {
        key: 'Emag',
        name: 'Emag',
        type: 'boolean',
      },
      {
        key: 'Notes',
        name: 'Notes',
        type: 'text',
      },
      {
        key: 'DoNotSolicit',
        name: 'DoNotSolicit',
        type: 'boolean',
      },
      {
        key: 'Industry',
        name: 'Industry',
        type: 'text',
      },
      {
        key: 'Services',
        name: 'Services',
        type: 'text',
      },
      {
        key: 'Linkedin',
        name: 'Linkedin',
        type: 'text',
      },
      {
        key: 'Twitter',
        name: 'Twitter',
        type: 'text',
      },
      {
        key: 'Address1',
        name: 'Address1',
        type: 'text',
      },
      {
        key: 'Address2',
        name: 'Address2',
        type: 'text',
      },
      {
        key: 'Address3',
        name: 'Address3',
        type: 'text',
      },
      {
        key: 'City',
        name: 'City',
        type: 'text',
      },
      {
        key: 'State',
        name: 'State',
        type: 'text',
      },
      {
        key: 'PostcodeZip',
        name: 'PostcodeZip',
        type: 'text',
      },
      {
        key: 'Country',
        name: 'Country',
        type: 'text',
      },
      {
        key: 'PersonalEmail',
        name: 'PersonalEmail',
        type: 'text',
      },
      {
        key: 'DOB',
        name: 'DOB',
        type: 'date',
      },
      {
        key: 'MedicalNotes',
        name: 'MedicalNotes',
        type: 'text',
      },
      {
        key: 'PayrollType',
        name: 'PayrollType',
        type: 'text',
      },
      {
        key: 'EmployeementCondition',
        name: 'EmployeementCondition',
        type: 'text',
      },
      {
        key: 'ImportFromPayroll',
        name: 'ImportFromPayroll',
        type: 'boolean',
      },
      {
        key: 'ProbationEnd',
        name: 'ProbationEnd',
        type: 'date',
      },
      {
        key: 'ContactExpiry',
        name: 'ContactExpiry',
        type: 'date',
      },
      {
        key: 'WorkPatternType',
        name: 'WorkPatternType',
        type: 'text',
      },
      {
        key: 'InOutStatus',
        name: 'InOutStatus',
        type: 'json',
      },
      {
        key: 'custom_fields',
        name: 'custom_fields',
        type: 'json',
      },
      {
        key: 'AssignedTo',
        name: 'AssignedTo',
        type: 'number',
      },
      {
        key: 'Manager',
        name: 'Manager',
        type: 'number',
      },
      {
        key: 'image_filename',
        name: 'image_filename',
        type: 'text',
      },
      {
        key: 'image_mimetype',
        name: 'image_mimetype',
        type: 'text',
      },
      {
        key: 'ExternalSystemPayrollID',
        name: 'ExternalSystemPayrollID',
        type: 'text',
      },
      {
        key: 'ApprovalSetting',
        name: 'ApprovalSetting',
        type: 'text',
      },
      {
        key: 'ApiSync',
        name: 'ApiSync',
        type: 'text',
      },
      {
        key: 'Log',
        name: 'Log',
        type: 'json',
      },
      {
        key: 'Bio',
        name: 'Bio',
        type: 'text',
      },
      {
        key: 'general',
        name: 'general',
        type: 'relationship',
      },
      {
        key: 'education',
        name: 'education',
        type: 'relationship',
      },
      {
        key: 'skills',
        name: 'skills',
        type: 'relationship',
      },
      {
        key: 'kpis',
        name: 'kpis',
        type: 'relationship',
      },
      {
        key: 'reviews',
        name: 'reviews',
        type: 'relationship',
      },
      {
        key: 'training',
        name: 'training',
        type: 'relationship',
      },
      {
        key: 'salary_raises',
        name: 'salary_raises',
        type: 'relationship',
      },
      {
        key: 'rates',
        name: 'rates',
        type: 'relationship',
      },
      {
        key: 'work_pattern',
        name: 'work_pattern',
        type: 'relationship',
      },
      {
        key: 'people_leave',
        name: 'people_leave',
        type: 'relationship',
      },
      {
        key: 'contact_points',
        name: 'contact_points',
        type: 'relationship',
      },
    ],
    metrics: [
      {
        key: 'FteEquivalent',
        name: 'FteEquivalent',
        type: 'number',
      },
      {
        key: 'TargetUtilisationRation',
        name: 'TargetUtilisationRation',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimPlanHeader',
    tablename: 'DimPlanHeader',
    dimensions: [
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'PlanType',
        name: 'PlanType',
        type: 'text',
      },
      {
        key: 'StartDate',
        name: 'StartDate',
        type: 'date',
      },
      {
        key: 'EndDate',
        name: 'EndDate',
        type: 'date',
      },
      {
        key: 'Interval',
        name: 'Interval',
        type: 'text',
      },
      {
        key: 'DimPlanHeaderKey_ParentID',
        name: 'DimPlanHeaderKey_ParentID',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'boolean',
      },
      {
        key: 'CreateUser',
        name: 'CreateUser',
        type: 'text',
      },
      {
        key: 'ModUser',
        name: 'ModUser',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimProject',
    tablename: 'DimProject',
    dimensions: [
      {
        key: 'ProjectId',
        name: 'ProjectId',
        type: 'text',
      },
      {
        key: 'ProjectName',
        name: 'ProjectName',
        type: 'text',
      },
      {
        key: 'Image',
        name: 'Image',
        type: 'text',
      },
      {
        key: 'ProjectType',
        name: 'ProjectType',
        type: 'text',
      },
      {
        key: 'ProjectSubType',
        name: 'ProjectSubType',
        type: 'text',
      },
      {
        key: 'ProjectStatus',
        name: 'ProjectStatus',
        type: 'text',
      },
      {
        key: 'DimParentProjectKey',
        name: 'DimParentProjectKey',
        type: 'number',
      },
      {
        key: 'Tasks',
        name: 'Tasks',
        type: 'json',
      },
      {
        key: 'Overhead',
        name: 'Overhead',
        type: 'boolean',
      },
      {
        key: 'temp_id',
        name: 'temp_id',
        type: 'number',
      },
      {
        key: 'image_filename',
        name: 'image_filename',
        type: 'text',
      },
      {
        key: 'image_mimetype',
        name: 'image_mimetype',
        type: 'text',
      },
      {
        key: 'custom_fields',
        name: 'custom_fields',
        type: 'json',
      },
      {
        key: 'PurchaseOrderNumber',
        name: 'PurchaseOrderNumber',
        type: 'text',
      },
      {
        key: 'SiteCountry',
        name: 'SiteCountry',
        type: 'text',
      },
      {
        key: 'SiteState',
        name: 'SiteState',
        type: 'text',
      },
      {
        key: 'SiteCity',
        name: 'SiteCity',
        type: 'text',
      },
      {
        key: 'SiteAddress1',
        name: 'SiteAddress1',
        type: 'text',
      },
      {
        key: 'SiteAddress2',
        name: 'SiteAddress2',
        type: 'text',
      },
      {
        key: 'InvoiceNumber',
        name: 'InvoiceNumber',
        type: 'number',
      },
      {
        key: 'ProjectNotes',
        name: 'ProjectNotes',
        type: 'text',
      },
      {
        key: 'Color',
        name: 'Color',
        type: 'text',
      },
      {
        key: 'PaymentTerm',
        name: 'PaymentTerm',
        type: 'number',
      },
      {
        key: 'EnableNotes',
        name: 'EnableNotes',
        type: 'boolean',
      },
      {
        key: 'TaskNotes',
        name: 'TaskNotes',
        type: 'text',
      },
      {
        key: 'Log',
        name: 'Log',
        type: 'json',
      },
      {
        key: 'SimplePlan',
        name: 'SimplePlan',
        type: 'boolean',
      },
      {
        key: 'LinkedProject',
        name: 'LinkedProject',
        type: 'json',
      },
      {
        key: 'Resource',
        name: 'Resource',
        type: 'json',
      },
    ],
    metrics: [
      {
        key: 'ProjectTotalFee',
        name: 'ProjectTotalFee',
        type: 'number',
      },
      {
        key: 'ProjectLabourFee',
        name: 'ProjectLabourFee',
        type: 'number',
      },
      {
        key: 'ProjectConsultantFee',
        name: 'ProjectConsultantFee',
        type: 'number',
      },
      {
        key: 'ProjectExpenseFee',
        name: 'ProjectExpenseFee',
        type: 'number',
      },
      {
        key: 'ConstructionCost',
        name: 'ConstructionCost',
        type: 'number',
      },
      {
        key: 'PercentConstruction',
        name: 'PercentConstruction',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimProjectInvoiceConfig',
    tablename: 'DimProjectInvoiceConfig',
    dimensions: [
      {
        key: 'SavedOption',
        name: 'SavedOption',
        type: 'text',
      },
      {
        key: 'AutomaticInvoice',
        name: 'AutomaticInvoice',
        type: 'boolean',
      },
      {
        key: 'DetailedInvoice',
        name: 'DetailedInvoice',
        type: 'boolean',
      },
      {
        key: 'InvoiceFixedFee',
        name: 'InvoiceFixedFee',
        type: 'boolean',
      },
      {
        key: 'InvoiceLabour',
        name: 'InvoiceLabour',
        type: 'boolean',
      },
      {
        key: 'ShowDetailedTime',
        name: 'ShowDetailedTime',
        type: 'boolean',
      },
      {
        key: 'SelectRate',
        name: 'SelectRate',
        type: 'text',
      },
      {
        key: 'AutoConfig',
        name: 'AutoConfig',
        type: 'number',
      },
      {
        key: 'SpecificDay',
        name: 'SpecificDay',
        type: 'number',
      },
      {
        key: 'InvoiceDate',
        name: 'InvoiceDate',
        type: 'date',
      },
      {
        key: 'SelectedTax',
        name: 'SelectedTax',
        type: 'text',
      },
      {
        key: 'ProjectNotes',
        name: 'ProjectNotes',
        type: 'text',
      },
      {
        key: 'ImportTask',
        name: 'ImportTask',
        type: 'boolean',
      },
      {
        key: 'ImportActiveTask',
        name: 'ImportActiveTask',
        type: 'boolean',
      },
      {
        key: 'AutoPriorBilling',
        name: 'AutoPriorBilling',
        type: 'boolean',
      },
      {
        key: 'GroupByRoleName',
        name: 'GroupByRoleName',
        type: 'text',
      },
      {
        key: 'InvoiceExpenses',
        name: 'InvoiceExpenses',
        type: 'boolean',
      },
      {
        key: 'DetailedExpenses',
        name: 'DetailedExpenses',
        type: 'boolean',
      },
      {
        key: 'InvoiceUnits',
        name: 'InvoiceUnits',
        type: 'boolean',
      },
      {
        key: 'InvoiceTaskNotes',
        name: 'InvoiceTaskNotes',
        type: 'boolean',
      },
      {
        key: 'ActiveFees',
        name: 'ActiveFees',
        type: 'json',
      },
      {
        key: 'AccountFees',
        name: 'AccountFees',
        type: 'json',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
      {
        name: 'DimTemplate',
        pkey: 'DimTemplateKey',
        fkey: 'id',
      },
      {
        name: 'DimTemplate',
        pkey: 'DimCreditTemplateKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimProjectPdf',
    tablename: 'DimProjectPdf',
    dimensions: [
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'File',
        name: 'File',
        type: 'text',
      },
      {
        key: 'FileName',
        name: 'FileName',
        type: 'text',
      },
      {
        key: 'Annotation',
        name: 'Annotation',
        type: 'json',
      },
      {
        key: 'FormField',
        name: 'FormField',
        type: 'json',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimProjectRate',
    tablename: 'DimProjectRate',
    dimensions: [],
    metrics: [
      {
        key: 'TargetRate',
        name: 'TargetRate',
        type: 'number',
      },
      {
        key: 'BillingRate',
        name: 'BillingRate',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimRate',
        pkey: 'DimRateKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'DimProjectSubType',
    tablename: 'DimProjectSubType',
    dimensions: [
      {
        key: 'ProjectSubTypeCode',
        name: 'ProjectSubTypeCode',
        type: 'text',
      },
      {
        key: 'ProjectSubTypeDescription',
        name: 'ProjectSubTypeDescription',
        type: 'text',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'DimProjectType',
    tablename: 'DimProjectType',
    dimensions: [
      {
        key: 'ProjectTypeCode',
        name: 'ProjectTypeCode',
        type: 'text',
      },
      {
        key: 'ProjectTypeDescription',
        name: 'ProjectTypeDescription',
        type: 'text',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'DimProspect',
    tablename: 'DimProspect',
    dimensions: [
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'ProspectType',
        name: 'ProspectType',
        type: 'text',
      },
      {
        key: 'ProspectSubType',
        name: 'ProspectSubType',
        type: 'text',
      },
      {
        key: 'OpenedDate',
        name: 'OpenedDate',
        type: 'date',
      },
      {
        key: 'ClosedDate',
        name: 'ClosedDate',
        type: 'date',
      },
      {
        key: 'EstimatedStart',
        name: 'EstimatedStart',
        type: 'date',
      },
      {
        key: 'EstimatedFinish',
        name: 'EstimatedFinish',
        type: 'date',
      },
      {
        key: 'Notes',
        name: 'Notes',
        type: 'text',
      },
      {
        key: 'ReasonClosed',
        name: 'ReasonClosed',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'PotentialRevenue',
        name: 'PotentialRevenue',
        type: 'number',
      },
      {
        key: 'ProbabilityOfSuccess',
        name: 'ProbabilityOfSuccess',
        type: 'number',
      },
      {
        key: 'WeightedPotentialRevenue',
        name: 'WeightedPotentialRevenue',
        type: 'number',
      },
    ],
    linked_databases: [],
  },
  {
    database: 'DimRate',
    tablename: 'DimRate',
    dimensions: [
      {
        key: 'TableName',
        name: 'TableName',
        type: 'text',
      },
      {
        key: 'RoleName',
        name: 'RoleName',
        type: 'text',
      },
      {
        key: 'EffectiveDate',
        name: 'EffectiveDate',
        type: 'date',
      },
    ],
    metrics: [
      {
        key: 'TargetRate',
        name: 'TargetRate',
        type: 'number',
      },
      {
        key: 'BillingRate',
        name: 'BillingRate',
        type: 'number',
      },
    ],
    linked_databases: [],
  },
  {
    database: 'DimReport',
    tablename: 'DimReport',
    dimensions: [
      {
        key: 'ReportName',
        name: 'ReportName',
        type: 'text',
      },
      {
        key: 'ReportSettings',
        name: 'ReportSettings',
        type: 'json',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'DimSystemSettings',
    tablename: 'DimSystemSettings',
    dimensions: [
      {
        key: 'InvoiceNumber',
        name: 'InvoiceNumber',
        type: 'number',
      },
      {
        key: 'TaxCode',
        name: 'TaxCode',
        type: 'json',
      },
      {
        key: 'InOut',
        name: 'InOut',
        type: 'json',
      },
      {
        key: 'BdColumns',
        name: 'BdColumns',
        type: 'json',
      },
      {
        key: 'LeaveSetting',
        name: 'LeaveSetting',
        type: 'json',
      },
      {
        key: 'TimeApproval',
        name: 'TimeApproval',
        type: 'json',
      },
      {
        key: 'ExpenseApproval',
        name: 'ExpenseApproval',
        type: 'json',
      },
      {
        key: 'LeaveApproval',
        name: 'LeaveApproval',
        type: 'json',
      },
      {
        key: 'InvoiceApproval',
        name: 'InvoiceApproval',
        type: 'json',
      },
      {
        key: 'OrderApproval',
        name: 'OrderApproval',
        type: 'json',
      },
      {
        key: 'IconSetting',
        name: 'IconSetting',
        type: 'json',
      },
      {
        key: 'LeadingChar',
        name: 'LeadingChar',
        type: 'text',
      },
      {
        key: 'NumberLength',
        name: 'NumberLength',
        type: 'number',
      },
      {
        key: 'PaddingChar',
        name: 'PaddingChar',
        type: 'text',
      },
      {
        key: 'DefaultPaymentTerm',
        name: 'DefaultPaymentTerm',
        type: 'number',
      },
      {
        key: 'InvoiceNumberByProject',
        name: 'InvoiceNumberByProject',
        type: 'boolean',
      },
      {
        key: 'PayrollSetting',
        name: 'PayrollSetting',
        type: 'json',
      },
      {
        key: 'ProjectSetting',
        name: 'ProjectSetting',
        type: 'json',
      },
      {
        key: 'OpportunitySetting',
        name: 'OpportunitySetting',
        type: 'json',
      },
      {
        key: 'OrdersQuotesSetting',
        name: 'OrdersQuotesSetting',
        type: 'json',
      },
      {
        key: 'HolidaySetting',
        name: 'HolidaySetting',
        type: 'json',
      },
      {
        key: 'expense',
        name: 'expense',
        type: 'json',
      },
      {
        key: 'InvoiceAccounts',
        name: 'InvoiceAccounts',
        type: 'json',
      },
      {
        key: 'DateFormat',
        name: 'DateFormat',
        type: 'text',
      },
      {
        key: 'Currency',
        name: 'Currency',
        type: 'text',
      },
      {
        key: 'SelectedTax',
        name: 'SelectedTax',
        type: 'text',
      },
      {
        key: 'ProjectNotes',
        name: 'ProjectNotes',
        type: 'text',
      },
      {
        key: 'TaxPair',
        name: 'TaxPair',
        type: 'json',
      },
      {
        key: 'InviteEmail',
        name: 'InviteEmail',
        type: 'json',
      },
      {
        key: 'html',
        name: 'html',
        type: 'text',
      },
      {
        key: 'subject',
        name: 'subject',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'templates',
        pkey: 'DimTemplateKey',
        fkey: 'id',
      },
      {
        name: 'templates',
        pkey: 'DimCreditTemplateKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'EmailInbox',
    tablename: 'EmailInbox',
    dimensions: [
      {
        key: 'EmailID',
        name: 'EmailID',
        type: 'text',
      },
      {
        key: 'Sender',
        name: 'Sender',
        type: 'text',
      },
      {
        key: 'Subject',
        name: 'Subject',
        type: 'text',
      },
      {
        key: 'Attachment',
        name: 'Attachment',
        type: 'text',
      },
      {
        key: 'Content',
        name: 'Content',
        type: 'json',
      },
      {
        key: 'Body',
        name: 'Body',
        type: 'text',
      },
      {
        key: 'DateTime_Received',
        name: 'DateTime_Received',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'FactInvoiceExpense',
        pkey: 'FactInvoiceExpenseKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactApprovals',
    tablename: 'FactApprovals',
    dimensions: [
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'ApprovalID',
        name: 'ApprovalID',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'Notes',
        name: 'Notes',
        type: 'text',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Step',
        name: 'Step',
        type: 'json',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'AssignedTo',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactBusinessDevelopment',
    tablename: 'FactBusinessDevelopment',
    dimensions: [
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'Label',
        name: 'Label',
        type: 'text',
      },
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'Reason',
        name: 'Reason',
        type: 'text',
      },
      {
        key: 'IsLost',
        name: 'IsLost',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimLead',
        pkey: 'DimLeadKey',
        fkey: 'id',
      },
      {
        name: 'DimProspect',
        pkey: 'DimProspectKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimEmployeeKey',
        fkey: 'id',
      },
      {
        name: 'DimClient',
        pkey: 'DimClientKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactClientsContactsToProjects',
    tablename: 'FactClientsContactsToProjects',
    dimensions: [
      {
        key: 'SendBill',
        name: 'SendBill',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimClient',
        pkey: 'DimClientKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactContactPoints',
    tablename: 'FactContactPoints',
    dimensions: [
      {
        key: 'Notes',
        name: 'Notes',
        type: 'text',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimLead',
        pkey: 'DimLeadKey',
        fkey: 'id',
      },
      {
        name: 'DimProspect',
        pkey: 'DimProspectKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimClient',
        pkey: 'DimClientKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactFiles',
    tablename: 'FactFiles',
    dimensions: [
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'File',
        name: 'File',
        type: 'text',
      },
      {
        key: 'FileName',
        name: 'FileName',
        type: 'text',
      },
      {
        key: 'file_mimetype',
        name: 'file_mimetype',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactGeneralLedgerBalanceSheet',
    tablename: 'FactGeneralLedgerBalanceSheet',
    dimensions: [
      {
        key: 'BalanceSheetCategory',
        name: 'BalanceSheetCategory',
        type: 'text',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [
      {
        key: 'OpeningBalance',
        name: 'OpeningBalance',
        type: 'number',
      },
      {
        key: 'ClosingBalance',
        name: 'ClosingBalance',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
      {
        name: 'DimDate',
        pkey: 'DimDateKey',
        fkey: 'id',
      },
      {
        name: 'DimChartOfAccounts',
        pkey: 'DimChartOfAccountsKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactGeneralLedgerCashflow',
    tablename: 'FactGeneralLedgerCashflow',
    dimensions: [
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'boolean',
      },
    ],
    metrics: [
      {
        key: 'OpeningBalance',
        name: 'OpeningBalance',
        type: 'number',
      },
      {
        key: 'ClosingBalance',
        name: 'ClosingBalance',
        type: 'number',
      },
      {
        key: 'ChangeDuringPeriod',
        name: 'ChangeDuringPeriod',
        type: 'number',
      },
      {
        key: 'ForecastChangeDuringPeriod',
        name: 'ForecastChangeDuringPeriod',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
      {
        name: 'DimDate',
        pkey: 'DimDateKey',
        fkey: 'id',
      },
      {
        name: 'DimChartOfAccounts',
        pkey: 'DimChartOfAccountsKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactGeneralLedgerIncomeStatement',
    tablename: 'FactGeneralLedgerIncomeStatement',
    dimensions: [
      {
        key: 'IncomeStatementCategory',
        name: 'IncomeStatementCategory',
        type: 'text',
      },
      {
        key: 'IncomeStatementSubCategory',
        name: 'IncomeStatementSubCategory',
        type: 'text',
      },
      {
        key: 'IsActive',
        name: 'IsActive',
        type: 'number',
      },
    ],
    metrics: [
      {
        key: 'Actual',
        name: 'Actual',
        type: 'number',
      },
      {
        key: 'Budget',
        name: 'Budget',
        type: 'number',
      },
      {
        key: 'Forecast',
        name: 'Forecast',
        type: 'number',
      },
      {
        key: 'ActualVsBudgetVariance',
        name: 'ActualVsBudgetVariance',
        type: 'number',
      },
      {
        key: 'ActualVsForecastVariance',
        name: 'ActualVsForecastVariance',
        type: 'number',
      },
      {
        key: 'YTDActual',
        name: 'YTDActual',
        type: 'number',
      },
      {
        key: 'YTDBudget',
        name: 'YTDBudget',
        type: 'number',
      },
      {
        key: 'YTDForecast',
        name: 'YTDForecast',
        type: 'number',
      },
      {
        key: 'YTDActualVsBudgetVariance',
        name: 'YTDActualVsBudgetVariance',
        type: 'number',
      },
      {
        key: 'YTDActualVsForecastVariance',
        name: 'YTDActualVsForecastVariance',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
      {
        name: 'DimDate',
        pkey: 'DimDateKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactInvoiceExpense',
    tablename: 'FactInvoiceExpense',
    dimensions: [
      {
        key: 'ProjectTaskId',
        name: 'ProjectTaskId',
        type: 'number',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'BillStatus',
        name: 'BillStatus',
        type: 'boolean',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'DueDate',
        name: 'DueDate',
        type: 'date',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'LineItems',
        name: 'LineItems',
        type: 'text',
      },
      {
        key: 'Tax',
        name: 'Tax',
        type: 'text',
      },
      {
        key: 'TaxType',
        name: 'TaxType',
        type: 'text',
      },
      {
        key: 'APIKEY',
        name: 'APIKEY',
        type: 'text',
      },
      {
        key: 'CurrencyCode',
        name: 'CurrencyCode',
        type: 'text',
      },
      {
        key: 'InvoiceNumber',
        name: 'InvoiceNumber',
        type: 'text',
      },
      {
        key: 'Reason',
        name: 'Reason',
        type: 'text',
      },
      {
        key: 'Comments',
        name: 'Comments',
        type: 'text',
      },
      {
        key: 'Overhead',
        name: 'Overhead',
        type: 'boolean',
      },
      {
        key: 'ExpenseType',
        name: 'ExpenseType',
        type: 'text',
      },
      {
        key: 'Image',
        name: 'Image',
        type: 'text',
      },
      {
        key: 'ExpenseType',
        name: 'ExpenseType',
        type: 'text',
      },
      {
        key: 'image_filename',
        name: 'image_filename',
        type: 'text',
      },
      {
        key: 'image_mimetype',
        name: 'image_mimetype',
        type: 'text',
      },
      {
        key: 'Project',
        name: 'Project',
        type: 'text',
      },
      {
        key: 'Account',
        name: 'Account',
        type: 'text',
      },
      {
        key: 'APType',
        name: 'APType',
        type: 'text',
      },
      {
        key: 'DimAccountKey',
        name: 'DimAccountKey',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimClient',
        pkey: 'DimClientKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimInvoice',
        pkey: 'DimInvoiceKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactInvoiceFee',
    tablename: 'FactInvoiceFee',
    dimensions: [
      {
        key: 'ProjectTaskId',
        name: 'ProjectTaskId',
        type: 'text',
      },
      {
        key: 'Project',
        name: 'Project',
        type: 'text',
      },
      {
        key: 'FeeCategory',
        name: 'FeeCategory',
        type: 'text',
      },
      {
        key: 'Account',
        name: 'Account',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'Fee',
        name: 'Fee',
        type: 'number',
      },
      {
        key: 'PriorBilling',
        name: 'PriorBilling',
        type: 'number',
      },
      {
        key: 'ThisInvoice',
        name: 'ThisInvoice',
        type: 'number',
      },
      {
        key: 'PercentComplete',
        name: 'PercentComplete',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimInvoice',
        pkey: 'DimInvoiceKey',
        fkey: 'id',
      },
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimProjectInvoiceConfig',
        pkey: 'DimConfigKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactKeyPerformanceIndicatorResults',
    tablename: 'FactKeyPerformanceIndicatorResults',
    dimensions: [],
    metrics: [
      {
        key: 'Target',
        name: 'Target',
        type: 'number',
      },
      {
        key: 'Result',
        name: 'Result',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
      {
        name: 'DimDate',
        pkey: 'DimDateKey',
        fkey: 'id',
      },
      {
        name: 'DimKeyPerformanceIndicator',
        pkey: 'DimKeyPerformanceIndicatorKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactLeaveApplication',
    tablename: 'FactLeaveApplication',
    dimensions: [
      {
        key: 'LeaveTypeID',
        name: 'LeaveTypeID',
        type: 'text',
      },
      {
        key: 'Title',
        name: 'Title',
        type: 'text',
      },
      {
        key: 'StartDate',
        name: 'StartDate',
        type: 'date',
      },
      {
        key: 'EndDate',
        name: 'EndDate',
        type: 'date',
      },
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'Days',
        name: 'Days',
        type: 'json',
      },
      {
        key: 'Hours',
        name: 'Hours',
        type: 'number',
      },
      {
        key: 'File',
        name: 'File',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleCompanies',
    tablename: 'FactPeopleCompanies',
    dimensions: [
      {
        key: 'Role',
        name: 'Role',
        type: 'text',
      },
      {
        key: 'ExternalSystemID',
        name: 'ExternalSystemID',
        type: 'text',
      },
      {
        key: 'Index',
        name: 'Index',
        type: 'text',
      },
      {
        key: 'DateStartedInRole',
        name: 'DateStartedInRole',
        type: 'date',
      },
      {
        key: 'DateFinishedInRole',
        name: 'DateFinishedInRole',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimClient',
        pkey: 'DimClientKey',
        fkey: 'id',
      },
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleEducation',
    tablename: 'FactPeopleEducation',
    dimensions: [
      {
        key: 'DegreeQualification',
        name: 'DegreeQualification',
        type: 'date',
      },
      {
        key: 'Year',
        name: 'Year',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleHolidays',
    tablename: 'FactPeopleHolidays',
    dimensions: [
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'Color',
        name: 'Color',
        type: 'text',
      },
      {
        key: 'FontColor',
        name: 'FontColor',
        type: 'text',
      },
      {
        key: 'Default',
        name: 'Default',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleKPIs',
    tablename: 'FactPeopleKPIs',
    dimensions: [
      {
        key: 'DateFrom',
        name: 'DateFrom',
        type: 'date',
      },
      {
        key: 'DateTo',
        name: 'DateTo',
        type: 'date',
      },
      {
        key: 'KPI',
        name: 'KPI',
        type: 'text',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'Target',
        name: 'Target',
        type: 'number',
      },
      {
        key: 'Actual',
        name: 'Actual',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleLeave',
    tablename: 'FactPeopleLeave',
    dimensions: [
      {
        key: 'LeaveName',
        name: 'LeaveName',
        type: 'text',
      },
      {
        key: 'LeaveTypeID',
        name: 'LeaveTypeID',
        type: 'text',
      },
      {
        key: 'TypeOfUnits',
        name: 'TypeOfUnits',
        type: 'text',
      },
      {
        key: 'InOutStatus',
        name: 'InOutStatus',
        type: 'text',
      },
      {
        key: 'AutoApprove',
        name: 'AutoApprove',
        type: 'boolean',
      },
    ],
    metrics: [
      {
        key: 'Balance',
        name: 'Balance',
        type: 'number',
      },
      {
        key: 'AccrualRate',
        name: 'AccrualRate',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleReviews',
    tablename: 'FactPeopleReviews',
    dimensions: [
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'Outcomes',
        name: 'Outcomes',
        type: 'text',
      },
      {
        key: 'Note',
        name: 'Note',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'ManagerScore',
        name: 'ManagerScore',
        type: 'number',
      },
      {
        key: 'EmployeeScore',
        name: 'EmployeeScore',
        type: 'number',
      },
      {
        key: 'AverageScore',
        name: 'AverageScore',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleSkills',
    tablename: 'FactPeopleSkills',
    dimensions: [
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'Expiry',
        name: 'Expiry',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPeopleTraining',
    tablename: 'FactPeopleTraining',
    dimensions: [
      {
        key: 'Course',
        name: 'Course',
        type: 'text',
      },
      {
        key: 'Link',
        name: 'Link',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'ApprovedBy',
        name: 'ApprovedBy',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'Cost',
        name: 'Cost',
        type: 'text',
      },
    ],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPlanDetail',
    tablename: 'FactPlanDetail',
    dimensions: [
      {
        key: 'ParentKey',
        name: 'ParentKey',
        type: 'number',
      },
      {
        key: 'Interval_ID',
        name: 'Interval_ID',
        type: 'number',
      },
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'Employee',
        name: 'Employee',
        type: 'text',
      },
      {
        key: 'OpeningSituation',
        name: 'OpeningSituation',
        type: 'text',
      },
      {
        key: 'Milestone',
        name: 'Milestone',
        type: 'text',
      },
      {
        key: 'CreateUser',
        name: 'CreateUser',
        type: 'text',
      },
      {
        key: 'ModUser',
        name: 'ModUser',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'Percent_complete',
        name: 'Percent_complete',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
      {
        name: 'DimPlanHeader',
        pkey: 'DimPlanHeaderKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactPlanSummary',
    tablename: 'FactPlanSummary',
    dimensions: [
      {
        key: 'ParentKey',
        name: 'ParentKey',
        type: 'number',
      },
      {
        key: 'Interval_ID',
        name: 'Interval_ID',
        type: 'number',
      },
      {
        key: 'Description',
        name: 'Description',
        type: 'text',
      },
      {
        key: 'MeasureTarget',
        name: 'MeasureTarget',
        type: 'text',
      },
      {
        key: 'CurrentStatus',
        name: 'CurrentStatus',
        type: 'text',
      },
      {
        key: 'CreateUser',
        name: 'CreateUser',
        type: 'text',
      },
      {
        key: 'ModUser',
        name: 'ModUser',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'BaseLineRating',
        name: 'BaseLineRating',
        type: 'number',
      },
      {
        key: 'CurrentRating',
        name: 'CurrentRating',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimOrganisation',
        pkey: 'DimOrganisationKey',
        fkey: 'id',
      },
      {
        name: 'DimPlanHeader',
        pkey: 'DimPlanHeaderKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactProjectForecasts',
    tablename: 'FactProjectForecasts',
    dimensions: [
      {
        key: 'ProjectTaskId',
        name: 'ProjectTaskId',
        type: 'number',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'Amount',
        name: 'Amount',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactProjectMilestones',
    tablename: 'FactProjectMilestones',
    dimensions: [
      {
        key: 'ProjectTaskId',
        name: 'ProjectTaskId',
        type: 'number',
      },
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Color',
        name: 'Color',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactResourceForecasts',
    tablename: 'FactResourceForecasts',
    dimensions: [
      {
        key: 'ProjectTaskId',
        name: 'ProjectTaskId',
        type: 'number',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'Hours',
        name: 'Hours',
        type: 'number',
      },
      {
        key: 'CostAmount',
        name: 'CostAmount',
        type: 'number',
      },
      {
        key: 'RawCostAmount',
        name: 'RawCostAmount',
        type: 'number',
      },
      {
        key: 'BillingAmount',
        name: 'BillingAmount',
        type: 'number',
      },
      {
        key: 'TargetAmount',
        name: 'TargetAmount',
        type: 'number',
      },
      {
        key: 'CostRate',
        name: 'CostRate',
        type: 'number',
      },
      {
        key: 'RawCostRate',
        name: 'RawCostRate',
        type: 'number',
      },
      {
        key: 'BillingRate',
        name: 'BillingRate',
        type: 'number',
      },
      {
        key: 'TargetRate',
        name: 'TargetRate',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
      {
        name: 'DimClient',
        pkey: 'DimClientKey',
        fkey: 'id',
      },
      {
        name: 'DimRate',
        pkey: 'DimRateKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactTask',
    tablename: 'FactTask',
    dimensions: [
      {
        key: 'ProjectTaskId',
        name: 'ProjectTaskId',
        type: 'number',
      },
      {
        key: 'Project',
        name: 'Project',
        type: 'text',
      },
      {
        key: 'Title',
        name: 'Title',
        type: 'text',
      },
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'Completed',
        name: 'Completed',
        type: 'boolean',
      },
      {
        key: 'DateCompleted',
        name: 'DateCompleted',
        type: 'date',
      },
      {
        key: 'DueDate',
        name: 'DueDate',
        type: 'date',
      },
    ],
    metrics: [
      {
        key: 'Progress',
        name: 'Progress',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactTeam',
    tablename: 'FactTeam',
    dimensions: [
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'Email',
        name: 'Email',
        type: 'text',
      },
      {
        key: 'SendBill',
        name: 'SendBill',
        type: 'boolean',
      },
      {
        key: 'InvoiceAddress',
        name: 'InvoiceAddress',
        type: 'boolean',
      },
      {
        key: 'NameOnInvoice',
        name: 'NameOnInvoice',
        type: 'boolean',
      },
      {
        key: 'Role',
        name: 'Role',
        type: 'text',
      },
      {
        key: 'ParentId',
        name: 'ParentId',
        type: 'number',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimClient',
        pkey: 'DimClientKey',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactTime',
    tablename: 'FactTime',
    dimensions: [
      {
        key: 'ProjectTaskId',
        name: 'ProjectTaskId',
        type: 'text',
      },
      {
        key: 'Date',
        name: 'Date',
        type: 'date',
      },
      {
        key: 'Project',
        name: 'Project',
        type: 'text',
      },
      {
        key: 'Variation',
        name: 'Variation',
        type: 'boolean',
      },
      {
        key: 'Comment',
        name: 'Comment',
        type: 'text',
      },
      {
        key: 'Status',
        name: 'Status',
        type: 'text',
      },
      {
        key: 'FeeType',
        name: 'FeeType',
        type: 'text',
      },
      {
        key: 'Overhead',
        name: 'Overhead',
        type: 'number',
      },
      {
        key: 'Account',
        name: 'Account',
        type: 'text',
      },
      {
        key: 'BillStatus',
        name: 'BillStatus',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'Hours',
        name: 'Hours',
        type: 'number',
      },
      {
        key: 'Overtime',
        name: 'Overtime',
        type: 'number',
      },
      {
        key: 'Til',
        name: 'Til',
        type: 'number',
      },
      {
        key: 'RawCost',
        name: 'RawCost',
        type: 'number',
      },
      {
        key: 'Cost',
        name: 'Cost',
        type: 'number',
      },
      {
        key: 'Target',
        name: 'Target',
        type: 'number',
      },
      {
        key: 'Billing',
        name: 'Billing',
        type: 'number',
      },
      {
        key: 'TotalRawCost',
        name: 'TotalRawCost',
        type: 'number',
      },
      {
        key: 'TotalCost',
        name: 'TargetCost',
        type: 'number',
      },
      {
        key: 'TotalTarget',
        name: 'TotalTarget',
        type: 'number',
      },
      {
        key: 'TotalBilling',
        name: 'TotalBilling',
        type: 'number',
      },
      {
        key: 'WOHours',
        name: 'WOHours',
        type: 'number',
      },
      {
        key: 'WOOvertime',
        name: 'WOOvertime',
        type: 'number',
      },
      {
        key: 'WOTil',
        name: 'WOTil',
        type: 'number',
      },
      {
        key: 'WOTotalRawCost',
        name: 'WOTotalRawCost',
        type: 'number',
      },
      {
        key: 'WOTotalCost',
        name: 'WOTotalCost',
        type: 'number',
      },
      {
        key: 'WOTotalTarget',
        name: 'WOTotalTarget',
        type: 'number',
      },
      {
        key: 'WOTotalBilling',
        name: 'WOTotalBilling',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimOpportunity',
        pkey: 'DimOpportunityKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactWorkGenerated',
    tablename: 'FactWorkGenerated',
    dimensions: [
      {
        key: 'ExistingOrNew',
        name: 'ExistingOrNew',
        type: 'text',
      },
    ],
    metrics: [
      {
        key: 'ProjectFee',
        name: 'ProjectFee',
        type: 'number',
      },
      {
        key: 'WorkGeneratedAmount',
        name: 'WorkGeneratedAmount',
        type: 'number',
      },
    ],
    linked_databases: [
      {
        name: 'DimProject',
        pkey: 'DimProjectKey',
        fkey: 'id',
      },
      {
        name: 'DimDate',
        pkey: 'DimDateKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'FactWorkPattern',
    tablename: 'FactWorkPattern',
    dimensions: [
      {
        key: 'Mon',
        name: 'Mon',
        type: 'text',
      },
      {
        key: 'Tue',
        name: 'Tue',
        type: 'text',
      },
      {
        key: 'Wed',
        name: 'Wed',
        type: 'text',
      },
      {
        key: 'Thu',
        name: 'Thu',
        type: 'text',
      },
      {
        key: 'Fri',
        name: 'Fri',
        type: 'text',
      },
      {
        key: 'Sat',
        name: 'Sat',
        type: 'text',
      },
      {
        key: 'Sun',
        name: 'Sun',
        type: 'text',
      },
      {
        key: 'Total',
        name: 'Total',
        type: 'text',
      },
      {
        key: 'InOut',
        name: 'InOut',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'ReportConfig',
    tablename: 'ReportConfig',
    dimensions: [
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'Name',
        name: 'Name',
        type: 'text',
      },
      {
        key: 'Options',
        name: 'Options',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'SettingAccounts',
    tablename: 'SettingAccounts',
    dimensions: [
      {
        key: 'Type',
        name: 'Type',
        type: 'text',
      },
      {
        key: 'Username',
        name: 'Username',
        type: 'text',
      },
      {
        key: 'Password',
        name: 'Password',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'CompanyPageLayout',
    tablename: 'CompanyPageLayout',
    dimensions: [
      {
        key: 'company_id',
        name: 'company_id',
        type: 'number',
      },
      {
        key: 'page_name',
        name: 'page_name',
        type: 'text',
      },
      {
        key: 'layout',
        name: 'layout',
        type: 'json',
      },
      {
        key: 'all_access',
        name: 'all_access',
        type: 'boolean',
      },
      {
        key: 'standard',
        name: 'standard',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'CompanyUser',
    tablename: 'CompanyUser',
    dimensions: [
      {
        key: 'user_id',
        name: 'user_id',
        type: 'number',
      },
      {
        key: 'role_id',
        name: 'role_id',
        type: 'number',
      },
      {
        key: 'dashboard',
        name: 'dashboard',
        type: 'json',
      },
      {
        key: 'last_rating_date',
        name: 'last_rating_date',
        type: 'date',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'Role',
        pkey: 'role_id',
        fkey: 'id',
      },
      {
        name: 'DimPeople',
        pkey: 'DimPeopleKey',
        fkey: 'id',
      },
      {
        name: 'DimCompany',
        pkey: 'DimCompanyKey',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'CustomPages',
    tablename: 'CustomPages',
    dimensions: [
      {
        key: 'record_name',
        name: 'record_name',
        type: 'text',
      },
      {
        key: 'field_data',
        name: 'field_data',
        type: 'text',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'PeoplePageLayout',
    tablename: 'PeoplePageLayout',
    dimensions: [
      {
        key: 'page_name',
        name: 'page_name',
        type: 'text',
      },
      {
        key: 'layout',
        name: 'layout',
        type: 'json',
      },
      {
        key: 'all_access',
        name: 'all_access',
        type: 'boolean',
      },
      {
        key: 'standard',
        name: 'standard',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'company_id',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'ProjectPageLayout',
    tablename: 'ProjectPageLayout',
    dimensions: [
      {
        key: 'page_name',
        name: 'page_name',
        type: 'text',
      },
      {
        key: 'layout',
        name: 'layout',
        type: 'json',
      },
      {
        key: 'all_access',
        name: 'all_access',
        type: 'boolean',
      },
      {
        key: 'standard',
        name: 'standard',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'company_id',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'Role',
    tablename: 'Role',
    dimensions: [
      {
        key: 'name',
        name: 'name',
        type: 'text',
      },
      {
        key: 'accesses',
        name: 'accesses',
        type: 'json',
      },
      {
        key: 'dashboard_access',
        name: 'dashboard_access',
        type: 'json',
      },
      {
        key: 'people_access',
        name: 'people_access',
        type: 'json',
      },
      {
        key: 'company_access',
        name: 'company_access',
        type: 'json',
      },
      {
        key: 'business_access',
        name: 'business_access',
        type: 'json',
      },
      {
        key: 'project_access',
        name: 'project_access',
        type: 'json',
      },
      {
        key: 'job_report_access',
        name: 'job_report_access',
        type: 'json',
      },
      {
        key: 'business_report_access',
        name: 'business_report_access',
        type: 'json',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
  {
    database: 'SettingsPageLayout',
    tablename: 'SettingsPageLayout',
    dimensions: [
      {
        key: 'page_name',
        name: 'page_name',
        type: 'text',
      },
      {
        key: 'layout',
        name: 'layout',
        type: 'json',
      },
      {
        key: 'all_access',
        name: 'all_access',
        type: 'boolean',
      },
      {
        key: 'standard',
        name: 'standard',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimCompany',
        pkey: 'company_id',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'tasks',
    tablename: 'tasks',
    dimensions: [
      {
        key: 'title',
        name: 'title',
        type: 'text',
      },
      {
        key: 'type',
        name: 'type',
        type: 'text',
      },
      {
        key: 'progress',
        name: 'progress',
        type: 'number',
      },
      {
        key: 'completed',
        name: 'completed',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [
      {
        name: 'DimPeople',
        pkey: 'user_id',
        fkey: 'id',
      },
    ],
  },
  {
    database: 'Template',
    tablename: 'Template',
    dimensions: [
      {
        key: 'name',
        name: 'name',
        type: 'text',
      },
      {
        key: 'template_path',
        name: 'template_path',
        type: 'text',
      },
      {
        key: 'template_type',
        name: 'template_type',
        type: 'text',
      },
      {
        key: 'template_active',
        name: 'template_active',
        type: 'text',
      },
      {
        key: 'template_setting',
        name: 'template_setting',
        type: 'text',
      },
      {
        key: 'template_property',
        name: 'template_property',
        type: 'text',
      },
      {
        key: 'currency_name',
        name: 'currency_name',
        type: 'boolean',
      },
      {
        key: 'currency_symbol',
        name: 'currency_symbol',
        type: 'boolean',
      },
      {
        key: 'thousand_format',
        name: 'thousand_format',
        type: 'boolean',
      },
    ],
    metrics: [],
    linked_databases: [],
  },
];

export {
  areaData,
  countries, 
  chartTypes,
  months,
  fMonths,
  enMonths,
  menuItems,
  years,
  dates,
  single_years,
  daysRangeKeys,
  debtorKeyTextMap,
  debtorColors,
  daysRanges,
  borderHoverColor,
  positiveActiveColor,
  positiveDisableColor,
  negativeActiveColor,
  negativeDisableColor,
  labelColor,
  activeLabelColor,
  minusActiveColor,
  minusDisableColor,
  subtitle_inv,
  formatedStyle,
  MenuProps,
  barHeight,
  barThinHeight,
  ship,
  axis,
  thinAxis,
  tooltip,
  item,
  thinItem,
  mediumItem,
  empty,
  dashparts,
  timeparts,
  database,
  approvalparts,
  APparts,
  invoiceparts, 
  listItems
};
